<template>
  <div class="w-full lg:grid lg:grid-cols-1 min-h-[100vh] pt-14 sm:pt-0">
    <div class="flex items-center justify-center py-12 sm:pt-[150px]">
      <div class="mx-auto grid w-full p-4 sm:p-0 sm:w-[390px] gap-14">
        <div class="grid gap-2 text-center">
          <div class="flex-col sm:flex-row flex items-center gap-3 mx-auto">
            <img v-if="tenantFavicon" :src="tenantFavicon.bucketLocation" height="38" width="38" class="" />
            <h1 class="text-3xl font-bold">
              Área de Membros
            </h1>
          </div>
          <!-- <h1 class="text-3xl font-bold">
            Login
          </h1> -->
          <p class="text-muted-foreground">
            Digite seu email e senha abaixo para fazer login
          </p>
        </div>
        <div class="grid gap-4">
          <div class="grid gap-2">
            <Label for="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="ex: email@exemplo.com"
              required
              v-model="loginPayload.email"
            />
          </div>
          <div class="grid gap-2">
            <div class="flex items-center">
              <Label for="password">Senha</Label>
              <RouterLink
                :to="{ name: 'auth.password-recovery' }"
                class="ml-auto inline-block text-sm"
              >
                Esqueceu sua senha?
              </RouterLink>
            </div>
            <Input id="password" type="password" required v-model="loginPayload.password" />
          </div>
          <Button type="submit" class="w-full py-6 mt-6" :disabled="isAuthLoading"
            @click="login(
              loginPayload,
              () => {
                if (isTradersPlatform) {
                  $router.push({ name: 'trader.home' })
                } else {
                  $router.push({ name: 'home' })
                }
              },
              () => toast({
                title: 'Login falhou',
                description: 'credenciais inválidas!',
                variant: 'destructive'
              })
            )"
          > 
            <Loader2 v-if="isAuthLoading" class="w-4 h-4 mr-2 animate-spin" />
            Entrar
          </Button>
        </div>
        <div class="mt-4 text-center text-sm">
          Não tem uma conta?
          <RouterLink :to="{ name: 'register' }" class="underline">
            Cadastrar-se
          </RouterLink>
        </div>
      </div>
    </div>
    <p v-if="isTradersPlatform" class="text-center text-xs pb-10">© 2024 Traders Cup Brasil 29.435.748/0001-07. Todos os direitos reservados.</p>
    <!-- <div class="hidden bg-muted lg:block">
      <img
        src="https://www.shadcn-vue.com/placeholder.svg"
        alt="Image"
        width="1920"
        height="1080"
        class="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
      >
    </div> -->
  </div>
  <Toaster />
</template>

<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/toast/use-toast'
import { Toaster } from '@/components/ui/toast'
import { onMounted, ref } from 'vue'
import { Loader2 } from 'lucide-vue-next'
import { useAuth } from '@/composables/useAuth'
import { useTenant } from '@/composables/useTenant'

const { toast } = useToast()
const loginPayload = ref({ email: '', password: '' })
const { login, isAuthLoading } = useAuth()
const { tenantFavicon, initializeTenant, isTradersPlatform } = useTenant()

onMounted(() => {
  initializeTenant()
})
</script>