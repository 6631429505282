import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex items-center space-x-4" }

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { CardDescription, CardTitle } from '@/components/ui/card';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useAuth } from '@/composables/useAuth';
import { useDate } from '@/composables/useDate';
import { ClockIcon, Handshake, MoreHorizontalIcon, Trash2 } from 'lucide-vue-next';

import { useTenant } from '@/composables/useTenant'
import { AclEnum } from '@/enums/acl/AclEnum'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileComponent',
  props: {
    topic: {},
    hideDropdown: { type: Boolean }
  },
  emits: ['delete'],
  setup(__props: any) {

const { tenant } = useTenant()
const { hasRoles } = useAuth()

const { userAuthenticated } = useAuth()

const props = __props
const { timeAgo } = useDate()

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        to: { name: 'profile', params: { profileId: _ctx.topic.authorId } }
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Avatar), null, {
            default: _withCtx(() => [
              (_ctx.topic.author.avatar)
                ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                    key: 0,
                    src: _ctx.topic.author.avatar.bucketLocation,
                    alt: _ctx.topic.author.name
                  }, null, 8, ["src", "alt"]))
                : _createCommentVNode("", true),
              _createVNode(_unref(AvatarFallback), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.topic.author?.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", null, [
        _createVNode(_component_RouterLink, {
          class: "flex gap-2",
          to: { name: 'profile', params: { profileId: _ctx.topic.authorId} }
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), { class: "text-md font-semibold text-gray-100" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.topic.author?.name), 1)
              ]),
              _: 1
            }),
            (_ctx.topic.author.isTrader)
              ? (_openBlock(), _createBlock(_unref(Handshake), {
                  key: 0,
                  class: "text-green-500 w-5 h-5 relative",
                  title: "Trader Verificado"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_unref(CardDescription), { class: "text-xs text-gray-400 flex items-center" }, {
          default: _withCtx(() => [
            _createVNode(_unref(ClockIcon), { class: "h-3 w-3 mr-1" }),
            _createTextVNode(" " + _toDisplayString(_unref(timeAgo)(_ctx.topic.createdAt)), 1)
          ]),
          _: 1
        })
      ])
    ]),
    (!_ctx.hideDropdown && (_ctx.topic.authorId == _unref(userAuthenticated).userId || (_unref(userAuthenticated)?.roles.find((role) => role === 'PRODUCER') && _unref(tenant)?.id === 1 || _unref(hasRoles)([_unref(AclEnum).ADMIN_MEMBER_AREA]))))
      ? (_openBlock(), _createBlock(_unref(DropdownMenu), { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_unref(DropdownMenuTrigger), { asChild: "" }, {
              default: _withCtx(() => [
                _createVNode(_unref(Button), {
                  variant: "ghost",
                  size: "icon"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(MoreHorizontalIcon), {
                      class: "h-5 w-5",
                      "stroke-width": 1.25
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(DropdownMenuContent), { class: "w-26" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DropdownMenuItem), {
                  class: "text-red-500",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Trash2), {
                      class: "mr-2 h-4 w-4",
                      "stroke-width": 1.25
                    }),
                    _cache[1] || (_cache[1] = _createElementVNode("span", null, "Excluír", -1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})