import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full max-w-[1700px]"
}
const _hoisted_2 = { class: "grid md:grid-cols-12 gap-6" }
const _hoisted_3 = { class: "col-span-6 space-y-4" }
const _hoisted_4 = { class: "space-x-1" }
const _hoisted_5 = { class: "flex items-center gap-3" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "grid gap-4 py-4" }
const _hoisted_8 = { class: "grid gap-4 py-4" }
const _hoisted_9 = { class: "flex gap-2 items-center" }

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { Loader2, CalendarIcon, PlusCircle, Trophy, XCircle, AlertTriangle, Pencil, Trash2 } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { onMounted, ref } from 'vue'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { TournamentStatusEnum, tournamentStatusLabels } from "@/enums/tournaments/TournamentStatusEnum"
import { format } from 'date-fns'
import { useForm } from 'vee-validate'
import { ptBR } from 'date-fns/locale'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Tournament } from '@/interfaces/tournaments/Tournament'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { TournamentPrizeTypeEnum, tournamentPrizeTypeEnumLabels } from '@/enums/tournaments/prizes/TournamentPrizeTypeEnum'
import { AxiosResponse } from 'axios'
import { TournamentPrize } from '@/interfaces/tournaments/TournamentPrize'
import { CalendarDate } from '@internationalized/date'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { TournamentParticipant } from '@/interfaces/tournaments/TournamentParticipant'
import { Pagination } from '@/interfaces/Pagination'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Variant } from '@/interfaces/products/Variant'
import { useVariant } from '@/composables/useVariant'
import { useTenant } from '@/composables/useTenant'


export default /*@__PURE__*/_defineComponent({
  __name: 'EditTournamentView',
  props: {
    tournamentId: {}
  },
  setup(__props: any) {

function formatDate(date: Date | null) {
  return date ? format(date, 'dd/MM/yyyy', { locale: ptBR }) : 'Selecione uma data'
}

const { getVariantsByProductId } = useVariant()
const variants = ref<Variant[]>()
const props = __props
const isCreatingTournament = ref(false)
const axios = useAxios()
const tournament = ref<Tournament>()
const formSchema = z.object({
  name: z.string({ required_error: "Este campo é obrigatório" }).min(3, "O nome deve ter pelo menos 3 caracteres"),
  description: z.string().optional(),
  variantId: z.any(),
  status: z.nativeEnum(TournamentStatusEnum, { required_error: "Este campo é obrigatório" }),
  startAt: z.date({ required_error: "Este campo é obrigatório" }),
  endAt: z.date({ required_error: "Este campo é obrigatório" }),
  prizes: z.array(
    z.object({
      type: z.enum(Object.values(TournamentPrizeTypeEnum) as [string, ...string[]]),
      amount: z.number().optional(),
      position: z.number().int(),
      description: z.string().optional(),
    })
  ).default([])
}).refine((data) => data.endAt > data.startAt, {
  message: "A data de término deve ser posterior à data de início",
  path: ["endAt"],
});
const isPrizeFormOpen = ref(false)
const newPrize = ref<{ type: string, amount?: number, position?: number, description: string }>({
  type: '',
  amount: undefined,
  position: undefined,
  description: ''
})
const isDeletingPrize = ref(false)
const isDeletePrizeConfirmationDialogOpen = ref(false)
const selectedPrizeToDeleteId = ref<number>()
const { handleSubmit, setFieldValue, values, setValues } = useForm({
  validationSchema: toTypedSchema(formSchema),
})
const isUpdatingPrize = ref(false)
const isUpdatePrizeDialogOpen = ref(false)
const prizeToUpdate = ref<TournamentPrize>()
const tournamentParticipants = ref<Pagination<TournamentParticipant>>()
const { tenant } = useTenant()

const onSubmit = handleSubmit((values) => {
  updateTournament(values)
})

function openPrizeForm() {
  isPrizeFormOpen.value = true
}

function addPrize() {
  const updatedPrizes = [
    ...(values.prizes ?? []),
    { ...newPrize.value, position: newPrize.value.position! }
  ].sort((a, b) => a.position - b.position);

  setFieldValue('prizes', updatedPrizes)
  newPrize.value = { type: '', amount: undefined, position: undefined, description: '' }
  isPrizeFormOpen.value = false
}

function removePrize(index: number) {
  setFieldValue('prizes', values.prizes?.filter((_, i) => i !== index) ?? [])
}

function updateTournament(payload: z.infer<typeof formSchema>) {
  isCreatingTournament.value = true
  axios.patch(`/tournaments/${tournament.value!.id}`, {
    ...payload,
    startAt: format(payload.startAt, 'yyyy-MM-dd'),
    endAt: format(payload.endAt, 'yyyy-MM-dd'),
  })
    .then(() => {
      toast({
        title: 'Torneio editado com sucesso!',
        class: 'bg-green-500 text-white'
      })
    })
    .catch(() => {
      toast({
        title: 'Erro ao editar torneio',
        description: 'Verifique os dados e tente novamente',
        variant: 'destructive'
      })
    })
    .finally(() => isCreatingTournament.value = false)
}

function getTournament() {
  axios.get(`/tournaments/${props.tournamentId}`)
    .then((res: AxiosResponse<Tournament>) => {
      setValues({
        name: res.data.name,
        description: res.data.description ?? '',
        status: res.data.status,
        startAt: new Date(res.data.startAt),
        endAt: new Date(res.data.endAt),
        prizes: [],
        variantId: String(res.data.variantId)
      })
      tournament.value = res.data
    })
}

function updatePrize() {
  isUpdatingPrize.value = true

  axios.patch(`/tournaments/${tournament.value!.id}/prizes/${prizeToUpdate.value!.id}`, {
    type: prizeToUpdate.value!.type,
    amount: prizeToUpdate.value!.amount ? prizeToUpdate.value!.amount : null,
    position: prizeToUpdate.value!.position,
    description: prizeToUpdate.value!.description,
  })
    .then(() => {
      toast({
        title: 'Prêmio editado com sucesso',
        class: 'bg-green-500 text-white'
      })
      isUpdatePrizeDialogOpen.value = false
    })
    .finally(() => {
      isUpdatingPrize.value = false
    })
}

function deletePrize(id: number) {
  isDeletingPrize.value = true

  axios.delete(`/tournaments/${tournament.value!.id}/prizes/${id}`)
    .then(() => {
      toast({
        title: 'Torneio excluído com sucesso',
        class: 'bg-green-500 text-white'
      })

      tournament.value!.prizes = tournament.value!.prizes.filter(p => p.id !== id)
    })
    .finally(() => {
      isDeletingPrize.value = false
      isDeletePrizeConfirmationDialogOpen.value = false
    })
}

function getTournamentParticipants() {
  axios.get(`/tournaments/${props.tournamentId}/participants`)
    .then((res) => {
      tournamentParticipants.value = res.data
    })
}

onMounted(() => {
  getVariantsByProductId(tenant.value!.productId!, tenant.value!.organizationId).then(value => {
    variants.value = value
  })
  getTournament()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (tournament.value)
      ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)), ["prevent"])),
            class: "space-y-8"
          }, [
            _cache[27] || (_cache[27] = _createElementVNode("div", { class: "space-y-2" }, [
              _createElementVNode("h1", { class: "text-[1.1rem] font-bold tracking-tighter text-[#000] dark:text-white" }, "Editar Torneio"),
              _createElementVNode("p", { class: "text-sm text-[#666] dark:text-[#888]" }, "Crie um novo torneio para engajar participantes")
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(Card), { class: "space-y-4 col-span-6 p-6" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(FormField), { name: "name" }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(FormItem), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                              _createTextVNode("Nome do Torneio")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(FormControl), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Input), _mergeProps({
                                type: "text",
                                placeholder: "Digite o nome do torneio"
                              }, field), null, 16)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_unref(FormMessage))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(FormField), { name: "description" }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(FormItem), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                            default: _withCtx(() => _cache[17] || (_cache[17] = [
                              _createTextVNode("Descrição")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(FormControl), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Textarea), _mergeProps({
                                "default-value": tournament.value.description,
                                placeholder: "Digite uma breve descrição do torneio"
                              }, field), null, 16, ["default-value"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_unref(FormMessage))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(FormField), { name: "variantId" }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(FormItem), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                            default: _withCtx(() => _cache[18] || (_cache[18] = [
                              _createTextVNode("Variante de Produto")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(FormControl), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Select), _mergeProps({
                                "default-value": String(tournament.value.variantId)
                              }, field), {
                                default: _withCtx(() => [
                                  _createVNode(_unref(SelectTrigger), {
                                    id: "variantId",
                                    "aria-label": "Selecionar variante"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_unref(SelectContent), null, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(variants.value, (variant) => {
                                        return (_openBlock(), _createBlock(_unref(SelectItem), {
                                          key: variant.id,
                                          value: String(variant.id)
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(variant.title), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1040, ["default-value"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_unref(FormMessage))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(FormField), { name: "status" }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(FormItem), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode("Status do Torneio")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(FormControl), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Select), _mergeProps({
                                "default-value": tournament.value.status
                              }, field), {
                                default: _withCtx(() => [
                                  _createVNode(_unref(SelectTrigger), {
                                    id: "status",
                                    "aria-label": "Selecionar status"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_unref(SelectContent), null, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(TournamentStatusEnum), (status) => {
                                        return (_openBlock(), _createBlock(_unref(SelectItem), {
                                          key: status,
                                          value: status
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(tournamentStatusLabels)[status]), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1040, ["default-value"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_unref(FormMessage))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(FormField), { name: "startAt" }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(FormItem), { class: "flex flex-col" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                              _createTextVNode("Data de Início")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(Popover), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(PopoverTrigger), { asChild: "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(FormControl), null, {
                                    default: _withCtx(() => [
                                      _createVNode(_unref(Button), {
                                        variant: "outline",
                                        class: _normalizeClass([
                        'w-full pl-3 text-left font-normal',
                        !field.value && 'text-muted-foreground'
                      ])
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(formatDate(field.value)) + " ", 1),
                                          _createVNode(_unref(CalendarIcon), { class: "ml-auto h-4 w-4 opacity-50" })
                                        ]),
                                        _: 2
                                      }, 1032, ["class"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(PopoverContent), {
                                class: "w-auto p-0",
                                align: "start"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(Calendar), {
                                    mode: "single",
                                    selected: field.value,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = (date) => {
                      const constructedDate = new Date(date.year, date.month - 1, date.day);
                      _unref(setFieldValue)('startAt', constructedDate);
                    }),
                                    "disabled-dates": { before: new Date() },
                                    initialFocus: "",
                                    locale: 'pt-BR'
                                  }, null, 8, ["selected", "disabled-dates"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_unref(FormMessage))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(FormField), { name: "endAt" }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(FormItem), { class: "flex flex-col" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                            default: _withCtx(() => _cache[21] || (_cache[21] = [
                              _createTextVNode("Data de Término")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(Popover), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(PopoverTrigger), { asChild: "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(FormControl), null, {
                                    default: _withCtx(() => [
                                      _createVNode(_unref(Button), {
                                        variant: "outline",
                                        class: _normalizeClass([
                        'w-full pl-3 text-left font-normal',
                        !field.value && 'text-muted-foreground'
                      ])
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(formatDate(field.value)) + " ", 1),
                                          _createVNode(_unref(CalendarIcon), { class: "ml-auto h-4 w-4 opacity-50" })
                                        ]),
                                        _: 2
                                      }, 1032, ["class"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(PopoverContent), {
                                class: "w-auto p-0",
                                align: "start"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(Calendar), {
                                    mode: "single",
                                    selected: field.value,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = (date) => {
                      const constructedDate = new Date(date.year, date.month - 1, date.day);
                      _unref(setFieldValue)('endAt', constructedDate);
                    }),
                                    "disabled-dates": { before: new Date() },
                                    initialFocus: "",
                                    locale: 'pt-BR'
                                  }, null, 8, ["selected", "disabled-dates"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_unref(FormMessage))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(Button), {
                    disabled: isCreatingTournament.value,
                    type: "submit",
                    class: "w-full text-white dark:text-[#000] font-medium py-2 px-4 rounded-md transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#000] dark:focus:ring-white focus:ring-opacity-50"
                  }, {
                    default: _withCtx(() => [
                      (isCreatingTournament.value)
                        ? (_openBlock(), _createBlock(_unref(Loader2), {
                            key: 0,
                            class: "w-4 h-4 mr-2 animate-spin"
                          }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(isCreatingTournament.value ? 'Salvando...' : 'Salvar'), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(Card), { class: "bg-card" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(CardHeader)),
                    _createVNode(_unref(CardContent), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Tabs), {
                          "default-value": "prizes",
                          class: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(TabsList), { class: "grid w-full grid-cols-2" }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(TabsTrigger), { value: "prizes" }, {
                                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                                    _createTextVNode(" Prêmios ")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(TabsTrigger), {
                                  value: "participants",
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (!tournamentParticipants.value ? getTournamentParticipants() : ''))
                                }, {
                                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                                    _createTextVNode(" Participantes ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(TabsContent), { value: "prizes" }, {
                              default: _withCtx(() => [
                                _createVNode(_TransitionGroup, {
                                  name: "prize-list",
                                  tag: "ul",
                                  class: "space-y-2"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tournament.value.prizes, (prize, index) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        key: index,
                                        class: "flex justify-between items-center p-2 bg-secondary rounded-md"
                                      }, [
                                        _createVNode(_unref(Trophy), {
                                          class: "size-7 stroke-yellow-500",
                                          "stroke-width": 1.25
                                        }),
                                        _createElementVNode("span", null, _toDisplayString(_unref(tournamentPrizeTypeEnumLabels)[prize.type as TournamentPrizeTypeEnum]) + " - " + _toDisplayString(prize.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'brl' })) + " (" + _toDisplayString(prize.position) + "º lugar)", 1),
                                        _createElementVNode("div", _hoisted_4, [
                                          _createVNode(_unref(Button), {
                                            variant: "ghost",
                                            size: "icon",
                                            onClick: _withModifiers(($event: any) => {prizeToUpdate.value = prize; isUpdatePrizeDialogOpen.value = true}, ["prevent"])
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_unref(Pencil), { class: "h-4 w-4" })
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]),
                                          _createVNode(_unref(Button), {
                                            variant: "ghost",
                                            size: "icon",
                                            onClick: _withModifiers(($event: any) => {selectedPrizeToDeleteId.value = prize.id; isDeletePrizeConfirmationDialogOpen.value = true}, ["prevent"])
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_unref(Trash2), { class: "w-4 h-4" })
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"])
                                        ])
                                      ]))
                                    }), 128)),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(values).prizes, (prize, index) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        key: index,
                                        class: "flex justify-between items-center p-2 bg-secondary rounded-md"
                                      }, [
                                        _createVNode(_unref(Trophy), {
                                          class: "size-7 stroke-yellow-500",
                                          "stroke-width": 1.25
                                        }),
                                        _createElementVNode("span", null, _toDisplayString(_unref(tournamentPrizeTypeEnumLabels)[prize.type as TournamentPrizeTypeEnum]) + " - " + _toDisplayString(prize.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'brl' })) + " (" + _toDisplayString(prize.position) + "º lugar)", 1),
                                        _createVNode(_unref(Button), {
                                          variant: "ghost",
                                          size: "icon",
                                          onClick: _withModifiers(($event: any) => (removePrize(index)), ["prevent"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_unref(XCircle), { class: "h-4 w-4" })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(Button), {
                                  onClick: _withModifiers(openPrizeForm, ["prevent"]),
                                  class: "w-full mt-4"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(PlusCircle), { class: "w-4 h-4 mr-2" }),
                                    _cache[24] || (_cache[24] = _createTextVNode(" Adicionar Novo Prêmio "))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(TabsContent), { value: "participants" }, {
                              default: _withCtx(() => [
                                (tournamentParticipants.value)
                                  ? (_openBlock(), _createBlock(_unref(Table), { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(TableHeader), null, {
                                          default: _withCtx(() => [
                                            _createVNode(_unref(TableRow), null, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(TableHead), null, {
                                                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                                                    _createTextVNode("Perfil")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_unref(TableBody), null, {
                                          default: _withCtx(() => [
                                            (tournamentParticipants.value.data.length)
                                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(tournamentParticipants.value.data, (participant, index) => {
                                                  return (_openBlock(), _createBlock(_unref(TableRow), {
                                                    key: participant.id,
                                                    class: "transition-all hover:bg-gray-700/50"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_unref(TableCell), null, {
                                                        default: _withCtx(() => [
                                                          _createElementVNode("div", _hoisted_5, [
                                                            _createVNode(_unref(Avatar), null, {
                                                              default: _withCtx(() => [
                                                                (participant.profile.avatar)
                                                                  ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                                                                      key: 0,
                                                                      src: participant.profile.avatar?.bucketLocation,
                                                                      alt: participant.profile.name
                                                                    }, null, 8, ["src", "alt"]))
                                                                  : _createCommentVNode("", true),
                                                                _createVNode(_unref(AvatarFallback), null, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(participant.profile.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
                                                                  ]),
                                                                  _: 2
                                                                }, 1024)
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createElementVNode("div", null, [
                                                              _createElementVNode("div", _hoisted_6, _toDisplayString(participant.profile.name), 1)
                                                            ])
                                                          ])
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                }), 128))
                                              : (_openBlock(), _createBlock(_unref(TableRow), { key: 1 }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(TableCell), {
                                                      colspan: "5",
                                                      class: "text-center font-medium py-8"
                                                    }, {
                                                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                                                        _createTextVNode(" Sem participantes. ")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ])
          ], 32)
        ]))
      : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
    _createVNode(_unref(Dialog), {
      open: isPrizeFormOpen.value,
      "onUpdate:open": _cache[8] || (_cache[8] = ($event: any) => (isPrizeFormOpen.value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(DialogContent), { class: "sm:max-w-[425px]" }, {
          default: _withCtx(() => [
            _createVNode(_unref(DialogHeader), null, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogTitle), null, {
                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                    _createTextVNode("Adicionar Novo Prêmio")
                  ])),
                  _: 1
                }),
                _createVNode(_unref(DialogDescription), null, {
                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                    _createTextVNode("Preencha os detalhes do prêmio abaixo.")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("form", {
              onSubmit: _withModifiers(addPrize, ["prevent"])
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_unref(FormField), { name: "type" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormLabel), null, {
                          default: _withCtx(() => _cache[30] || (_cache[30] = [
                            _createTextVNode("Tipo de Prêmio")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(Select), {
                          modelValue: newPrize.value.type,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((newPrize.value.type) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(SelectTrigger), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(SelectValue), { placeholder: "Selecione o tipo" })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(SelectContent), null, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_unref(TournamentPrizeTypeEnum)), (type) => {
                                  return (_openBlock(), _createBlock(_unref(SelectItem), {
                                    key: type,
                                    value: type
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(tournamentPrizeTypeEnumLabels)[type]), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(FormField), { name: "amount" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormLabel), null, {
                          default: _withCtx(() => _cache[31] || (_cache[31] = [
                            _createTextVNode("Valor")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(FormControl), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Input), {
                              type: "number",
                              modelValue: newPrize.value.amount,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((newPrize.value.amount) = $event)),
                              placeholder: "Valor do prêmio"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(FormField), { name: "position" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormLabel), null, {
                          default: _withCtx(() => _cache[32] || (_cache[32] = [
                            _createTextVNode("Posição")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(FormControl), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Input), {
                              type: "number",
                              modelValue: newPrize.value.position,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((newPrize.value.position) = $event)),
                              placeholder: "Posição do vencedor"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(FormField), { name: "description" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormLabel), null, {
                          default: _withCtx(() => _cache[33] || (_cache[33] = [
                            _createTextVNode("Descrição")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(FormControl), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Textarea), {
                              modelValue: newPrize.value.description,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((newPrize.value.description) = $event)),
                              placeholder: "Descrição do prêmio"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_unref(DialogFooter), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Button), {
                    disabled: !newPrize.value.type || !newPrize.value.position || (!newPrize.value.amount && newPrize.value.type === _unref(TournamentPrizeTypeEnum).PIX),
                    type: "submit"
                  }, {
                    default: _withCtx(() => _cache[34] || (_cache[34] = [
                      _createTextVNode("Adicionar Prêmio")
                    ])),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ], 32)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["open"]),
    (prizeToUpdate.value)
      ? (_openBlock(), _createBlock(_unref(Dialog), {
          key: 2,
          open: isUpdatePrizeDialogOpen.value,
          "onUpdate:open": _cache[13] || (_cache[13] = ($event: any) => (isUpdatePrizeDialogOpen.value = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DialogContent), { class: "sm:max-w-[425px]" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogHeader), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DialogTitle), null, {
                      default: _withCtx(() => _cache[35] || (_cache[35] = [
                        _createTextVNode("Editar Prêmio")
                      ])),
                      _: 1
                    }),
                    _createVNode(_unref(DialogDescription), null, {
                      default: _withCtx(() => _cache[36] || (_cache[36] = [
                        _createTextVNode("Preencha os detalhes do prêmio abaixo.")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("form", {
                  onSubmit: _withModifiers(addPrize, ["prevent"])
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_unref(FormField), { name: "type" }, {
                      default: _withCtx(({ field }) => [
                        _createVNode(_unref(FormItem), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(FormLabel), null, {
                              default: _withCtx(() => _cache[37] || (_cache[37] = [
                                _createTextVNode("Tipo de Prêmio")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(Select), {
                              modelValue: prizeToUpdate.value.type,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((prizeToUpdate.value.type) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(SelectTrigger), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(SelectValue), { placeholder: "Selecione o tipo" })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(SelectContent), null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_unref(TournamentPrizeTypeEnum)), (type) => {
                                      return (_openBlock(), _createBlock(_unref(SelectItem), {
                                        key: type,
                                        value: type
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_unref(tournamentPrizeTypeEnumLabels)[type]), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(FormField), { name: "amount" }, {
                      default: _withCtx(({ field }) => [
                        _createVNode(_unref(FormItem), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(FormLabel), null, {
                              default: _withCtx(() => _cache[38] || (_cache[38] = [
                                _createTextVNode("Valor")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(FormControl), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Input), {
                                  type: "number",
                                  modelValue: prizeToUpdate.value.amount,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((prizeToUpdate.value.amount) = $event)),
                                  placeholder: "Valor do prêmio"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(FormField), { name: "position" }, {
                      default: _withCtx(({ field }) => [
                        _createVNode(_unref(FormItem), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(FormLabel), null, {
                              default: _withCtx(() => _cache[39] || (_cache[39] = [
                                _createTextVNode("Posição")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(FormControl), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Input), {
                                  type: "number",
                                  modelValue: prizeToUpdate.value.position,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((prizeToUpdate.value.position) = $event)),
                                  placeholder: "Posição do vencedor"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(FormField), { name: "description" }, {
                      default: _withCtx(({ field }) => [
                        _createVNode(_unref(FormItem), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(FormLabel), null, {
                              default: _withCtx(() => _cache[40] || (_cache[40] = [
                                _createTextVNode("Descrição")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(FormControl), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Textarea), {
                                  modelValue: prizeToUpdate.value.description,
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((prizeToUpdate.value.description) = $event)),
                                  placeholder: "Descrição do prêmio"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_unref(DialogFooter), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        disabled: !prizeToUpdate.value.type || !prizeToUpdate.value.position || (!prizeToUpdate.value.amount && prizeToUpdate.value.type === _unref(TournamentPrizeTypeEnum).PIX),
                        onClick: _withModifiers(updatePrize, ["prevent"])
                      }, {
                        default: _withCtx(() => _cache[41] || (_cache[41] = [
                          _createTextVNode("Salvar")
                        ])),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ], 32)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open"]))
      : _createCommentVNode("", true),
    (selectedPrizeToDeleteId.value)
      ? (_openBlock(), _createBlock(_unref(Dialog), {
          key: 3,
          open: isDeletePrizeConfirmationDialogOpen.value,
          "onUpdate:open": _cache[15] || (_cache[15] = (open) => isDeletePrizeConfirmationDialogOpen.value = open)
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogHeader), null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                      _createVNode(_unref(DialogTitle), { class: "text-[1.1rem]" }, {
                        default: _withCtx(() => _cache[42] || (_cache[42] = [
                          _createTextVNode("Cuidado")
                        ])),
                        _: 1
                      })
                    ]),
                    _createVNode(_unref(DialogDescription), { class: "py-2" }, {
                      default: _withCtx(() => _cache[43] || (_cache[43] = [
                        _createTextVNode(" Deseja mesmo deletar esse prêmio? ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _cache[46] || (_cache[46] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
                _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DialogClose), { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          type: "button",
                          variant: "secondary"
                        }, {
                          default: _withCtx(() => _cache[44] || (_cache[44] = [
                            _createTextVNode(" Cancelar ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(Button), {
                      type: "button",
                      disabled: isDeletingPrize.value,
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (deletePrize(selectedPrizeToDeleteId.value)))
                    }, {
                      default: _withCtx(() => [
                        (isDeletingPrize.value)
                          ? (_openBlock(), _createBlock(_unref(Loader2), {
                              key: 0,
                              class: "w-4 h-4 mr-2 animate-spin"
                            }))
                          : _createCommentVNode("", true),
                        _cache[45] || (_cache[45] = _createTextVNode(" Confirmar "))
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open"]))
      : _createCommentVNode("", true),
    _createVNode(Toaster)
  ], 64))
}
}

})