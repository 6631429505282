import {
  Users,
  Book,
  LibraryBig,
  Trophy,
  MessageSquare,
  Waypoints,
  Handshake,
  Medal,
  Home,
  CheckCircle,
  BookOpen
} from 'lucide-vue-next'

type MenuIconsType = {
  home: any,
  courses: any,
  feed: any,
  chat: any,
  affiliates: any,
  traders: any,
  tournaments: any,
  achievements: any,
  manageCourses?: any,
  manageTournaments?: any,
  allCourses: any
}

export const defaultMenuIcons: MenuIconsType = {
  home: Home,
  allCourses: BookOpen,
  courses: LibraryBig,
  feed: Users,
  chat: MessageSquare,
  affiliates: Waypoints,
  traders: Handshake,
  tournaments: Trophy,
  achievements: CheckCircle,
  manageCourses: Book,
  manageTournaments: Medal,
}

export const tradersPlatformMenuIcons: MenuIconsType = {
  home: Home,
  courses: LibraryBig,
  allCourses: BookOpen,
  feed: Users,
  chat: MessageSquare,
  affiliates: Waypoints,
  traders: Handshake,
  tournaments: Trophy,
  achievements: CheckCircle,
  manageCourses: Book,
  manageTournaments: Medal,
}