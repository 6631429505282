import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex flex-col sm:gap-4" }
const _hoisted_2 = { class: "grid flex-1 items-start gap-4 sm:py-0 md:gap-8 w-full max-w-[1700px] mx-auto" }
const _hoisted_3 = { class: "mx-auto grid w-full flex-1 auto-rows-max gap-4" }
const _hoisted_4 = { class: "absolute left-0 right-0 w-full h-auto" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "object-cover w-full max-w-full h-80 absolute bg-zinc-500"
}
const _hoisted_7 = {
  for: "backgroundImage",
  class: "absolute top-8 -translate-y-1/2 -translate-x-1/2 left-1/2 bg-muted rounded-full p-4 cursor-pointer"
}
const _hoisted_8 = ["onSubmit"]
const _hoisted_9 = { class: "grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8" }
const _hoisted_10 = { class: "grid gap-6" }
const _hoisted_11 = { class: "absolute left-1/2 -translate-x-1/2 -top-[130px]" }
const _hoisted_12 = { class: "grid gap-3" }
const _hoisted_13 = { class: "grid gap-3" }
const _hoisted_14 = { class: "grid gap-3" }
const _hoisted_15 = { class: "grid gap-3" }
const _hoisted_16 = { class: "grid gap-3" }
const _hoisted_17 = { class: "grid gap-3" }
const _hoisted_18 = { class: "grid gap-3" }
const _hoisted_19 = { class: "grid gap-3" }
const _hoisted_20 = { class: "grid gap-3" }
const _hoisted_21 = { class: "flex justify-between" }
const _hoisted_22 = { class: "flex items-center space-x-2" }
const _hoisted_23 = { class: "flex items-center space-x-2" }
const _hoisted_24 = { class: "flex items-center space-x-2" }
const _hoisted_25 = { class: "flex items-center space-x-2" }
const _hoisted_26 = { class: "flex items-center space-x-2" }
const _hoisted_27 = { class: "grid auto-rows-max items-start gap-4 lg:gap-8" }
const _hoisted_28 = { class: "grid gap-6 min-w-[300px]" }
const _hoisted_29 = { class: "grid gap-3" }
const _hoisted_30 = { class: "grid gap-3" }
const _hoisted_31 = { class: "grid gap-3" }
const _hoisted_32 = { class: "flex flex-col gap-6" }
const _hoisted_33 = { class: "grid gap-3" }
const _hoisted_34 = {
  key: 1,
  class: "grid gap-3"
}

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { Edit2, ImageUp, Loader2 } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { onMounted, ref } from 'vue'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import router from '@/router'
import { Product } from '@/interfaces/Product'
import ImageInput from '@/components/ui/input/ImageInput.vue'
import { CourseVideoTypeEnum, courseVideoTypeLabels } from '@/enums/course/CourseVideoTypeEnum'
import { CourseStatusEnum, courseStatusLabels } from '@/enums/course/CourseStatusEnum'
import { CourseCommentsTypeEnum, commentsTypeLabels } from '@/enums/course/CourseCommentsTypeEnum'
import { useForm } from 'vee-validate'
import TagsInput from '@/components/ui/tags-input/TagsInput.vue'
import TagsInputItem from '@/components/ui/tags-input/TagsInputItem.vue'
import TagsInputItemText from '@/components/ui/tags-input/TagsInputItemText.vue'
import TagsInputItemDelete from '@/components/ui/tags-input/TagsInputItemDelete.vue'
import TagsInputInput from '@/components/ui/tags-input/TagsInputInput.vue'
import { CourseLevelEnum, CourseLevelLabels } from '@/enums/course/CourseLevelEnum'
import { Variant } from '@/interfaces/products/Variant'
import { useVariant } from '@/composables/useVariant'
import { useAuth } from '@/composables/useAuth'
import { CourseCategoryEnum, CourseCategoryLabels } from '@/enums/course/CourseCategoryEnum'
import VideoUpload from './editCourse/components/VideoUpload.vue'

type FormSchemaType = z.infer<typeof formSchema>

export default /*@__PURE__*/_defineComponent({
  __name: 'CreateCourseView',
  setup(__props) {

const products = ref<Product[]>()
const isCreatingCourse = ref(false)
const axios = useAxios()
const form = ref<Record<string, any>>({
  image: undefined,
  backgroundImage: undefined,
  learningOutcomes: [],
  prerequisites: [],
  video: undefined
})
const mediaPreviews = ref({
  image: '',
  backgroundImage: ''
})
const requiredMessage = {
  required_error: "Obrigatório",
}
const formSchema = z.object({
  title: z.string(requiredMessage),
  image: z.any(requiredMessage).refine(file => !!form.value.image, {
    message: "Obrigatório."
  }),
  backgroundImage: z.any(requiredMessage),
  video: z.string(requiredMessage).url('Url deve ser válida').optional(),
  videoOriginType: z.nativeEnum(CourseVideoTypeEnum, requiredMessage).optional(),
  introduction: z.string(requiredMessage),
  description: z.string(requiredMessage),
  upcoming: z.boolean().default(false).default(false),
  commentsType: z.string(requiredMessage),
  communityType: z.string(requiredMessage),
  // limitClientsFree: z.number(requiredMessage),
  status: z.nativeEnum(CourseStatusEnum).default(CourseStatusEnum.ACTIVE).optional(),
  certification: z.boolean().default(true).default(false),
  // duration: z.number().optional(),
  featuredInStore: z.boolean().default(false),
  productId: z.string(requiredMessage),
  level: z.nativeEnum(CourseLevelEnum),
  hasReviews: z.boolean().default(false),
  displayTotalStudents: z.boolean().default(false),
  variantId: z.string(requiredMessage),
  category: z.nativeEnum(CourseCategoryEnum, requiredMessage),
});
const { handleSubmit, setFieldValue, values, setValues } = useForm({
  validationSchema: toTypedSchema(formSchema),
})
const { getVariantsByProductId } = useVariant()
const variants = ref<Variant[]>()
const { organization } = useAuth()

function createCourse(payload: FormSchemaType) {
  isCreatingCourse.value = true
  const formData = new FormData();

  formData.append('title', payload.title);
  formData.append('image', form.value.image!);
  if (form.value.backgroundImage) {
    formData.append('backgroundImage', form.value.backgroundImage);
  }
  if (payload.video && payload.videoOriginType !== CourseVideoTypeEnum.HOOPAY) {
    formData.append('video', payload.video)
  }
  if (form.value.video && payload.videoOriginType === CourseVideoTypeEnum.HOOPAY) {
    formData.append('video', form.value.video);
  }
  if (payload.videoOriginType) formData.append('videoOriginType', payload.videoOriginType);
  formData.append('introduction', payload.introduction);
  formData.append('description', payload.description);
  formData.append('upcoming', payload.upcoming ? 'true' : 'false');
  formData.append('commentsType', payload.commentsType);
  formData.append('communityType', payload.communityType);
  formData.append('status', String(payload.status));
  formData.append('certification', payload.certification ? 'true' : 'false');
  formData.append('featuredInStore', payload.featuredInStore ? 'true' : 'false');
  formData.append('variantId', String(payload.variantId));
  form.value.learningOutcomes.forEach((value: string, index: number) => {
    formData.append(`learningOutcomes[${index}]`, value);
  });

  form.value.prerequisites.forEach((value: string, index: number) => {
    formData.append(`prerequisites[${index}]`, value);
  });
  formData.append('category', payload.category);
  formData.append('level', payload.level);
  formData.append('hasReviews', payload.hasReviews ? 'true' : 'false');
  formData.append('displayTotalStudents', payload.displayTotalStudents ? 'true' : 'false');

  axios.post('/courses', formData)
    .then((res) => {
      router.push({ name: 'courses.edit', params: { courseId: res.data.id } })

      toast({
        title: 'Curso cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
    })
    .finally(() => isCreatingCourse.value = false)
}

function getProducts() {
  axios.get('/products')
    .then((res) => {
      products.value = res.data
    })
}

function onBackgroundImageChange(e: Event) {
  const file = (e.target as HTMLInputElement).files![0]

  form.value.backgroundImage = file

  mediaPreviews.value.backgroundImage = URL.createObjectURL(file)
}

onMounted(() => {
  getProducts()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("input", {
            class: "hidden",
            type: "file",
            onChange: onBackgroundImageChange,
            id: "backgroundImage",
            accept: "image/png, image/jpg, image/jpeg, image/gif, image/avif"
          }, null, 32),
          (mediaPreviews.value.backgroundImage)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: mediaPreviews.value.backgroundImage,
                class: "object-cover w-full max-w-full h-80 absolute"
              }, null, 8, _hoisted_5))
            : (_openBlock(), _createElementBlock("div", _hoisted_6)),
          _createElementVNode("label", _hoisted_7, [
            (!mediaPreviews.value.backgroundImage)
              ? (_openBlock(), _createBlock(_unref(ImageUp), {
                  key: 0,
                  class: "w-4 h-4"
                }))
              : (_openBlock(), _createBlock(_unref(Edit2), {
                  key: 1,
                  class: "w-4 h-4"
                }))
          ])
        ]),
        _createVNode(_unref(Form), {
          as: "",
          "keep-values": "",
          "validation-schema": _unref(toTypedSchema)(_unref(formSchema))
        }, {
          default: _withCtx(({ meta, values, validate }) => [
            _createElementVNode("form", {
              onSubmit: _withModifiers(() => {
          validate();
          if (meta.valid) {
            createCourse(values as FormSchemaType)
          }
        }, ["prevent"]),
              class: "grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8 relative mt-[14rem]"
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_unref(Card), { class: "pt-[240px] relative" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(CardContent), null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_unref(FormField), { name: "image" }, {
                              default: _withCtx(({ componentField }) => [
                                _createVNode(ImageInput, _mergeProps({
                                  modelValue: form.value.image,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.image) = $event))
                                }, componentField, {
                                  width: '250px',
                                  height: '333px'
                                }), null, 16, ["modelValue"]),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_unref(FormField), {
                              name: "title",
                              class: "col-span-1"
                            }, {
                              default: _withCtx(({ componentField }) => [
                                _createVNode(_unref(FormItem), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(FormLabel), null, {
                                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                                        _createTextVNode("Título")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_unref(FormControl), null, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Input), _mergeProps({
                                          type: "text",
                                          class: "w-full",
                                          placeholder: "ex: Design criativo, de ideias a realidade!"
                                        }, componentField), null, 16)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_unref(FormMessage))
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_unref(FormField), {
                              name: "introduction",
                              class: "col-span-1"
                            }, {
                              default: _withCtx(({ componentField }) => [
                                _createVNode(_unref(FormItem), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(FormLabel), null, {
                                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                                        _createTextVNode("Texto de Introdução")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_unref(FormControl), null, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Textarea), _mergeProps({
                                          placeholder: "ex: Neste curso, você irá explorar o fascinante mundo do design...",
                                          class: "min-h-32"
                                        }, componentField), null, 16)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_unref(FormMessage))
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_unref(FormField), {
                              name: "description",
                              class: "col-span-1"
                            }, {
                              default: _withCtx(({ componentField }) => [
                                _createVNode(_unref(FormItem), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(FormLabel), null, {
                                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                                        _createTextVNode("Descrição")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_unref(FormControl), null, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Textarea), _mergeProps({
                                          placeholder: "ex: Aprenda métodos práticos, fundamentos, a receber feedback...",
                                          class: "min-h-32"
                                        }, componentField), null, 16)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_unref(FormMessage))
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_unref(FormField), {
                              name: "learningOutcomes",
                              class: "col-span-1"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(FormItem), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(FormLabel), null, {
                                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                                        _createTextVNode("O que os alunos irão aprender")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_unref(FormControl), null, {
                                      default: _withCtx(() => [
                                        _createVNode(TagsInput, {
                                          modelValue: form.value.learningOutcomes,
                                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.learningOutcomes) = $event))
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(form.value.learningOutcomes, (item) => {
                                              return (_openBlock(), _createBlock(TagsInputItem, {
                                                key: item,
                                                value: item
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(TagsInputItemText),
                                                  _createVNode(TagsInputItemDelete)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128)),
                                            _createVNode(TagsInputInput, { placeholder: "Você se especializará em..." })
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(FormMessage))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_unref(FormField), {
                              name: "learningOutcomes",
                              class: "col-span-1"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(FormItem), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(FormLabel), null, {
                                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                                        _createTextVNode("Pré-requisitos")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_unref(FormControl), null, {
                                      default: _withCtx(() => [
                                        _createVNode(TagsInput, {
                                          modelValue: form.value.prerequisites,
                                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.prerequisites) = $event))
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(form.value.prerequisites, (item) => {
                                              return (_openBlock(), _createBlock(TagsInputItem, {
                                                key: item,
                                                value: item
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(TagsInputItemText),
                                                  _createVNode(TagsInputItemDelete)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128)),
                                            _createVNode(TagsInputInput, { placeholder: "Computador com acesso à internet..." })
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(FormMessage))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createVNode(_unref(FormField), { name: "category" }, {
                              default: _withCtx(({ componentField }) => [
                                _createVNode(_unref(FormLabel), { for: "level" }, {
                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                    _createTextVNode("Categoria")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Select), _normalizeProps(_guardReactiveProps(componentField)), {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(SelectTrigger), {
                                      id: "category",
                                      "aria-label": "Selecionar a categoria"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(SelectContent), null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseCategoryEnum), (category) => {
                                          return (_openBlock(), _createBlock(_unref(SelectItem), { value: category }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_unref(CourseCategoryLabels)[category]), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 256))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1040),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_18, [
                            _createVNode(_unref(FormField), { name: "level" }, {
                              default: _withCtx(({ componentField }) => [
                                _createVNode(_unref(FormLabel), { for: "level" }, {
                                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                                    _createTextVNode("Nível")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Select), _normalizeProps(_guardReactiveProps(componentField)), {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(SelectTrigger), {
                                      id: "level",
                                      "aria-label": "Selecionar o nível"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(SelectContent), null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseLevelEnum), (level) => {
                                          return (_openBlock(), _createBlock(_unref(SelectItem), { value: level }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_unref(CourseLevelLabels)[level]), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 256))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1040),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_19, [
                            _createVNode(_unref(FormField), { name: "productId" }, {
                              default: _withCtx(({ componentField, value }) => [
                                _createVNode(_unref(FormLabel), { for: "productId" }, {
                                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                                    _createTextVNode("Produto")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Select), _mergeProps(componentField, {
                                  "onUpdate:modelValue": (value) => _unref(getVariantsByProductId)(Number(value), _unref(organization).id!).then(_variants => variants.value = _variants)
                                }), {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(SelectTrigger), {
                                      id: "productId",
                                      "aria-label": "Selecionar o produto"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(SelectContent), null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(products.value, (product) => {
                                          return (_openBlock(), _createBlock(_unref(SelectItem), {
                                            value: String(product.id)
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(product.title), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 256))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1040, ["onUpdate:modelValue"]),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            _createVNode(_unref(FormField), { name: "variantId" }, {
                              default: _withCtx(({ field }) => [
                                _createVNode(_unref(FormItem), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(FormLabel), { for: "variantId" }, {
                                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                                        _createTextVNode("Variante de Produto")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_unref(FormControl), null, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Select), _normalizeProps(_guardReactiveProps(field)), {
                                          default: _withCtx(() => [
                                            _createVNode(_unref(SelectTrigger), {
                                              id: "variantId",
                                              "aria-label": "Selecionar variante"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_unref(SelectContent), null, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(variants.value, (variant) => {
                                                  return (_openBlock(), _createBlock(_unref(SelectItem), {
                                                    key: variant.id,
                                                    value: String(variant.id)
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(variant.title), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1040)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_unref(FormMessage))
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _createVNode(_unref(FormField), { name: "upcoming" }, {
                                default: _withCtx(({ componentField }) => [
                                  _createVNode(_unref(Switch), _normalizeProps(_guardReactiveProps(componentField)), null, 16),
                                  _createVNode(_unref(Label), { for: "upcoming" }, {
                                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                                      _createTextVNode("Em breve")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _createElementVNode("div", _hoisted_23, [
                              _createVNode(_unref(FormField), { name: "certification" }, {
                                default: _withCtx(({ componentField }) => [
                                  _createVNode(_unref(Switch), _normalizeProps(_guardReactiveProps(componentField)), null, 16),
                                  _createVNode(_unref(Label), { for: "certification" }, {
                                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                                      _createTextVNode("Certificado")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _createElementVNode("div", _hoisted_24, [
                              _createVNode(_unref(FormField), { name: "hasReviews" }, {
                                default: _withCtx(({ componentField }) => [
                                  _createVNode(_unref(Switch), _normalizeProps(_guardReactiveProps(componentField)), null, 16),
                                  _createVNode(_unref(Label), { for: "certification" }, {
                                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                                      _createTextVNode("Avaliações")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _createElementVNode("div", _hoisted_25, [
                              _createVNode(_unref(FormField), { name: "displayTotalStudents" }, {
                                default: _withCtx(({ componentField }) => [
                                  _createVNode(_unref(Switch), _normalizeProps(_guardReactiveProps(componentField)), null, 16),
                                  _createVNode(_unref(Label), { for: "certification" }, {
                                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                                      _createTextVNode("Exibir total de alunos")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _createElementVNode("div", _hoisted_26, [
                              _createVNode(_unref(FormField), { name: "featuredInStore" }, {
                                default: _withCtx(({ componentField }) => [
                                  _createVNode(_unref(Switch), _normalizeProps(_guardReactiveProps(componentField)), null, 16),
                                  _createVNode(_unref(Label), { for: "featuredInStore" }, {
                                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                                      _createTextVNode("Loja")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _createVNode(_unref(Button), {
                            disabled: values.videoOriginType === _unref(CourseVideoTypeEnum).HOOPAY && !form.value.video,
                            type: "submit"
                          }, {
                            default: _withCtx(() => [
                              (isCreatingCourse.value)
                                ? (_openBlock(), _createBlock(_unref(Loader2), {
                                    key: 0,
                                    class: "w-4 h-4 mr-2 animate-spin"
                                  }))
                                : _createCommentVNode("", true),
                              _cache[18] || (_cache[18] = _createTextVNode(" Adicionar "))
                            ]),
                            _: 2
                          }, 1032, ["disabled"])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createVNode(_unref(Card), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(CardHeader), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(CardTitle), { class: "text-[1.1rem]" }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createTextVNode("Configurações")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(CardContent), null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_28, [
                          _createElementVNode("div", _hoisted_29, [
                            _createVNode(_unref(FormField), { name: "status" }, {
                              default: _withCtx(({ componentField }) => [
                                _createVNode(_unref(FormLabel), { for: "status" }, {
                                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                                    _createTextVNode("Status")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Select), _normalizeProps(_guardReactiveProps(componentField)), {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(SelectTrigger), {
                                      id: "status",
                                      "aria-label": "Selecionar status"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(SelectContent), null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseStatusEnum), (status) => {
                                          return (_openBlock(), _createBlock(_unref(SelectItem), { value: status }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_unref(courseStatusLabels)[status]), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 256))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1040),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            _createVNode(_unref(FormField), { name: "commentsType" }, {
                              default: _withCtx(({ componentField }) => [
                                _createVNode(_unref(FormLabel), { for: "commentsType" }, {
                                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                                    _createTextVNode("Comentários")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Select), _normalizeProps(_guardReactiveProps(componentField)), {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(SelectTrigger), {
                                      id: "commentsType",
                                      "aria-label": "Selecionar tipo de comentários"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(SelectContent), null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseCommentsTypeEnum), (type) => {
                                          return (_openBlock(), _createBlock(_unref(SelectItem), { value: type }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_unref(commentsTypeLabels)[type]), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 256))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1040),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _createVNode(_unref(FormField), { name: "communityType" }, {
                              default: _withCtx(({ componentField }) => [
                                _createVNode(_unref(FormLabel), { for: "communityType" }, {
                                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                                    _createTextVNode("Tipo de comunidade")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Select), _normalizeProps(_guardReactiveProps(componentField)), {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(SelectTrigger), {
                                      id: "communityType",
                                      "aria-label": "Selecionar tipo de comunidade"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(SelectContent), null, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(SelectItem), { value: "Aberto" }, {
                                          default: _withCtx(() => _cache[23] || (_cache[23] = [
                                            _createTextVNode("Aberto")
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(_unref(SelectItem), { value: "Fechado" }, {
                                          default: _withCtx(() => _cache[24] || (_cache[24] = [
                                            _createTextVNode("Fechado")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1040),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Card), { class: "overflow-hidden" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(CardHeader), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(CardTitle), { class: "text-[1.1rem]" }, {
                          default: _withCtx(() => _cache[25] || (_cache[25] = [
                            _createTextVNode("Mídias")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(CardDescription), null, {
                          default: _withCtx(() => _cache[26] || (_cache[26] = [
                            _createTextVNode("Vídeo do curso")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(CardContent), null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("div", _hoisted_33, [
                            _createVNode(_unref(FormField), { name: "videoOriginType" }, {
                              default: _withCtx(({ componentField }) => [
                                _createVNode(_unref(FormLabel), { for: "contentType" }, {
                                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                                    _createTextVNode("Origem do vídeo")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Select), _normalizeProps(_guardReactiveProps(componentField)), {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(SelectTrigger), {
                                      id: "contentType",
                                      "aria-label": "Selecionar"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(SelectContent), null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseVideoTypeEnum), (type) => {
                                          return (_openBlock(), _createBlock(_unref(SelectItem), { value: type }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_unref(courseVideoTypeLabels)[type]), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 256))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1040),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 1
                            })
                          ]),
                          (values.videoOriginType === _unref(CourseVideoTypeEnum).HOOPAY)
                            ? (_openBlock(), _createBlock(VideoUpload, {
                                key: 0,
                                modelValue: form.value.video,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.video) = $event))
                              }, null, 8, ["modelValue"]))
                            : _createCommentVNode("", true),
                          (values.videoOriginType !== _unref(CourseVideoTypeEnum).HOOPAY)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                _createVNode(_unref(FormField), { name: "video" }, {
                                  default: _withCtx(({ componentField }) => [
                                    _createVNode(_unref(FormLabel), null, {
                                      default: _withCtx(() => _cache[28] || (_cache[28] = [
                                        _createTextVNode("Vídeo de Introdução")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_unref(Input), _mergeProps({
                                      type: "text",
                                      class: "w-full",
                                      placeholder: "ex: https://youtube.com..."
                                    }, componentField), null, 16),
                                    _createVNode(_unref(FormMessage))
                                  ]),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ])
            ], 40, _hoisted_8)
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ])
    ]),
    _createVNode(Toaster)
  ]))
}
}

})