<template>
  <div @click="$emit('click', true)" class="min-w-[100px] sm:min-w-[unset] group relative bg-gray-900 rounded-xl overflow-hidden hover:transform hover:scale-[1.02] transition-all duration-300 cursor-pointer">
    <div class="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent z-10"></div>
    <img :src="courseMainImage?.bucketLocation" :alt="course.title" class="w-full h-[140px] sm:h-[330px] object-cover" />
    <div class="relative z-20 p-4 sm:p-6">
      <div class="items-center justify-between mb-4 hidden sm:flex">
        <span class="inline-block px-3 py-1 rounded-full text-xs font-medium" 
          :class="{ 
            'bg-green-500/20 text-green-400': course.level === CourseLevelEnum.BEGINNER, 
            'bg-yellow-500/20 text-yellow-400': course.level === CourseLevelEnum.INTERMEDIATE, 
            'bg-purple-500/20 text-purple-400': course.level === CourseLevelEnum.ADVANCED, 
            'bg-red-500/20 text-red-400': course.level === CourseLevelEnum.EXPERT, 
          }">
          {{ CourseLevelLabels[course.level] }}
        </span>
        <span class="text-gray-400 text-sm">{{ course.duration }}</span>
      </div>
      <h3 class="text-md sm:text-xl font-bold mb-2">{{ course.title }}</h3>
      <p class="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-4 line-clamp-2">{{ course.introduction }}</p>
      <div class="flex items-center justify-between">
        <div v-if="!course.upcoming">
          <p v-if="course.variant.promotionalPrice" class="text-gray-400 text-sm line-through">{{ course.variant.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
          <p class="font-semibold text-lg text-white">{{ displayPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
        </div>
        <button class="text-blue-400 hover:text-blue-300 text-sm font-medium hidden sm:block">
          Ver Mais →
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CourseLevelEnum, CourseLevelLabels } from '@/enums/course/CourseLevelEnum';
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum';
import { Course } from '@/interfaces/Course';
import { computed } from 'vue';

defineEmits(['click'])
const props = defineProps<{ course: Course }>()

const courseMainImage = computed(() => props.course?.media?.find((m => m.type === CourseMediaTypeEnum.MAIN)))

const displayPrice = computed(() => {
  return props.course.variant.promotionalPrice || props.course.variant.price;
})
</script>
