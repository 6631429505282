export enum LessonContentOriginEnum {
  HOOPAY = 'hoopay',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  PANDA_VIDEO = 'panda_video',
  EPLAY = 'eplay',
}

export const lessonContentOriginLabels = {
  [LessonContentOriginEnum.HOOPAY]: 'HooPay',
  [LessonContentOriginEnum.VIMEO]: 'Vimeo',
  [LessonContentOriginEnum.PANDA_VIDEO]: 'Panda Video',
  [LessonContentOriginEnum.YOUTUBE]: 'Youtube',
  [LessonContentOriginEnum.EPLAY]: 'Eplay',
}