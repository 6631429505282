import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseLoader',
  props: {
    isVisible: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "transition-opacity duration-200",
    "enter-from-class": "opacity-0",
    "enter-to-class": "opacity-100",
    "leave-active-class": "transition-opacity duration-200",
    "leave-from-class": "opacity-100",
    "leave-to-class": "opacity-0"
  }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "relative" }, [
              _createElementVNode("div", { class: "w-16 h-16 rounded-full border-4 border-purple-500/30" }),
              _createElementVNode("div", {
                class: "absolute inset-0 rounded-full border-4 border-t-purple-600 border-r-purple-500 border-b-purple-400 border-l-transparent animate-spin",
                style: {"animation-duration":"1.5s"}
              }),
              _createElementVNode("div", { class: "absolute inset-0 flex items-center justify-center" }, [
                _createElementVNode("div", { class: "w-2 h-2 bg-purple-500 rounded-full animate-pulse" })
              ])
            ], -1),
            _createElementVNode("p", { class: "absolute mt-24 text-white/90 text-sm font-medium animate-fade-in" }, " Carregando... ", -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})