import {
  Code,
  Database,
  PenTool,
  Briefcase,
  Megaphone,
  User,
  HeartPulse,
  Music,
  Camera,
  Languages,
  DollarSign,
  Wrench,
  FlaskConical,
  BookOpen,
  Palette,
  Smile,
  Monitor,
  Shield,
  Wifi,
  Joystick,
  Brain,
  Link,
  Scale,
  Rocket,
  ClipboardList,
  Globe,
  Search,
  Scissors,
  Type,
  Users,
  MoreHorizontal
} from "lucide-vue-next";

export enum CourseCategoryEnum {
  PROGRAMMING = "programming",
  DATA_SCIENCE = "data_science",
  DESIGN = "design",
  BUSINESS = "business",
  MARKETING = "marketing",
  PERSONAL_DEVELOPMENT = "personal_development",
  HEALTH = "health_wellness",
  MUSIC = "music",
  PHOTOGRAPHY = "photography",
  LANGUAGE = "language_learning",
  FINANCE = "finance_investment",
  ENGINEERING = "engineering",
  SCIENCE = "science",
  EDUCATION = "education_teaching",
  ART = "art_creativity",
  LIFESTYLE = "lifestyle",
  IT_SOFTWARE = "it_software",
  CYBERSECURITY = "cybersecurity",
  NETWORKING = "networking",
  GAME_DEVELOPMENT = "game_development",
  AI_MACHINE_LEARNING = "ai_machine_learning",
  BLOCKCHAIN = "blockchain_cryptocurrencies",
  LEGAL = "legal_law",
  ENTREPRENEURSHIP = "entrepreneurship",
  PROJECT_MANAGEMENT = "project_management",
  DIGITAL_MARKETING = "digital_marketing",
  SEO = "seo_content_marketing",
  VIDEO_EDITING = "video_editing",
  COPYWRITING = "copywriting",
  SOCIAL_MEDIA = "social_media_marketing",
  OTHER = "other"
}

export const CourseCategoryLabels = {
  programming: "Programação",
  data_science: "Ciência de dados",
  design: "Design",
  business: "Negócios",
  marketing: "Marketing",
  personal_development: "Desenvolvimento pessoal",
  health_wellness: "Saúde e bem-estar",
  music: "Música",
  photography: "Fotografia",
  language_learning: "Aprendizado de línguas",
  finance_investment: "Finanças e investimentos",
  engineering: "Engenharia",
  science: "Ciência",
  education_teaching: "Educação e ensino",
  art_creativity: "Arte e criatividade",
  lifestyle: "Estilo de vida",
  it_software: "TI e software",
  cybersecurity: "Cibersegurança",
  networking: "Redes de computadores",
  game_development: "Desenvolvimento de jogos",
  ai_machine_learning: "IA e aprendizado de máquina",
  blockchain_cryptocurrencies: "Blockchain e criptomoedas",
  legal_law: "Direito e legislação",
  entrepreneurship: "Empreendedorismo",
  project_management: "Gestão de projetos",
  digital_marketing: "Marketing digital",
  seo_content_marketing: "SEO e marketing de conteúdo",
  video_editing: "Edição de vídeos",
  copywriting: "Copywriting",
  social_media_marketing: "Marketing em redes sociais",
  other: "Outro"
};

export const CourseCategoryDetails = {
  programming: {
    label: "Programação",
    description: "Aprenda linguagens de programação e desenvolvimento de software.",
    icon: Code
  },
  data_science: {
    label: "Ciência de Dados",
    description: "Análise de dados, estatística e aprendizado de máquina.",
    icon: Database
  },
  design: {
    label: "Design",
    description: "Design gráfico, UX/UI e ferramentas como Figma e Photoshop.",
    icon: PenTool
  },
  business: {
    label: "Negócios",
    description: "Gestão, estratégias empresariais e empreendedorismo.",
    icon: Briefcase
  },
  marketing: {
    label: "Marketing",
    description: "Técnicas de marketing e estratégias de crescimento.",
    icon: Megaphone
  },
  personal_development: {
    label: "Desenvolvimento Pessoal",
    description: "Habilidades interpessoais e produtividade.",
    icon: User
  },
  health_wellness: {
    label: "Saúde e Bem-Estar",
    description: "Nutrição, fitness e bem-estar mental.",
    icon: HeartPulse
  },
  music: {
    label: "Música",
    description: "Teoria musical, instrumentos e produção.",
    icon: Music
  },
  photography: {
    label: "Fotografia",
    description: "Técnicas de fotografia e edição de imagens.",
    icon: Camera
  },
  language_learning: {
    label: "Aprendizado de Línguas",
    description: "Cursos de idiomas para diferentes níveis.",
    icon: Languages
  },
  finance_investment: {
    label: "Finanças e Investimentos",
    description: "Gestão financeira, investimentos e economia.",
    icon: DollarSign
  },
  engineering: {
    label: "Engenharia",
    description: "Conceitos e aplicações em diversas engenharias.",
    icon: Wrench
  },
  science: {
    label: "Ciência",
    description: "Exploração científica e novas descobertas.",
    icon: FlaskConical
  },
  education_teaching: {
    label: "Educação e Ensino",
    description: "Metodologias e técnicas de ensino.",
    icon: BookOpen
  },
  art_creativity: {
    label: "Arte e Criatividade",
    description: "Pintura, escultura e expressão artística.",
    icon: Palette
  },
  lifestyle: {
    label: "Estilo de Vida",
    description: "Bem-estar, hobbies e equilíbrio pessoal.",
    icon: Smile
  },
  it_software: {
    label: "TI e Software",
    description: "Infraestrutura, redes e suporte técnico.",
    icon: Monitor
  },
  cybersecurity: {
    label: "Cibersegurança",
    description: "Proteção digital, criptografia e hacking ético.",
    icon: Shield
  },
  networking: {
    label: "Redes de Computadores",
    description: "Administração de redes e protocolos.",
    icon: Wifi
  },
  game_development: {
    label: "Desenvolvimento de Jogos",
    description: "Criação de jogos e motores gráficos.",
    icon: Joystick
  },
  ai_machine_learning: {
    label: "IA e Aprendizado de Máquina",
    description: "Modelos preditivos e inteligência artificial.",
    icon: Brain
  },
  blockchain_cryptocurrencies: {
    label: "Blockchain e Criptomoedas",
    description: "Tecnologias descentralizadas e contratos inteligentes.",
    icon: Link
  },
  legal_law: {
    label: "Direito e Legislação",
    description: "Leis, contratos e normas jurídicas.",
    icon: Scale
  },
  entrepreneurship: {
    label: "Empreendedorismo",
    description: "Criação e gestão de novos negócios.",
    icon: Rocket
  },
  project_management: {
    label: "Gestão de Projetos",
    description: "Planejamento e execução de projetos.",
    icon: ClipboardList
  },
  digital_marketing: {
    label: "Marketing Digital",
    description: "Estrategias online e publicidade digital.",
    icon: Globe
  },
  seo_content_marketing: {
    label: "SEO e Marketing de Conteúdo",
    description: "Otimização para buscadores e produção de conteúdo.",
    icon: Search
  },
  video_editing: {
    label: "Edição de Vídeos",
    description: "Técnicas de edição e produção audiovisual.",
    icon: Scissors
  },
  copywriting: {
    label: "Copywriting",
    description: "Redação persuasiva e estratégias de escrita.",
    icon: Type
  },
  social_media_marketing: {
    label: "Marketing em Redes Sociais",
    description: "Crescimento de audiência e engajamento.",
    icon: Users
  },
  other: {
    label: "Outro",
    description: "Outras categorias de conhecimento.",
    icon: MoreHorizontal
  }
};