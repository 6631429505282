<template>
  <div class="p-4 sm:p-8 sm:container mx-auto">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-[26px] pb-[44px]">
      <RouterLink :to="{ name: 'tournaments.show', params: { tournamentId: 16 } }">
        <img class="col-span-1 w-full rounded-lg" src="https://cdn.member-area.hoopay.com.br/traders-cup-brasil/memberarea-home/campeonato_fevereiro_home.jpg" />
      </RouterLink>
      <img class="col-span-1 w-full rounded-lg" src="https://cdn.member-area.hoopay.com.br/traders-cup-brasil/memberarea-home/premiacao2.jpg" />
    </div>
    <h2 class="text-[1.1rem] font-semibold tracking-tight0 bg-clip-text mb-6">Acesso rápido</h2>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      <!-- Conta Safe -->
      <!-- <div class="group relative bg-card rounded-lg p-4 hover:bg-gray-800 transition-all duration-300 cursor-pointer">
        <div class="absolute top-2 right-2">
          <span class="bg-gray-500 text-xs text-white px-2 py-1 rounded-full">BETA</span>
        </div>
        <div class="flex flex-col items-center space-y-4">
          <div class="w-24 h-24 rounded-lg flex items-center justify-center group-hover:scale-105 transition-transform duration-300">
            <div class="text-blue-400 relative">
              <ShieldDollarIcon size="48" class="relative z-10" />
              <div class="absolute inset-0 blur-sm opacity-50 bg-blue-400"></div>
            </div>
          </div>
          <span class="text-gray-300 text-sm font-medium">Conta Safe</span>
        </div>
      </div> -->

      <!-- Conquistas -->
      <RouterLink :to="{ name: 'achievements' }" class="group bg-card rounded-lg p-4 hover:bg-gray-800 transition-all duration-300 cursor-pointer" style='padding: 0'>
        <img src="https://cdn.member-area.hoopay.com.br/traders-cup-brasil/quick-access/achievements-v2-9269746c-426d-4bd4-8e06-2a267e33507b.jpg" class="relative z-10" style='border-radius: 5px;' />
      </RouterLink>

      <!-- Torneios -->
      <RouterLink :to="{ name: 'tournaments' }" class="group bg-card rounded-lg p-4 hover:bg-gray-800 transition-all duration-300 cursor-pointer" style='padding: 0'>
        <img src="https://cdn.member-area.hoopay.com.br/traders-cup-brasil/quick-access/tournament-v2-4c7752d3-eded-4937-84d7-f8785601bcf5.jpg" class="relative z-10" style='border-radius: 5px;' />
      </RouterLink>

      <!-- Top Traders -->
      <RouterLink :to="{ name: 'traders' }" class="group bg-card rounded-lg p-4 hover:bg-gray-800 transition-all duration-300 cursor-pointer" style='padding: 0'>
        <img src="https://cdn.member-area.hoopay.com.br/traders-cup-brasil/quick-access/ranking-v2-6cdfad40-1933-4ed5-b549-5ac7686e4730.jpg" class="relative z-10" style='border-radius: 5px;' />
      </RouterLink>

      <!-- FAQ -->
      <RouterLink :to="{ name: 'faq' }" class="group bg-card rounded-lg p-4 hover:bg-gray-800 transition-all duration-300 cursor-pointer" style='padding: 0'>
        <img src="https://cdn.member-area.hoopay.com.br/traders-cup-brasil/quick-access/faq-v2-8bdf5207-f4fb-4e6f-b8fe-23bcdfaed807.jpg" class="relative z-10" style='border-radius: 5px;' />
      </RouterLink>
    </div>
  </div>
</template>

<script setup>
import { useTenant } from '@/composables/useTenant';
import router from '@/router';
import { onMounted } from 'vue';

const { isTradersPlatform } = useTenant()

onMounted(() => {
  if (!isTradersPlatform.value) {
    router.push({ name: 'home' })
  }
})
</script>

<style scoped>
.group {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.group:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>
