<template>
  <div class="w-full lg:w-[821px] p-4 sm:p-0 pt-0" v-if="topic">
    <button @click="$router.go(-1)" class="bg-[#d5d5d5] p-2 mb-4 rounded-full inline-flex">
      <MoveLeft color="black" />
    </button>
    <div class="lg:w-[821px] mx-auto bg-card rounded-xl shadow-md overflow-hidden">
      <!-- Post Section -->
      <div class="p-6 border-b border-gray-200 min-w-full">
        <div class="flex items-center space-x-3 mb-4">
          <ProfileComponent :hideDropdown="true" :topic="{ ...topic, author: { ...topic.author, avatar: profileAvatar } }" />
        </div>
        <p class="text-lg mb-4 whitespace-pre-wrap text-sm sm:text-md">{{ topic.content }}</p>
        <div class="flex justify-between items-center text-gray-300 text-sm">
          <div class="flex items-center space-x-2">
            <span class="flex -space-x-1">
              <div v-for="(reaction, index) in distinctReactions" :key="index" class="rounded-full bg-gray-500 w-5 h-5 flex items-center justify-center text-xs text-white">
                {{ reaction }}
              </div>
            </span>
            <span v-if="topic.reactions.length">{{ topic.reactions.length }}</span>
          </div>
          <div>
            <span>{{ topic.postsCount }} comentários</span>
          </div>
        </div>
      </div>

      <!-- Interaction Buttons -->
      <div class="flex justify-start py-2 border-b border-gray-200">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div class="flex justify-center w-full" :class="{ 'cursor-not-allowed': !hasActiveParticipation }">
                <Button variant="ghost" class="flex-1" @click="toggleReaction(CommunityTopicReactionContentEnum.LIKE)"
                :disabled="!hasActiveParticipation"
                :class="{ 
                  'text-blue-500 hover:text-blue-500': alreadyReacted?.content === CommunityTopicReactionContentEnum.LIKE,
                  'text-red-500 hover:text-red-500': alreadyReacted?.content === CommunityTopicReactionContentEnum.LOVE,
                }">
                  <component
                    :is="alreadyReacted ? communityTopicReactionIcons[alreadyReacted.content] : ThumbsUpIcon"
                    :stroke-width="1.25"
                    class="h-5 w-5 mr-2 transition-colors duration-200"
                    :class="{ 
                      'text-blue-500 fill-blue-500': alreadyReacted?.content === CommunityTopicReactionContentEnum.LIKE,
                      'text-red-500 fill-red-500': alreadyReacted?.content === CommunityTopicReactionContentEnum.LOVE,
                    }"
                  />
                  <!-- <div>{{ alreadyReacted ? alreadyReacted.content : '👍' }}</div> -->
                  {{ alreadyReacted ? communityTopicReactionLabels[alreadyReacted?.content] : 'Curtir' }}
                </Button>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div class="flex space-x-2" v-if="hasActiveParticipation">
                <button @click="toggleReaction(reaction)" v-for="reaction in CommunityTopicReactionContentEnum" :key="reaction" class="cursor-pointer text-xl hover:scale-125 transition-transform duration-200">
                  {{ reaction }}
                </button>
              </div>
              <p v-else>Ação bloqueada! É necessário estar participando de um torneio.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <!-- <button class="flex items-center space-x-2 text-gray-400 hover:bg-muted px-4 py-2 rounded-md transition duration-200">
          <MessageCircleIcon class="w-6 h-6" />
          <span>Comentar</span>
        </button>
        <button class="flex items-center space-x-2 text-gray-400 hover:bg-muted px-4 py-2 rounded-md transition duration-200">
          <ShareIcon class="w-6 h-6" />
          <span>Compartilhar</span>
        </button> -->
      </div>

      <!-- posts Section -->
      <div class="p-6">
        <h2 class="text-lg font-semibold mb-4">Comentários</h2>
        <div v-if="posts" class="space-y-6">
          <CommunityPostComponent v-for="post in posts.data" :key="post.id" :post="post"/>
        </div>

        <button class="pl-8 w-full text-center flex justify-center items-center text-sm" v-if="posts?.pagination.nextPage" @click="getPosts(posts?.pagination.nextPage)" :class="{ 'text-zinc-400': arePostsLoading }">
          <Loader2 v-if="arePostsLoading" class="w-4 h-4 mr-2 animate-spin" />
          Ver mais respostas
        </button>

        <!-- New post Input -->
        <div class="mt-6">
          <form class="flex items-start space-x-2">
            <Avatar>
              <AvatarImage v-if="authProfileAvatar" :src="authProfileAvatar.bucketLocation" :alt="userAuthenticatedProfile!.name" />
              <AvatarFallback>{{ userAuthenticatedProfile?.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }}</AvatarFallback>
            </Avatar>
            <div class="flex-grow">
              <Textarea
                v-model="postPayload.content"
                placeholder="Escreva um comentário..."
                rows="2"
              />
              <div class="mt-2 flex justify-end">
                <TooltipProvider>
                  <Tooltip :delay-duration="100">
                    <TooltipTrigger asChild>
                      <div :class="{ 'cursor-not-allowed': !hasActiveParticipation }">
                        <Button
                          type="submit"
                          @click="createPost(postPayload)"
                          :disabled="isCreatingPost || !postPayload.content || !hasActiveParticipation"
                        >
                        <Loader2 v-if="isCreatingPost" class="w-4 h-4 mr-2 animate-spin" />
                        Comentar
                      </Button>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent v-if="!hasActiveParticipation">
                      <p>Ação bloqueada! É necessário estar participando de um torneio.</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <SpinnerLoader v-else />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { ThumbsUpIcon, Loader2, MoveLeft } from 'lucide-vue-next'
import { Textarea } from '@/components/ui/textarea'
import Button from '@/components/ui/button/Button.vue'
import { useAxios } from '@/composables/useAxios';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { CommunityTopic } from '@/interfaces/courses/communities/CommunityTopic';
import { Pagination } from '@/interfaces/Pagination';
import { CommunityPost } from '@/interfaces/courses/communities/CommunityPost';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useDate } from '@/composables/useDate';
import { CommunityTopicReactionContentEnum, communityTopicReactionIcons, communityTopicReactionLabels } from '@/enums/course/communities/communityTopics/communityTopicReactions/CommunityTopicReactionContentEnum';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import CommunityPostComponent from './components/CommunityPostComponent.vue';
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum';
import { Profile } from '@/interfaces/profiles/Profile';
import { useAuth } from '@/composables/useAuth';
import ProfileComponent from '@/views/profile/components/ProfileComponent.vue';
import { useTournament } from '@/composables/useTournament';

const { timeAgo } = useDate()
const { hasActiveParticipation } = useTournament()
const axios = useAxios()
const { userAuthenticated, getProfile } = useAuth()
const props = defineProps<{ communityTopicId: number }>()
const arePostsLoading = ref(false)
const distinctReactions = computed(() => {
  return Array.from(new Set(topic.value?.reactions.map(reaction => reaction.content))) as CommunityTopicReactionContentEnum[];
})
const topic = ref<CommunityTopic>()
const alreadyReacted = computed(() => topic.value?.reactions.find((r) => r.userId === userAuthenticated.userId))
const profileAvatar = computed(() =>
  topic.value?.author.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const authProfileAvatar = computed(() =>
  userAuthenticatedProfile.value?.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const posts = ref<Pagination<CommunityPost>>()
const postPayload = ref({
  content: ''
})
const isCreatingPost = ref(false)
const userAuthenticatedProfile = ref<Profile>()

const toggleReaction = async (content: CommunityTopicReactionContentEnum) => {
  axios.post(`/courses/communities/topics/${topic.value!.id}/reactions`, { content })

  if (!alreadyReacted.value) {
    topic.value!.reactions.push({
      content,
      userId: userAuthenticated.userId
    })
  } else {
    topic.value!.reactions = topic.value!.reactions.filter((r) => r.userId !== userAuthenticated.userId)
  }
}

function getTopic() {
  axios.get(`/courses/communities/topics/${props.communityTopicId}`)
    .then((res) => {
      topic.value = res.data
    })
}

function getPosts(page = 1, reset = false) {
  arePostsLoading.value = true

  axios.get(`/courses/communities/topics/${props.communityTopicId}/posts`, { params: { page } })
    .then((res) => {
      posts.value = reset ? res.data : { ...res.data, data: posts.value ? [...posts.value.data, ...res.data.data] : res.data.data }
    })
    .finally(() => arePostsLoading.value = false)
}

function createPost(payload: { content: string }) {
  isCreatingPost.value = true

  axios.post(`/courses/communities/topics/${props.communityTopicId}/posts`, payload)
    .then(() => {
      getPosts(1, true)
      postPayload.value = {
        content: ''
      }
    })
    .finally(() => {
      isCreatingPost.value = false
    })
}

onMounted(async () => {
  getTopic()
  getPosts()
  userAuthenticatedProfile.value = await getProfile()
})
</script>