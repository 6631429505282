import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex min-h-screen w-full flex-col bg-black/50" }
const _hoisted_2 = { class: "fixed top-10 left-8 mt-7 h-full flex-col pt-4 hidden md:flex w-30 text-center z-10" }
const _hoisted_3 = { class: "grid gap-6 p-2 rounded-lg bg-black/50 backdrop-blur-xl" }
const _hoisted_4 = {
  key: 7,
  class: "w-full h-px bg-gray-200 dark:bg-gray-700"
}
const _hoisted_5 = { class: "border-b border-gray-800 bg-black/50 backdrop-blur-xl sticky top-0 flex h-16 items-center gap-4 border-b mb-3 px-4 md:px-7 z-50" }
const _hoisted_6 = { class: "fixed ml-7 hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6 left-5" }
const _hoisted_7 = {
  href: "/#",
  class: "flex items-center gap-2 text-lg font-semibold md:text-base"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4" }
const _hoisted_10 = {
  key: 0,
  class: "absolute -top-1 -right-1 h-5 w-5 rounded-full bg-red-500 text-xs text-white flex items-center justify-center"
}
const _hoisted_11 = { class: "md:ml-[100px] flex justify-center sm:px-7 flex items-center flex-col" }
const _hoisted_12 = {
  key: 0,
  class: "text-center text-xs pb-10 pt-4 sm:pt-0"
}
const _hoisted_13 = { class: "fixed bottom-0 left-0 right-0 bg-background border-t border-border px-2 py-1 md:hidden z-20" }
const _hoisted_14 = { class: "flex justify-around items-center" }
const _hoisted_15 = {
  key: 0,
  class: "absolute bottom-full left-0 right-0 bg-background border-t border-border p-4 space-y-4"
}

import {
  Package2,
  Users,
  CircleUser,
  Menu,
  LogOut,
  HelpCircle,
  Book,
  LibraryBig,
  Trophy,
  UserCog,
  MessageSquare,
  Waypoints,
  Handshake,
  Bell,
  Medal,
  Home,
  CheckCircle,
  SearchIcon
} from 'lucide-vue-next'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { AclEnum } from '@/enums/acl/AclEnum'
import { Button } from '@/components/ui/button'

import TooltipProvider from '@/components/ui/tooltip/TooltipProvider.vue'
import Tooltip from '@/components/ui/tooltip/Tooltip.vue'
import TooltipTrigger from '@/components/ui/tooltip/TooltipTrigger.vue'
import TooltipContent from '@/components/ui/tooltip/TooltipContent.vue'
import { useAuth } from '@/composables/useAuth'
import { computed, onMounted, ref } from 'vue'
import { useTenant } from '@/composables/useTenant'
import { useNotification } from '@/composables/useNotification'
import NotificationCard from '@/views/notifications/NotificationCard.vue'
import { NotificationStatusEnum } from '@/enums/notifications/NotificationStatusEnum'
import { useTournament } from '@/composables/useTournament'
import { useRoute } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'PanelLayout',
  setup(__props) {

const isNotificationDropdownOpen = ref(false)
const {
  tenantLogo,
  tenant,
  initializeTenant,
  menuIcons,
  hasHome,
  hasCourses,
  hasChat,
  hasAffiliates,
  hasTraders,
  hasTournaments,
  hasAchievements,
  isTradersPlatform
} = useTenant()
const { checkActiveParticipation, hasActiveParticipation } = useTournament()

const unreadCount = computed(() => notifications.value?.data.filter(n => n.status === NotificationStatusEnum.UNREAD).length)

const { logout, userAuthenticated, hasRoles } = useAuth()
const { notifications, initializeNotifications, readAllNotifications } = useNotification()

const route = useRoute()
const showMoreMenu = ref(false)
const isActive = (name: string) => route.name === name
const toggleMoreMenu = () => {
  showMoreMenu.value = !showMoreMenu.value
}

onMounted(() => {
  checkActiveParticipation()
  initializeTenant()
  initializeNotifications()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("aside", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        _createVNode(TooltipProvider, null, {
          default: _withCtx(() => [
            _createVNode(Tooltip, { "delay-duration": 100 }, {
              default: _withCtx(() => [
                _createVNode(TooltipTrigger, { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: { name: _unref(hasHome) ? 'trader.home' : 'home' },
                      class: "rounded-lg",
                      "exact-active-class": "bg-muted"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon",
                          class: "rounded-lg",
                          "aria-label": "Meus cursos"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).home), {
                              class: "size-7",
                              "stroke-width": 1.25
                            }))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _: 1
                }),
                _createVNode(TooltipContent, {
                  side: "right",
                  "side-offset": 5
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Home ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_unref(hasCourses))
          ? (_openBlock(), _createBlock(TooltipProvider, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(Tooltip, { "delay-duration": 100 }, {
                  default: _withCtx(() => [
                    _createVNode(TooltipTrigger, { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RouterLink, {
                          to: { name: 'courses.all' },
                          class: "rounded-lg",
                          "exact-active-class": "bg-muted"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Button), {
                              variant: "ghost",
                              size: "icon",
                              class: "rounded-lg",
                              "aria-label": "Meus cursos"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).allCourses), {
                                  class: "size-7",
                                  "stroke-width": 1.25
                                }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(TooltipContent, {
                      side: "right",
                      "side-offset": 5
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Explorar ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_unref(hasCourses))
          ? (_openBlock(), _createBlock(TooltipProvider, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(Tooltip, { "delay-duration": 100 }, {
                  default: _withCtx(() => [
                    _createVNode(TooltipTrigger, { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RouterLink, {
                          to: { name: 'courses.my' },
                          class: "rounded-lg",
                          "exact-active-class": "bg-muted"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Button), {
                              variant: "ghost",
                              size: "icon",
                              class: "rounded-lg",
                              "aria-label": "Meus cursos"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).courses), {
                                  class: "size-7",
                                  "stroke-width": 1.25
                                }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(TooltipContent, {
                      side: "right",
                      "side-offset": 5
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Meus cursos ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(TooltipProvider, null, {
          default: _withCtx(() => [
            _createVNode(Tooltip, { "delay-duration": 100 }, {
              default: _withCtx(() => [
                _createVNode(TooltipTrigger, { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: { name: 'feed' },
                      class: "rounded-lg",
                      "exact-active-class": "bg-muted"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon",
                          class: "rounded-lg",
                          "aria-label": "Comunidade"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).feed), {
                              class: "size-7",
                              "stroke-width": 1.25
                            }))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(TooltipContent, {
                  side: "right",
                  "side-offset": 5
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" Comunidade ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_unref(hasChat))
          ? (_openBlock(), _createBlock(TooltipProvider, { key: 2 }, {
              default: _withCtx(() => [
                _createVNode(Tooltip, { "delay-duration": 100 }, {
                  default: _withCtx(() => [
                    _createVNode(TooltipTrigger, { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RouterLink, {
                          to: { name: 'chat' },
                          class: "rounded-lg",
                          "exact-active-class": "bg-muted"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Button), {
                              variant: "ghost",
                              size: "icon",
                              class: "rounded-lg",
                              "aria-label": "Chat"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).chat), {
                                  class: "size-7",
                                  "stroke-width": 1.25
                                }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(TooltipContent, {
                      side: "right",
                      "side-offset": 5
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" Chat ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_unref(hasAffiliates) && _unref(hasRoles)([_unref(AclEnum).AFFILIATE]))
          ? (_openBlock(), _createBlock(TooltipProvider, { key: 3 }, {
              default: _withCtx(() => [
                _createVNode(Tooltip, { "delay-duration": 100 }, {
                  default: _withCtx(() => [
                    _createVNode(TooltipTrigger, { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RouterLink, {
                          to: { name: 'affiliates' },
                          class: "rounded-lg",
                          "exact-active-class": "bg-muted"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Button), {
                              variant: "ghost",
                              size: "icon",
                              class: "rounded-lg",
                              "aria-label": "Afiliados"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).affiliates), {
                                  class: "size-7",
                                  "stroke-width": 1.25
                                }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(TooltipContent, {
                      side: "right",
                      "side-offset": 5
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode(" Afiliados ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_unref(hasTraders))
          ? (_openBlock(), _createBlock(TooltipProvider, { key: 4 }, {
              default: _withCtx(() => [
                _createVNode(Tooltip, { "delay-duration": 100 }, {
                  default: _withCtx(() => [
                    _createVNode(TooltipTrigger, { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RouterLink, {
                          to: { name: 'traders' },
                          class: "rounded-lg",
                          "exact-active-class": "bg-muted"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Button), {
                              variant: "ghost",
                              size: "icon",
                              class: "rounded-lg",
                              "aria-label": "Traders"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).traders), {
                                  class: "size-7",
                                  "stroke-width": 1.25
                                }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(TooltipContent, {
                      side: "right",
                      "side-offset": 5
                    }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode(" Traders ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_unref(hasTournaments))
          ? (_openBlock(), _createBlock(TooltipProvider, { key: 5 }, {
              default: _withCtx(() => [
                _createVNode(Tooltip, { "delay-duration": 100 }, {
                  default: _withCtx(() => [
                    _createVNode(TooltipTrigger, { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RouterLink, {
                          to: { name: 'tournaments' },
                          class: "rounded-lg",
                          "exact-active-class": "bg-muted"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Button), {
                              variant: "ghost",
                              size: "icon",
                              class: "rounded-lg",
                              "aria-label": "Torneios"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).tournaments), {
                                  class: "size-7",
                                  "stroke-width": 1.25
                                }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(TooltipContent, {
                      side: "right",
                      "side-offset": 5
                    }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode(" Torneios ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_unref(hasAchievements))
          ? (_openBlock(), _createBlock(TooltipProvider, { key: 6 }, {
              default: _withCtx(() => [
                _createVNode(Tooltip, { "delay-duration": 100 }, {
                  default: _withCtx(() => [
                    _createVNode(TooltipTrigger, { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RouterLink, {
                          to: { name: 'achievements' },
                          class: "rounded-lg",
                          "exact-active-class": "bg-muted"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Button), {
                              variant: "ghost",
                              size: "icon",
                              class: "rounded-lg",
                              "aria-label": "Conquistas"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).achievements), {
                                  class: "size-7",
                                  "stroke-width": 1.25
                                }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(TooltipContent, {
                      side: "right",
                      "side-offset": 5
                    }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode(" Conquistas ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_unref(hasRoles)([_unref(AclEnum).PRODUCER, _unref(AclEnum).ADMIN]) && _unref(tenant)?.id === 1 || _unref(hasRoles)([_unref(AclEnum).ADMIN_MEMBER_AREA]))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : _createCommentVNode("", true),
        (_unref(hasRoles)([_unref(AclEnum).PRODUCER, _unref(AclEnum).ADMIN]) && _unref(tenant)?.id === 1 || _unref(hasRoles)([_unref(AclEnum).ADMIN_MEMBER_AREA]))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 8 }, [
              _createVNode(TooltipProvider, null, {
                default: _withCtx(() => [
                  _createVNode(Tooltip, { "delay-duration": 10 }, {
                    default: _withCtx(() => [
                      _createVNode(TooltipTrigger, { "as-child": "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_RouterLink, {
                            to: { name: 'courses.manage' },
                            class: "rounded-lg",
                            "exact-active-class": "bg-muted"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Button), {
                                variant: "ghost",
                                size: "icon",
                                class: "rounded-lg",
                                "aria-label": "Gerenciar Cursos"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).manageCourses), {
                                    class: "size-7",
                                    "stroke-width": 1.25
                                  }))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(TooltipContent, {
                        side: "right",
                        "side-offset": 5
                      }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode(" Gerenciar Cursos ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_unref(hasTournaments))
                ? (_openBlock(), _createBlock(TooltipProvider, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(Tooltip, { "delay-duration": 100 }, {
                        default: _withCtx(() => [
                          _createVNode(TooltipTrigger, { "as-child": "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_RouterLink, {
                                to: { name: 'tournaments.manage' },
                                class: "rounded-lg",
                                "exact-active-class": "bg-muted"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(Button), {
                                    variant: "ghost",
                                    size: "icon",
                                    class: "rounded-lg",
                                    "aria-label": "Gerenciar Torneios"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).manageTournaments), {
                                        class: "size-7",
                                        "stroke-width": 1.25
                                      }))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(TooltipContent, {
                            side: "right",
                            "side-offset": 5
                          }, {
                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                              _createTextVNode(" Gerenciar Torneios ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("header", _hoisted_5, [
      _createElementVNode("nav", _hoisted_6, [
        _createElementVNode("a", _hoisted_7, [
          (_unref(tenantLogo))
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _unref(tenantLogo).bucketLocation,
                style: {"max-width":"120px"}
              }, null, 8, _hoisted_8))
            : _createCommentVNode("", true)
        ]),
        _cache[17] || (_cache[17] = _createElementVNode("a", {
          href: "/#",
          class: "text-foreground transition-colors hover:text-foreground"
        }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[22] || (_cache[22] = _createElementVNode("form", { class: "ml-auto flex-1 sm:flex-initial" }, null, -1)),
        _createVNode(_unref(DropdownMenu), {
          open: isNotificationDropdownOpen.value,
          "onUpdate:open": [
            _cache[1] || (_cache[1] = ($event: any) => ((isNotificationDropdownOpen).value = $event)),
            _cache[2] || (_cache[2] = ($event: any) => (isNotificationDropdownOpen.value = $event))
          ]
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DropdownMenuTrigger), { "as-child": "" }, {
              default: _withCtx(() => [
                _createVNode(_unref(Button), {
                  variant: "secondary",
                  size: "icon",
                  class: "rounded-full relative"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Bell), { class: "h-5 w-5" }),
                    (unreadCount.value && unreadCount.value > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(unreadCount.value), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(DropdownMenuContent), {
              class: "w-80",
              align: "end"
            }, {
              default: _withCtx(() => [
                _createVNode(NotificationCard, {
                  onShowMore: _cache[0] || (_cache[0] = () => { _ctx.$router.push({ name: 'notifications' }); isNotificationDropdownOpen.value = false }),
                  "has-scroll-area": true
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open"]),
        _createVNode(_unref(DropdownMenu), null, {
          default: _withCtx(() => [
            _createVNode(_unref(DropdownMenuTrigger), { "as-child": "" }, {
              default: _withCtx(() => [
                _createVNode(_unref(Button), {
                  variant: "secondary",
                  size: "icon",
                  class: "rounded-full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(CircleUser), { class: "h-5 w-5" }),
                    _cache[18] || (_cache[18] = _createElementVNode("span", { class: "sr-only" }, "Toggle user menu", -1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(DropdownMenuContent), { align: "end" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DropdownMenuItem), {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'profile', params: { profileId: _unref(userAuthenticated).userId }  })))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(UserCog), { class: "h-4 w-4 mr-2" }),
                    _cache[19] || (_cache[19] = _createTextVNode(" Minha Conta"))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(DropdownMenuSeparator)),
                _createVNode(_unref(DropdownMenuItem), {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push({ name: 'terms-of-use' })))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(HelpCircle), { class: "h-4 w-4 mr-2" }),
                    _cache[20] || (_cache[20] = _createTextVNode(" Regras e Contrato "))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(DropdownMenuSeparator)),
                _createVNode(_unref(DropdownMenuItem), {
                  class: "cursor-pointer",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(logout)(
              () => _ctx.$router.push({ name: 'login' })
            )))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(LogOut), { class: "h-4 w-4 mr-2" }),
                    _cache[21] || (_cache[21] = _createTextVNode(" Sair "))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_RouterView, { class: "w-full pb-20 sm:pb-0" }),
      (_unref(isTradersPlatform))
        ? (_openBlock(), _createElementBlock("p", _hoisted_12, "© 2024 Traders Cup Brasil 29.435.748/0001-07. Todos os direitos reservados."))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("nav", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_RouterLink, {
          to: { name: _unref(hasHome) ? 'trader.home' : 'home' },
          class: _normalizeClass(["flex flex-col items-center p-2 transition-colors duration-200", { 'text-primary': isActive('home'), 'text-muted-foreground': !isActive('home') }])
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).home), {
              class: "h-6 w-6",
              "stroke-width": 1.25
            })),
            _cache[23] || (_cache[23] = _createElementVNode("span", { class: "text-xs mt-1" }, "Home", -1))
          ]),
          _: 1
        }, 8, ["to", "class"]),
        (_unref(hasCourses))
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 0,
              to: { name: 'courses.all' },
              class: _normalizeClass(["flex flex-col items-center p-2 transition-colors duration-200", { 'text-primary': isActive('courses.all'), 'text-muted-foreground': !isActive('courses.all') }])
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).allCourses), {
                  class: "h-6 w-6",
                  "stroke-width": 1.25
                })),
                _cache[24] || (_cache[24] = _createElementVNode("span", { class: "text-xs mt-1" }, "Explorar", -1))
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        (_unref(hasCourses))
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 1,
              to: { name: 'courses.my' },
              class: _normalizeClass(["flex flex-col items-center p-2 transition-colors duration-200", { 'text-primary': isActive('courses.my'), 'text-muted-foreground': !isActive('courses.my') }])
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).courses), {
                  class: "h-6 w-6",
                  "stroke-width": 1.25
                })),
                _cache[25] || (_cache[25] = _createElementVNode("span", { class: "text-xs mt-1" }, "Cursos", -1))
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        _createVNode(_component_RouterLink, {
          to: { name: 'feed' },
          class: _normalizeClass(["flex flex-col items-center p-2 transition-colors duration-200", { 'text-primary': isActive('feed'), 'text-muted-foreground': !isActive('feed') }])
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).feed), {
              class: "h-6 w-6",
              "stroke-width": 1.25
            })),
            _cache[26] || (_cache[26] = _createElementVNode("span", { class: "text-xs mt-1" }, "Comunidade", -1))
          ]),
          _: 1
        }, 8, ["class"]),
        (_unref(hasChat))
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 2,
              to: { name: 'chat' },
              class: _normalizeClass(["flex flex-col items-center p-2 transition-colors duration-200", { 'text-primary': isActive('chat'), 'text-muted-foreground': !isActive('chat') }])
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).chat), {
                  class: "h-6 w-6",
                  "stroke-width": 1.25
                })),
                _cache[27] || (_cache[27] = _createElementVNode("span", { class: "text-xs mt-1" }, "Chat", -1))
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      (showMoreMenu.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            (_unref(hasAffiliates) && _unref(hasRoles)([_unref(AclEnum).AFFILIATE]))
              ? (_openBlock(), _createBlock(_component_RouterLink, {
                  key: 0,
                  to: { name: 'affiliates' },
                  class: "flex items-center gap-2 p-2 rounded-lg hover:bg-muted"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).affiliates), {
                      class: "h-5 w-5",
                      "stroke-width": 1.25
                    })),
                    _cache[28] || (_cache[28] = _createElementVNode("span", null, "Afiliados", -1))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(hasTraders))
              ? (_openBlock(), _createBlock(_component_RouterLink, {
                  key: 1,
                  to: { name: 'traders' },
                  class: "flex items-center gap-2 p-2 rounded-lg hover:bg-muted"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).traders), {
                      class: "h-5 w-5",
                      "stroke-width": 1.25
                    })),
                    _cache[29] || (_cache[29] = _createElementVNode("span", null, "Traders", -1))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(hasTournaments))
              ? (_openBlock(), _createBlock(_component_RouterLink, {
                  key: 2,
                  to: { name: 'tournaments' },
                  class: "flex items-center gap-2 p-2 rounded-lg hover:bg-muted"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).tournaments), {
                      class: "h-5 w-5",
                      "stroke-width": 1.25
                    })),
                    _cache[30] || (_cache[30] = _createElementVNode("span", null, "Torneios", -1))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(hasAchievements))
              ? (_openBlock(), _createBlock(_component_RouterLink, {
                  key: 3,
                  to: { name: 'achievements' },
                  class: "flex items-center gap-2 p-2 rounded-lg hover:bg-muted"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(menuIcons).achievements), {
                      class: "h-5 w-5",
                      "stroke-width": 1.25
                    })),
                    _cache[31] || (_cache[31] = _createElementVNode("span", null, "Conquistas", -1))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})