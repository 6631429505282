import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import type { HTMLAttributes } from 'vue'
import { ChevronRight } from 'lucide-vue-next'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'BreadcrumbSeparator',
  props: {
    class: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    role: "presentation",
    "aria-hidden": "true",
    class: _normalizeClass(_unref(cn)('[&>svg]:size-3.5', props.class))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createVNode(_unref(ChevronRight))
    ])
  ], 2))
}
}

})