import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "mt-12" }
const _hoisted_2 = { class: "space-y-3" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "flex items-center gap-3" }
const _hoisted_5 = { class: "w-10 h-10 rounded-lg bg-purple-500/20 flex items-center justify-center" }
const _hoisted_6 = { class: "font-medium text-white group-hover:text-purple-400 transition-colors" }
const _hoisted_7 = { class: "text-sm text-zinc-400" }
const _hoisted_8 = { class: "text-sm text-zinc-400" }

import { FolderIcon } from 'lucide-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseModules',
  props: {
    course: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-xl font-semibold text-white mb-6" }, "Conteúdo do Curso", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.modules, (module, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "bg-zinc-800/30 p-4 rounded-lg hover:bg-zinc-800/50 transition-colors cursor-pointer group"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(FolderIcon), { class: "w-5 h-5 text-purple-400" })
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_6, _toDisplayString(module.title), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(module.lessons.length) + " aulas", 1)
              ])
            ]),
            _createElementVNode("span", _hoisted_8, _toDisplayString(module.duration), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})