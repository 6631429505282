import { useAxios } from "./useAxios"

const axios = useAxios()

export function useLesson() {
  const saveLessonNote = (courseId: number, moduleId: number, lessonId: number, content: string, callback: Function) => {
    axios.put(`/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/notes`, { content })
      .then(() => callback())
  }

  return {
    saveLessonNote
  }
}