import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "border-b border-gray-800 sticky top-0 z-10 backdrop-blur" }
const _hoisted_2 = { class: "container mx-auto px-4" }
const _hoisted_3 = ["onClick"]

import { ref } from 'vue';
import { CourseCategoryEnum, CourseCategoryLabels } from '@/enums/course/CourseCategoryEnum';


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseCategoryNavigation',
  props: {
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any) {




const carousel = ref<HTMLElement | null>(null);
let isDragging = false;
let startX: number;
let scrollLeft: number;

const startDrag = (e: MouseEvent | TouchEvent) => {
  isDragging = true;
  if (carousel.value) {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    startX = clientX - carousel.value.offsetLeft;
    scrollLeft = carousel.value.scrollLeft;
    carousel.value.style.scrollBehavior = 'auto';
  }
};

const onDrag = (e: MouseEvent | TouchEvent) => {
  if (!isDragging) return;
  e.preventDefault();
  if (carousel.value) {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const x = clientX - carousel.value.offsetLeft;
    const walk = (x - startX) * 2; // Scroll-fast
    carousel.value.scrollLeft = scrollLeft - walk;
  }
};

const endDrag = () => {
  isDragging = false;
  if (carousel.value) {
    carousel.value.style.scrollBehavior = 'smooth';
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        ref_key: "carousel",
        ref: carousel,
        class: "flex gap-8 overflow-x-auto py-4 scrollbar-hide cursor-grab active:cursor-grabbing",
        onMousedown: startDrag,
        onMousemove: onDrag,
        onMouseup: endDrag,
        onMouseleave: endDrag,
        onTouchstart: startDrag,
        onTouchmove: onDrag,
        onTouchend: endDrag
      }, [
        _createElementVNode("button", {
          class: _normalizeClass([
          'whitespace-nowrap px-4 py-2 rounded-full transition-colors text-sm sm:text-md',
          !_ctx.modelValue
            ? 'bg-purple-600 text-white' 
            : 'text-gray-400 hover:text-white'
        ]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', undefined)))
        }, " Todos ", 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseCategoryEnum), (category) => {
          return (_openBlock(), _createElementBlock("button", {
            key: category,
            class: _normalizeClass([
            'whitespace-nowrap px-4 py-2 rounded-full transition-colors text-sm sm:text-md',
            _ctx.modelValue === category
              ? 'bg-purple-600 text-white' 
              : 'text-gray-400 hover:text-white'
          ]),
            onClick: ($event: any) => (_ctx.$emit('update:modelValue', category))
          }, _toDisplayString(_unref(CourseCategoryLabels)[category]), 11, _hoisted_3))
        }), 128))
      ], 544)
    ])
  ]))
}
}

})