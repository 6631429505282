import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 h-auto py-2" }


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseGridSkeleton',
  setup(__props) {

// No props or additional logic needed for this skeleton component

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(8, (i) => {
      return _createElementVNode("div", {
        key: i,
        class: "group relative bg-zinc-800 rounded-xl overflow-hidden animate-pulse min-w-[100px]"
      }, _cache[0] || (_cache[0] = [
        _createStaticVNode("<div class=\"w-full h-[150px] sm:h-[330px] bg-zinc-700\" data-v-4b14c0a6></div><div class=\"relative p-4 sm:p-6\" data-v-4b14c0a6><div class=\"flex items-center justify-between mb-4\" data-v-4b14c0a6><div class=\"w-20 h-6 bg-zinc-700 rounded-full\" data-v-4b14c0a6></div><div class=\"w-16 h-4 bg-zinc-700 rounded\" data-v-4b14c0a6></div></div><div class=\"w-3/4 h-6 bg-zinc-700 rounded mb-2\" data-v-4b14c0a6></div><div class=\"w-full h-4 bg-zinc-700 rounded mb-2 hidden sm:block\" data-v-4b14c0a6></div><div class=\"w-2/3 h-4 bg-zinc-700 rounded mb-4 hidden sm:block\" data-v-4b14c0a6></div><div class=\"flex items-center justify-between\" data-v-4b14c0a6><div class=\"flex items-center space-x-4\" data-v-4b14c0a6><div class=\"w-24 h-5 bg-zinc-700 rounded\" data-v-4b14c0a6></div></div><div class=\"w-20 h-5 bg-zinc-700 rounded\" data-v-4b14c0a6></div></div></div>", 2)
      ]))
    }), 64))
  ]))
}
}

})