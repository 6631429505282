<template>
  <div class="flex flex-col sm:gap-4 sm:py-4">
    <main class="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 max-w-[1700px] mx-auto w-full">
      <Tabs default-value="all">
        <div class="flex items-center">
          <!-- <TabsList>
            <TabsTrigger value="all">
              Cursos
            </TabsTrigger>
          </TabsList> -->
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink>Cursos</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
            </BreadcrumbList>
          </Breadcrumb>
          <div class="ml-auto flex items-center gap-2">
            <!-- <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <Button variant="outline" size="sm" class="h-9 gap-1">
                  <ListFilter class="h-3.5 w-3.5" />
                  <span class="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Filtro
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Filtrar por</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem checked>
                  Ativo
                </DropdownMenuItem>
                <DropdownMenuItem>Inativo</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu> -->
            <Button @click="$router.push({ name: 'courses.create' })" size="sm" class="h-9 gap-1">
              <PlusCircle class="h-3.5 w-3.5" />
              <span class="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Adicionar Curso
              </span>
            </Button>
          </div>
        </div>
        <TabsContent value="all">
          <Card class="bg-[unset]">
            <CardHeader>
              <CardTitle class="text-[1.1rem]">Cursos</CardTitle>
              <CardDescription>
                Gerencie, crie e edite os seus cursos
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead class="hidden w-[100px] sm:table-cell">
                      <span class="sr-only">img</span>
                    </TableHead>
                    <TableHead>Título</TableHead>
                    <TableHead>Introdução</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>
                      Ações
                    </TableHead>
                  </TableRow>
                </TableHeader>

                <TableBody v-if="courses">
                  <template v-if="courses?.data && courses?.data.length > 0" class="relative">
                    <!-- Exibindo os cursos -->
                    <TableRow v-for="course in courses.data" :key="course.id">
                      <TableCell class="hidden sm:table-cell">
                        <img alt="Imagem do curso" class="aspect-square rounded-md object-cover" height="64"
                          :src="course.media.find((m) => m.type === CourseMediaTypeEnum.MAIN)?.bucketLocation ?? '/assets/img/no-image.png'"
                          @error="($event.target as HTMLInputElement).src = '/assets/img/no-image.png'" width="64" />
                      </TableCell>
                      <TableCell class="font-medium">
                        {{ course.title }}
                      </TableCell>
                      <TableCell class="font-medium">
                        {{ course.introduction }}
                      </TableCell>
                      <TableCell class="font-medium">
                        {{ courseStatusLabels[course.status] }}
                      </TableCell>
                      <TableCell>
                        <div class="flex gap-3">
                          <TooltipProvider>
                            <Tooltip :delay-duration="100">
                              <TooltipTrigger as-child>
                                <button
                                  class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg"
                                  @click="$router.push({ name: 'courses.users.index', params: { courseId: course.id } })">
                                  <GraduationCap class="w-5 h-5" />
                                </button>
                              </TooltipTrigger>
                              <TooltipContent side="top" :side-offset="5">
                                Alunos
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                          <TooltipProvider>
                            <Tooltip :delay-duration="100">
                              <TooltipTrigger as-child>
                                <button class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg" @click="$router.push({ name: 'courses.edit', params: { courseId: course.id } })">
                                  <Pencil class="w-5 h-5" />
                                </button>
                              </TooltipTrigger>
                              <TooltipContent side="top" :side-offset="5">
                                Editar
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                          <TooltipProvider>
                            <Tooltip :delay-duration="100">
                              <TooltipTrigger as-child>
                                <button class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg"
                                  @click="isDeleteCourseConfirmationDialogOpen = true; selectedCourseIdToDelete = course.id">
                                  <Trash2 class="w-5 h-5" />
                                </button>
                              </TooltipTrigger>
                              <TooltipContent side="top" :side-offset="5">
                                Excluir
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </TableCell>

                      <!-- <DropdownMenu>
                        <DropdownMenuTrigger as-child>
                          <Button
                            aria-haspopup="true"
                            size="icon"
                            variant="ghost"
                          >
                            <MoreHorizontal class="h-4 w-4" :stroke-width="1.25" />
                            <span class="sr-only">Toggle menu</span>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>Ações</DropdownMenuLabel>
                          <DropdownMenuItem @click="$router.push({ name: 'courses.edit', params: { courseId: course.id } })">Editar</DropdownMenuItem>
                          <DropdownMenuItem @click="isDeleteCourseConfirmationDialogOpen = true; selectedCourseIdToDelete = course.id">Excluir</DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu> -->
                    </TableRow>
                  </template>

                  <!-- Exibindo a mensagem quando não houver cursos -->
                  <template v-else class="relative">
                    <TableRow>
                      <TableCell colspan="5" class="text-center font-medium py-8">
                        Nenhum curso encontrado.
                      </TableCell>
                    </TableRow>
                  </template>
                </TableBody>

              </Table>
              <SpinnerLoader v-if="!courses" />
              <PaginationApi v-if="courses" :data="courses.pagination"
                :paginate="(page) => { courses = undefined; getCourses(page) }" />
            </CardContent>
            <CardFooter>
              <div v-if="courses" class="text-xs text-muted-foreground">
                Exibindo <strong>1-{{ courses.data.length }}</strong> de <strong>{{ courses.pagination.total }}</strong>
                resultados
              </div>
            </CardFooter>
          </Card>
          <Dialog v-if="selectedCourseIdToDelete" :open="isDeleteCourseConfirmationDialogOpen"
            @update:open="(open) => isDeleteCourseConfirmationDialogOpen = open">
            <DialogContent class="sm:max-w-md">
              <DialogHeader>
                <div class="flex gap-2 items-center">
                  <AlertTriangle class="h-6 w-6" />
                  <DialogTitle class="text-[1.1rem]">Cuidado</DialogTitle>
                </div>
                <DialogDescription class="py-2">
                  Deseja mesmo deletar esse curso?
                </DialogDescription>
              </DialogHeader>
              <div class="flex items-center space-x-2">

              </div>
              <DialogFooter class="sm:justify-start">
                <DialogClose as-child>
                  <Button type="button" variant="secondary">
                    Cancelar
                  </Button>
                </DialogClose>
                <Button type="button" :disabled="isDeletingCourse" @click="deleteCourse(selectedCourseIdToDelete)">
                  <Loader2 v-if="isDeletingCourse" class="w-4 h-4 mr-2 animate-spin" />
                  Confirmar
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </TabsContent>
      </Tabs>
      <Toaster />
    </main>
  </div>
</template>

<script setup lang="ts">
import {
  PlusCircle,
  AlertTriangle,
  Loader2,
  Pencil,
  Trash2,
  GraduationCap
} from 'lucide-vue-next'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import {
  Tabs,
  TabsContent,
} from '@/components/ui/tabs'

import { onMounted, ref } from 'vue'
import { useAxios } from '@/composables/useAxios'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Course } from '@/interfaces/Course'
import { Pagination as IPagination } from '@/interfaces/Pagination'
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue'
import { Dialog } from '@/components/ui/dialog'
import DialogContent from '@/components/ui/dialog/DialogContent.vue'
import DialogHeader from '@/components/ui/dialog/DialogHeader.vue'
import DialogTitle from '@/components/ui/dialog/DialogTitle.vue'
import DialogDescription from '@/components/ui/dialog/DialogDescription.vue'
import DialogFooter from '@/components/ui/dialog/DialogFooter.vue'
import DialogClose from '@/components/ui/dialog/DialogClose.vue'
import { toast, Toaster } from '@/components/ui/toast'
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum'
import { courseStatusLabels } from '@/enums/course/CourseStatusEnum'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { Breadcrumb, BreadcrumbEllipsis, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb"

const axios = useAxios()
const courses = ref<IPagination<Course>>()
const isDeleteCourseConfirmationDialogOpen = ref(false)
const selectedCourseIdToDelete = ref<number>()
const isDeletingCourse = ref(false)

function getCourses(page = 1) {
  axios.get('/courses', { params: { page, owner: true } })
    .then((res) => {
      courses.value = res.data
    })
}

function deleteCourse(id: number) {
  isDeletingCourse.value = true

  axios.delete(`/courses/${id}`)
    .then(() => {
      toast({
        title: 'Curso excluído com sucesso',
        class: 'bg-green-500 text-white'
      })

      courses.value!.data = courses.value!.data.filter((course) => course.id !== id)
    })
    .catch(() => isDeletingCourse.value = false)
    .finally(() => {
      isDeletingCourse.value = false
      isDeleteCourseConfirmationDialogOpen.value = false
    })
}

onMounted(() => {
  getCourses()
})
</script>