export enum CourseLevelEnum {
  BEGINNER = 'beginner',
  INTERMEDIATE = 'intermediate',
  ADVANCED = 'advanced',
  EXPERT = 'expert'
}

export const CourseLevelLabels = {
  [CourseLevelEnum.BEGINNER]: 'Iniciante',
  [CourseLevelEnum.INTERMEDIATE]: 'Intermediário',
  [CourseLevelEnum.ADVANCED]: 'Avançado',
  [CourseLevelEnum.EXPERT]: 'Especialista'
}