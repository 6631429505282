import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen text-gray-100" }
const _hoisted_2 = { class: "border-b border-gray-800 py-4 sm:py-6" }
const _hoisted_3 = { class: "container mx-auto px-4 flex items-center gap-6" }
const _hoisted_4 = { class: "container mx-auto px-4 py-6" }

import Select from '@/components/ui/select/Select.vue'
import SelectContent from '@/components/ui/select/SelectContent.vue'
import SelectItem from '@/components/ui/select/SelectItem.vue'
import SelectTrigger from '@/components/ui/select/SelectTrigger.vue'
import SelectValue from '@/components/ui/select/SelectValue.vue'
import { MoveLeft } from 'lucide-vue-next'
import { ref, computed, onMounted, watch } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import CourseGrid from '../components/CourseGrid.vue'
import { Pagination } from '@/interfaces/Pagination'
import { Course } from '@/interfaces/Course'
import { useAxios } from '@/composables/useAxios'
import CourseGridSkeleton from '../components/CourseGridSkeleton.vue'
import CourseCategoryNavigation from './components/CourseCategoryNavigation.vue'
import { CourseCategoryEnum } from '@/enums/course/CourseCategoryEnum'
import NoCoursesFound from './components/NoCoursesFound.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AllCoursesView',
  setup(__props) {

const skills = [
  { name: 'Habilidades em comunicação', students: 'Mais de 2 mi de alunos' },
  { name: 'Técnicas de apresentação', students: 'Mais de 2 mi de alunos' },
  { name: 'Discurso em público', students: 'Mais de 3 mi de alunos' },
  { name: 'Redação', students: 'Mais de 1 mi de alunos' },
  { name: 'PowerPoint', students: 'Mais de 2 mi de alunos' },
  { name: 'Comunicação', students: 'Mais de 407.400 alunos' }
]

const route = useRoute()
const courses = ref<Pagination<Course>>()
const axios = useAxios()

const selectedCategory = ref<CourseCategoryEnum | undefined>(route.query.category as CourseCategoryEnum)
const filters = ref({
  rating: '',
  price: '',
  duration: ''
})

function getCourses(page = 1, category?: CourseCategoryEnum) {
  courses.value = undefined

  let params: any = { page, featuredInStore: true }

  if (category) {
    params.category = category
  }

  axios.get('/courses', { params: params })
    .then((res) => {
      courses.value = res.data
    })
}

watch(() => selectedCategory.value, () => {
  getCourses(1, selectedCategory.value)
})

onMounted(() => {
  getCourses(1, selectedCategory.value)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(RouterLink), {
          to: { name: 'home' },
          class: "bg-[#d5d5d5] rounded-full p-3 hidden sm:block"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(MoveLeft), { class: "text-black" })
          ]),
          _: 1
        }),
        _cache[2] || (_cache[2] = _createElementVNode("div", null, [
          _createElementVNode("h1", { class: "text-lg sm:text-3xl font-bold mb-2 sm:mb-4" }, "Tudo o que você precisa para crescer profissionalmente"),
          _createElementVNode("p", { class: "text-sm sm:text-[1rem] text-gray-400" }, "Aprimore suas capacidades com conteúdos que vão do básico ao avançado.")
        ], -1))
      ])
    ]),
    _createVNode(CourseCategoryNavigation, {
      modelValue: selectedCategory.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedCategory).value = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_4, [
      (courses.value)
        ? (_openBlock(), _createBlock(CourseGrid, {
            key: 0,
            courses: courses.value.data
          }, null, 8, ["courses"]))
        : (_openBlock(), _createBlock(CourseGridSkeleton, { key: 1 })),
      (courses.value && !courses.value.data.length)
        ? (_openBlock(), _createBlock(NoCoursesFound, {
            key: 2,
            onClear: _cache[1] || (_cache[1] = ($event: any) => (selectedCategory.value = undefined))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})