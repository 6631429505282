<template>
  <!-- Feed principal -->
  <section class="w-full lg:w-[821px] space-y-6 p-4 sm:p-0">
    <!-- Criar post -->
    <Card v-if="userAuthenticated.roles.find((role: string) => role === 'ADMIN' || 'PRODUCER') && tenant?.id === 1 || hasRoles([AclEnum.ADMIN_MEMBER_AREA])" class="">
      <CardHeader>
        <CardTitle class="text-[1.0rem]">Criar Publicação</CardTitle>
      </CardHeader>
      <CardContent class="grid gap-6">
        <Textarea v-model="payload.content" placeholder="No que você está pensando?" />
      </CardContent>
      <CardFooter class="flex justify-between">
        <!-- <Button variant="ghost" size="sm">
          <ImageIcon class="h-5 w-5 mr-2" />
          Foto
        </Button>
        <Button variant="ghost" size="sm">
          <VideoIcon class="h-5 w-5 mr-2" />
          Vídeo
        </Button> -->
        <Button :disabled="!payload.content || isCreatingFeedTopic" @click="createFeedTopic(payload)">
          <Loader2 v-if="isCreatingFeedTopic" class="w-4 h-4 mr-2 animate-spin" />
          Publicar
        </Button>
      </CardFooter>
    </Card>

    <!-- Posts -->
    <div class="grid gap-6" ref="topicsWrapper" v-if="topics">
      <Topic v-for="(topic, index) in topics.data" :key="topic.id" :topic="topic" @delete="topics.data.splice(index, 1)" />
    </div>
    <div class="grid gap-6" v-if="isFeedLoading">
      <div class="h-[260px] w-full bg-zinc-800 animate-pulse rounded-lg" v-for="i in [1,2,3,4]"></div>
    </div>
    <div v-if="topics && !topics.data.length" className="rounded-lg p-6 pb-14 mb-8 bg-card">
      <div className="flex items-center justify-center mb-4">
        <Inbox className="h-12 w-12 text-yellow-400" />
      </div>
      <h3 className="text-xl font-semibold text-white text-center mb-2">Sem publicações por aqui</h3>
      <p className="text-gray-300 text-center">
        Volte mais tarde e verifique novamente por novas publicações
      </p>
    </div>
    <!-- <SpinnerLoader v-if="isFeedLoading" /> -->
  </section>

  <!-- Barra lateral direita -->
  <!-- <aside class="hidden xl:block w-[700px] pl-6 space-y-6"> -->
    <!-- Sugestões de amizade -->
    <!-- <Card class="">
      <CardHeader>
        <CardTitle>Sugestões de Amizade</CardTitle>
      </CardHeader>
      <CardContent>
        <ul class="space-y-4">
          <li v-for="friend in friendSuggestions" :key="friend.id" class="flex items-center justify-between">
            <div class="flex items-center space-x-3">
              <Avatar>
                <AvatarImage :src="friend.avatar" :alt="friend.name" />
                <AvatarFallback>{{ friend.initials }}</AvatarFallback>
              </Avatar>
              <span>{{ friend.name }}</span>
            </div>
            <Button size="sm">Adicionar</Button>
          </li>
        </ul>
      </CardContent>
    </Card> -->

    <!-- Eventos próximos -->
    <!-- <Card class="sticky top-[]">
      <CardHeader>
        <CardTitle>Eventos Próximos</CardTitle>
      </CardHeader>
      <CardContent>
        <ul class="space-y-4">
          <li v-for="event in upcomingEvents" :key="event.id" class="flex items-center space-x-3">
            <CalendarIcon class="h-5 w-5 text-blue-500" />
            <div>
              <p class="font-medium">{{ event.name }}</p>
              <p class="text-sm text-gray-400">{{ event.date }}</p>
            </div>
            <Button
              variant="outline"
              size="sm"
              class="text-gray-200 hover:bg-gray-700"
            >
              <ArrowRightIcon class="h-4 w-4 mr-1" />
            </Button>
          </li>
        </ul>
      </CardContent>
    </Card> -->
  <!-- </aside> -->
  <Toaster />
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { Button } from '@/components/ui/button'
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '@/components/ui/card'
import { Textarea } from '@/components/ui/textarea'
import { Inbox, Loader2 } from 'lucide-vue-next'
import Topic from './components/topic/Topic.vue'
import { useAxios } from '@/composables/useAxios'
import { toast, Toaster } from '@/components/ui/toast'
import { Pagination } from '@/interfaces/Pagination'
import { CommunityTopic } from '@/interfaces/courses/communities/CommunityTopic'
import { useAuth } from '@/composables/useAuth'
import { useTenant } from '@/composables/useTenant'
import { AclEnum } from '@/enums/acl/AclEnum'

const { tenant } = useTenant()
const { hasRoles, userAuthenticated } = useAuth()

const topicsWrapper = ref<HTMLElement | null>(null)
const axios = useAxios()
const isFeedLoading = ref(false)

interface CreateFeedTopicPayload {
  content: string,
}

const payload = ref<CreateFeedTopicPayload>({
  content: '',
})
const isCreatingFeedTopic = ref(false)
const topics = ref<Pagination<CommunityTopic>>()

// const friendSuggestions = ref([
//   { id: 1, name: 'Carlos Oliveira', avatar: '/placeholder.svg?height=40&width=40', initials: 'CO' },
//   { id: 2, name: 'Ana Rodrigues', avatar: '/placeholder.svg?height=40&width=40', initials: 'AR' },
// ])

// const upcomingEvents = ref([
//   { id: 1, name: 'Meetup de Desenvolvedores', date: '15 de Maio, 19:00' },
//   { id: 2, name: 'Workshop de Vue.js', date: '22 de Maio, 14:00' },
// ])

function createFeedTopic(data: CreateFeedTopicPayload) {
  isCreatingFeedTopic.value = true

  axios.post(`/courses/communities/topics`, { content: data.content, type: 'feed'})
    .then(() => {
      toast({
        title: 'Publicação cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
      getFeed()
      payload.value = {
        content: '',
      }
    })
    .finally(() => isCreatingFeedTopic.value = false)
}

function getFeed() {
  const page = topics.value?.pagination.nextPage ?? 1
  isFeedLoading.value = true

  axios.get('/courses/communities/topics/feed', { params: { page } })
    .then((res) => {
      topics.value = { ...res.data, data: topics.value && topics.value?.pagination.nextPage ? [...topics.value.data, ...res.data.data] : res.data.data }
    })
    .finally(() => isFeedLoading.value = false)
}

function handleScroll() {
  if ((topicsWrapper.value?.getBoundingClientRect().bottom ?? 0) < window.innerHeight && (topicsWrapper.value && topics.value?.pagination.nextPage) && !isFeedLoading.value) {
    getFeed()
  }
}

onMounted(() => {
  getFeed()
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped>
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.bg-zinc-700 {
  background-image: linear-gradient(
    to right,
    #2a2a2a 0%,
    #3a3a3a 20%,
    #2a2a2a 40%,
    #2a2a2a 100%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
}
</style>