import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "min-h-screen text-white" }
const _hoisted_2 = { class: "max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 md:pb-16" }
const _hoisted_3 = { class: "mb-16" }
const _hoisted_4 = { class: "flex items-center justify-between mb-8" }
const _hoisted_5 = {
  id: "categories",
  class: "mb-16"
}
const _hoisted_6 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6" }
const _hoisted_7 = { class: "w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500 to-purple-600 flex items-center justify-center mb-4 group-hover:scale-110 transition-transform" }
const _hoisted_8 = { class: "text-md sm:text-lg font-bold mb-2" }
const _hoisted_9 = { class: "text-gray-400 text-sm mb-4" }
const _hoisted_10 = { class: "flex items-center justify-between" }

import { PlayCircleIcon, ArrowRightIcon,BookOpenIcon, CodeIcon, BrushIcon, CameraIcon, SparklesIcon, } from 'lucide-vue-next'
import CourseGrid from '../courses/components/CourseGrid.vue';
import { onMounted, ref } from 'vue';
import { useAxios } from '@/composables/useAxios';
import CourseGridSkeleton from '../courses/components/CourseGridSkeleton.vue';
import HomeHeroBanner from './components/HomeHeroBanner.vue';
import HomeHeroBannerSkeleton from './components/HomeHeroBannerSkeleton.vue';
import { Pagination } from '@/interfaces/Pagination';
import { Course } from '@/interfaces/Course';
import { CourseCategoryDetails } from '@/enums/course/CourseCategoryEnum';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const courses = ref<Pagination<Course>>()
const axios = useAxios()

// const featuredCourses = [
//   {
//     id: 1,
//     title: 'Masterclass de Photoshop',
//     description: 'Aprenda técnicas avançadas de manipulação de imagens e composição digital.',
//     image: 'https://images.unsplash.com/photo-1611926653458-09294b3142bf?auto=format&fit=crop&q=80',
//     level: 'Avançado',
//     levelClass: 'bg-purple-500/20 text-purple-400',
//     lessons: 42,
//     duration: '12h 30min',
//     students: '2.1k alunos'
//   },
//   {
//     id: 2,
//     title: 'Design UI/UX Completo',
//     description: 'Do wireframe ao protótipo final: aprenda a criar interfaces modernas e funcionais.',
//     image: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?auto=format&fit=crop&q=80',
//     level: 'Intermediário',
//     levelClass: 'bg-blue-500/20 text-blue-400',
//     lessons: 38,
//     duration: '15h 45min',
//     students: '1.8k alunos'
//   },
//   {
//     id: 3,
//     title: 'Figma para Iniciantes',
//     description: 'Comece sua jornada no design de interfaces com a ferramenta mais utilizada do mercado.',
//     image: 'https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?auto=format&fit=crop&q=80',
//     level: 'Iniciante',
//     levelClass: 'bg-green-500/20 text-green-400',
//     lessons: 24,
//     duration: '8h 15min',
//     students: '3.4k alunos'
//   },
//   {
//     id: 4,
//     title: 'Motion Graphics com After Effects',
//     description: 'Crie animações impressionantes e efeitos visuais para vídeos e apresentações.',
//     image: 'https://images.unsplash.com/photo-1550751827-4bd374c3f58b?auto=format&fit=crop&q=80',
//     level: 'Intermediário',
//     levelClass: 'bg-yellow-500/20 text-yellow-400',
//     lessons: 36,
//     duration: '14h 20min',
//     students: '1.5k alunos'
//   },
//   {
//     id: 5,
//     title: 'Fotografia para Redes Sociais',
//     description: 'Aprenda a tirar e editar fotos incríveis para impulsionar seu perfil nas redes sociais.',
//     image: 'https://images.unsplash.com/photo-1554048612-b6a482bc67e5?auto=format&fit=crop&q=80',
//     level: 'Iniciante',
//     levelClass: 'bg-green-500/20 text-green-400',
//     lessons: 28,
//     duration: '9h 45min',
//     students: '2.7k alunos'
//   },
//   {
//     id: 6,
//     title: 'Ilustração Digital Avançada',
//     description: 'Domine técnicas avançadas de ilustração digital e crie artes profissionais.',
//     image: 'https://images.unsplash.com/photo-1607799279861-4dd421887fb3?auto=format&fit=crop&q=80',
//     level: 'Avançado',
//     levelClass: 'bg-purple-500/20 text-purple-400',
//     lessons: 45,
//     duration: '18h 30min',
//     students: '1.2k alunos'
//   }
// ]

function getCourses(page = 1) {
  axios.get('/courses', { params: { page, featuredInStore: true } })
    .then((res) => {
      courses.value = res.data
    })
}


onMounted(() => {
  getCourses()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (courses.value)
      ? (_openBlock(), _createBlock(HomeHeroBanner, { key: 0 }))
      : (_openBlock(), _createBlock(HomeHeroBannerSkeleton, { key: 1 })),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-xl sm:text-2xl font-bold" }, "Cursos em Destaque", -1)),
          _createVNode(_component_RouterLink, {
            to: { name: 'courses.all' },
            class: "text-blue-400 hover:text-blue-300 text-xs sm:text-sm font-medium flex items-center"
          }, {
            default: _withCtx(() => [
              _cache[0] || (_cache[0] = _createTextVNode(" Ver todos os cursos ")),
              _createVNode(_unref(ArrowRightIcon), { class: "w-4 h-4 ml-1" })
            ]),
            _: 1
          })
        ]),
        (courses.value)
          ? (_openBlock(), _createBlock(CourseGrid, {
              key: 0,
              courses: courses.value.data
            }, null, 8, ["courses"]))
          : (_openBlock(), _createBlock(CourseGridSkeleton, { key: 1 }))
      ]),
      _createElementVNode("section", _hoisted_5, [
        _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-xl sm:text-2xl font-bold mb-8" }, "Trilhas de Aprendizado", -1)),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseCategoryDetails), (detail, index) => {
            return (_openBlock(), _createBlock(_component_RouterLink, {
              to: { name: 'courses.all', query: { category: index } },
              key: index,
              class: "group bg-zinc-900/50 backdrop-blur p-6 rounded-xl border border-gray-800 hover:border-blue-500/50 transition-all duration-300"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(detail.icon), { class: "w-6 h-6 text-white" }))
                ]),
                _createElementVNode("h3", _hoisted_8, _toDisplayString(detail.label), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(detail.description), 1),
                _createElementVNode("div", _hoisted_10, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
                  _createVNode(_unref(ArrowRightIcon), { class: "w-5 h-5 text-gray-400 group-hover:text-blue-400 group-hover:translate-x-1 transition-all" })
                ])
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})