import { Course } from "@/interfaces/Course"
import { useAxios } from "./useAxios"
import router from "@/router"

const axios = useAxios()

export function useCourse() {
  function redirectToCurrentLesson(course: Course) {
    axios.get(`/courses/${course.id}/modules/lessons/latest-progress`)
      .then((res) => {
        router.push({ name: 'lessons.show', params: { lessonId: res.data.lesson.id, moduleId: res.data.lesson.moduleId, courseId: course.id } })
      })
  }

  return {
    redirectToCurrentLesson
  }
}