import { useAxios } from "./useAxios"

const apiRestAxios = useAxios(process.env.VUE_APP_API_REST_BASE_URL)

export function useVariant() {
  async function getVariantsByProductId(productId: number, organizationId: number) {
    const response = await apiRestAxios.get(`/organizations/${organizationId}/products/${productId}/variants`)

    return response.data
  }

  return {
    getVariantsByProductId
  }
}