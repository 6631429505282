import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-24" }
const _hoisted_3 = { class: "relative rounded-2xl overflow-hidden" }
const _hoisted_4 = { class: "relative z-10 p-12" }
const _hoisted_5 = { class: "max-w-2xl" }
const _hoisted_6 = { class: "inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-500/20 text-blue-400 mb-4" }
const _hoisted_7 = { class: "flex flex-col sm:flex-row sm:items-center gap-4" }
const _hoisted_8 = {
  href: "#categories",
  class: "bg-gradient-to-r from-blue-500 to-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:opacity-90 transition-opacity flex items-center justify-center"
}

import { PlayCircleIcon, SparklesIcon } from 'lucide-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeHeroBanner',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "absolute inset-0" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "absolute inset-0" }, [
          _createElementVNode("img", {
            src: "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?auto=format&fit=crop&q=80",
            alt: "Banner",
            class: "w-full h-full object-cover"
          }),
          _createElementVNode("div", { class: "absolute inset-0 bg-gradient-to-r from-black via-black/80 to-transparent" })
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_unref(SparklesIcon), { class: "w-4 h-4 mr-2" }),
              _cache[0] || (_cache[0] = _createTextVNode(" Novo Lançamento "))
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-3xl sm:text-4xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent" }, " Desbloqueie seu Potencial Criativo ", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-md sm:text-xl text-gray-300 mb-8 max-w-xl" }, " Aprenda com especialistas e domine as ferramentas essenciais para sua jornada criativa. Comece sua transformação hoje. ", -1)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("a", _hoisted_8, [
                _createVNode(_unref(PlayCircleIcon), { class: "w-5 h-5 mr-2" }),
                _cache[1] || (_cache[1] = _createTextVNode(" Começar Agora "))
              ]),
              _createVNode(_component_RouterLink, {
                to: { name: 'courses.all' },
                class: "justify-center text-center bg-white/10 backdrop-blur text-white px-8 py-3 rounded-lg font-medium hover:bg-white/20 transition-all"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Ver Catálogo ")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})