<template>
  <div class="relative sm:min-h-[357px] max-h-[257px] w-full bg-zinc-800 flex items-center justify-center" :class="{ 'min-h-[120px]': !banner }">
    <template v-if="!displayTrailer">
      <img 
        v-if="banner" 
        :src="banner" 
        alt="Course banner"
        class="w-full h-full object-cover"
      />
      <div v-else class="text-zinc-400 text-lg">Sem imagem disponível</div>
    </template>
    <div class="absolute inset-0 bg-gradient-to-t from-zinc-900 via-zinc-900/80 to-transparent"></div>
    <button 
      @click="$emit('close')"
      class="absolute top-4 right-4 p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors z-10"
    >
      <XIcon class="w-6 h-6 text-white" />
    </button>
    <template v-if="trailer && displayTrailer">
      <iframe ref="iframeRef" v-if="trailerOrigin === CourseVideoTypeEnum.HOOPAY" class="w-full h-full border-0 absolute top-0" :src="trailer" title="HooPay video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <iframe ref="iframeRef" v-if="trailerOrigin === CourseVideoTypeEnum.YOUTUBE" :src="trailer" class="w-full h-full absolute top-0" />
      <iframe ref="iframeRef" v-if="trailerOrigin === CourseVideoTypeEnum.VIMEO" :src="trailer" class="w-full h-full absolute top-0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen />
      <iframe ref="iframeRef" v-if="trailerOrigin === CourseVideoTypeEnum.PANDA_VIDEO" :src="trailer" class="w-full h-full absolute top-0" />
      <iframe ref="iframeRef" v-if="trailerOrigin === CourseVideoTypeEnum.EPLAY" :src="trailer" class="w-full h-full absolute top-0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { CourseVideoTypeEnum } from '@/enums/course/CourseVideoTypeEnum';
import { XIcon } from 'lucide-vue-next';
import { displayTrailer } from '../states/CoursePreviewState';
import { ref, watch, onMounted } from 'vue';

const iframeRef = ref<HTMLIFrameElement | null>(null);

const playVideo = () => {
  if (iframeRef.value) {
    const iframe = iframeRef.value;
    const iframeSrc = iframe.src;

    // Enviar o comando de play para o iframe, dependendo do seu origin
    if (iframeSrc.includes('youtube.com')) {
      iframe.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    } else if (iframeSrc.includes('vimeo.com')) {
      iframe.contentWindow?.postMessage({ method: 'play' }, '*');
    }
    // Adicionar casos específicos para outros players se necessário.
  }
};

// Usar um watcher para observar `displayTrailer` e dar play quando for `true`
watch(() => displayTrailer, (newValue) => {
  if (newValue) {
    playVideo();
  }
}, { immediate: true });

defineEmits(['close']);
defineProps<{ banner?: string, trailer: string, trailerOrigin: CourseVideoTypeEnum }>();
</script>

