<template>
  <div class="lg:flex items-start gap-8">
    <div class="flex-1">
      <div class="flex items-center gap-3 mb-4">
        <span class="bg-purple-500/20 text-purple-300 px-3 py-1 rounded-full text-sm font-medium">
          Mais Popular
        </span>
        <!-- <div class="flex items-center gap-1 text-amber-400">
          <StarIcon class="w-5 h-5 fill-current" />
          <span class="font-medium">{{ course.rating }}</span>
          <span class="text-zinc-400 text-sm">({{ course.reviewCount }})</span>
        </div> -->
      </div>

      <h1 class="text-3xl font-bold text-white mb-3">{{ course.title }}</h1>
      <p class="text-zinc-300 text-lg mb-6">{{ course.description }}</p>
      
      <div class="flex flex-wrap items-center gap-4 text-sm mb-6">
        <span 
          :class="{ 
            'bg-green-500/20 text-green-400': course.level === CourseLevelEnum.BEGINNER, 
            'bg-yellow-500/20 text-yellow-400': course.level === CourseLevelEnum.INTERMEDIATE, 
            'bg-purple-500/20 text-purple-400': course.level === CourseLevelEnum.ADVANCED, 
            'bg-red-500/20 text-red-400': course.level === CourseLevelEnum.EXPERT, 
          }"
          class="px-3 py-1 rounded-full font-medium"
        >
          {{ CourseLevelLabels[course.level] }}
        </span>
        <div v-if="course.duration" class="flex items-center gap-2 text-zinc-400">
          <ClockIcon class="w-4 h-4" />
          {{ formatInterval(course.duration) }}
        </div>
        <div class="flex items-center gap-2 text-zinc-400">
          <BookOpenIcon class="w-4 h-4" />
          {{ course.totalLessons }} aulas
        </div>
        <div v-if="course.displayTotalStudents" class="flex items-center gap-2 text-zinc-400">
          <UsersIcon class="w-4 h-4" />
          {{ course.totalStudents }} alunos
        </div>
      </div>

      <div v-if="course.creator" class="flex items-center gap-3 mb-8">
        <img
          :src="course.creator.avatar"
          alt="Instructor"
          class="w-12 h-12 rounded-full border-2 border-purple-500"
        />
        <div>
          <h3 class="text-white font-medium">{{ course.creator.name }}</h3>
          <p class="text-zinc-400 text-sm">{{ course.creator.description }}</p>
        </div>
      </div>
    </div>

    <!-- Pricing Card -->
    <CoursePrincingCard :course="course" />
  </div>
</template>

<script lang="ts" setup>
import { BookOpenIcon, ClockIcon, StarIcon, UsersIcon } from 'lucide-vue-next';
import CoursePrincingCard from './CoursePrincingCard.vue';
import { CourseLevelEnum, CourseLevelLabels } from '@/enums/course/CourseLevelEnum';
import { Course } from '@/interfaces/Course';
import { Duration } from '@/interfaces/Duration';

defineProps<{ course: Course }>()

function formatInterval(duration: Duration): string {
  let { seconds = 0, minutes = 0, hours = 0, days = 0 } = duration;

  // Converte os dias para horas
  hours += days * 24;

  // Se houver mais de 60s, converte para minutos
  minutes += Math.floor(seconds / 60);
  seconds %= 60;

  // Se houver mais de 60m, converte para horas
  hours += Math.floor(minutes / 60);
  minutes %= 60;

  // Gera a string legível com horas ou minutos
  if (hours > 0) {
      return `${hours}h`;
  } else if (minutes > 0) {
      return `${minutes}m`;
  } else {
      return `${seconds}s`;  // Exibe em segundos caso não haja minutos ou horas
  }
}
</script>