import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeHeroBannerSkeleton',
  setup(__props) {

// No additional logic needed for the skeleton

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"absolute inset-0 bg-gradient-to-b from-blue-900/20 to-purple-900/20 mix-blend-overlay\" data-v-b4a68212></div><div class=\"max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-24\" data-v-b4a68212><div class=\"relative rounded-2xl overflow-hidden\" data-v-b4a68212><div class=\"absolute inset-0 bg-zinc-800 animate-pulse\" data-v-b4a68212></div><div class=\"relative z-10 p-12\" data-v-b4a68212><div class=\"max-w-2xl\" data-v-b4a68212><div class=\"w-36 h-7 bg-blue-500/20 rounded-full mb-4\" data-v-b4a68212></div><div class=\"h-14 md:h-20 bg-gradient-to-r from-zinc-700 to-zinc-600 rounded-lg mb-6\" data-v-b4a68212></div><div class=\"space-y-3 mb-8\" data-v-b4a68212><div class=\"h-6 bg-zinc-700 rounded w-full\" data-v-b4a68212></div><div class=\"h-6 bg-zinc-700 rounded w-5/6\" data-v-b4a68212></div><div class=\"h-6 bg-zinc-700 rounded w-4/6\" data-v-b4a68212></div></div><div class=\"flex flex-col sm:flex-row sm:items-center gap-4\" data-v-b4a68212><div class=\"h-12 w-full sm:w-40 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg\" data-v-b4a68212></div><div class=\"h-12 w-full sm:w-40 bg-white/10 rounded-lg\" data-v-b4a68212></div></div></div></div></div></div>", 2)
  ])))
}
}

})