<template>
  <div class="sm:py-4">
    <Card class="col-span-12 p-6 mx-auto max-w-[1700px]">
      <div class="flex items-center justify-between">
        <div class="space-y-1">
          <h2 class="text-[1.1rem] font-semibold tracking-tight">
            Meus cursos
          </h2>
          <p class="text-sm text-muted-foreground">
            Explore todos os cursos que você adquiriu e comece sua jornada de aprendizado!
          </p>
        </div>
      </div>
      <Separator class="my-4" />

      <!-- Condição mais simples para garantir que courses.data existe e tem itens -->
      <div v-if="courses" class="relative">
        <div v-if="courses?.data && courses?.data.length > 0" class="relative">
          <div class="flex flex-col gap-14 sm:gap-0 sm:flex-row sm:space-x-2 md:space-x-4 flex-wrap pb-4">
            <a class="cursor-pointer" @click="redirectToCurrentLesson(course)" v-for="course in courses.data"
              :key="course.title">
              <CourseArtwork :course="course" class="w-full sm:w-[250px]" aspect-ratio="portrait" :width="250"
                :height="330" />
            </a>
          </div>
        </div>

        <!-- Estado vazio estilizado -->
        <div v-else class="flex flex-col items-center justify-center py-16 px-4">
          <div class="bg-white/5 rounded-full p-6 mb-6">
            <BookOpenIcon class="h-12 w-12" />
          </div>
          <h3 class="text-xl font-semibold mb-2 text-center">Nenhum curso encontrado</h3>
          <p class="text-muted-foreground text-center max-w-md mb-8 text-sm">
            Parece que você ainda não adquiriu nenhum curso. Explore nossa plataforma para descobrir conteúdos incríveis para sua jornada de aprendizado.
          </p>
          <button 
            @click="navigateToExplore" 
            class="text-white font-medium inline-flex items-center justify-center rounded-md bg-gradient-to-r from-blue-500 to-purple-600 px-6 py-3 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
          >
            <SearchIcon class="mr-2 h-4 w-4" />
            Explorar cursos disponíveis
          </button>
        </div>
      </div>

      <SpinnerLoader v-else />

      <PaginationApi v-if="courses && (courses?.pagination.total ?? 0) > 1" :data="courses.pagination"
        :paginate="(page) => { courses = undefined; getCourses(page) }" />
    </Card>
  </div>
</template>

<script setup lang="ts">
import Card from '@/components/ui/card/Card.vue';
import { useAxios } from '@/composables/useAxios';
import { Course } from '@/interfaces/Course';
import CourseArtwork from '@/views/home/components/CourseArtwork.vue';
import { Separator } from 'radix-vue';
import { onMounted, ref } from 'vue';
import { Pagination as IPagination } from '@/interfaces/Pagination'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue';
import router from '@/router';
import { useCourse } from '@/composables/useCourse';
import { BookOpenIcon, SearchIcon } from 'lucide-vue-next';

const axios = useAxios()
const courses = ref<IPagination<Course>>()
const { redirectToCurrentLesson } = useCourse()

function getCourses(page = 1) {
  axios.get('/courses', { params: { page, mine: true, owner: false } })
    .then((res) => {
      courses.value = res.data
    })
}

function navigateToExplore() {
  router.push({ name: 'courses.all' }); // Ajuste para a rota correta de exploração de cursos
}

onMounted(() => {
  getCourses()
})
</script>