import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 h-auto py-2" }

import { ref } from 'vue';
import CoursePreviewView from '../preview/CoursePreviewView.vue';
import CourseCard from './CourseCard.vue';
import { Course } from '@/interfaces/Course';


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseGrid',
  props: {
    courses: {}
  },
  setup(__props: any) {



const isCoursePreviewOpen = ref(false)
const selectedCourse = ref<Course>()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
        return (_openBlock(), _createBlock(CourseCard, {
          onClick: ($event: any) => {isCoursePreviewOpen.value = true; selectedCourse.value = course},
          course: course,
          key: course.id
        }, null, 8, ["onClick", "course"]))
      }), 128))
    ]),
    _createVNode(CoursePreviewView, {
      course: selectedCourse.value,
      open: isCoursePreviewOpen.value,
      "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((isCoursePreviewOpen).value = $event))
    }, null, 8, ["course", "open"])
  ], 64))
}
}

})