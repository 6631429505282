import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "relative z-20 p-4 sm:p-6" }
const _hoisted_3 = { class: "items-center justify-between mb-4 hidden sm:flex" }
const _hoisted_4 = { class: "text-gray-400 text-sm" }
const _hoisted_5 = { class: "text-md sm:text-xl font-bold mb-2" }
const _hoisted_6 = { class: "text-gray-400 text-xs sm:text-sm mb-2 sm:mb-4 line-clamp-2" }
const _hoisted_7 = { class: "flex items-center justify-between" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "text-gray-400 text-sm line-through"
}
const _hoisted_10 = { class: "font-semibold text-lg text-white" }

import { CourseLevelEnum, CourseLevelLabels } from '@/enums/course/CourseLevelEnum';
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum';
import { Course } from '@/interfaces/Course';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseCard',
  props: {
    course: {}
  },
  emits: ['click'],
  setup(__props: any) {


const props = __props

const courseMainImage = computed(() => props.course?.media?.find((m => m.type === CourseMediaTypeEnum.MAIN)))

const displayPrice = computed(() => {
  return props.course.variant.promotionalPrice || props.course.variant.price;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', true))),
    class: "min-w-[100px] sm:min-w-[unset] group relative bg-gray-900 rounded-xl overflow-hidden hover:transform hover:scale-[1.02] transition-all duration-300 cursor-pointer"
  }, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent z-10" }, null, -1)),
    _createElementVNode("img", {
      src: courseMainImage.value?.bucketLocation,
      alt: _ctx.course.title,
      class: "w-full h-[140px] sm:h-[330px] object-cover"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", {
          class: _normalizeClass(["inline-block px-3 py-1 rounded-full text-xs font-medium", { 
            'bg-green-500/20 text-green-400': _ctx.course.level === _unref(CourseLevelEnum).BEGINNER, 
            'bg-yellow-500/20 text-yellow-400': _ctx.course.level === _unref(CourseLevelEnum).INTERMEDIATE, 
            'bg-purple-500/20 text-purple-400': _ctx.course.level === _unref(CourseLevelEnum).ADVANCED, 
            'bg-red-500/20 text-red-400': _ctx.course.level === _unref(CourseLevelEnum).EXPERT, 
          }])
        }, _toDisplayString(_unref(CourseLevelLabels)[_ctx.course.level]), 3),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.course.duration), 1)
      ]),
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.course.title), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.course.introduction), 1),
      _createElementVNode("div", _hoisted_7, [
        (!_ctx.course.upcoming)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_ctx.course.variant.promotionalPrice)
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.course.variant.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_10, _toDisplayString(displayPrice.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1)
            ]))
          : _createCommentVNode("", true),
        _cache[1] || (_cache[1] = _createElementVNode("button", { class: "text-blue-400 hover:text-blue-300 text-sm font-medium hidden sm:block" }, " Ver Mais → ", -1))
      ])
    ])
  ]))
}
}

})