import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full lg:grid lg:grid-cols-1 min-h-[100vh] pt-14 sm:pt-0" }
const _hoisted_2 = { class: "flex items-center justify-center py-12 sm:pt-[150px]" }
const _hoisted_3 = { class: "mx-auto grid w-full p-4 sm:p-0 sm:w-[390px] gap-14" }
const _hoisted_4 = { class: "grid gap-2 text-center" }
const _hoisted_5 = { class: "flex-col sm:flex-row flex items-center gap-3 mx-auto" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "grid gap-4" }
const _hoisted_8 = { class: "grid gap-2" }
const _hoisted_9 = { class: "grid gap-2" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "mt-4 text-center text-sm" }
const _hoisted_12 = {
  key: 0,
  class: "text-center text-xs pb-10"
}

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/toast/use-toast'
import { Toaster } from '@/components/ui/toast'
import { onMounted, ref } from 'vue'
import { Loader2 } from 'lucide-vue-next'
import { useAuth } from '@/composables/useAuth'
import { useTenant } from '@/composables/useTenant'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const { toast } = useToast()
const loginPayload = ref({ email: '', password: '' })
const { login, isAuthLoading } = useAuth()
const { tenantFavicon, initializeTenant, isTradersPlatform } = useTenant()

onMounted(() => {
  initializeTenant()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_unref(tenantFavicon))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _unref(tenantFavicon).bucketLocation,
                    height: "38",
                    width: "38",
                    class: ""
                  }, null, 8, _hoisted_6))
                : _createCommentVNode("", true),
              _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-3xl font-bold" }, " Área de Membros ", -1))
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-muted-foreground" }, " Digite seu email e senha abaixo para fazer login ", -1))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(Label), { for: "email" }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Email")
                ])),
                _: 1
              }),
              _createVNode(_unref(Input), {
                id: "email",
                type: "email",
                placeholder: "ex: email@exemplo.com",
                required: "",
                modelValue: loginPayload.value.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((loginPayload.value.email) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_unref(Label), { for: "password" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Senha")
                  ])),
                  _: 1
                }),
                _createVNode(_component_RouterLink, {
                  to: { name: 'auth.password-recovery' },
                  class: "ml-auto inline-block text-sm"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" Esqueceu sua senha? ")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_unref(Input), {
                id: "password",
                type: "password",
                required: "",
                modelValue: loginPayload.value.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((loginPayload.value.password) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_unref(Button), {
              type: "submit",
              class: "w-full py-6 mt-6",
              disabled: _unref(isAuthLoading),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(login)(
              loginPayload.value,
              () => {
                if (_unref(isTradersPlatform)) {
                  _ctx.$router.push({ name: 'trader.home' })
                } else {
                  _ctx.$router.push({ name: 'home' })
                }
              },
              () => _unref(toast)({
                title: 'Login falhou',
                description: 'credenciais inválidas!',
                variant: 'destructive'
              })
            )))
            }, {
              default: _withCtx(() => [
                (_unref(isAuthLoading))
                  ? (_openBlock(), _createBlock(_unref(Loader2), {
                      key: 0,
                      class: "w-4 h-4 mr-2 animate-spin"
                    }))
                  : _createCommentVNode("", true),
                _cache[8] || (_cache[8] = _createTextVNode(" Entrar "))
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[10] || (_cache[10] = _createTextVNode(" Não tem uma conta? ")),
            _createVNode(_component_RouterLink, {
              to: { name: 'register' },
              class: "underline"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" Cadastrar-se ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      (_unref(isTradersPlatform))
        ? (_openBlock(), _createElementBlock("p", _hoisted_12, "© 2024 Traders Cup Brasil 29.435.748/0001-07. Todos os direitos reservados."))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_unref(Toaster))
  ], 64))
}
}

})