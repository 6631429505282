<template>
  <div class="flex flex-col sm:gap-4">
    <main class="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 max-w-[1700px] mx-auto w-full max-h-full">
      <Tabs default-value="course" :model-value="selectedTab">
        <div class="mx-auto grid max-w-full flex-1 auto-rows-max gap-4">
          <TabsList class="hidden">
            <TabsTrigger value="course" @click="selectedTab = 'course';">
              Curso
            </TabsTrigger>
            <TabsTrigger value="modules" @click="selectedTab = 'modules'; moduleToEdit = undefined; resetModuleForm()">
              Módulos
            </TabsTrigger>
            <TabsTrigger value="lesson" @click="selectedTab = 'lesson';">
              Aula
            </TabsTrigger>
          </TabsList>
          <TabsContent value="course" class="mt-0">
            <div class="absolute w-full left-0 right-0 h-auto" v-if="!isLoadingCourse">
              <input class="hidden" type="file" @change="onBackgroundImageChange" id="backgroundImage">
              <img v-if="courseMediaPreviews.backgroundImage || courseBackgroundImage?.bucketLocation" :src="courseMediaPreviews.backgroundImage ? courseMediaPreviews.backgroundImage : courseBackgroundImage?.bucketLocation" class="object-cover w-full max-w-full h-80 absolute" />
              <div v-else class="object-cover w-full max-w-full h-80 absolute bg-zinc-500"></div>
              <label for="backgroundImage" class="absolute top-8 -translate-y-1/2 -translate-x-1/2 left-1/2 bg-muted rounded-full p-4 cursor-pointer">
                <ImageUp v-if="!courseBackgroundImage?.bucketLocation && !courseMediaPreviews.backgroundImage" class="w-4 h-4" />
                <Pencil v-else class="w-4 h-4" />
              </label>
            </div>
            <Form
              v-if="!isLoadingCourse"
              v-slot="{ meta, values, validate }"
              as="" keep-values :validation-schema="toTypedSchema(formSchema)"
            >
              <form @submit.prevent="() => {
                validate();

                if (values.videoOriginType === CourseVideoTypeEnum.HOOPAY && !formValues.videoFile) {
                  toast({
                    title: 'Upload de vídeo de introdução é obrigatório',
                    variant: 'destructive'
                  })
                }

                if (meta.valid) {
                  updateCourse(formValues)
                }
              }" class="grid gap-4 grid-cols-1 md:grid-cols-[1fr_250px] xl:grid-cols-5 lg:gap-8 z-40 relative mt-[14rem]" >
                <div class="grid auto-rows-max items-start gap-4 col-span-2 lg:col-span-2 lg:gap-8 cols-span-2">
                  <Card>
                    <!-- <CardHeader>
                      <CardTitle>Editar curso</CardTitle>
                      <CardDescription>Informe os dados do curso</CardDescription>
                    </CardHeader> -->
                    <CardContent>
                      <div class="grid gap-6 pt-[240px] relative">
                        <div class="absolute left-1/2 -translate-x-1/2 -top-[130px]">
                          <ImageInput v-model="courseMediaForm.image" :defaultPreview="courseImage?.bucketLocation" :width="'250px'" :height="'333px'" />
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="title" class="col-span-1">
                            <FormItem>
                              <FormLabel>Título</FormLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  class="w-full"
                                  placeholder="ex: Design criativo, de ideias a realidade!"
                                  v-bind="componentField"
                                  v-model="formValues.title"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="introduction" class="col-span-1">
                            <FormItem>
                              <FormLabel>Texto de Introdução</FormLabel>
                              <FormControl>
                                <Textarea
                                  placeholder="ex: Neste curso, você irá explorar o fascinante mundo do design..."
                                  class="min-h-32"
                                  v-bind="componentField"
                                  v-model="formValues.introduction"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="description" class="col-span-1">
                            <FormItem>
                              <FormLabel>Descrição</FormLabel>
                              <FormControl>
                                <Textarea
                                  placeholder="ex: Aprenda métodos práticos, fundamentos, a receber feedback..."
                                  class="min-h-32"
                                  v-bind="componentField"
                                  v-model="formValues.description"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField name="learningOutcomes" class="col-span-1">
                            <FormItem>
                              <FormLabel>O que os alunos irão aprender</FormLabel>
                              <FormControl>
                                <TagsInput @enter.prevent v-model="formValues.learningOutcomes">
                                  <TagsInputItem v-for="item in formValues.learningOutcomes" :key="item" :value="item">
                                    <TagsInputItemText />
                                    <TagsInputItemDelete />
                                  </TagsInputItem>

                                  <TagsInputInput placeholder="Você se especializará em..." />
                                </TagsInput>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField name="prerequisites" class="col-span-1">
                            <FormItem>
                              <FormLabel>Pré-requisitos</FormLabel>
                              <FormControl>
                                <TagsInput v-model="formValues.prerequisites">
                                  <TagsInputItem v-for="item in formValues.prerequisites" :key="item" :value="item">
                                    <TagsInputItemText />
                                    <TagsInputItemDelete />
                                  </TagsInputItem>

                                  <TagsInputInput placeholder="Computador com acesso à internet..." />
                                </TagsInput>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="category">
                            <FormLabel for="level">Categoria</FormLabel>
                            <Select v-model="formValues.category">
                              <SelectTrigger id="category" aria-label="Selecionar a categoria">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem v-for="category in CourseCategoryEnum" :value="category">{{ CourseCategoryLabels[category] }}</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="level">
                            <FormLabel for="level">Nível</FormLabel>
                            <Select v-model="formValues.level">
                              <SelectTrigger id="level" aria-label="Selecionar o nível">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem v-for="level in CourseLevelEnum" :value="level">{{ CourseLevelLabels[level] }}</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                        <!-- <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="duration" class="col-span-1">
                            <FormItem>
                              <FormLabel>Duração</FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  class="w-full"
                                  placeholder="ex: 10"
                                  v-bind="componentField"
                                  v-model="formValues.duration"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="limitClientsFree" class="col-span-1">
                            <FormItem>
                              <FormLabel>Clientes gratuitos</FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  class="w-full"
                                  placeholder="ex: 10"
                                  v-bind="componentField"
                                  v-model="formValues.limitClientsFree"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div> -->
                        <div class="grid grid-cols-2 xl:grid-cols-3 gap-y-4">
                          <div class="flex items-center gap-2">
                            <FormField v-slot="{ componentField }" name="upcoming">
                              <Switch v-bind="componentField"
                                      :checked="formValues.upcoming"
                                      @update:checked="(checked) => formValues.upcoming = checked" />
                              <FormLabel class="flex items-center gap-2" for="upcoming">
                                Em breve
                                <TooltipProvider>
                                  <Tooltip :delay-duration="100">
                                    <TooltipTrigger as-child>
                                      <CircleHelp class="size-5" />
                                    </TooltipTrigger>
                                    <TooltipContent side="right" :side-offset="5">
                                        Exiba o curso de forma visível, mas sem a opção de compra, apenas com fins informativos.
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </FormLabel>
                            </FormField>
                          </div>
                          <div class="flex items-center space-x-2">
                            <FormField v-slot="{ componentField }" name="hasReviews">
                              <Switch :checked="formValues.hasReviews"
                              @update:checked="(checked) => formValues.hasReviews = checked" v-bind="componentField" />
                              <Label for="certification">Avaliações</Label>
                            </FormField>
                          </div>
                          <div class="flex items-center space-x-2">
                            <FormField v-slot="{ componentField }" name="displayTotalStudents">
                              <Switch v-bind="componentField" :checked="formValues.displayTotalStudents"
                              @update:checked="(checked) => formValues.displayTotalStudents = checked" />
                              <Label for="certification">Exibir total de alunos</Label>
                            </FormField>
                          </div>
                          <div class="flex items-center space-x-2">
                            <FormField v-slot="{ componentField }" name="certification">
                              <Switch v-bind="componentField"
                                      :checked="formValues.certification"
                                      @update:checked="(checked) => formValues.certification = checked" />
                              <FormLabel for="certification">Certificado</FormLabel>
                            </FormField>
                          </div>

                          <div class="flex items-center space-x-2">
                            <FormField v-slot="{ componentField }" name="featuredInStore">
                              <Switch v-bind="componentField"
                                      :checked="formValues.featuredInStore"
                                      @update:checked="(checked) => formValues.featuredInStore = checked" />
                              <FormLabel for="featuredInStore">Loja</FormLabel>
                            </FormField>
                          </div>
                        </div>
                        <Button type="submit">
                          <Loader2 v-if="isUpdatingCourse" class="w-4 h-4 mr-2 animate-spin" />
                          Salvar
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle class="text-[1.1rem]">Configurações</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div class="grid gap-6 min-w-[300px]">
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="status">
                            <Label for="status">Status</Label>
                            <Select v-bind="componentField" v-model="formValues.status">
                              <SelectTrigger id="status" aria-label="Selecionar status">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem v-for="status in CourseStatusEnum" :value="status">{{ courseStatusLabels[status] }}</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="commentsType">
                            <Label for="commentsType">Comentários</Label>
                            <Select v-bind="componentField" v-model="formValues.commentsType">
                              <SelectTrigger id="commentsType" aria-label="Selecionar tipo de comentários">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem v-for="type in CourseCommentsTypeEnum" :value="type">{{ commentsTypeLabels[type] }}</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="communityType">
                            <Label for="communityType">Tipo de comunidade</Label>
                            <Select v-bind="componentField" v-model="formValues.communityType">
                              <SelectTrigger id="communityType" aria-label="Selecionar tipo de comunidade">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Aberto">Aberto</SelectItem>
                                <SelectItem value="Fechado">Fechado</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <Card class="overflow-hidden">
                    <CardHeader>
                      <CardTitle class="text-[1.1rem]">Mídias</CardTitle>
                      <CardDescription>Imagem e vídeo do curso</CardDescription>
                    </CardHeader>
                    <CardContent>
                      <div class="flex flex-col gap-6">
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="videoOriginType">
                            <FormLabel for="contentType">Origem do vídeo</FormLabel>
                            <Select v-bind="componentField" v-model="formValues.videoOriginType">
                              <SelectTrigger id="contentType" aria-label="Selecionar">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem v-for="type in CourseVideoTypeEnum" :value="type">{{ courseVideoTypeLabels[type] }}</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                        <VideoUpload :current-video-src="formValues.video" :current-video-origin="course?.videoOriginType" v-if="formValues.videoOriginType === CourseVideoTypeEnum.HOOPAY" v-model="formValues.videoFile" />
                        <div v-if="formValues.videoOriginType !== CourseVideoTypeEnum.HOOPAY" class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="video">
                            <FormLabel>Vídeo de Introdução</FormLabel>
                            <Input
                              type="text"
                              class="w-full"
                              placeholder="ex: https://youtube.com..."
                              v-bind="componentField"
                              v-model="formValues.video"
                            />
                            <FormMessage />
                          </FormField>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div class="sm:grid auto-rows-max items-start gap-4 lg:gap-8 sm:col-span-3">
                  <Card>
                    <CardHeader>
                      <div class="flex justify-between items-center">
                        <CardTitle class="text-[1.1rem]">Módulos</CardTitle>
                        <Button @click="selectedTab = 'modules'; moduleToEdit = undefined; resetModuleForm()">Adicionar</Button>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <div class="grid gap-6 flex-wrap" v-if="course?.modules.length">
                        <draggable
                          v-model="course.modules"
                          group="modules"
                          @start="drag=true"
                          @end="onEndDragModule"
                          item-key="id"
                          handle=".handle"
                        >
                          <template #item="{element, index}">
                            <div class="flex flex-col gap-4 border-b">
                              <div class="flex items-center justify-between hover:bg-muted transition-background duration-200 p-4">
                                <div class="flex items-center gap-6">
                                  <div>
                                    <h3>{{ element.title }}</h3>
                                    <p class="text-zinc-400 text-xs">{{ element.description }}</p>
                                  </div>
                                </div>
                                <div class="flex items-center gap-2">
                                  <div class="flex justify-center flex-col gap-2 pr-4">
                                    <Badge class="text-center justify-center inline-flex">{{ moduleStatusLabels[element.status as ModuleStatusEnum] }}</Badge>
                                    <Badge class="bg-yellow-500 hover:bg-yellow-500" v-if="element.upcoming">Conteúdo em breve</Badge>
                                  </div>
                                  <TooltipProvider>
                                    <Tooltip :delay-duration="100">
                                      <TooltipTrigger as-child>
                                        <div>
                                          <button class="handle bg p-2 rounded-lg bg-gray-500 mr-2">
                                            <ChevronsUpDown class="w-4 h-4" />
                                          </button>
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent side="top" :side-offset="5">
                                        Arraste para mudar a ordem
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                  <TooltipProvider>
                                    <Tooltip :delay-duration="100">
                                      <TooltipTrigger as-child>
                                        <button class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground h-10 w-10 rounded-lg hover:bg-emerald-500/10"
                                        @click.prevent="selectedTab = 'modules'; moduleToEdit = undefined; getModuleById(element.id)">
                                          <Pencil class="w-4 h-4" />
                                        </button>
                                      </TooltipTrigger>
                                      <TooltipContent side="top" :side-offset="5">
                                        Editar
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                  <TooltipProvider>
                                    <Tooltip :delay-duration="100">
                                      <TooltipTrigger as-child>
                                        <button class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground h-10 w-10 rounded-lg hover:bg-red-500/10"
                                        @click.prevent="isDeleteModuleDialogConfirmationOpen = true; selectedModuleIdToDelete = element.id; resetDeleteModuleForm()">
                                          <Trash2 class="w-4 h-4" />
                                        </button>
                                      </TooltipTrigger>
                                      <TooltipContent side="top" :side-offset="5">
                                        Excluir
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                </div>
                              </div>
                            </div>
                          </template>
                        </draggable>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </form>
            </Form>
            <SpinnerLoader v-else />
            <Dialog v-if="selectedModuleIdToDelete" :open="isDeleteModuleDialogConfirmationOpen" @update:open="(open) => isDeleteModuleDialogConfirmationOpen = open">
              <DialogContent class="sm:max-w-md">
                <DialogHeader>
                  <div class="flex gap-2 items-center">
                    <AlertTriangle class="h-6 w-6" />
                    <DialogTitle class="text-[1.1rem]">Cuidado</DialogTitle>
                  </div>
                  <DialogDescription class="py-2 pb-4">
                    A exclusão deste módulo é permanente. Você tem certeza de que quer prosseguir?
                  </DialogDescription>
                  <div class="flex flex-col gap-6">
                    <div class="flex items-center space-x-2">
                      <Switch id="DeleteLessons" :checked="deleteModuleFormValues.deleteLessons"
                      @update:checked="(checked) => deleteModuleFormValues.deleteLessons = checked" />
                      <Label for="deleteLessons">excluir aulas</Label>
                    </div>
                    <div class="grid gap-3" v-if="!deleteModuleFormValues.deleteLessons">
                      <Label>Transferir aulas</Label>
                      <Select v-model="deleteModuleFormValues.moduleId">
                        <SelectTrigger class="w-full">
                          <SelectValue placeholder="Selecionar" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem v-for="module in course?.modules.filter(m => m.id !== selectedModuleIdToDelete)" :key="module.id" :value="String(module.id)">
                            {{ module.title }}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </DialogHeader>
                <div class="flex items-center space-x-2">

                </div>
                <DialogFooter class="sm:justify-start">
                  <DialogClose as-child>
                    <Button type="button" variant="secondary">
                      Cancelar
                    </Button>
                  </DialogClose>
                  <Button type="button" :disabled="isDeletingModule || (!deleteModuleFormValues.deleteLessons && !deleteModuleFormValues.moduleId)" @click="deleteModule(selectedModuleIdToDelete)">
                    <Loader2 v-if="isDeletingModule" class="w-4 h-4 mr-2 animate-spin" />
                      Excluir Módulo
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </TabsContent>

          <!-- Modules -->
          <TabsContent v-if="!isLoadingCourse && !isLoadingModule" value="modules">
            <Form
              v-slot="{ meta, values, validate }"
              as="" keep-values :validation-schema="toTypedSchema(moduleToEdit ? editModuleFormSchema : moduleFormSchema)"
            >
              <form @submit.prevent="() => {
                validate();
                if (meta.valid) {
                  moduleToEdit ? updateModule(moduleToEdit!.id, moduleFormValues) : createModule(moduleFormValues)
                }
              }" class="col-span-1 flex flex-wrap flex-col sm:grid sm:gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-4 lg:gap-8" >
                <div class="sm:grid auto-rows-max items-start sm:gap-4 lg:col-span-2 lg:gap-8">
                  <Card>
                    <CardHeader>
                      <div class="flex items-center justify-between">
                        <CardTitle class="text-[1.1rem]">{{ moduleToEdit ? 'Editar módulo' : 'Adicionar módulo' }}</CardTitle>
                        <Button variant="secondary" @click="selectedTab = 'course';">Voltar</Button>
                      </div>
                      <CardDescription>Detalhes do Módulo</CardDescription>
                    </CardHeader>
                    <CardContent>
                      <div class="grid gap-6">
                        <!-- <div class="">
                          <FormField v-slot="{ componentField }" name="image" class="col-span-1">
                            <FormItem>
                              <FormControl>
                                <ImageInput v-bind="componentField" v-model="moduleFormValues.image" :defaultPreview="(moduleToEdit?.image as ModuleMedia).bucketLocation" />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div> -->
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="title" class="col-span-1">
                            <FormItem>
                              <FormLabel>Título</FormLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  class="w-full"
                                  placeholder="ex: Fundamentos do Design Gráfico"
                                  v-bind="componentField"
                                  v-model="moduleFormValues.title"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="description" class="col-span-1">
                            <FormItem>
                              <FormLabel>Descrição</FormLabel>
                              <FormControl>
                                <Textarea
                                  placeholder="ex: Neste módulo, você mergulhará nos princípios essenciais do design gráfico..."
                                  class="min-h-32"
                                  v-bind="componentField"
                                  v-model="moduleFormValues.description"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="status">
                            <FormLabel for="status">Status</FormLabel>
                            <Select v-bind="componentField" v-model="moduleFormValues.status">
                              <SelectTrigger id="status" aria-label="Selecionar status">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem v-for="status in ModuleStatusEnum" :value="status">{{ moduleStatusLabels[status] }}</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                        <!-- <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="order" class="col-span-1">
                            <FormItem>
                              <FormLabel>Ordem</FormLabel>
                              <FormControl>
                                <Input
                                  min="1"
                                  type="number"
                                  class="w-full"
                                  placeholder="ex: 1"
                                  v-bind="componentField"
                                  v-model="moduleFormValues.order"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div> -->
                        <div class="flex justify-between">
                          <div class="flex items-center space-x-2">
                            <FormField v-slot="{ componentField }" name="upcoming">
                              <Switch v-bind="componentField"
                                      :checked="moduleFormValues.upcoming"
                                      @update:checked="(checked) => moduleFormValues.upcoming = checked" />
                              <FormLabel for="upcoming">Conteúdo em breve</FormLabel>
                            </FormField>
                          </div>
                        </div>
                        <Button type="submit" :disabled="isCreatingModule || isUpdatingModule">
                          <Loader2 v-if="isCreatingModule || isUpdatingModule" class="w-4 h-4 mr-2 animate-spin" />
                          {{ moduleToEdit ? 'Salvar' : 'Adicionar' }}
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div v-if="moduleToEdit" class="sm:grid auto-rows-max items-start gap-4 lg:gap-8 col-span-2 mt-8 sm:mt-0">
                  <Card>
                    <CardHeader>
                      <div class="flex justify-between items-center">
                        <CardTitle>Aulas</CardTitle>
                        <Button @click="selectedTab = 'lesson'; lessonToEdit = undefined; resetLessonForm()">Adicionar</Button>
                      </div>
                    </CardHeader>
                    <CardContent class="">
                      <div v-if="moduleToEdit.lessons && !moduleToEdit.lessons.length">
                        <p class="text-zinc-400 text-sm">Nenhuma aula registrada.</p>
                      </div>
                      <draggable 
                          v-model="moduleToEdit.lessons" 
                          group="lessons" 
                          @start="dragLesson=true" 
                          @end="onEndDragLesson" 
                          item-key="id"
                          handle=".handle"
                        >
                          <template #item="{element, index}">
                            <div class="flex flex-col gap-4 border-b">
                              <div class="flex items-center justify-between hover:bg-muted transition-background duration-200 sm:p-4">
                                <div class="flex gap-4">
                                  <!-- <div>
                                    <img class="object-fit max-w-20 h-full" :src="lesson.image" alt="">
                                  </div> -->
                                  <div>
                                    <h3>{{ element.title }}</h3>
                                    <p class="text-zinc-400 text-xs">{{ element.description }}</p>
                                  </div>
                                </div>
                                <div class="flex items-center gap-2">
                                  <div class="flex justify-center flex-col gap-2 pr-4">
                                    <Badge class="text-center justify-center">{{ lessonStatusLabels[element.status as LessonStatusEnum] }}</Badge>
                                    <Badge class="bg-yellow-500 hover:bg-yellow-500" v-if="element.upcoming">Conteúdo em breve</Badge>
                                  </div>
                                  <TooltipProvider>
                                    <Tooltip :delay-duration="100">
                                      <TooltipTrigger as-child>
                                        <div>
                                          <button class="handle bg p-2 rounded-lg bg-gray-500 mr-2">
                                            <ChevronsUpDown class="w-4 h-4" />
                                          </button>
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent side="top" :side-offset="5">
                                        Arraste para mudar a ordem
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                  <TooltipProvider>
                                    <Tooltip :delay-duration="100">
                                      <TooltipTrigger as-child>
                                        <button class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-emerald-500/10 hover:text-accent-foreground h-10 w-10 rounded-lg"
                                        @click.prevent="lessonToEdit = element; getLessonById(element.id); selectedTab = 'lesson'">
                                          <Pencil class="w-4 h-4" />
                                        </button>
                                      </TooltipTrigger>
                                      <TooltipContent side="top" :side-offset="5">
                                        Editar
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                  <TooltipProvider>
                                    <Tooltip :delay-duration="100">
                                      <TooltipTrigger as-child>
                                        <button class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-red-500/10 hover:text-accent-foreground h-10 w-10 rounded-lg"
                                        @click.prevent="isDeleteLessonDialogConfirmationOpen = true; selectedLessonIdToDelete = element.id">
                                          <Trash2 class="w-4 h-4" />
                                        </button>
                                      </TooltipTrigger>
                                      <TooltipContent side="top" :side-offset="5">
                                        Excluir
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                </div>
                                <!-- <DropdownMenu>
                                  <DropdownMenuTrigger as-child>
                                    <Button
                                      aria-haspopup="true"
                                      size="icon"
                                      variant="ghost"
                                    >
                                      <MoreHorizontal class="h-4 w-4" :stroke-width="1.25" />
                                      <span class="sr-only">Toggle menu</span>
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent align="end">
                                    <DropdownMenuLabel>Ações</DropdownMenuLabel>
                                    <DropdownMenuItem @click="lessonToEdit = lesson; getLessonById(lesson.id); selectedTab = 'lesson'">Editar</DropdownMenuItem>
                                    <DropdownMenuItem @click="isDeleteLessonDialogConfirmationOpen = true; selectedLessonIdToDelete = lesson.id">Excluir</DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu> -->
                              </div>
                            </div>
                          </template>
                      </draggable>
                    </CardContent>
                  </Card>
                </div>
              </form>
            </Form>
            <Dialog v-if="selectedLessonIdToDelete" :open="isDeleteLessonDialogConfirmationOpen" @update:open="(open) => isDeleteLessonDialogConfirmationOpen = open">
              <DialogContent class="sm:max-w-md">
                <DialogHeader>
                  <div class="flex gap-2 items-center">
                    <AlertTriangle class="h-6 w-6" />
                    <DialogTitle class="text-[1.1rem]">Você está prestes a excluir esta aula.</DialogTitle>
                  </div>
                  <DialogDescription class="py-2">
                    Essa ação é permanente e não poderá ser desfeita. Todas as informações e progresso relacionados a esta aula serão perdidos.
                  </DialogDescription>
                </DialogHeader>
                <div class="flex items-center space-x-2">

                </div>
                <DialogFooter class="sm:justify-start">
                  <DialogClose as-child>
                    <Button type="button" variant="secondary">
                      Cancelar
                    </Button>
                  </DialogClose>
                  <Button type="button" :disabled="isDeletingLesson" @click="deleteLesson(selectedLessonIdToDelete)">
                    <Loader2 v-if="isDeletingLesson" class="w-4 h-4 mr-2 animate-spin" />
                    Excluir Aula
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </TabsContent>
          <!-- Modules -->
          <TabsContent v-if="!isLoadingCourse" value="lesson">
            <Form
              v-slot="{ meta, values, validate }"
              as="" keep-values :validation-schema="toTypedSchema(lessonToEdit ? editLessonFormSchema : lessonFormSchema)"
            >
              <form @submit.prevent="() => {
                validate();
                if (meta.valid) {
                  lessonToEdit ? updateLesson(lessonToEdit!.id) : createLesson(lessonFormValues)
                }
              }" class="sm:grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-4 lg:gap-8" >
                <div class="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
                  <Card>
                    <CardHeader>
                      <div class="flex items-center justify-between">
                        <CardTitle>{{ lessonToEdit ? 'Editar aula' : 'Adicionar aula' }}</CardTitle>
                        <Button variant="secondary" @click="selectedTab = 'modules';">Voltar</Button>
                      </div>
                      <CardDescription>Detalhes da Aula</CardDescription>
                    </CardHeader>
                    <CardContent>
                      <div class="grid gap-6">
                        <!-- <div class="pb-4">
                          <ImageInput v-model="lessonFormValues.image" :defaultPreview="lessonToEdit?.image?.bucketLocation" />
                        </div> -->
                        <div class="grid gap-6 mb-6">
                          <Label>Anexos</Label>
                          <input type="file" name="attachments" id="attachments" class="hidden" accept=".txt, .rtf, .doc, .docx, .odt, .pdf" @change="onAttachmentsInputChange">
                          <label for="attachments" class="border-2 border-dashed rounded-xl grid gap-4 border-gray-700">
                            <div class="p-14 flex flex-col gap-4 items-center">
                              <p class="text-[13px] text-zinc-400">Clique aqui para adicionar um arquivo</p>
                              <FileUp class="h-8 w-8" />
                            </div>
                            <div @click.prevent v-if="lessonFormValues.newAttachments?.length || lessonFormValues.attachments?.length" class="bg-muted rounded-bl-xl rounded-br-xl w-full p-4 grid gap-4">
                              <p class="font-bold">ARQUIVOS</p>
                              <div class="flex flex-col gap-4" v-for="(attachment, index) in lessonFormValues.newAttachments" :key="index">
                                <div class="flex justify-between items-center gap-4">
                                  <Input
                                    type="text"
                                    class="w-full"
                                    placeholder="ex: arquivo"
                                    v-model="attachment.title"
                                  />
                                  <button @click.prevent.stop="lessonFormValues.newAttachments.splice(index, 1)">
                                    <Trash2 class="h-5 w-5" />
                                  </button>
                                </div>
                              </div>
                              <div class="flex flex-col gap-4" v-for="(attachment, index) in lessonFormValues.attachments" :key="index">
                                <div class="flex justify-between items-center gap-4">
                                  <Input
                                    type="text"
                                    class="w-full"
                                    placeholder="ex: arquivo"
                                    :default-value="attachment.title"
                                    @blur="updateLessonAttachment(attachment.id, { title: $event.target.value })"
                                  />
                                  <button @click.prevent.stop="isDeleteLessonAttachmentDialogConfirmationOpen = true; lessonAttachmentToDeleteId = attachment.id">
                                    <Trash2 class="h-5 w-5" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </label>
                          <Dialog v-if="lessonAttachmentToDeleteId" :open="isDeleteLessonAttachmentDialogConfirmationOpen" @update:open="(open) => isDeleteLessonAttachmentDialogConfirmationOpen = open">
                            <DialogContent class="sm:max-w-md">
                              <DialogHeader>
                                <div class="flex gap-2 items-center">
                                  <AlertTriangle class="h-6 w-6" />
                                  <DialogTitle class="text-[1.1rem]">Excluir Anexo da Aula</DialogTitle>
                                </div>
                                <DialogDescription class="py-2">
                                  Você está prestes a excluir este anexo. Essa ação é irreversível e todos os dados relacionados a este anexo serão permanentemente removidos.
                                </DialogDescription>
                              </DialogHeader>
                              <div class="flex items-center space-x-2">

                              </div>
                              <DialogFooter class="sm:justify-start">
                                <DialogClose as-child>
                                  <Button type="button" variant="secondary">
                                    Cancelar
                                  </Button>
                                </DialogClose>
                                <Button type="button" :disabled="isDeletingLessonAttachment" @click="deleteLessonAttachment(lessonAttachmentToDeleteId)">
                                  <Loader2 v-if="isDeletingLessonAttachment" class="w-4 h-4 mr-2 animate-spin" />
                                  Excluir Anexo
                                </Button>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="title" class="col-span-1">
                            <FormItem>
                              <FormLabel>Título</FormLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  class="w-full"
                                  placeholder="ex: Introdução à Teoria das Cores"
                                  v-bind="componentField"
                                  v-model="lessonFormValues.title"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="description" class="col-span-1">
                            <FormItem>
                              <FormLabel>Descrição</FormLabel>
                              <FormControl>
                                <Textarea
                                  placeholder="ex: Nesta aula, você irá explorar os fundamentos da teoria das cores e seu impacto no design..."
                                  class="min-h-32"
                                  v-bind="componentField"
                                  v-model="lessonFormValues.description"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="type">
                            <Label>Tipo</Label>
                            <Select v-bind="componentField" v-model="lessonFormValues.type">
                              <SelectTrigger class="w-full">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem :value="type" v-for="type in LessonTypeEnum">
                                  {{ lessonTypeLabels[type] }}
                                </SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                        <!-- <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="order" class="col-span-1">
                            <FormItem>
                              <FormLabel>Ordem</FormLabel>
                              <FormControl>
                                <Input
                                  min="1"
                                  type="number"
                                  class="w-full"
                                  placeholder="ex: 1"
                                  v-bind="componentField"
                                  v-model="lessonFormValues.order"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div> -->
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="content.origin">
                            <FormLabel for="contentOrigin">Origem do vídeo</FormLabel>
                            <Select v-bind="componentField" v-model="lessonFormValues.content.origin">
                              <SelectTrigger id="contentOrigin" aria-label="Selecionar">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem v-for="type in LessonContentOriginEnum" :value="type">{{ lessonContentOriginLabels[type] }}</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                        <div  v-if="lessonFormValues.content?.origin !== LessonContentOriginEnum.HOOPAY && lessonFormValues.type === LessonTypeEnum.VIDEO" class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="content.url" class="col-span-1">
                            <FormItem>
                              <FormLabel>URL do vídeo</FormLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  class="w-full"
                                  placeholder="ex: https://video..."
                                  v-bind="componentField"
                                  v-model="lessonFormValues.content.url"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <VideoUpload :is-uploading="isUploading" :progress-percentage="progressPercentage" :estimated-time="estimatedTime" :upload-speed="uploadSpeed" :current-video-src="lessonFormValues.content.url" :current-video-origin="lessonFormValues.content.origin" v-if="lessonFormValues.content?.origin === LessonContentOriginEnum.HOOPAY && lessonFormValues.type === LessonTypeEnum.VIDEO" v-model="lessonFormValues.content.video" />
                        <div class="grid gap-3" v-if="lessonFormValues.content?.origin !== LessonContentOriginEnum.HOOPAY">
                          <FormField v-slot="{ componentField }" name="content.duration" class="col-span-1">
                            <FormItem>
                              <FormLabel>Duração em segundos</FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  class="w-full"
                                  placeholder="ex: 20"
                                  v-bind="componentField"
                                  v-model="lessonFormValues.content.duration"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="showreleaseDelayAfterPurchase" v-model="showLessonReleaseDelayAfterPurchaseInput">
                            <FormLabel class="flex items-center gap-2" for="releaseDelayAfterPurchase">
                              Liberação
                              <TooltipProvider>
                                <Tooltip :delay-duration="100">
                                  <TooltipTrigger as-child>
                                    <CircleHelp class="size-5" />
                                  </TooltipTrigger>
                                  <TooltipContent side="right" :side-offset="5">
                                    Configure o desbloqueio da aula para X dias após a confirmação da compra.
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </FormLabel>
                            <Select v-bind="componentField">
                              <SelectTrigger id="releaseDelayAfterPurchase" aria-label="Selecionar">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem :value="'false'">Imediato</SelectItem>
                                <SelectItem :value="'true'">Após X dias da compra</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                        <div v-if="showLessonReleaseDelayAfterPurchaseInput === 'true'" class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="releaseDelayAfterPurchase" class="col-span-1">
                            <FormItem>
                              <FormLabel>
                                Liberar após
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  class="w-full"
                                  placeholder="ex: 10 dias"
                                  v-bind="componentField"
                                  v-model="lessonFormValues.releaseDelayAfterPurchase"
                                  min="1"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          </FormField>
                        </div>
                        <div class="grid gap-3">
                          <FormField v-slot="{ componentField }" name="status">
                            <FormLabel for="status">Status</FormLabel>
                            <Select v-bind="componentField" v-model="lessonFormValues.status">
                              <SelectTrigger id="status" aria-label="Selecionar status">
                                <SelectValue placeholder="Selecionar" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem v-for="status in LessonStatusEnum" :value="status">{{ lessonStatusLabels[status] }}</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormField>
                        </div>
                        <div class="flex justify-between">
                          <div class="flex items-center space-x-2">
                            <FormField v-slot="{ componentField }" name="upcoming">
                              <Switch v-bind="componentField"
                                      :checked="lessonFormValues.upcoming"
                                      @update:checked="(checked) => lessonFormValues.upcoming = checked" />
                              <FormLabel for="upcoming">Conteúdo em breve</FormLabel>
                            </FormField>
                          </div>
                        </div>
                        <Button type="submit" :disabled="isCreatingLesson || isUpdatingLesson || (lessonFormValues.type === LessonTypeEnum.VIDEO && (!lessonFormValues.content.origin || (!lessonFormValues.content.url && !lessonFormValues.content.video) || (!lessonFormValues.content.duration && lessonFormValues.content.origin !== LessonContentOriginEnum.HOOPAY)))">
                          <Loader2 v-if="isCreatingLesson || isUpdatingLesson" class="w-4 h-4 mr-2 animate-spin" />
                          {{ lessonToEdit ? 'Salvar' : 'Adicionar' }}
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div v-if="moduleToEdit && course?.commentsType !== CourseCommentsTypeEnum.INACTIVE" class="grid auto-rows-max items-start gap-4 lg:gap-8 col-span-2 mt-8 sm:mt-0">
                  <CommentsCard :lesson="lessonToEdit" v-if="!!lessonToEdit" @onAdd="(tab) => selectedTab = tab" />
                </div>
              </form>
            </Form>
          </TabsContent>
          <SpinnerLoader v-if="isLoadingModule || isLoadingModule" />
        </div>
      </Tabs>
    </main>
    <Toaster />
  </div>
</template>

<script lang="ts" setup>
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import draggable from 'vuedraggable'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { AlertTriangle, Loader2, MoreHorizontal, ImageUp, Pencil, Trash2, ChevronsUpDown, CircleHelp, FileUp } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { computed, onMounted, ref } from 'vue'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Course } from '@/interfaces/Course'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Module, ModuleMedia } from '@/interfaces/Module'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Lesson } from '@/interfaces/lesson/Lessons'
import { LessonContentOriginEnum, lessonContentOriginLabels } from '@/enums/lesson/LessonContentOriginEnum'
import CommentsCard from './components/CommentsCard.vue'
import { CourseStatusEnum, courseStatusLabels } from '@/enums/course/CourseStatusEnum'
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum'
import ImageInput from '@/components/ui/input/ImageInput.vue'
import { CourseVideoTypeEnum, courseVideoTypeLabels } from '@/enums/course/CourseVideoTypeEnum'
import { Badge } from '@/components/ui/badge'
import { ModuleStatusEnum, moduleStatusLabels } from '@/enums/module/ModuleStatusEnum'
import { LessonStatusEnum, lessonStatusLabels } from '@/enums/lesson/LessonStatusEnum'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { CourseCommentsTypeEnum, commentsTypeLabels } from '@/enums/course/CourseCommentsTypeEnum'
import { LessonTypeEnum, lessonTypeLabels } from '@/enums/lesson/LessonTypeEnum'
import { LessonAttachment } from '@/interfaces/lesson/LessonAttachment'
import { CourseLevelEnum, CourseLevelLabels } from '@/enums/course/CourseLevelEnum'
import TagsInput from '@/components/ui/tags-input/TagsInput.vue'
import TagsInputItem from '@/components/ui/tags-input/TagsInputItem.vue'
import TagsInputItemText from '@/components/ui/tags-input/TagsInputItemText.vue'
import TagsInputItemDelete from '@/components/ui/tags-input/TagsInputItemDelete.vue'
import TagsInputInput from '@/components/ui/tags-input/TagsInputInput.vue'
import { CourseCategoryEnum, CourseCategoryLabels } from '@/enums/course/CourseCategoryEnum'
import VideoUpload from './components/VideoUpload.vue'

const courseMediaForm = ref<Record<string, File | undefined>>({
  image: undefined,
  backgroundImage: undefined
})
const courseMediaPreviews = ref({
  backgroundImage: ''
})
const drag =  ref(false)
const dragLesson =  ref(false)
const selectedTab = ref('course')
const isLoadingCourse = ref(false)
const formValues = ref<Partial<Course & { videoFile?: File }>>({
  title: '',
  video: '',
  introduction: '',
  description: '',
  upcoming: false,
  commentsType: 'Livre',
  communityType: 'Aberta',
  // limitClientsFree: 0,
  status: CourseStatusEnum.ACTIVE,
  certification: true,
  // duration: 0,
  featuredInStore: false,
  learningOutcomes: [],
  prerequisites: [],
  level: undefined,
  hasReviews: false,
  displayTotalStudents: false,
  videoFile: undefined
})
const moduleFormValues = ref<Partial<Module>>({
  title: '',
  image: undefined,
  description: '',
  order: undefined,
  upcoming: false,
  status: undefined,
})
const deleteModuleFormValues = ref({
  deleteLessons: false,
  moduleId: undefined
})
const lessonFormValues = ref<any>({
  title: '',
  description: '',
  image: undefined,
  status: undefined,
  // order: undefined,
  upcoming: false,
  content: {
    url: '',
    origin: undefined
  },
  origin: undefined,
  releaseDelayAfterPurchase: undefined,
  attachments: [],
  newAttachments: [],
})
const showLessonReleaseDelayAfterPurchaseInput = ref<'true' | 'false'>('false')
const moduleToEdit = ref<Module>()
const lessonToEdit = ref<Lesson>()
const course = ref<Course>()
const courseBackgroundImage = computed(() => course.value?.media.find((m) => m.type === CourseMediaTypeEnum.BACKGROUND))
const courseImage = computed(() => course.value?.media.find((m) => m.type === CourseMediaTypeEnum.MAIN))
const props = defineProps<{ courseId: number }>()
const isUpdatingCourse = ref(false)
const isCreatingModule = ref(false)
const isUpdatingModule = ref(false)
const isLoadingModule = ref(false)
const isCreatingLesson = ref(false)
const isUpdatingLesson = ref(false)
const isLoadingLesson = ref(false)
const selectedModuleIdToDelete = ref<number>()
const isDeleteModuleDialogConfirmationOpen = ref(false)
const selectedLessonIdToDelete = ref<number>()
const lessonAttachmentToDeleteId = ref<number>()
const isDeleteLessonDialogConfirmationOpen = ref(false)
const isDeleteLessonAttachmentDialogConfirmationOpen = ref(false)
const isDeletingLessonAttachment = ref(false)
const isDeletingModule = ref(false)
const isDeletingLesson = ref(false)
const axios = useAxios()
const requiredMessage = {
  required_error: "Obrigatório",
}
const formSchema = z.object({
  title: z.string(requiredMessage).default(formValues.value.title || ''),
  videoOriginType: z.string(requiredMessage).optional().default(formValues.value.videoOriginType || ''),
  video: z.string(requiredMessage).optional().default(formValues.value.video || ''),
  introduction: z.string(requiredMessage).default(formValues.value.introduction || ''),
  description: z.string(requiredMessage).default(formValues.value.description || ''),
  upcoming: z.boolean().default(formValues.value.upcoming !== undefined ? formValues.value.upcoming : false),
  communityType: z.string(requiredMessage).default(formValues.value.communityType || ''),
  // limitClientsFree: z.number(requiredMessage).default(formValues.value.limitClientsFree !== undefined ? formValues.value.limitClientsFree : 0),
  status: z.nativeEnum(CourseStatusEnum).default(formValues.value.status!),
  certification: z.boolean().default(formValues.value.certification !== undefined ? formValues.value.certification : true),
  // duration: z.number().optional().default(formValues.value.duration || 0),
  featuredInStore: z.boolean().default(!!formValues.value.featuredInStore),
  level: z.nativeEnum(CourseLevelEnum).default(formValues.value.level || CourseLevelEnum.BEGINNER),
  hasReviews: z.boolean().default(!!formValues.value.hasReviews),
  displayTotalStudents: z.boolean().default(!!formValues.value.displayTotalStudents),
  category: z.nativeEnum(CourseCategoryEnum, requiredMessage).default(formValues.value.category || CourseCategoryEnum.OTHER),
});
const moduleFormSchema = z.object({
  title: z.string(requiredMessage),
  // image: z.any().refine(file => !!moduleFormValues.value.image, {
  //   message: "Obrigatório."
  // }),
  description: z.string(requiredMessage),
  status: z.nativeEnum(ModuleStatusEnum, requiredMessage),
  upcoming: z.boolean().default(false),
  // order: z.number(requiredMessage),
});
const editModuleFormSchema = z.object({
  title: z.string(requiredMessage).default(moduleToEdit.value?.title ?? ''),
  image: z.any().optional(),
  description: z.string(requiredMessage).default(moduleToEdit.value?.description ?? ''),
  status: z.nativeEnum(ModuleStatusEnum, requiredMessage).default(moduleToEdit.value?.status ?? ModuleStatusEnum.ACTIVE),
  upcoming: z.boolean().default(false),
  order: z.number(requiredMessage).default(moduleToEdit.value?.order ?? 1),
});
const lessonFormSchema = z.object({
  title: z.string(requiredMessage),
  description: z.string(requiredMessage),
  // image: z.any().refine(file => !!lessonFormValues.value.image, {
  //   message: "Obrigatório."
  // }),
  status: z.nativeEnum(LessonStatusEnum, requiredMessage),
  // order: z.number(requiredMessage),
  upcoming: z.boolean().default(false),
  type: z.nativeEnum(LessonTypeEnum, requiredMessage),
  content: z.object({
    url: z.string(requiredMessage).url('Deve ser uma url válida')
      .optional(),
      origin: z.nativeEnum(LessonContentOriginEnum, requiredMessage)
      .optional(),
    duration: z.number(requiredMessage)
      .optional()
  }).optional(),
});
const editLessonFormSchema = z.object({
  title: z.string(requiredMessage).default(lessonToEdit.value?.title ?? ''),
  description: z.string(requiredMessage).default(lessonToEdit.value?.description ?? ''),
  // image: z.any().optional(),
  status: z.nativeEnum(LessonStatusEnum, requiredMessage).default(lessonToEdit.value?.status ?? LessonStatusEnum.ACTIVE),
  // order: z.number(requiredMessage).default(lessonToEdit.value?.order ?? 1),
  upcoming: z.boolean().default(false),
  type: z.nativeEnum(LessonTypeEnum, requiredMessage).default(lessonToEdit.value?.type ?? LessonTypeEnum.VIDEO),
  content: z.object({
    url: z.string().default(lessonToEdit.value?.content.url!)
      .optional(),
    origin: z.nativeEnum(LessonContentOriginEnum, requiredMessage).default(lessonToEdit.value?.content.origin!)
      .optional(),
    duration: z.number(requiredMessage).default(Number(lessonToEdit.value?.content.duration?.seconds ?? 0))
      .optional(),
  })
});

type ModuleFormSchemaType = z.infer<typeof moduleFormSchema>

function updateCourse(payload: Partial<Course & { videoFile?: File }>) {
  isUpdatingCourse.value = true

  const formData = new FormData();

  formData.append('title', payload.title || course.value!.title);
  if (payload.video) formData.append('video', payload.video || course.value!.video);
  if (payload.videoOriginType === CourseVideoTypeEnum.HOOPAY && payload.videoFile) formData.append('video', payload.videoFile)
  if (payload.videoOriginType) formData.append('videoOriginType', payload.videoOriginType || course.value!.videoOriginType);
  formData.append('introduction', payload.introduction || course.value!.introduction);
  formData.append('description', payload.description || course.value!.description);
  formData.append('upcoming', String(payload.upcoming ?? false));
  formData.append('commentsType', payload.commentsType ?? 'Livre');
  formData.append('communityType', payload.communityType ?? 'Aberta');
  formData.append('status', payload.status ?? 'active');
  formData.append('certification', String(payload.certification ?? true));
  formData.append('featuredInStore', String(payload.featuredInStore ?? false));
  payload.learningOutcomes?.forEach((value: string, index: number) => {
    formData.append(`learningOutcomes[${index}]`, value);
  });

  payload.prerequisites?.forEach((value: string, index: number) => {
    formData.append(`prerequisites[${index}]`, value);
  });
  formData.append('category', payload.category as string);
  formData.append('level', payload.level as string);
  formData.append('hasReviews', payload.hasReviews ? 'true' : 'false');
  formData.append('displayTotalStudents', payload.displayTotalStudents ? 'true' : 'false');

  if (courseMediaForm.value.image) {
    formData.append('image', courseMediaForm.value.image);
  }

  if (courseMediaForm.value.backgroundImage) {
    formData.append('backgroundImage', courseMediaForm.value.backgroundImage);
  }

  axios.patch(`/courses/${props.courseId}`, formData)
    .then(() => {
      toast({
        title: 'Curso atualizado com sucesso',
        class: 'bg-green-500 text-white'
      })
      getCourseById({ hasLoading: false })
    })
    .finally(() => isUpdatingCourse.value = false)
}

function createModule(payload: Partial<Module>) {
  isCreatingModule.value = true

  const formData = new FormData();

  formData.append('title', String(payload.title));
  // formData.append('image', payload.image as File);
  formData.append('description', payload.description ?? '');
  // formData.append('order', String(payload.order));
  formData.append('upcoming', String(payload.upcoming ?? false));
  formData.append('status', String(payload.status));

  axios.post(`/courses/${props.courseId}/modules`, formData)
    .then(() => {
      toast({
        title: 'Módulo cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
      getCourseById({ hasLoading: false })
      resetModuleForm()
    })
    .finally(() => isCreatingModule.value = false)
}

function onEndDragModule(e: any) {
  const modulesOrder = course.value?.modules.map((m, index) => ({ id: m.id, order: index }))
  updateModulesOrder(modulesOrder!)
}

function updateModulesOrder(modules: { id: number, order: number }[]) {
  axios.patch(`/courses/${props.courseId}/modules/order`, { modules })
    .then(() => {
      toast({
        title: 'Ordem de exibição atualizada com sucesso',
        class: 'bg-green-500 text-white'
      })
      getCourseById({ hasLoading: false })
    })
}

function updateModule(moduleId: number, payload: Partial<Module>, isOrderUpdate = false) {
  isUpdatingModule.value = true

  const formData = new FormData();

  if (!isOrderUpdate) {
    formData.append('title', String(payload.title));
    // if (payload.image) formData.append('image', payload.image as File);
    formData.append('description', payload.description ?? '');
    formData.append('upcoming', String(payload.upcoming ?? false));
    formData.append('status', String(payload.status));
  }

  if (payload.order !== undefined && payload.order !== null) formData.append('order', String(payload.order));

  axios.patch(`/courses/${props.courseId}/modules/${moduleId}`, formData)
    .then(() => {
      toast({
        title: 'Módulo atualizado com sucesso',
        class: 'bg-green-500 text-white'
      })
      getCourseById({ hasLoading: false })
    })
    .finally(() => isUpdatingModule.value = false)
}

function getModuleById(id: number) {
  isLoadingModule.value = true

  axios.get(`/courses/${props.courseId}/modules/${id}`)
    .then((res) => {
      moduleFormValues.value = {
        title: res.data.title,
        image: undefined,
        description: res.data.description,
        order: res.data.order,
        upcoming: res.data.upcoming,
        status: res.data.status,
      }
      moduleToEdit.value = res.data
    })
    .finally(() => isLoadingModule.value = false)
}

function deleteModule(id: number) {
  isDeletingModule.value = true

  axios.delete(`/courses/${props.courseId}/modules/${id}`, !deleteModuleFormValues.value.deleteLessons ? {
    moduleToTransferLessonsId: deleteModuleFormValues.value.moduleId
  } : {})
    .then((res) => {
      toast({
        title: 'Módulo excluído com sucesso',
        class: 'bg-green-500 text-white'
      })

      isDeleteModuleDialogConfirmationOpen.value = false
      course.value!.modules = course.value!.modules.filter((m) => m.id !== id)
    })
    .finally(() => isDeletingModule.value = false)
}

function resetDeleteModuleForm() {
  deleteModuleFormValues.value = {
    deleteLessons: false,
    moduleId: undefined
  }
}

function getCourseById({ hasLoading }: { hasLoading: boolean } = { hasLoading: true }) {
  if (hasLoading) {
    isLoadingCourse.value = true
  }

  axios.get(`/courses/${props.courseId}`)
    .then((res) => {
      formValues.value = {
        title: res.data.course.title,
        videoOriginType: res.data.course.videoOriginType,
        video: res.data.course.video,
        introduction: res.data.course.introduction,
        description: res.data.course.description,
        upcoming: !!res.data.course.upcoming,
        commentsType: res.data.course.commentsType,
        communityType: res.data.course.communityType ?? '',
        // limitClientsFree: res.data.course.limitClientsFree,
        status: res.data.course.status ?? 'active',
        certification: !!res.data.course.certification,
        // duration: res.data.course.duration?.seconds,
        featuredInStore: !!res.data.course.featuredInStore,
        learningOutcomes: res.data.course.learningOutcomes ?? [],
        prerequisites: res.data.course.prerequisites ?? [],
        category: res.data.course.category,
        level: res.data.course.level,
        hasReviews: !!res.data.course.hasReviews,
        displayTotalStudents: !!res.data.course.displayTotalStudents,
      }
      course.value = res.data.course
    })
    .finally(() => isLoadingCourse.value = false)
}

function intervalToSeconds(interval: any): number {
  const { years = 0, months = 0, days = 0, hours = 0, minutes = 0, seconds = 0 } = interval;

  return (
    (years * 365 * 24 * 60 * 60) +
    (months * 30 * 24 * 60 * 60) +
    (days * 24 * 60 * 60) +
    (hours * 60 * 60) +
    (minutes * 60) +
    seconds
  );
}

function getLessonById(id: number) {
  isLoadingLesson.value = true

  axios.get(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${id}`)
  .then((res) => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0);

    lessonFormValues.value = {
      title: res.data.title,
      description: res.data.description,
      image: res.data.image,
      status: res.data.status,
      order: res.data.order,
      upcoming: res.data.upcoming,
      content: {
        url: res.data.content.url,
        origin: res.data.content.origin,
        duration: res.data.content.duration ? intervalToSeconds(res.data.content.duration) : undefined,
      },
      releaseDelayAfterPurchase: res.data.releaseDelayAfterPurchase,
      type: res.data.type,
      attachments: res.data.attachments,
      newAttachments: [],
    }

    showLessonReleaseDelayAfterPurchaseInput.value = res.data.releaseDelayAfterPurchase ? 'true' : 'false'
    lessonToEdit.value = res.data
  })
  .finally(() => isLoadingLesson.value = false)
}

function createLesson(payload: Partial<Lesson>) {
  isCreatingLesson.value = true

  const formData = new FormData()
  formData.append('title', lessonFormValues.value.title)
  formData.append('description', lessonFormValues.value.description)
  // formData.append('image', lessonFormValues.value.image!)
  formData.append('status', lessonFormValues.value.status!)
  // formData.append('order', lessonFormValues.value.order!)
  formData.append('upcoming', String(lessonFormValues.value.upcoming))
  formData.append('type', String(lessonFormValues.value.type))
  if (lessonFormValues.value.content.url) formData.append('content[url]', lessonFormValues.value.content.url)
  if (lessonFormValues.value.content.origin) formData.append('content[origin]', lessonFormValues.value.content.origin)
  if (lessonFormValues.value.content.origin === LessonContentOriginEnum.HOOPAY && lessonFormValues.value.type === LessonTypeEnum.VIDEO) formData.append('video', lessonFormValues.value.content.video)
  if (lessonFormValues.value.content.duration) formData.append('content[duration]', lessonFormValues.value.content.duration)
  for (let i = 0; i < lessonFormValues.value.newAttachments.length; i++) {
    const newAttachment = lessonFormValues.value.newAttachments[i];
    formData.append(`attachments[${i}][title]`, newAttachment.title);
    formData.append('attachmentFiles', newAttachment.file);
  }

  if (showLessonReleaseDelayAfterPurchaseInput.value === 'true') formData.append('releaseDelayAfterPurchase', lessonFormValues.value.releaseDelayAfterPurchase)

  axios.post(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons`, formData)
    .then(() => {
      toast({
        title: 'Aula cadastrada com sucesso',
        class: 'bg-green-500 text-white'
      })
      resetLessonForm()
      getModuleById(moduleToEdit.value!.id)
    })
    .finally(() => isCreatingLesson.value = false)
}

function onEndDragLesson(e: any) {
  const lessonsOrder = moduleToEdit.value?.lessons.map((m, index) => ({ id: m.id, order: index }))
  updateLessonsOrder(lessonsOrder!, moduleToEdit.value!.id)
}

function updateLessonsOrder(lessons: { id: number, order: number }[], moduleId: number) {
  axios.patch(`/courses/${props.courseId}/modules/${moduleId}/lessons/order`, { lessons })
    .then(() => {
      toast({
        title: 'Ordem de exibição da aula atualizada com sucesso',
        class: 'bg-green-500 text-white'
      })
      getCourseById({ hasLoading: false })
    })
}

const CHUNK_SIZE = 5 * 1024 * 1024 // 5MB por chunk
const isUploading = ref(false)
const progress = ref(0)
const progressPercentage = ref(0)
const uploadSpeed = ref(0) // Velocidade em MB/s
const estimatedTime = ref(0) // Tempo estimado restante em segundos

async function updateLesson(lessonId: number) {
  isUpdatingLesson.value = true

  const formData = new FormData()
  formData.append('title', lessonFormValues.value.title)
  formData.append('description', lessonFormValues.value.description)
  formData.append('status', lessonFormValues.value.status!)
  formData.append('upcoming', String(lessonFormValues.value.upcoming))
  if (lessonFormValues.value.content.url) formData.append('content[url]', lessonFormValues.value.content.url)
  if (lessonFormValues.value.content.origin) formData.append('content[origin]', lessonFormValues.value.content.origin)
  if (lessonFormValues.value.content.duration) formData.append('content[duration]', lessonFormValues.value.content.duration)
  formData.append('releaseDelayAfterPurchase', showLessonReleaseDelayAfterPurchaseInput.value === 'false' ? '' : lessonFormValues.value.releaseDelayAfterPurchase)

  // Adiciona os anexos normalmente
  for (let i = 0; i < lessonFormValues.value.newAttachments.length; i++) {
    const newAttachment = lessonFormValues.value.newAttachments[i]
    formData.append(`attachments[${i}][title]`, newAttachment.title)
    formData.append('attachmentFiles', newAttachment.file)
  }

  // Atualiza os metadados da aula primeiro
  await axios.put(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${lessonId}`, formData)

  // Enviar vídeo em chunks (se existir)
  if (lessonFormValues.value.content.origin === LessonContentOriginEnum.HOOPAY && lessonFormValues.value.type === LessonTypeEnum.VIDEO) {
    await uploadVideoInChunks(lessonFormValues.value.content.video, lessonId)
  }

  isUpdatingLesson.value = false

  toast({
    title: 'Aula atualizada com sucesso',
    class: 'bg-green-500 text-white'
  })
  await getModuleById(moduleToEdit.value!.id)
}

// Função para enviar o vídeo em partes
async function uploadVideoInChunks(videoFile: File, lessonId: number) {
  isUploading.value = true
  progressPercentage.value = 0
  uploadSpeed.value = 0
  estimatedTime.value = 0

  const totalChunks = Math.ceil(videoFile.size / CHUNK_SIZE)
  let uploadedSize = 0
  let startTime = Date.now()
  for (let i = 0; i < totalChunks; i++) {
    const start = i * CHUNK_SIZE
    const end = Math.min(start + CHUNK_SIZE, videoFile.size)
    const chunk = videoFile.slice(start, end)
    
    const chunkFormData = new FormData()
    chunkFormData.append('video', chunk)
    // chunkFormData.append('filename', videoFile.name)
    // chunkFormData.append('chunkIndex', String(i))
    // chunkFormData.append('totalChunks', String(totalChunks))
    
    const chunkStartTime = Date.now()
    console.log({uploadedSize, startTime, totalChunks, chunk })
    await axios.put(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${lessonId}`, chunkFormData)
    const chunkElapsedTime = (Date.now() - chunkStartTime) / 1000 // Tempo em segundos

    uploadedSize += chunk.size
    progressPercentage.value = Math.round((uploadedSize / videoFile.size) * 100)
    console.log(progressPercentage.value)
    // Calcular velocidade e ETA
    const elapsedTime = (Date.now() - startTime) / 1000 // Tempo em segundos
    uploadSpeed.value = (uploadedSize / 1024 / 1024) / elapsedTime // MB/s
    estimatedTime.value = uploadSpeed.value > 0 ? Math.max(0, (videoFile.size - uploadedSize) / (uploadSpeed.value * 1024 * 1024)) : 0
  }

  isUploading.value = false
}

function deleteLesson(id: number) {
  isDeletingLesson.value = true

  axios.delete(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${id}`)
    .then((res) => {
      toast({
        title: 'Aula excluída com sucesso',
        class: 'bg-green-500 text-white'
      })

      isDeleteLessonDialogConfirmationOpen.value = false
      moduleToEdit.value!.lessons = moduleToEdit.value!.lessons.filter((l) => l.id !== id)
    })
    .finally(() => isDeletingLesson.value = false)
}

function updateLessonAttachment(id: number, payload: { title: string }) {
  const lessonAttachment = lessonToEdit.value?.attachments.find((a) => a.id === id)

  if (payload.title !== lessonAttachment?.title) {
    axios.patch(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${lessonToEdit.value!.id}/attachments/${id}`, payload)
      .then(() => {
        toast({
          title: 'Anexo atualizado com sucesso',
          class: 'bg-green-500 text-white'
        })
        lessonAttachment!.title = payload.title
      })
  }
}

function deleteLessonAttachment(id: number) {
  isDeletingLessonAttachment.value = true

  axios.delete(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${lessonToEdit.value!.id}/attachments/${id}`)
    .then(() => {
      toast({
        title: 'Anexo excluído com sucesso',
        class: 'bg-green-500 text-white'
      })

      const index = lessonFormValues.value.attachments.findIndex((attachment: LessonAttachment) => attachment.id === id)
      lessonFormValues.value.attachments.splice(index, 1)
      isDeleteLessonAttachmentDialogConfirmationOpen.value = false
    })
    .finally(() => isDeletingLessonAttachment.value = false)

}

function onAttachmentsInputChange(e: Event) {
  lessonFormValues.value.newAttachments.push({
    title: (e.target as HTMLInputElement).files![0].name,
    file: (e.target as HTMLInputElement).files![0],
  })
}

function resetLessonForm() {
  lessonFormValues.value = {
    title: '',
    description: '',
    image: undefined,
    status: undefined,
    order: undefined,
    upcoming: false,
    content: {
      url: '',
      origin: undefined
    },
    releaseDelayAfterPurchase: undefined,
    attachments: [],
    newAttachments: []
  }
}

function resetModuleForm() {
  moduleFormValues.value = {
    title: '',
    image: undefined,
    description: '',
    order: undefined,
    upcoming: false,
    status: undefined,
  }
}

function onBackgroundImageChange(e: Event) {
  const file = (e.target as HTMLInputElement).files![0]

  courseMediaForm.value.backgroundImage = file

  courseMediaPreviews.value.backgroundImage = URL.createObjectURL(file)
}

onMounted(() => {
  getCourseById()
})
</script>

<style lang="scss">
.hover\:bg-success:hover {
  background-color: #1d876f
}
.hover\:bg-danger:hover {
  background-color: #792a2a
}
</style>
