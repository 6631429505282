<template>
  <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 h-auto py-2">
    <CourseCard @click="isCoursePreviewOpen = true; selectedCourse = course" v-for="course in courses" :course="course" :key="course.id" />
  </div>
  <CoursePreviewView :course="selectedCourse" v-model:open="isCoursePreviewOpen" />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import CoursePreviewView from '../preview/CoursePreviewView.vue';
import CourseCard from './CourseCard.vue';
import { Course } from '@/interfaces/Course';

defineProps<{ courses: any[] }>()

const isCoursePreviewOpen = ref(false)
const selectedCourse = ref<Course>()
</script>