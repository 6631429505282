import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "mt-12" }
const _hoisted_2 = { class: "grid md:grid-cols-2 gap-4" }
const _hoisted_3 = { class: "text-zinc-300" }

import { CheckCircleIcon } from 'lucide-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseOverview',
  props: {
    course: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-xl font-semibold text-white mb-6" }, "O que você vai aprender", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.learningOutcomes, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "flex gap-3 bg-zinc-800/30 p-4 rounded-lg"
        }, [
          _createVNode(_unref(CheckCircleIcon), { class: "w-5 h-5 text-purple-500 flex-shrink-0" }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(item), 1)
        ]))
      }), 128))
    ])
  ]))
}
}

})