import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { cn } from '@/lib/utils'
import { TagsInputItemText, type TagsInputItemTextProps, useForwardProps } from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TagsInputItemText',
  props: {
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TagsInputItemText), _mergeProps(_unref(forwardedProps), {
    class: _unref(cn)('py-1 px-2 text-sm rounded bg-transparent', props.class)
  }), null, 16, ["class"]))
}
}

})