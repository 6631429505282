<template>
  <div class="lg:w-80 mt-6 lg:mt-0">
    <div class="bg-zinc-800/50 rounded-xl p-6 border border-zinc-700">
      <div v-if="!course.user && !course.upcoming" class="flex items-start justify-between mb-4">
        <div class="flex items-baseline gap-2 flex-wrap">
          <span class="text-3xl font-bold text-white">{{ course.variant.promotionalPrice.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' }) }}</span>
          <span v-if="isPromotional" class="text-zinc-400 line-through">{{ course.variant.price.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' }) }}</span>
        </div>
        <p v-if="isPromotional" style="min-width: fit-content" class="bg-green-500/20 text-green-300 px-2 py-1 rounded text-sm w-auto">
          {{ discountPercentage }}% OFF
        </p>
      </div>

      <div v-if="course.user" class="flex items-center gap-3 pb-4">
        <Info class="min-w-5 min-h-5 text-white" />
        <span class="text-white text-sm">
          Você se matriculou neste curso em {{ formatDate(course.user.createdAt) }}
        </span>
      </div>
      <div v-if="course.upcoming && !course.user" class="flex items-center gap-3 pb-4">
        <Info class="min-w-5 min-h-5 text-white" />
        <span class="text-white text-sm">
          Este curso será lançado em breve
        </span>
      </div>
      <button v-if="course.user" @click="redirectToCurrentLesson(course)"
        class="w-full bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg font-medium transition-colors mb-4 block text-center"
      >
        Ir para o curso
      </button>

      <a v-if="!course.user && !course.upcoming" :href="`${checkoutBaseUrl}/?productId[]=${course.variant.uuid}&qty[]=1`" target="_blank" class="w-full bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg font-medium transition-colors mb-4 block text-center">
        Matricular Agora
      </a>
      
      <!-- <a :href="`${checkoutBaseUrl}/?productId[]=${course.variant.uuid}&qty[]=1`" target="_blank" class="w-full border border-zinc-700 hover:bg-zinc-800 text-white px-6 py-3 rounded-lg font-medium transition-colors mb-6 block text-center">
        Adicionar ao Carrinho
      </a> -->

      <button v-if="course.video" @click="displayTrailer = !displayTrailer" class="w-full border border-zinc-700 hover:bg-zinc-800 text-white px-6 py-3 rounded-lg font-medium transition-colors mb-6 block text-center flex justify-center gap-2 items-center">
        <Clapperboard v-if="!displayTrailer" class="w-5 h-5" />
        <Pause v-else class="w-5 h-5" />
        Trailer
      </button>

      <div class="space-y-3 text-sm">
        <div class="flex items-center gap-2 text-zinc-300">
          <CheckCircleIcon class="w-5 h-5 text-purple-500" />
          Acesso vitalício
        </div>
        <div v-if="course.certification" class="flex items-center gap-2 text-zinc-300">
          <CheckCircleIcon class="w-5 h-5 text-purple-500" />
          Certificado de conclusão
        </div>
        <div v-if="course.variant.product!.refundPeriod" class="flex items-center gap-2 text-zinc-300">
          <CheckCircleIcon class="w-5 h-5 text-purple-500" />
          {{ course.variant.product!.refundPeriod }} dias de garantia
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { CheckCircleIcon, Clapperboard, Info, Pause } from 'lucide-vue-next';
import { Course } from '@/interfaces/Course';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useCourse } from '@/composables/useCourse';
import { displayTrailer } from '../states/CoursePreviewState';

const { redirectToCurrentLesson } = useCourse()

const formatDate = (date: Date) => {
  return format(date, "d MMMM, yyyy", { locale: ptBR })
}

const props = defineProps<{ course: Course }>();
const checkoutBaseUrl = process.env.VUE_APP_CHECKOUT_BASE_URL

const isPromotional = computed(() => props.course.variant.promotionalPrice < props.course.variant.price);

const discountPercentage = computed(() => {
  if (!isPromotional.value) return 0;
  const discount = ((props.course.variant.price - props.course.variant.promotionalPrice) / props.course.variant.price) * 100;
  return Math.round(discount);
});
</script>
