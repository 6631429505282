import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = { class: "flex flex-col sm:gap-4" }
const _hoisted_2 = { class: "grid flex-1 items-start gap-4 sm:py-0 md:gap-8 max-w-[1700px] mx-auto w-full max-h-full" }
const _hoisted_3 = { class: "mx-auto grid max-w-full flex-1 auto-rows-max gap-4" }
const _hoisted_4 = {
  key: 0,
  class: "absolute w-full left-0 right-0 h-auto"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "object-cover w-full max-w-full h-80 absolute bg-zinc-500"
}
const _hoisted_7 = {
  for: "backgroundImage",
  class: "absolute top-8 -translate-y-1/2 -translate-x-1/2 left-1/2 bg-muted rounded-full p-4 cursor-pointer"
}
const _hoisted_8 = ["onSubmit"]
const _hoisted_9 = { class: "grid auto-rows-max items-start gap-4 col-span-2 lg:col-span-2 lg:gap-8 cols-span-2" }
const _hoisted_10 = { class: "grid gap-6 pt-[240px] relative" }
const _hoisted_11 = { class: "absolute left-1/2 -translate-x-1/2 -top-[130px]" }
const _hoisted_12 = { class: "grid gap-3" }
const _hoisted_13 = { class: "grid gap-3" }
const _hoisted_14 = { class: "grid gap-3" }
const _hoisted_15 = { class: "grid gap-3" }
const _hoisted_16 = { class: "grid gap-3" }
const _hoisted_17 = { class: "grid gap-3" }
const _hoisted_18 = { class: "grid gap-3" }
const _hoisted_19 = { class: "grid grid-cols-2 xl:grid-cols-3 gap-y-4" }
const _hoisted_20 = { class: "flex items-center gap-2" }
const _hoisted_21 = { class: "flex items-center space-x-2" }
const _hoisted_22 = { class: "flex items-center space-x-2" }
const _hoisted_23 = { class: "flex items-center space-x-2" }
const _hoisted_24 = { class: "flex items-center space-x-2" }
const _hoisted_25 = { class: "grid gap-6 min-w-[300px]" }
const _hoisted_26 = { class: "grid gap-3" }
const _hoisted_27 = { class: "grid gap-3" }
const _hoisted_28 = { class: "grid gap-3" }
const _hoisted_29 = { class: "flex flex-col gap-6" }
const _hoisted_30 = { class: "grid gap-3" }
const _hoisted_31 = {
  key: 1,
  class: "grid gap-3"
}
const _hoisted_32 = { class: "sm:grid auto-rows-max items-start gap-4 lg:gap-8 sm:col-span-3" }
const _hoisted_33 = { class: "flex justify-between items-center" }
const _hoisted_34 = {
  key: 0,
  class: "grid gap-6 flex-wrap"
}
const _hoisted_35 = { class: "flex flex-col gap-4 border-b" }
const _hoisted_36 = { class: "flex items-center justify-between hover:bg-muted transition-background duration-200 p-4" }
const _hoisted_37 = { class: "flex items-center gap-6" }
const _hoisted_38 = { class: "text-zinc-400 text-xs" }
const _hoisted_39 = { class: "flex items-center gap-2" }
const _hoisted_40 = { class: "flex justify-center flex-col gap-2 pr-4" }
const _hoisted_41 = { class: "handle bg p-2 rounded-lg bg-gray-500 mr-2" }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = ["onClick"]
const _hoisted_44 = { class: "flex gap-2 items-center" }
const _hoisted_45 = { class: "flex flex-col gap-6" }
const _hoisted_46 = { class: "flex items-center space-x-2" }
const _hoisted_47 = {
  key: 0,
  class: "grid gap-3"
}
const _hoisted_48 = ["onSubmit"]
const _hoisted_49 = { class: "sm:grid auto-rows-max items-start sm:gap-4 lg:col-span-2 lg:gap-8" }
const _hoisted_50 = { class: "flex items-center justify-between" }
const _hoisted_51 = { class: "grid gap-6" }
const _hoisted_52 = { class: "grid gap-3" }
const _hoisted_53 = { class: "grid gap-3" }
const _hoisted_54 = { class: "grid gap-3" }
const _hoisted_55 = { class: "flex justify-between" }
const _hoisted_56 = { class: "flex items-center space-x-2" }
const _hoisted_57 = {
  key: 0,
  class: "sm:grid auto-rows-max items-start gap-4 lg:gap-8 col-span-2 mt-8 sm:mt-0"
}
const _hoisted_58 = { class: "flex justify-between items-center" }
const _hoisted_59 = { key: 0 }
const _hoisted_60 = { class: "flex flex-col gap-4 border-b" }
const _hoisted_61 = { class: "flex items-center justify-between hover:bg-muted transition-background duration-200 sm:p-4" }
const _hoisted_62 = { class: "flex gap-4" }
const _hoisted_63 = { class: "text-zinc-400 text-xs" }
const _hoisted_64 = { class: "flex items-center gap-2" }
const _hoisted_65 = { class: "flex justify-center flex-col gap-2 pr-4" }
const _hoisted_66 = { class: "handle bg p-2 rounded-lg bg-gray-500 mr-2" }
const _hoisted_67 = ["onClick"]
const _hoisted_68 = ["onClick"]
const _hoisted_69 = { class: "flex gap-2 items-center" }
const _hoisted_70 = ["onSubmit"]
const _hoisted_71 = { class: "grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8" }
const _hoisted_72 = { class: "flex items-center justify-between" }
const _hoisted_73 = { class: "grid gap-6" }
const _hoisted_74 = { class: "grid gap-6 mb-6" }
const _hoisted_75 = {
  for: "attachments",
  class: "border-2 border-dashed rounded-xl grid gap-4 border-gray-700"
}
const _hoisted_76 = { class: "p-14 flex flex-col gap-4 items-center" }
const _hoisted_77 = { class: "flex justify-between items-center gap-4" }
const _hoisted_78 = ["onClick"]
const _hoisted_79 = { class: "flex justify-between items-center gap-4" }
const _hoisted_80 = ["onClick"]
const _hoisted_81 = { class: "flex gap-2 items-center" }
const _hoisted_82 = { class: "grid gap-3" }
const _hoisted_83 = { class: "grid gap-3" }
const _hoisted_84 = { class: "grid gap-3" }
const _hoisted_85 = { class: "grid gap-3" }
const _hoisted_86 = {
  key: 0,
  class: "grid gap-3"
}
const _hoisted_87 = {
  key: 2,
  class: "grid gap-3"
}
const _hoisted_88 = { class: "grid gap-3" }
const _hoisted_89 = {
  key: 3,
  class: "grid gap-3"
}
const _hoisted_90 = { class: "grid gap-3" }
const _hoisted_91 = { class: "flex justify-between" }
const _hoisted_92 = { class: "flex items-center space-x-2" }
const _hoisted_93 = {
  key: 0,
  class: "grid auto-rows-max items-start gap-4 lg:gap-8 col-span-2 mt-8 sm:mt-0"
}

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import draggable from 'vuedraggable'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { AlertTriangle, Loader2, MoreHorizontal, ImageUp, Pencil, Trash2, ChevronsUpDown, CircleHelp, FileUp } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { computed, onMounted, ref } from 'vue'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Course } from '@/interfaces/Course'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Module, ModuleMedia } from '@/interfaces/Module'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Lesson } from '@/interfaces/lesson/Lessons'
import { LessonContentOriginEnum, lessonContentOriginLabels } from '@/enums/lesson/LessonContentOriginEnum'
import CommentsCard from './components/CommentsCard.vue'
import { CourseStatusEnum, courseStatusLabels } from '@/enums/course/CourseStatusEnum'
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum'
import ImageInput from '@/components/ui/input/ImageInput.vue'
import { CourseVideoTypeEnum, courseVideoTypeLabels } from '@/enums/course/CourseVideoTypeEnum'
import { Badge } from '@/components/ui/badge'
import { ModuleStatusEnum, moduleStatusLabels } from '@/enums/module/ModuleStatusEnum'
import { LessonStatusEnum, lessonStatusLabels } from '@/enums/lesson/LessonStatusEnum'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { CourseCommentsTypeEnum, commentsTypeLabels } from '@/enums/course/CourseCommentsTypeEnum'
import { LessonTypeEnum, lessonTypeLabels } from '@/enums/lesson/LessonTypeEnum'
import { LessonAttachment } from '@/interfaces/lesson/LessonAttachment'
import { CourseLevelEnum, CourseLevelLabels } from '@/enums/course/CourseLevelEnum'
import TagsInput from '@/components/ui/tags-input/TagsInput.vue'
import TagsInputItem from '@/components/ui/tags-input/TagsInputItem.vue'
import TagsInputItemText from '@/components/ui/tags-input/TagsInputItemText.vue'
import TagsInputItemDelete from '@/components/ui/tags-input/TagsInputItemDelete.vue'
import TagsInputInput from '@/components/ui/tags-input/TagsInputInput.vue'
import { CourseCategoryEnum, CourseCategoryLabels } from '@/enums/course/CourseCategoryEnum'
import VideoUpload from './components/VideoUpload.vue'

type ModuleFormSchemaType = z.infer<typeof moduleFormSchema>

const CHUNK_SIZE = 5 * 1024 * 1024 // 5MB por chunk

export default /*@__PURE__*/_defineComponent({
  __name: 'EditCourseView',
  props: {
    courseId: {}
  },
  setup(__props: any) {

const courseMediaForm = ref<Record<string, File | undefined>>({
  image: undefined,
  backgroundImage: undefined
})
const courseMediaPreviews = ref({
  backgroundImage: ''
})
const drag =  ref(false)
const dragLesson =  ref(false)
const selectedTab = ref('course')
const isLoadingCourse = ref(false)
const formValues = ref<Partial<Course & { videoFile?: File }>>({
  title: '',
  video: '',
  introduction: '',
  description: '',
  upcoming: false,
  commentsType: 'Livre',
  communityType: 'Aberta',
  // limitClientsFree: 0,
  status: CourseStatusEnum.ACTIVE,
  certification: true,
  // duration: 0,
  featuredInStore: false,
  learningOutcomes: [],
  prerequisites: [],
  level: undefined,
  hasReviews: false,
  displayTotalStudents: false,
  videoFile: undefined
})
const moduleFormValues = ref<Partial<Module>>({
  title: '',
  image: undefined,
  description: '',
  order: undefined,
  upcoming: false,
  status: undefined,
})
const deleteModuleFormValues = ref({
  deleteLessons: false,
  moduleId: undefined
})
const lessonFormValues = ref<any>({
  title: '',
  description: '',
  image: undefined,
  status: undefined,
  // order: undefined,
  upcoming: false,
  content: {
    url: '',
    origin: undefined
  },
  origin: undefined,
  releaseDelayAfterPurchase: undefined,
  attachments: [],
  newAttachments: [],
})
const showLessonReleaseDelayAfterPurchaseInput = ref<'true' | 'false'>('false')
const moduleToEdit = ref<Module>()
const lessonToEdit = ref<Lesson>()
const course = ref<Course>()
const courseBackgroundImage = computed(() => course.value?.media.find((m) => m.type === CourseMediaTypeEnum.BACKGROUND))
const courseImage = computed(() => course.value?.media.find((m) => m.type === CourseMediaTypeEnum.MAIN))
const props = __props
const isUpdatingCourse = ref(false)
const isCreatingModule = ref(false)
const isUpdatingModule = ref(false)
const isLoadingModule = ref(false)
const isCreatingLesson = ref(false)
const isUpdatingLesson = ref(false)
const isLoadingLesson = ref(false)
const selectedModuleIdToDelete = ref<number>()
const isDeleteModuleDialogConfirmationOpen = ref(false)
const selectedLessonIdToDelete = ref<number>()
const lessonAttachmentToDeleteId = ref<number>()
const isDeleteLessonDialogConfirmationOpen = ref(false)
const isDeleteLessonAttachmentDialogConfirmationOpen = ref(false)
const isDeletingLessonAttachment = ref(false)
const isDeletingModule = ref(false)
const isDeletingLesson = ref(false)
const axios = useAxios()
const requiredMessage = {
  required_error: "Obrigatório",
}
const formSchema = z.object({
  title: z.string(requiredMessage).default(formValues.value.title || ''),
  videoOriginType: z.string(requiredMessage).optional().default(formValues.value.videoOriginType || ''),
  video: z.string(requiredMessage).optional().default(formValues.value.video || ''),
  introduction: z.string(requiredMessage).default(formValues.value.introduction || ''),
  description: z.string(requiredMessage).default(formValues.value.description || ''),
  upcoming: z.boolean().default(formValues.value.upcoming !== undefined ? formValues.value.upcoming : false),
  communityType: z.string(requiredMessage).default(formValues.value.communityType || ''),
  // limitClientsFree: z.number(requiredMessage).default(formValues.value.limitClientsFree !== undefined ? formValues.value.limitClientsFree : 0),
  status: z.nativeEnum(CourseStatusEnum).default(formValues.value.status!),
  certification: z.boolean().default(formValues.value.certification !== undefined ? formValues.value.certification : true),
  // duration: z.number().optional().default(formValues.value.duration || 0),
  featuredInStore: z.boolean().default(!!formValues.value.featuredInStore),
  level: z.nativeEnum(CourseLevelEnum).default(formValues.value.level || CourseLevelEnum.BEGINNER),
  hasReviews: z.boolean().default(!!formValues.value.hasReviews),
  displayTotalStudents: z.boolean().default(!!formValues.value.displayTotalStudents),
  category: z.nativeEnum(CourseCategoryEnum, requiredMessage).default(formValues.value.category || CourseCategoryEnum.OTHER),
});
const moduleFormSchema = z.object({
  title: z.string(requiredMessage),
  // image: z.any().refine(file => !!moduleFormValues.value.image, {
  //   message: "Obrigatório."
  // }),
  description: z.string(requiredMessage),
  status: z.nativeEnum(ModuleStatusEnum, requiredMessage),
  upcoming: z.boolean().default(false),
  // order: z.number(requiredMessage),
});
const editModuleFormSchema = z.object({
  title: z.string(requiredMessage).default(moduleToEdit.value?.title ?? ''),
  image: z.any().optional(),
  description: z.string(requiredMessage).default(moduleToEdit.value?.description ?? ''),
  status: z.nativeEnum(ModuleStatusEnum, requiredMessage).default(moduleToEdit.value?.status ?? ModuleStatusEnum.ACTIVE),
  upcoming: z.boolean().default(false),
  order: z.number(requiredMessage).default(moduleToEdit.value?.order ?? 1),
});
const lessonFormSchema = z.object({
  title: z.string(requiredMessage),
  description: z.string(requiredMessage),
  // image: z.any().refine(file => !!lessonFormValues.value.image, {
  //   message: "Obrigatório."
  // }),
  status: z.nativeEnum(LessonStatusEnum, requiredMessage),
  // order: z.number(requiredMessage),
  upcoming: z.boolean().default(false),
  type: z.nativeEnum(LessonTypeEnum, requiredMessage),
  content: z.object({
    url: z.string(requiredMessage).url('Deve ser uma url válida')
      .optional(),
      origin: z.nativeEnum(LessonContentOriginEnum, requiredMessage)
      .optional(),
    duration: z.number(requiredMessage)
      .optional()
  }).optional(),
});
const editLessonFormSchema = z.object({
  title: z.string(requiredMessage).default(lessonToEdit.value?.title ?? ''),
  description: z.string(requiredMessage).default(lessonToEdit.value?.description ?? ''),
  // image: z.any().optional(),
  status: z.nativeEnum(LessonStatusEnum, requiredMessage).default(lessonToEdit.value?.status ?? LessonStatusEnum.ACTIVE),
  // order: z.number(requiredMessage).default(lessonToEdit.value?.order ?? 1),
  upcoming: z.boolean().default(false),
  type: z.nativeEnum(LessonTypeEnum, requiredMessage).default(lessonToEdit.value?.type ?? LessonTypeEnum.VIDEO),
  content: z.object({
    url: z.string().default(lessonToEdit.value?.content.url!)
      .optional(),
    origin: z.nativeEnum(LessonContentOriginEnum, requiredMessage).default(lessonToEdit.value?.content.origin!)
      .optional(),
    duration: z.number(requiredMessage).default(Number(lessonToEdit.value?.content.duration?.seconds ?? 0))
      .optional(),
  })
});

function updateCourse(payload: Partial<Course & { videoFile?: File }>) {
  isUpdatingCourse.value = true

  const formData = new FormData();

  formData.append('title', payload.title || course.value!.title);
  if (payload.video) formData.append('video', payload.video || course.value!.video);
  if (payload.videoOriginType === CourseVideoTypeEnum.HOOPAY && payload.videoFile) formData.append('video', payload.videoFile)
  if (payload.videoOriginType) formData.append('videoOriginType', payload.videoOriginType || course.value!.videoOriginType);
  formData.append('introduction', payload.introduction || course.value!.introduction);
  formData.append('description', payload.description || course.value!.description);
  formData.append('upcoming', String(payload.upcoming ?? false));
  formData.append('commentsType', payload.commentsType ?? 'Livre');
  formData.append('communityType', payload.communityType ?? 'Aberta');
  formData.append('status', payload.status ?? 'active');
  formData.append('certification', String(payload.certification ?? true));
  formData.append('featuredInStore', String(payload.featuredInStore ?? false));
  payload.learningOutcomes?.forEach((value: string, index: number) => {
    formData.append(`learningOutcomes[${index}]`, value);
  });

  payload.prerequisites?.forEach((value: string, index: number) => {
    formData.append(`prerequisites[${index}]`, value);
  });
  formData.append('category', payload.category as string);
  formData.append('level', payload.level as string);
  formData.append('hasReviews', payload.hasReviews ? 'true' : 'false');
  formData.append('displayTotalStudents', payload.displayTotalStudents ? 'true' : 'false');

  if (courseMediaForm.value.image) {
    formData.append('image', courseMediaForm.value.image);
  }

  if (courseMediaForm.value.backgroundImage) {
    formData.append('backgroundImage', courseMediaForm.value.backgroundImage);
  }

  axios.patch(`/courses/${props.courseId}`, formData)
    .then(() => {
      toast({
        title: 'Curso atualizado com sucesso',
        class: 'bg-green-500 text-white'
      })
      getCourseById({ hasLoading: false })
    })
    .finally(() => isUpdatingCourse.value = false)
}

function createModule(payload: Partial<Module>) {
  isCreatingModule.value = true

  const formData = new FormData();

  formData.append('title', String(payload.title));
  // formData.append('image', payload.image as File);
  formData.append('description', payload.description ?? '');
  // formData.append('order', String(payload.order));
  formData.append('upcoming', String(payload.upcoming ?? false));
  formData.append('status', String(payload.status));

  axios.post(`/courses/${props.courseId}/modules`, formData)
    .then(() => {
      toast({
        title: 'Módulo cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
      getCourseById({ hasLoading: false })
      resetModuleForm()
    })
    .finally(() => isCreatingModule.value = false)
}

function onEndDragModule(e: any) {
  const modulesOrder = course.value?.modules.map((m, index) => ({ id: m.id, order: index }))
  updateModulesOrder(modulesOrder!)
}

function updateModulesOrder(modules: { id: number, order: number }[]) {
  axios.patch(`/courses/${props.courseId}/modules/order`, { modules })
    .then(() => {
      toast({
        title: 'Ordem de exibição atualizada com sucesso',
        class: 'bg-green-500 text-white'
      })
      getCourseById({ hasLoading: false })
    })
}

function updateModule(moduleId: number, payload: Partial<Module>, isOrderUpdate = false) {
  isUpdatingModule.value = true

  const formData = new FormData();

  if (!isOrderUpdate) {
    formData.append('title', String(payload.title));
    // if (payload.image) formData.append('image', payload.image as File);
    formData.append('description', payload.description ?? '');
    formData.append('upcoming', String(payload.upcoming ?? false));
    formData.append('status', String(payload.status));
  }

  if (payload.order !== undefined && payload.order !== null) formData.append('order', String(payload.order));

  axios.patch(`/courses/${props.courseId}/modules/${moduleId}`, formData)
    .then(() => {
      toast({
        title: 'Módulo atualizado com sucesso',
        class: 'bg-green-500 text-white'
      })
      getCourseById({ hasLoading: false })
    })
    .finally(() => isUpdatingModule.value = false)
}

function getModuleById(id: number) {
  isLoadingModule.value = true

  axios.get(`/courses/${props.courseId}/modules/${id}`)
    .then((res) => {
      moduleFormValues.value = {
        title: res.data.title,
        image: undefined,
        description: res.data.description,
        order: res.data.order,
        upcoming: res.data.upcoming,
        status: res.data.status,
      }
      moduleToEdit.value = res.data
    })
    .finally(() => isLoadingModule.value = false)
}

function deleteModule(id: number) {
  isDeletingModule.value = true

  axios.delete(`/courses/${props.courseId}/modules/${id}`, !deleteModuleFormValues.value.deleteLessons ? {
    moduleToTransferLessonsId: deleteModuleFormValues.value.moduleId
  } : {})
    .then((res) => {
      toast({
        title: 'Módulo excluído com sucesso',
        class: 'bg-green-500 text-white'
      })

      isDeleteModuleDialogConfirmationOpen.value = false
      course.value!.modules = course.value!.modules.filter((m) => m.id !== id)
    })
    .finally(() => isDeletingModule.value = false)
}

function resetDeleteModuleForm() {
  deleteModuleFormValues.value = {
    deleteLessons: false,
    moduleId: undefined
  }
}

function getCourseById({ hasLoading }: { hasLoading: boolean } = { hasLoading: true }) {
  if (hasLoading) {
    isLoadingCourse.value = true
  }

  axios.get(`/courses/${props.courseId}`)
    .then((res) => {
      formValues.value = {
        title: res.data.course.title,
        videoOriginType: res.data.course.videoOriginType,
        video: res.data.course.video,
        introduction: res.data.course.introduction,
        description: res.data.course.description,
        upcoming: !!res.data.course.upcoming,
        commentsType: res.data.course.commentsType,
        communityType: res.data.course.communityType ?? '',
        // limitClientsFree: res.data.course.limitClientsFree,
        status: res.data.course.status ?? 'active',
        certification: !!res.data.course.certification,
        // duration: res.data.course.duration?.seconds,
        featuredInStore: !!res.data.course.featuredInStore,
        learningOutcomes: res.data.course.learningOutcomes ?? [],
        prerequisites: res.data.course.prerequisites ?? [],
        category: res.data.course.category,
        level: res.data.course.level,
        hasReviews: !!res.data.course.hasReviews,
        displayTotalStudents: !!res.data.course.displayTotalStudents,
      }
      course.value = res.data.course
    })
    .finally(() => isLoadingCourse.value = false)
}

function intervalToSeconds(interval: any): number {
  const { years = 0, months = 0, days = 0, hours = 0, minutes = 0, seconds = 0 } = interval;

  return (
    (years * 365 * 24 * 60 * 60) +
    (months * 30 * 24 * 60 * 60) +
    (days * 24 * 60 * 60) +
    (hours * 60 * 60) +
    (minutes * 60) +
    seconds
  );
}

function getLessonById(id: number) {
  isLoadingLesson.value = true

  axios.get(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${id}`)
  .then((res) => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0);

    lessonFormValues.value = {
      title: res.data.title,
      description: res.data.description,
      image: res.data.image,
      status: res.data.status,
      order: res.data.order,
      upcoming: res.data.upcoming,
      content: {
        url: res.data.content.url,
        origin: res.data.content.origin,
        duration: res.data.content.duration ? intervalToSeconds(res.data.content.duration) : undefined,
      },
      releaseDelayAfterPurchase: res.data.releaseDelayAfterPurchase,
      type: res.data.type,
      attachments: res.data.attachments,
      newAttachments: [],
    }

    showLessonReleaseDelayAfterPurchaseInput.value = res.data.releaseDelayAfterPurchase ? 'true' : 'false'
    lessonToEdit.value = res.data
  })
  .finally(() => isLoadingLesson.value = false)
}

function createLesson(payload: Partial<Lesson>) {
  isCreatingLesson.value = true

  const formData = new FormData()
  formData.append('title', lessonFormValues.value.title)
  formData.append('description', lessonFormValues.value.description)
  // formData.append('image', lessonFormValues.value.image!)
  formData.append('status', lessonFormValues.value.status!)
  // formData.append('order', lessonFormValues.value.order!)
  formData.append('upcoming', String(lessonFormValues.value.upcoming))
  formData.append('type', String(lessonFormValues.value.type))
  if (lessonFormValues.value.content.url) formData.append('content[url]', lessonFormValues.value.content.url)
  if (lessonFormValues.value.content.origin) formData.append('content[origin]', lessonFormValues.value.content.origin)
  if (lessonFormValues.value.content.origin === LessonContentOriginEnum.HOOPAY && lessonFormValues.value.type === LessonTypeEnum.VIDEO) formData.append('video', lessonFormValues.value.content.video)
  if (lessonFormValues.value.content.duration) formData.append('content[duration]', lessonFormValues.value.content.duration)
  for (let i = 0; i < lessonFormValues.value.newAttachments.length; i++) {
    const newAttachment = lessonFormValues.value.newAttachments[i];
    formData.append(`attachments[${i}][title]`, newAttachment.title);
    formData.append('attachmentFiles', newAttachment.file);
  }

  if (showLessonReleaseDelayAfterPurchaseInput.value === 'true') formData.append('releaseDelayAfterPurchase', lessonFormValues.value.releaseDelayAfterPurchase)

  axios.post(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons`, formData)
    .then(() => {
      toast({
        title: 'Aula cadastrada com sucesso',
        class: 'bg-green-500 text-white'
      })
      resetLessonForm()
      getModuleById(moduleToEdit.value!.id)
    })
    .finally(() => isCreatingLesson.value = false)
}

function onEndDragLesson(e: any) {
  const lessonsOrder = moduleToEdit.value?.lessons.map((m, index) => ({ id: m.id, order: index }))
  updateLessonsOrder(lessonsOrder!, moduleToEdit.value!.id)
}

function updateLessonsOrder(lessons: { id: number, order: number }[], moduleId: number) {
  axios.patch(`/courses/${props.courseId}/modules/${moduleId}/lessons/order`, { lessons })
    .then(() => {
      toast({
        title: 'Ordem de exibição da aula atualizada com sucesso',
        class: 'bg-green-500 text-white'
      })
      getCourseById({ hasLoading: false })
    })
}

const isUploading = ref(false)
const progress = ref(0)
const progressPercentage = ref(0)
const uploadSpeed = ref(0) // Velocidade em MB/s
const estimatedTime = ref(0) // Tempo estimado restante em segundos

async function updateLesson(lessonId: number) {
  isUpdatingLesson.value = true

  const formData = new FormData()
  formData.append('title', lessonFormValues.value.title)
  formData.append('description', lessonFormValues.value.description)
  formData.append('status', lessonFormValues.value.status!)
  formData.append('upcoming', String(lessonFormValues.value.upcoming))
  if (lessonFormValues.value.content.url) formData.append('content[url]', lessonFormValues.value.content.url)
  if (lessonFormValues.value.content.origin) formData.append('content[origin]', lessonFormValues.value.content.origin)
  if (lessonFormValues.value.content.duration) formData.append('content[duration]', lessonFormValues.value.content.duration)
  formData.append('releaseDelayAfterPurchase', showLessonReleaseDelayAfterPurchaseInput.value === 'false' ? '' : lessonFormValues.value.releaseDelayAfterPurchase)

  // Adiciona os anexos normalmente
  for (let i = 0; i < lessonFormValues.value.newAttachments.length; i++) {
    const newAttachment = lessonFormValues.value.newAttachments[i]
    formData.append(`attachments[${i}][title]`, newAttachment.title)
    formData.append('attachmentFiles', newAttachment.file)
  }

  // Atualiza os metadados da aula primeiro
  await axios.put(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${lessonId}`, formData)

  // Enviar vídeo em chunks (se existir)
  if (lessonFormValues.value.content.origin === LessonContentOriginEnum.HOOPAY && lessonFormValues.value.type === LessonTypeEnum.VIDEO) {
    await uploadVideoInChunks(lessonFormValues.value.content.video, lessonId)
  }

  isUpdatingLesson.value = false

  toast({
    title: 'Aula atualizada com sucesso',
    class: 'bg-green-500 text-white'
  })
  await getModuleById(moduleToEdit.value!.id)
}

// Função para enviar o vídeo em partes
async function uploadVideoInChunks(videoFile: File, lessonId: number) {
  isUploading.value = true
  progressPercentage.value = 0
  uploadSpeed.value = 0
  estimatedTime.value = 0

  const totalChunks = Math.ceil(videoFile.size / CHUNK_SIZE)
  let uploadedSize = 0
  let startTime = Date.now()
  for (let i = 0; i < totalChunks; i++) {
    const start = i * CHUNK_SIZE
    const end = Math.min(start + CHUNK_SIZE, videoFile.size)
    const chunk = videoFile.slice(start, end)
    
    const chunkFormData = new FormData()
    chunkFormData.append('video', chunk)
    // chunkFormData.append('filename', videoFile.name)
    // chunkFormData.append('chunkIndex', String(i))
    // chunkFormData.append('totalChunks', String(totalChunks))
    
    const chunkStartTime = Date.now()
    console.log({uploadedSize, startTime, totalChunks, chunk })
    await axios.put(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${lessonId}`, chunkFormData)
    const chunkElapsedTime = (Date.now() - chunkStartTime) / 1000 // Tempo em segundos

    uploadedSize += chunk.size
    progressPercentage.value = Math.round((uploadedSize / videoFile.size) * 100)
    console.log(progressPercentage.value)
    // Calcular velocidade e ETA
    const elapsedTime = (Date.now() - startTime) / 1000 // Tempo em segundos
    uploadSpeed.value = (uploadedSize / 1024 / 1024) / elapsedTime // MB/s
    estimatedTime.value = uploadSpeed.value > 0 ? Math.max(0, (videoFile.size - uploadedSize) / (uploadSpeed.value * 1024 * 1024)) : 0
  }

  isUploading.value = false
}

function deleteLesson(id: number) {
  isDeletingLesson.value = true

  axios.delete(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${id}`)
    .then((res) => {
      toast({
        title: 'Aula excluída com sucesso',
        class: 'bg-green-500 text-white'
      })

      isDeleteLessonDialogConfirmationOpen.value = false
      moduleToEdit.value!.lessons = moduleToEdit.value!.lessons.filter((l) => l.id !== id)
    })
    .finally(() => isDeletingLesson.value = false)
}

function updateLessonAttachment(id: number, payload: { title: string }) {
  const lessonAttachment = lessonToEdit.value?.attachments.find((a) => a.id === id)

  if (payload.title !== lessonAttachment?.title) {
    axios.patch(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${lessonToEdit.value!.id}/attachments/${id}`, payload)
      .then(() => {
        toast({
          title: 'Anexo atualizado com sucesso',
          class: 'bg-green-500 text-white'
        })
        lessonAttachment!.title = payload.title
      })
  }
}

function deleteLessonAttachment(id: number) {
  isDeletingLessonAttachment.value = true

  axios.delete(`/courses/${props.courseId}/modules/${moduleToEdit.value!.id}/lessons/${lessonToEdit.value!.id}/attachments/${id}`)
    .then(() => {
      toast({
        title: 'Anexo excluído com sucesso',
        class: 'bg-green-500 text-white'
      })

      const index = lessonFormValues.value.attachments.findIndex((attachment: LessonAttachment) => attachment.id === id)
      lessonFormValues.value.attachments.splice(index, 1)
      isDeleteLessonAttachmentDialogConfirmationOpen.value = false
    })
    .finally(() => isDeletingLessonAttachment.value = false)

}

function onAttachmentsInputChange(e: Event) {
  lessonFormValues.value.newAttachments.push({
    title: (e.target as HTMLInputElement).files![0].name,
    file: (e.target as HTMLInputElement).files![0],
  })
}

function resetLessonForm() {
  lessonFormValues.value = {
    title: '',
    description: '',
    image: undefined,
    status: undefined,
    order: undefined,
    upcoming: false,
    content: {
      url: '',
      origin: undefined
    },
    releaseDelayAfterPurchase: undefined,
    attachments: [],
    newAttachments: []
  }
}

function resetModuleForm() {
  moduleFormValues.value = {
    title: '',
    image: undefined,
    description: '',
    order: undefined,
    upcoming: false,
    status: undefined,
  }
}

function onBackgroundImageChange(e: Event) {
  const file = (e.target as HTMLInputElement).files![0]

  courseMediaForm.value.backgroundImage = file

  courseMediaPreviews.value.backgroundImage = URL.createObjectURL(file)
}

onMounted(() => {
  getCourseById()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_unref(Tabs), {
        "default-value": "course",
        "model-value": selectedTab.value
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(TabsList), { class: "hidden" }, {
              default: _withCtx(() => [
                _createVNode(_unref(TabsTrigger), {
                  value: "course",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => {selectedTab.value = 'course';})
                }, {
                  default: _withCtx(() => _cache[56] || (_cache[56] = [
                    _createTextVNode(" Curso ")
                  ])),
                  _: 1
                }),
                _createVNode(_unref(TabsTrigger), {
                  value: "modules",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => {selectedTab.value = 'modules'; moduleToEdit.value = undefined; resetModuleForm()})
                }, {
                  default: _withCtx(() => _cache[57] || (_cache[57] = [
                    _createTextVNode(" Módulos ")
                  ])),
                  _: 1
                }),
                _createVNode(_unref(TabsTrigger), {
                  value: "lesson",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => {selectedTab.value = 'lesson';})
                }, {
                  default: _withCtx(() => _cache[58] || (_cache[58] = [
                    _createTextVNode(" Aula ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(TabsContent), {
              value: "course",
              class: "mt-0"
            }, {
              default: _withCtx(() => [
                (!isLoadingCourse.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("input", {
                        class: "hidden",
                        type: "file",
                        onChange: onBackgroundImageChange,
                        id: "backgroundImage"
                      }, null, 32),
                      (courseMediaPreviews.value.backgroundImage || courseBackgroundImage.value?.bucketLocation)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: courseMediaPreviews.value.backgroundImage ? courseMediaPreviews.value.backgroundImage : courseBackgroundImage.value?.bucketLocation,
                            class: "object-cover w-full max-w-full h-80 absolute"
                          }, null, 8, _hoisted_5))
                        : (_openBlock(), _createElementBlock("div", _hoisted_6)),
                      _createElementVNode("label", _hoisted_7, [
                        (!courseBackgroundImage.value?.bucketLocation && !courseMediaPreviews.value.backgroundImage)
                          ? (_openBlock(), _createBlock(_unref(ImageUp), {
                              key: 0,
                              class: "w-4 h-4"
                            }))
                          : (_openBlock(), _createBlock(_unref(Pencil), {
                              key: 1,
                              class: "w-4 h-4"
                            }))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!isLoadingCourse.value)
                  ? (_openBlock(), _createBlock(_unref(Form), {
                      key: 1,
                      as: "",
                      "keep-values": "",
                      "validation-schema": _unref(toTypedSchema)(_unref(formSchema))
                    }, {
                      default: _withCtx(({ meta, values, validate }) => [
                        _createElementVNode("form", {
                          onSubmit: _withModifiers(() => {
                validate();

                if (values.videoOriginType === _unref(CourseVideoTypeEnum).HOOPAY && !formValues.value.videoFile) {
                  _unref(toast)({
                    title: 'Upload de vídeo de introdução é obrigatório',
                    variant: 'destructive'
                  })
                }

                if (meta.valid) {
                  updateCourse(formValues.value)
                }
              }, ["prevent"]),
                          class: "grid gap-4 grid-cols-1 md:grid-cols-[1fr_250px] xl:grid-cols-5 lg:gap-8 z-40 relative mt-[14rem]"
                        }, [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_unref(Card), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(CardContent), null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_10, [
                                      _createElementVNode("div", _hoisted_11, [
                                        _createVNode(ImageInput, {
                                          modelValue: courseMediaForm.value.image,
                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((courseMediaForm.value.image) = $event)),
                                          defaultPreview: courseImage.value?.bucketLocation,
                                          width: '250px',
                                          height: '333px'
                                        }, null, 8, ["modelValue", "defaultPreview"])
                                      ]),
                                      _createElementVNode("div", _hoisted_12, [
                                        _createVNode(_unref(FormField), {
                                          name: "title",
                                          class: "col-span-1"
                                        }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormItem), null, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(FormLabel), null, {
                                                  default: _withCtx(() => _cache[59] || (_cache[59] = [
                                                    _createTextVNode("Título")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormControl), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(Input), _mergeProps({
                                                      type: "text",
                                                      class: "w-full",
                                                      placeholder: "ex: Design criativo, de ideias a realidade!"
                                                    }, componentField, {
                                                      modelValue: formValues.value.title,
                                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formValues.value.title) = $event))
                                                    }), null, 16, ["modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_unref(FormMessage))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_13, [
                                        _createVNode(_unref(FormField), {
                                          name: "introduction",
                                          class: "col-span-1"
                                        }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormItem), null, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(FormLabel), null, {
                                                  default: _withCtx(() => _cache[60] || (_cache[60] = [
                                                    _createTextVNode("Texto de Introdução")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormControl), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(Textarea), _mergeProps({
                                                      placeholder: "ex: Neste curso, você irá explorar o fascinante mundo do design...",
                                                      class: "min-h-32"
                                                    }, componentField, {
                                                      modelValue: formValues.value.introduction,
                                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formValues.value.introduction) = $event))
                                                    }), null, 16, ["modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_unref(FormMessage))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_14, [
                                        _createVNode(_unref(FormField), {
                                          name: "description",
                                          class: "col-span-1"
                                        }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormItem), null, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(FormLabel), null, {
                                                  default: _withCtx(() => _cache[61] || (_cache[61] = [
                                                    _createTextVNode("Descrição")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormControl), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(Textarea), _mergeProps({
                                                      placeholder: "ex: Aprenda métodos práticos, fundamentos, a receber feedback...",
                                                      class: "min-h-32"
                                                    }, componentField, {
                                                      modelValue: formValues.value.description,
                                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((formValues.value.description) = $event))
                                                    }), null, 16, ["modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_unref(FormMessage))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_15, [
                                        _createVNode(_unref(FormField), {
                                          name: "learningOutcomes",
                                          class: "col-span-1"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_unref(FormItem), null, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(FormLabel), null, {
                                                  default: _withCtx(() => _cache[62] || (_cache[62] = [
                                                    _createTextVNode("O que os alunos irão aprender")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormControl), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(TagsInput, {
                                                      onEnter: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"])),
                                                      modelValue: formValues.value.learningOutcomes,
                                                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((formValues.value.learningOutcomes) = $event))
                                                    }, {
                                                      default: _withCtx(() => [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formValues.value.learningOutcomes, (item) => {
                                                          return (_openBlock(), _createBlock(TagsInputItem, {
                                                            key: item,
                                                            value: item
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(TagsInputItemText),
                                                              _createVNode(TagsInputItemDelete)
                                                            ]),
                                                            _: 2
                                                          }, 1032, ["value"]))
                                                        }), 128)),
                                                        _createVNode(TagsInputInput, { placeholder: "Você se especializará em..." })
                                                      ]),
                                                      _: 1
                                                    }, 8, ["modelValue"])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormMessage))
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_16, [
                                        _createVNode(_unref(FormField), {
                                          name: "prerequisites",
                                          class: "col-span-1"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_unref(FormItem), null, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(FormLabel), null, {
                                                  default: _withCtx(() => _cache[63] || (_cache[63] = [
                                                    _createTextVNode("Pré-requisitos")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormControl), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(TagsInput, {
                                                      modelValue: formValues.value.prerequisites,
                                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((formValues.value.prerequisites) = $event))
                                                    }, {
                                                      default: _withCtx(() => [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formValues.value.prerequisites, (item) => {
                                                          return (_openBlock(), _createBlock(TagsInputItem, {
                                                            key: item,
                                                            value: item
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(TagsInputItemText),
                                                              _createVNode(TagsInputItemDelete)
                                                            ]),
                                                            _: 2
                                                          }, 1032, ["value"]))
                                                        }), 128)),
                                                        _createVNode(TagsInputInput, { placeholder: "Computador com acesso à internet..." })
                                                      ]),
                                                      _: 1
                                                    }, 8, ["modelValue"])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormMessage))
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_17, [
                                        _createVNode(_unref(FormField), { name: "category" }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormLabel), { for: "level" }, {
                                              default: _withCtx(() => _cache[64] || (_cache[64] = [
                                                _createTextVNode("Categoria")
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), {
                                              modelValue: formValues.value.category,
                                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((formValues.value.category) = $event))
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), {
                                                  id: "category",
                                                  "aria-label": "Selecionar a categoria"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseCategoryEnum), (category) => {
                                                      return (_openBlock(), _createBlock(_unref(SelectItem), { value: category }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_unref(CourseCategoryLabels)[category]), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 256))
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }, 8, ["modelValue"]),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_18, [
                                        _createVNode(_unref(FormField), { name: "level" }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormLabel), { for: "level" }, {
                                              default: _withCtx(() => _cache[65] || (_cache[65] = [
                                                _createTextVNode("Nível")
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), {
                                              modelValue: formValues.value.level,
                                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((formValues.value.level) = $event))
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), {
                                                  id: "level",
                                                  "aria-label": "Selecionar o nível"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseLevelEnum), (level) => {
                                                      return (_openBlock(), _createBlock(_unref(SelectItem), { value: level }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_unref(CourseLevelLabels)[level]), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 256))
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }, 8, ["modelValue"]),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_19, [
                                        _createElementVNode("div", _hoisted_20, [
                                          _createVNode(_unref(FormField), { name: "upcoming" }, {
                                            default: _withCtx(({ componentField }) => [
                                              _createVNode(_unref(Switch), _mergeProps(componentField, {
                                                checked: formValues.value.upcoming,
                                                "onUpdate:checked": _cache[12] || (_cache[12] = (checked) => formValues.value.upcoming = checked)
                                              }), null, 16, ["checked"]),
                                              _createVNode(_unref(FormLabel), {
                                                class: "flex items-center gap-2",
                                                for: "upcoming"
                                              }, {
                                                default: _withCtx(() => [
                                                  _cache[67] || (_cache[67] = _createTextVNode(" Em breve ")),
                                                  _createVNode(_unref(TooltipProvider), null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_unref(CircleHelp), { class: "size-5" })
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_unref(TooltipContent), {
                                                            side: "right",
                                                            "side-offset": 5
                                                          }, {
                                                            default: _withCtx(() => _cache[66] || (_cache[66] = [
                                                              _createTextVNode(" Exiba o curso de forma visível, mas sem a opção de compra, apenas com fins informativos. ")
                                                            ])),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _createElementVNode("div", _hoisted_21, [
                                          _createVNode(_unref(FormField), { name: "hasReviews" }, {
                                            default: _withCtx(({ componentField }) => [
                                              _createVNode(_unref(Switch), _mergeProps({
                                                checked: formValues.value.hasReviews,
                                                "onUpdate:checked": _cache[13] || (_cache[13] = (checked) => formValues.value.hasReviews = checked)
                                              }, componentField), null, 16, ["checked"]),
                                              _createVNode(_unref(Label), { for: "certification" }, {
                                                default: _withCtx(() => _cache[68] || (_cache[68] = [
                                                  _createTextVNode("Avaliações")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _createElementVNode("div", _hoisted_22, [
                                          _createVNode(_unref(FormField), { name: "displayTotalStudents" }, {
                                            default: _withCtx(({ componentField }) => [
                                              _createVNode(_unref(Switch), _mergeProps(componentField, {
                                                checked: formValues.value.displayTotalStudents,
                                                "onUpdate:checked": _cache[14] || (_cache[14] = (checked) => formValues.value.displayTotalStudents = checked)
                                              }), null, 16, ["checked"]),
                                              _createVNode(_unref(Label), { for: "certification" }, {
                                                default: _withCtx(() => _cache[69] || (_cache[69] = [
                                                  _createTextVNode("Exibir total de alunos")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _createElementVNode("div", _hoisted_23, [
                                          _createVNode(_unref(FormField), { name: "certification" }, {
                                            default: _withCtx(({ componentField }) => [
                                              _createVNode(_unref(Switch), _mergeProps(componentField, {
                                                checked: formValues.value.certification,
                                                "onUpdate:checked": _cache[15] || (_cache[15] = (checked) => formValues.value.certification = checked)
                                              }), null, 16, ["checked"]),
                                              _createVNode(_unref(FormLabel), { for: "certification" }, {
                                                default: _withCtx(() => _cache[70] || (_cache[70] = [
                                                  _createTextVNode("Certificado")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _createElementVNode("div", _hoisted_24, [
                                          _createVNode(_unref(FormField), { name: "featuredInStore" }, {
                                            default: _withCtx(({ componentField }) => [
                                              _createVNode(_unref(Switch), _mergeProps(componentField, {
                                                checked: formValues.value.featuredInStore,
                                                "onUpdate:checked": _cache[16] || (_cache[16] = (checked) => formValues.value.featuredInStore = checked)
                                              }), null, 16, ["checked"]),
                                              _createVNode(_unref(FormLabel), { for: "featuredInStore" }, {
                                                default: _withCtx(() => _cache[71] || (_cache[71] = [
                                                  _createTextVNode("Loja")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ])
                                      ]),
                                      _createVNode(_unref(Button), { type: "submit" }, {
                                        default: _withCtx(() => [
                                          (isUpdatingCourse.value)
                                            ? (_openBlock(), _createBlock(_unref(Loader2), {
                                                key: 0,
                                                class: "w-4 h-4 mr-2 animate-spin"
                                              }))
                                            : _createCommentVNode("", true),
                                          _cache[72] || (_cache[72] = _createTextVNode(" Salvar "))
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(Card), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(CardHeader), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(CardTitle), { class: "text-[1.1rem]" }, {
                                      default: _withCtx(() => _cache[73] || (_cache[73] = [
                                        _createTextVNode("Configurações")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(CardContent), null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_25, [
                                      _createElementVNode("div", _hoisted_26, [
                                        _createVNode(_unref(FormField), { name: "status" }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(Label), { for: "status" }, {
                                              default: _withCtx(() => _cache[74] || (_cache[74] = [
                                                _createTextVNode("Status")
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), _mergeProps(componentField, {
                                              modelValue: formValues.value.status,
                                              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((formValues.value.status) = $event))
                                            }), {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), {
                                                  id: "status",
                                                  "aria-label": "Selecionar status"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseStatusEnum), (status) => {
                                                      return (_openBlock(), _createBlock(_unref(SelectItem), { value: status }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_unref(courseStatusLabels)[status]), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 256))
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1040, ["modelValue"]),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_27, [
                                        _createVNode(_unref(FormField), { name: "commentsType" }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(Label), { for: "commentsType" }, {
                                              default: _withCtx(() => _cache[75] || (_cache[75] = [
                                                _createTextVNode("Comentários")
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), _mergeProps(componentField, {
                                              modelValue: formValues.value.commentsType,
                                              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((formValues.value.commentsType) = $event))
                                            }), {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), {
                                                  id: "commentsType",
                                                  "aria-label": "Selecionar tipo de comentários"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseCommentsTypeEnum), (type) => {
                                                      return (_openBlock(), _createBlock(_unref(SelectItem), { value: type }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_unref(commentsTypeLabels)[type]), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 256))
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1040, ["modelValue"]),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_28, [
                                        _createVNode(_unref(FormField), { name: "communityType" }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(Label), { for: "communityType" }, {
                                              default: _withCtx(() => _cache[76] || (_cache[76] = [
                                                _createTextVNode("Tipo de comunidade")
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), _mergeProps(componentField, {
                                              modelValue: formValues.value.communityType,
                                              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((formValues.value.communityType) = $event))
                                            }), {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), {
                                                  id: "communityType",
                                                  "aria-label": "Selecionar tipo de comunidade"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectItem), { value: "Aberto" }, {
                                                      default: _withCtx(() => _cache[77] || (_cache[77] = [
                                                        _createTextVNode("Aberto")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _createVNode(_unref(SelectItem), { value: "Fechado" }, {
                                                      default: _withCtx(() => _cache[78] || (_cache[78] = [
                                                        _createTextVNode("Fechado")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1040, ["modelValue"]),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        })
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(Card), { class: "overflow-hidden" }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(CardHeader), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(CardTitle), { class: "text-[1.1rem]" }, {
                                      default: _withCtx(() => _cache[79] || (_cache[79] = [
                                        _createTextVNode("Mídias")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_unref(CardDescription), null, {
                                      default: _withCtx(() => _cache[80] || (_cache[80] = [
                                        _createTextVNode("Imagem e vídeo do curso")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(CardContent), null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_29, [
                                      _createElementVNode("div", _hoisted_30, [
                                        _createVNode(_unref(FormField), { name: "videoOriginType" }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormLabel), { for: "contentType" }, {
                                              default: _withCtx(() => _cache[81] || (_cache[81] = [
                                                _createTextVNode("Origem do vídeo")
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), _mergeProps(componentField, {
                                              modelValue: formValues.value.videoOriginType,
                                              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((formValues.value.videoOriginType) = $event))
                                            }), {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), {
                                                  id: "contentType",
                                                  "aria-label": "Selecionar"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CourseVideoTypeEnum), (type) => {
                                                      return (_openBlock(), _createBlock(_unref(SelectItem), { value: type }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_unref(courseVideoTypeLabels)[type]), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 256))
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1040, ["modelValue"]),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      (formValues.value.videoOriginType === _unref(CourseVideoTypeEnum).HOOPAY)
                                        ? (_openBlock(), _createBlock(VideoUpload, {
                                            key: 0,
                                            "current-video-src": formValues.value.video,
                                            "current-video-origin": course.value?.videoOriginType,
                                            modelValue: formValues.value.videoFile,
                                            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((formValues.value.videoFile) = $event))
                                          }, null, 8, ["current-video-src", "current-video-origin", "modelValue"]))
                                        : _createCommentVNode("", true),
                                      (formValues.value.videoOriginType !== _unref(CourseVideoTypeEnum).HOOPAY)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                            _createVNode(_unref(FormField), { name: "video" }, {
                                              default: _withCtx(({ componentField }) => [
                                                _createVNode(_unref(FormLabel), null, {
                                                  default: _withCtx(() => _cache[82] || (_cache[82] = [
                                                    _createTextVNode("Vídeo de Introdução")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(Input), _mergeProps({
                                                  type: "text",
                                                  class: "w-full",
                                                  placeholder: "ex: https://youtube.com..."
                                                }, componentField, {
                                                  modelValue: formValues.value.video,
                                                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((formValues.value.video) = $event))
                                                }), null, 16, ["modelValue"]),
                                                _createVNode(_unref(FormMessage))
                                              ]),
                                              _: 1
                                            })
                                          ]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_32, [
                            _createVNode(_unref(Card), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(CardHeader), null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_33, [
                                      _createVNode(_unref(CardTitle), { class: "text-[1.1rem]" }, {
                                        default: _withCtx(() => _cache[83] || (_cache[83] = [
                                          _createTextVNode("Módulos")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_unref(Button), {
                                        onClick: _cache[23] || (_cache[23] = ($event: any) => {selectedTab.value = 'modules'; moduleToEdit.value = undefined; resetModuleForm()})
                                      }, {
                                        default: _withCtx(() => _cache[84] || (_cache[84] = [
                                          _createTextVNode("Adicionar")
                                        ])),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(CardContent), null, {
                                  default: _withCtx(() => [
                                    (course.value?.modules.length)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                          _createVNode(_unref(draggable), {
                                            modelValue: course.value.modules,
                                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((course.value.modules) = $event)),
                                            group: "modules",
                                            onStart: _cache[25] || (_cache[25] = ($event: any) => (drag.value=true)),
                                            onEnd: onEndDragModule,
                                            "item-key": "id",
                                            handle: ".handle"
                                          }, {
                                            item: _withCtx(({element, index}) => [
                                              _createElementVNode("div", _hoisted_35, [
                                                _createElementVNode("div", _hoisted_36, [
                                                  _createElementVNode("div", _hoisted_37, [
                                                    _createElementVNode("div", null, [
                                                      _createElementVNode("h3", null, _toDisplayString(element.title), 1),
                                                      _createElementVNode("p", _hoisted_38, _toDisplayString(element.description), 1)
                                                    ])
                                                  ]),
                                                  _createElementVNode("div", _hoisted_39, [
                                                    _createElementVNode("div", _hoisted_40, [
                                                      _createVNode(_unref(Badge), { class: "text-center justify-center inline-flex" }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_unref(moduleStatusLabels)[element.status as ModuleStatusEnum]), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1024),
                                                      (element.upcoming)
                                                        ? (_openBlock(), _createBlock(_unref(Badge), {
                                                            key: 0,
                                                            class: "bg-yellow-500 hover:bg-yellow-500"
                                                          }, {
                                                            default: _withCtx(() => _cache[85] || (_cache[85] = [
                                                              _createTextVNode("Conteúdo em breve")
                                                            ])),
                                                            _: 1
                                                          }))
                                                        : _createCommentVNode("", true)
                                                    ]),
                                                    _createVNode(_unref(TooltipProvider), null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                                                              default: _withCtx(() => [
                                                                _createElementVNode("div", null, [
                                                                  _createElementVNode("button", _hoisted_41, [
                                                                    _createVNode(_unref(ChevronsUpDown), { class: "w-4 h-4" })
                                                                  ])
                                                                ])
                                                              ]),
                                                              _: 1
                                                            }),
                                                            _createVNode(_unref(TooltipContent), {
                                                              side: "top",
                                                              "side-offset": 5
                                                            }, {
                                                              default: _withCtx(() => _cache[86] || (_cache[86] = [
                                                                _createTextVNode(" Arraste para mudar a ordem ")
                                                              ])),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_unref(TooltipProvider), null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                                                              default: _withCtx(() => [
                                                                _createElementVNode("button", {
                                                                  class: "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground h-10 w-10 rounded-lg hover:bg-emerald-500/10",
                                                                  onClick: _withModifiers(($event: any) => {selectedTab.value = 'modules'; moduleToEdit.value = undefined; getModuleById(element.id)}, ["prevent"])
                                                                }, [
                                                                  _createVNode(_unref(Pencil), { class: "w-4 h-4" })
                                                                ], 8, _hoisted_42)
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_unref(TooltipContent), {
                                                              side: "top",
                                                              "side-offset": 5
                                                            }, {
                                                              default: _withCtx(() => _cache[87] || (_cache[87] = [
                                                                _createTextVNode(" Editar ")
                                                              ])),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_unref(TooltipProvider), null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                                                              default: _withCtx(() => [
                                                                _createElementVNode("button", {
                                                                  class: "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground h-10 w-10 rounded-lg hover:bg-red-500/10",
                                                                  onClick: _withModifiers(($event: any) => {isDeleteModuleDialogConfirmationOpen.value = true; selectedModuleIdToDelete.value = element.id; resetDeleteModuleForm()}, ["prevent"])
                                                                }, [
                                                                  _createVNode(_unref(Trash2), { class: "w-4 h-4" })
                                                                ], 8, _hoisted_43)
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_unref(TooltipContent), {
                                                              side: "top",
                                                              "side-offset": 5
                                                            }, {
                                                              default: _withCtx(() => _cache[88] || (_cache[88] = [
                                                                _createTextVNode(" Excluir ")
                                                              ])),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ])
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }, 8, ["modelValue"])
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ])
                        ], 40, _hoisted_8)
                      ]),
                      _: 1
                    }, 8, ["validation-schema"]))
                  : (_openBlock(), _createBlock(SpinnerLoader, { key: 2 })),
                (selectedModuleIdToDelete.value)
                  ? (_openBlock(), _createBlock(_unref(Dialog), {
                      key: 3,
                      open: isDeleteModuleDialogConfirmationOpen.value,
                      "onUpdate:open": _cache[29] || (_cache[29] = (open) => isDeleteModuleDialogConfirmationOpen.value = open)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(DialogHeader), null, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_44, [
                                  _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                                  _createVNode(_unref(DialogTitle), { class: "text-[1.1rem]" }, {
                                    default: _withCtx(() => _cache[89] || (_cache[89] = [
                                      _createTextVNode("Cuidado")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _createVNode(_unref(DialogDescription), { class: "py-2 pb-4" }, {
                                  default: _withCtx(() => _cache[90] || (_cache[90] = [
                                    _createTextVNode(" A exclusão deste módulo é permanente. Você tem certeza de que quer prosseguir? ")
                                  ])),
                                  _: 1
                                }),
                                _createElementVNode("div", _hoisted_45, [
                                  _createElementVNode("div", _hoisted_46, [
                                    _createVNode(_unref(Switch), {
                                      id: "DeleteLessons",
                                      checked: deleteModuleFormValues.value.deleteLessons,
                                      "onUpdate:checked": _cache[26] || (_cache[26] = (checked) => deleteModuleFormValues.value.deleteLessons = checked)
                                    }, null, 8, ["checked"]),
                                    _createVNode(_unref(Label), { for: "deleteLessons" }, {
                                      default: _withCtx(() => _cache[91] || (_cache[91] = [
                                        _createTextVNode("excluir aulas")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  (!deleteModuleFormValues.value.deleteLessons)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                        _createVNode(_unref(Label), null, {
                                          default: _withCtx(() => _cache[92] || (_cache[92] = [
                                            _createTextVNode("Transferir aulas")
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(_unref(Select), {
                                          modelValue: deleteModuleFormValues.value.moduleId,
                                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((deleteModuleFormValues.value.moduleId) = $event))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_unref(SelectTrigger), { class: "w-full" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_unref(SelectContent), null, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(course.value?.modules.filter(m => m.id !== selectedModuleIdToDelete.value), (module) => {
                                                  return (_openBlock(), _createBlock(_unref(SelectItem), {
                                                    key: module.id,
                                                    value: String(module.id)
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(module.title), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }),
                            _cache[95] || (_cache[95] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
                            _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(DialogClose), { "as-child": "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Button), {
                                      type: "button",
                                      variant: "secondary"
                                    }, {
                                      default: _withCtx(() => _cache[93] || (_cache[93] = [
                                        _createTextVNode(" Cancelar ")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(Button), {
                                  type: "button",
                                  disabled: isDeletingModule.value || (!deleteModuleFormValues.value.deleteLessons && !deleteModuleFormValues.value.moduleId),
                                  onClick: _cache[28] || (_cache[28] = ($event: any) => (deleteModule(selectedModuleIdToDelete.value)))
                                }, {
                                  default: _withCtx(() => [
                                    (isDeletingModule.value)
                                      ? (_openBlock(), _createBlock(_unref(Loader2), {
                                          key: 0,
                                          class: "w-4 h-4 mr-2 animate-spin"
                                        }))
                                      : _createCommentVNode("", true),
                                    _cache[94] || (_cache[94] = _createTextVNode(" Excluir Módulo "))
                                  ]),
                                  _: 1
                                }, 8, ["disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["open"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (!isLoadingCourse.value && !isLoadingModule.value)
              ? (_openBlock(), _createBlock(_unref(TabsContent), {
                  key: 0,
                  value: "modules"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Form), {
                      as: "",
                      "keep-values": "",
                      "validation-schema": _unref(toTypedSchema)(moduleToEdit.value ? _unref(editModuleFormSchema) : _unref(moduleFormSchema))
                    }, {
                      default: _withCtx(({ meta, values, validate }) => [
                        _createElementVNode("form", {
                          onSubmit: _withModifiers(() => {
                validate();
                if (meta.valid) {
                  moduleToEdit.value ? updateModule(moduleToEdit.value!.id, moduleFormValues.value) : createModule(moduleFormValues.value)
                }
              }, ["prevent"]),
                          class: "col-span-1 flex flex-wrap flex-col sm:grid sm:gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-4 lg:gap-8"
                        }, [
                          _createElementVNode("div", _hoisted_49, [
                            _createVNode(_unref(Card), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(CardHeader), null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_50, [
                                      _createVNode(_unref(CardTitle), { class: "text-[1.1rem]" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(moduleToEdit.value ? 'Editar módulo' : 'Adicionar módulo'), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_unref(Button), {
                                        variant: "secondary",
                                        onClick: _cache[30] || (_cache[30] = ($event: any) => {selectedTab.value = 'course';})
                                      }, {
                                        default: _withCtx(() => _cache[96] || (_cache[96] = [
                                          _createTextVNode("Voltar")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _createVNode(_unref(CardDescription), null, {
                                      default: _withCtx(() => _cache[97] || (_cache[97] = [
                                        _createTextVNode("Detalhes do Módulo")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(CardContent), null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_51, [
                                      _createElementVNode("div", _hoisted_52, [
                                        _createVNode(_unref(FormField), {
                                          name: "title",
                                          class: "col-span-1"
                                        }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormItem), null, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(FormLabel), null, {
                                                  default: _withCtx(() => _cache[98] || (_cache[98] = [
                                                    _createTextVNode("Título")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormControl), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(Input), _mergeProps({
                                                      type: "text",
                                                      class: "w-full",
                                                      placeholder: "ex: Fundamentos do Design Gráfico"
                                                    }, componentField, {
                                                      modelValue: moduleFormValues.value.title,
                                                      "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((moduleFormValues.value.title) = $event))
                                                    }), null, 16, ["modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_unref(FormMessage))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_53, [
                                        _createVNode(_unref(FormField), {
                                          name: "description",
                                          class: "col-span-1"
                                        }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormItem), null, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(FormLabel), null, {
                                                  default: _withCtx(() => _cache[99] || (_cache[99] = [
                                                    _createTextVNode("Descrição")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormControl), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(Textarea), _mergeProps({
                                                      placeholder: "ex: Neste módulo, você mergulhará nos princípios essenciais do design gráfico...",
                                                      class: "min-h-32"
                                                    }, componentField, {
                                                      modelValue: moduleFormValues.value.description,
                                                      "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((moduleFormValues.value.description) = $event))
                                                    }), null, 16, ["modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_unref(FormMessage))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_54, [
                                        _createVNode(_unref(FormField), { name: "status" }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormLabel), { for: "status" }, {
                                              default: _withCtx(() => _cache[100] || (_cache[100] = [
                                                _createTextVNode("Status")
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), _mergeProps(componentField, {
                                              modelValue: moduleFormValues.value.status,
                                              "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((moduleFormValues.value.status) = $event))
                                            }), {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), {
                                                  id: "status",
                                                  "aria-label": "Selecionar status"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ModuleStatusEnum), (status) => {
                                                      return (_openBlock(), _createBlock(_unref(SelectItem), { value: status }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_unref(moduleStatusLabels)[status]), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 256))
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1040, ["modelValue"]),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_55, [
                                        _createElementVNode("div", _hoisted_56, [
                                          _createVNode(_unref(FormField), { name: "upcoming" }, {
                                            default: _withCtx(({ componentField }) => [
                                              _createVNode(_unref(Switch), _mergeProps(componentField, {
                                                checked: moduleFormValues.value.upcoming,
                                                "onUpdate:checked": _cache[34] || (_cache[34] = (checked) => moduleFormValues.value.upcoming = checked)
                                              }), null, 16, ["checked"]),
                                              _createVNode(_unref(FormLabel), { for: "upcoming" }, {
                                                default: _withCtx(() => _cache[101] || (_cache[101] = [
                                                  _createTextVNode("Conteúdo em breve")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ])
                                      ]),
                                      _createVNode(_unref(Button), {
                                        type: "submit",
                                        disabled: isCreatingModule.value || isUpdatingModule.value
                                      }, {
                                        default: _withCtx(() => [
                                          (isCreatingModule.value || isUpdatingModule.value)
                                            ? (_openBlock(), _createBlock(_unref(Loader2), {
                                                key: 0,
                                                class: "w-4 h-4 mr-2 animate-spin"
                                              }))
                                            : _createCommentVNode("", true),
                                          _createTextVNode(" " + _toDisplayString(moduleToEdit.value ? 'Salvar' : 'Adicionar'), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["disabled"])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          (moduleToEdit.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                                _createVNode(_unref(Card), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(CardHeader), null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_58, [
                                          _createVNode(_unref(CardTitle), null, {
                                            default: _withCtx(() => _cache[102] || (_cache[102] = [
                                              _createTextVNode("Aulas")
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_unref(Button), {
                                            onClick: _cache[35] || (_cache[35] = ($event: any) => {selectedTab.value = 'lesson'; lessonToEdit.value = undefined; resetLessonForm()})
                                          }, {
                                            default: _withCtx(() => _cache[103] || (_cache[103] = [
                                              _createTextVNode("Adicionar")
                                            ])),
                                            _: 1
                                          })
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(CardContent), { class: "" }, {
                                      default: _withCtx(() => [
                                        (moduleToEdit.value.lessons && !moduleToEdit.value.lessons.length)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_59, _cache[104] || (_cache[104] = [
                                              _createElementVNode("p", { class: "text-zinc-400 text-sm" }, "Nenhuma aula registrada.", -1)
                                            ])))
                                          : _createCommentVNode("", true),
                                        _createVNode(_unref(draggable), {
                                          modelValue: moduleToEdit.value.lessons,
                                          "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((moduleToEdit.value.lessons) = $event)),
                                          group: "lessons",
                                          onStart: _cache[37] || (_cache[37] = ($event: any) => (dragLesson.value=true)),
                                          onEnd: onEndDragLesson,
                                          "item-key": "id",
                                          handle: ".handle"
                                        }, {
                                          item: _withCtx(({element, index}) => [
                                            _createElementVNode("div", _hoisted_60, [
                                              _createElementVNode("div", _hoisted_61, [
                                                _createElementVNode("div", _hoisted_62, [
                                                  _createElementVNode("div", null, [
                                                    _createElementVNode("h3", null, _toDisplayString(element.title), 1),
                                                    _createElementVNode("p", _hoisted_63, _toDisplayString(element.description), 1)
                                                  ])
                                                ]),
                                                _createElementVNode("div", _hoisted_64, [
                                                  _createElementVNode("div", _hoisted_65, [
                                                    _createVNode(_unref(Badge), { class: "text-center justify-center" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_unref(lessonStatusLabels)[element.status as LessonStatusEnum]), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    (element.upcoming)
                                                      ? (_openBlock(), _createBlock(_unref(Badge), {
                                                          key: 0,
                                                          class: "bg-yellow-500 hover:bg-yellow-500"
                                                        }, {
                                                          default: _withCtx(() => _cache[105] || (_cache[105] = [
                                                            _createTextVNode("Conteúdo em breve")
                                                          ])),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true)
                                                  ]),
                                                  _createVNode(_unref(TooltipProvider), null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                                                            default: _withCtx(() => [
                                                              _createElementVNode("div", null, [
                                                                _createElementVNode("button", _hoisted_66, [
                                                                  _createVNode(_unref(ChevronsUpDown), { class: "w-4 h-4" })
                                                                ])
                                                              ])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_unref(TooltipContent), {
                                                            side: "top",
                                                            "side-offset": 5
                                                          }, {
                                                            default: _withCtx(() => _cache[106] || (_cache[106] = [
                                                              _createTextVNode(" Arraste para mudar a ordem ")
                                                            ])),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_unref(TooltipProvider), null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                                                            default: _withCtx(() => [
                                                              _createElementVNode("button", {
                                                                class: "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-emerald-500/10 hover:text-accent-foreground h-10 w-10 rounded-lg",
                                                                onClick: _withModifiers(($event: any) => {lessonToEdit.value = element; getLessonById(element.id); selectedTab.value = 'lesson'}, ["prevent"])
                                                              }, [
                                                                _createVNode(_unref(Pencil), { class: "w-4 h-4" })
                                                              ], 8, _hoisted_67)
                                                            ]),
                                                            _: 2
                                                          }, 1024),
                                                          _createVNode(_unref(TooltipContent), {
                                                            side: "top",
                                                            "side-offset": 5
                                                          }, {
                                                            default: _withCtx(() => _cache[107] || (_cache[107] = [
                                                              _createTextVNode(" Editar ")
                                                            ])),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_unref(TooltipProvider), null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                                                            default: _withCtx(() => [
                                                              _createElementVNode("button", {
                                                                class: "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-red-500/10 hover:text-accent-foreground h-10 w-10 rounded-lg",
                                                                onClick: _withModifiers(($event: any) => {isDeleteLessonDialogConfirmationOpen.value = true; selectedLessonIdToDelete.value = element.id}, ["prevent"])
                                                              }, [
                                                                _createVNode(_unref(Trash2), { class: "w-4 h-4" })
                                                              ], 8, _hoisted_68)
                                                            ]),
                                                            _: 2
                                                          }, 1024),
                                                          _createVNode(_unref(TooltipContent), {
                                                            side: "top",
                                                            "side-offset": 5
                                                          }, {
                                                            default: _withCtx(() => _cache[108] || (_cache[108] = [
                                                              _createTextVNode(" Excluir ")
                                                            ])),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ])
                                              ])
                                            ])
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ], 40, _hoisted_48)
                      ]),
                      _: 1
                    }, 8, ["validation-schema"]),
                    (selectedLessonIdToDelete.value)
                      ? (_openBlock(), _createBlock(_unref(Dialog), {
                          key: 0,
                          open: isDeleteLessonDialogConfirmationOpen.value,
                          "onUpdate:open": _cache[39] || (_cache[39] = (open) => isDeleteLessonDialogConfirmationOpen.value = open)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(DialogHeader), null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_69, [
                                      _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                                      _createVNode(_unref(DialogTitle), { class: "text-[1.1rem]" }, {
                                        default: _withCtx(() => _cache[109] || (_cache[109] = [
                                          _createTextVNode("Você está prestes a excluir esta aula.")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _createVNode(_unref(DialogDescription), { class: "py-2" }, {
                                      default: _withCtx(() => _cache[110] || (_cache[110] = [
                                        _createTextVNode(" Essa ação é permanente e não poderá ser desfeita. Todas as informações e progresso relacionados a esta aula serão perdidos. ")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _cache[113] || (_cache[113] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
                                _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(DialogClose), { "as-child": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(Button), {
                                          type: "button",
                                          variant: "secondary"
                                        }, {
                                          default: _withCtx(() => _cache[111] || (_cache[111] = [
                                            _createTextVNode(" Cancelar ")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_unref(Button), {
                                      type: "button",
                                      disabled: isDeletingLesson.value,
                                      onClick: _cache[38] || (_cache[38] = ($event: any) => (deleteLesson(selectedLessonIdToDelete.value)))
                                    }, {
                                      default: _withCtx(() => [
                                        (isDeletingLesson.value)
                                          ? (_openBlock(), _createBlock(_unref(Loader2), {
                                              key: 0,
                                              class: "w-4 h-4 mr-2 animate-spin"
                                            }))
                                          : _createCommentVNode("", true),
                                        _cache[112] || (_cache[112] = _createTextVNode(" Excluir Aula "))
                                      ]),
                                      _: 1
                                    }, 8, ["disabled"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["open"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!isLoadingCourse.value)
              ? (_openBlock(), _createBlock(_unref(TabsContent), {
                  key: 1,
                  value: "lesson"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Form), {
                      as: "",
                      "keep-values": "",
                      "validation-schema": _unref(toTypedSchema)(lessonToEdit.value ? _unref(editLessonFormSchema) : _unref(lessonFormSchema))
                    }, {
                      default: _withCtx(({ meta, values, validate }) => [
                        _createElementVNode("form", {
                          onSubmit: _withModifiers(() => {
                validate();
                if (meta.valid) {
                  lessonToEdit.value ? updateLesson(lessonToEdit.value!.id) : createLesson(lessonFormValues.value)
                }
              }, ["prevent"]),
                          class: "sm:grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-4 lg:gap-8"
                        }, [
                          _createElementVNode("div", _hoisted_71, [
                            _createVNode(_unref(Card), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(CardHeader), null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_72, [
                                      _createVNode(_unref(CardTitle), null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(lessonToEdit.value ? 'Editar aula' : 'Adicionar aula'), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_unref(Button), {
                                        variant: "secondary",
                                        onClick: _cache[40] || (_cache[40] = ($event: any) => {selectedTab.value = 'modules';})
                                      }, {
                                        default: _withCtx(() => _cache[114] || (_cache[114] = [
                                          _createTextVNode("Voltar")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _createVNode(_unref(CardDescription), null, {
                                      default: _withCtx(() => _cache[115] || (_cache[115] = [
                                        _createTextVNode("Detalhes da Aula")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(CardContent), null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_73, [
                                      _createElementVNode("div", _hoisted_74, [
                                        _createVNode(_unref(Label), null, {
                                          default: _withCtx(() => _cache[116] || (_cache[116] = [
                                            _createTextVNode("Anexos")
                                          ])),
                                          _: 1
                                        }),
                                        _createElementVNode("input", {
                                          type: "file",
                                          name: "attachments",
                                          id: "attachments",
                                          class: "hidden",
                                          accept: ".txt, .rtf, .doc, .docx, .odt, .pdf",
                                          onChange: onAttachmentsInputChange
                                        }, null, 32),
                                        _createElementVNode("label", _hoisted_75, [
                                          _createElementVNode("div", _hoisted_76, [
                                            _cache[117] || (_cache[117] = _createElementVNode("p", { class: "text-[13px] text-zinc-400" }, "Clique aqui para adicionar um arquivo", -1)),
                                            _createVNode(_unref(FileUp), { class: "h-8 w-8" })
                                          ]),
                                          (lessonFormValues.value.newAttachments?.length || lessonFormValues.value.attachments?.length)
                                            ? (_openBlock(), _createElementBlock("div", {
                                                key: 0,
                                                onClick: _cache[41] || (_cache[41] = _withModifiers(() => {}, ["prevent"])),
                                                class: "bg-muted rounded-bl-xl rounded-br-xl w-full p-4 grid gap-4"
                                              }, [
                                                _cache[118] || (_cache[118] = _createElementVNode("p", { class: "font-bold" }, "ARQUIVOS", -1)),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lessonFormValues.value.newAttachments, (attachment, index) => {
                                                  return (_openBlock(), _createElementBlock("div", {
                                                    class: "flex flex-col gap-4",
                                                    key: index
                                                  }, [
                                                    _createElementVNode("div", _hoisted_77, [
                                                      _createVNode(_unref(Input), {
                                                        type: "text",
                                                        class: "w-full",
                                                        placeholder: "ex: arquivo",
                                                        modelValue: attachment.title,
                                                        "onUpdate:modelValue": ($event: any) => ((attachment.title) = $event)
                                                      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                                      _createElementVNode("button", {
                                                        onClick: _withModifiers(($event: any) => (lessonFormValues.value.newAttachments.splice(index, 1)), ["prevent","stop"])
                                                      }, [
                                                        _createVNode(_unref(Trash2), { class: "h-5 w-5" })
                                                      ], 8, _hoisted_78)
                                                    ])
                                                  ]))
                                                }), 128)),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lessonFormValues.value.attachments, (attachment, index) => {
                                                  return (_openBlock(), _createElementBlock("div", {
                                                    class: "flex flex-col gap-4",
                                                    key: index
                                                  }, [
                                                    _createElementVNode("div", _hoisted_79, [
                                                      _createVNode(_unref(Input), {
                                                        type: "text",
                                                        class: "w-full",
                                                        placeholder: "ex: arquivo",
                                                        "default-value": attachment.title,
                                                        onBlur: ($event: any) => (updateLessonAttachment(attachment.id, { title: $event.target.value }))
                                                      }, null, 8, ["default-value", "onBlur"]),
                                                      _createElementVNode("button", {
                                                        onClick: _withModifiers(($event: any) => {isDeleteLessonAttachmentDialogConfirmationOpen.value = true; lessonAttachmentToDeleteId.value = attachment.id}, ["prevent","stop"])
                                                      }, [
                                                        _createVNode(_unref(Trash2), { class: "h-5 w-5" })
                                                      ], 8, _hoisted_80)
                                                    ])
                                                  ]))
                                                }), 128))
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        (lessonAttachmentToDeleteId.value)
                                          ? (_openBlock(), _createBlock(_unref(Dialog), {
                                              key: 0,
                                              open: isDeleteLessonAttachmentDialogConfirmationOpen.value,
                                              "onUpdate:open": _cache[43] || (_cache[43] = (open) => isDeleteLessonAttachmentDialogConfirmationOpen.value = open)
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(DialogHeader), null, {
                                                      default: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_81, [
                                                          _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                                                          _createVNode(_unref(DialogTitle), { class: "text-[1.1rem]" }, {
                                                            default: _withCtx(() => _cache[119] || (_cache[119] = [
                                                              _createTextVNode("Excluir Anexo da Aula")
                                                            ])),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _createVNode(_unref(DialogDescription), { class: "py-2" }, {
                                                          default: _withCtx(() => _cache[120] || (_cache[120] = [
                                                            _createTextVNode(" Você está prestes a excluir este anexo. Essa ação é irreversível e todos os dados relacionados a este anexo serão permanentemente removidos. ")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _cache[123] || (_cache[123] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
                                                    _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_unref(DialogClose), { "as-child": "" }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_unref(Button), {
                                                              type: "button",
                                                              variant: "secondary"
                                                            }, {
                                                              default: _withCtx(() => _cache[121] || (_cache[121] = [
                                                                _createTextVNode(" Cancelar ")
                                                              ])),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        }),
                                                        _createVNode(_unref(Button), {
                                                          type: "button",
                                                          disabled: isDeletingLessonAttachment.value,
                                                          onClick: _cache[42] || (_cache[42] = ($event: any) => (deleteLessonAttachment(lessonAttachmentToDeleteId.value)))
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (isDeletingLessonAttachment.value)
                                                              ? (_openBlock(), _createBlock(_unref(Loader2), {
                                                                  key: 0,
                                                                  class: "w-4 h-4 mr-2 animate-spin"
                                                                }))
                                                              : _createCommentVNode("", true),
                                                            _cache[122] || (_cache[122] = _createTextVNode(" Excluir Anexo "))
                                                          ]),
                                                          _: 1
                                                        }, 8, ["disabled"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }, 8, ["open"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _createElementVNode("div", _hoisted_82, [
                                        _createVNode(_unref(FormField), {
                                          name: "title",
                                          class: "col-span-1"
                                        }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormItem), null, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(FormLabel), null, {
                                                  default: _withCtx(() => _cache[124] || (_cache[124] = [
                                                    _createTextVNode("Título")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormControl), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(Input), _mergeProps({
                                                      type: "text",
                                                      class: "w-full",
                                                      placeholder: "ex: Introdução à Teoria das Cores"
                                                    }, componentField, {
                                                      modelValue: lessonFormValues.value.title,
                                                      "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((lessonFormValues.value.title) = $event))
                                                    }), null, 16, ["modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_unref(FormMessage))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_83, [
                                        _createVNode(_unref(FormField), {
                                          name: "description",
                                          class: "col-span-1"
                                        }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormItem), null, {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(FormLabel), null, {
                                                  default: _withCtx(() => _cache[125] || (_cache[125] = [
                                                    _createTextVNode("Descrição")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(FormControl), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(Textarea), _mergeProps({
                                                      placeholder: "ex: Nesta aula, você irá explorar os fundamentos da teoria das cores e seu impacto no design...",
                                                      class: "min-h-32"
                                                    }, componentField, {
                                                      modelValue: lessonFormValues.value.description,
                                                      "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((lessonFormValues.value.description) = $event))
                                                    }), null, 16, ["modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_unref(FormMessage))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_84, [
                                        _createVNode(_unref(FormField), { name: "type" }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(Label), null, {
                                              default: _withCtx(() => _cache[126] || (_cache[126] = [
                                                _createTextVNode("Tipo")
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), _mergeProps(componentField, {
                                              modelValue: lessonFormValues.value.type,
                                              "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((lessonFormValues.value.type) = $event))
                                            }), {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), { class: "w-full" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(LessonTypeEnum), (type) => {
                                                      return (_openBlock(), _createBlock(_unref(SelectItem), { value: type }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_unref(lessonTypeLabels)[type]), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 256))
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1040, ["modelValue"]),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_85, [
                                        _createVNode(_unref(FormField), { name: "content.origin" }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormLabel), { for: "contentOrigin" }, {
                                              default: _withCtx(() => _cache[127] || (_cache[127] = [
                                                _createTextVNode("Origem do vídeo")
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), _mergeProps(componentField, {
                                              modelValue: lessonFormValues.value.content.origin,
                                              "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => ((lessonFormValues.value.content.origin) = $event))
                                            }), {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), {
                                                  id: "contentOrigin",
                                                  "aria-label": "Selecionar"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(LessonContentOriginEnum), (type) => {
                                                      return (_openBlock(), _createBlock(_unref(SelectItem), { value: type }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_unref(lessonContentOriginLabels)[type]), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 256))
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1040, ["modelValue"]),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      (lessonFormValues.value.content?.origin !== _unref(LessonContentOriginEnum).HOOPAY && lessonFormValues.value.type === _unref(LessonTypeEnum).VIDEO)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
                                            _createVNode(_unref(FormField), {
                                              name: "content.url",
                                              class: "col-span-1"
                                            }, {
                                              default: _withCtx(({ componentField }) => [
                                                _createVNode(_unref(FormItem), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(FormLabel), null, {
                                                      default: _withCtx(() => _cache[128] || (_cache[128] = [
                                                        _createTextVNode("URL do vídeo")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _createVNode(_unref(FormControl), null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_unref(Input), _mergeProps({
                                                          type: "text",
                                                          class: "w-full",
                                                          placeholder: "ex: https://video..."
                                                        }, componentField, {
                                                          modelValue: lessonFormValues.value.content.url,
                                                          "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => ((lessonFormValues.value.content.url) = $event))
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_unref(FormMessage))
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 1
                                            })
                                          ]))
                                        : _createCommentVNode("", true),
                                      (lessonFormValues.value.content?.origin === _unref(LessonContentOriginEnum).HOOPAY && lessonFormValues.value.type === _unref(LessonTypeEnum).VIDEO)
                                        ? (_openBlock(), _createBlock(VideoUpload, {
                                            key: 1,
                                            "is-uploading": isUploading.value,
                                            "progress-percentage": progressPercentage.value,
                                            "estimated-time": estimatedTime.value,
                                            "upload-speed": uploadSpeed.value,
                                            "current-video-src": lessonFormValues.value.content.url,
                                            "current-video-origin": lessonFormValues.value.content.origin,
                                            modelValue: lessonFormValues.value.content.video,
                                            "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((lessonFormValues.value.content.video) = $event))
                                          }, null, 8, ["is-uploading", "progress-percentage", "estimated-time", "upload-speed", "current-video-src", "current-video-origin", "modelValue"]))
                                        : _createCommentVNode("", true),
                                      (lessonFormValues.value.content?.origin !== _unref(LessonContentOriginEnum).HOOPAY)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_87, [
                                            _createVNode(_unref(FormField), {
                                              name: "content.duration",
                                              class: "col-span-1"
                                            }, {
                                              default: _withCtx(({ componentField }) => [
                                                _createVNode(_unref(FormItem), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(FormLabel), null, {
                                                      default: _withCtx(() => _cache[129] || (_cache[129] = [
                                                        _createTextVNode("Duração em segundos")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _createVNode(_unref(FormControl), null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_unref(Input), _mergeProps({
                                                          type: "number",
                                                          class: "w-full",
                                                          placeholder: "ex: 20"
                                                        }, componentField, {
                                                          modelValue: lessonFormValues.value.content.duration,
                                                          "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => ((lessonFormValues.value.content.duration) = $event))
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_unref(FormMessage))
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 1
                                            })
                                          ]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("div", _hoisted_88, [
                                        _createVNode(_unref(FormField), {
                                          name: "showreleaseDelayAfterPurchase",
                                          modelValue: showLessonReleaseDelayAfterPurchaseInput.value,
                                          "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((showLessonReleaseDelayAfterPurchaseInput).value = $event))
                                        }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormLabel), {
                                              class: "flex items-center gap-2",
                                              for: "releaseDelayAfterPurchase"
                                            }, {
                                              default: _withCtx(() => [
                                                _cache[131] || (_cache[131] = _createTextVNode(" Liberação ")),
                                                _createVNode(_unref(TooltipProvider), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(Tooltip), { "delay-duration": 100 }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_unref(TooltipTrigger), { "as-child": "" }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_unref(CircleHelp), { class: "size-5" })
                                                          ]),
                                                          _: 1
                                                        }),
                                                        _createVNode(_unref(TooltipContent), {
                                                          side: "right",
                                                          "side-offset": 5
                                                        }, {
                                                          default: _withCtx(() => _cache[130] || (_cache[130] = [
                                                            _createTextVNode(" Configure o desbloqueio da aula para X dias após a confirmação da compra. ")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), _normalizeProps(_guardReactiveProps(componentField)), {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), {
                                                  id: "releaseDelayAfterPurchase",
                                                  "aria-label": "Selecionar"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectItem), { value: 'false' }, {
                                                      default: _withCtx(() => _cache[132] || (_cache[132] = [
                                                        _createTextVNode("Imediato")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _createVNode(_unref(SelectItem), { value: 'true' }, {
                                                      default: _withCtx(() => _cache[133] || (_cache[133] = [
                                                        _createTextVNode("Após X dias da compra")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1040),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"])
                                      ]),
                                      (showLessonReleaseDelayAfterPurchaseInput.value === 'true')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                                            _createVNode(_unref(FormField), {
                                              name: "releaseDelayAfterPurchase",
                                              class: "col-span-1"
                                            }, {
                                              default: _withCtx(({ componentField }) => [
                                                _createVNode(_unref(FormItem), null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(FormLabel), null, {
                                                      default: _withCtx(() => _cache[134] || (_cache[134] = [
                                                        _createTextVNode(" Liberar após ")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _createVNode(_unref(FormControl), null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_unref(Input), _mergeProps({
                                                          type: "number",
                                                          class: "w-full",
                                                          placeholder: "ex: 10 dias"
                                                        }, componentField, {
                                                          modelValue: lessonFormValues.value.releaseDelayAfterPurchase,
                                                          "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => ((lessonFormValues.value.releaseDelayAfterPurchase) = $event)),
                                                          min: "1"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_unref(FormMessage))
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 1
                                            })
                                          ]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("div", _hoisted_90, [
                                        _createVNode(_unref(FormField), { name: "status" }, {
                                          default: _withCtx(({ componentField }) => [
                                            _createVNode(_unref(FormLabel), { for: "status" }, {
                                              default: _withCtx(() => _cache[135] || (_cache[135] = [
                                                _createTextVNode("Status")
                                              ])),
                                              _: 1
                                            }),
                                            _createVNode(_unref(Select), _mergeProps(componentField, {
                                              modelValue: lessonFormValues.value.status,
                                              "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((lessonFormValues.value.status) = $event))
                                            }), {
                                              default: _withCtx(() => [
                                                _createVNode(_unref(SelectTrigger), {
                                                  id: "status",
                                                  "aria-label": "Selecionar status"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_unref(SelectContent), null, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(LessonStatusEnum), (status) => {
                                                      return (_openBlock(), _createBlock(_unref(SelectItem), { value: status }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_unref(lessonStatusLabels)[status]), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value"]))
                                                    }), 256))
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 2
                                            }, 1040, ["modelValue"]),
                                            _createVNode(_unref(FormMessage))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_91, [
                                        _createElementVNode("div", _hoisted_92, [
                                          _createVNode(_unref(FormField), { name: "upcoming" }, {
                                            default: _withCtx(({ componentField }) => [
                                              _createVNode(_unref(Switch), _mergeProps(componentField, {
                                                checked: lessonFormValues.value.upcoming,
                                                "onUpdate:checked": _cache[54] || (_cache[54] = (checked) => lessonFormValues.value.upcoming = checked)
                                              }), null, 16, ["checked"]),
                                              _createVNode(_unref(FormLabel), { for: "upcoming" }, {
                                                default: _withCtx(() => _cache[136] || (_cache[136] = [
                                                  _createTextVNode("Conteúdo em breve")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ])
                                      ]),
                                      _createVNode(_unref(Button), {
                                        type: "submit",
                                        disabled: isCreatingLesson.value || isUpdatingLesson.value || (lessonFormValues.value.type === _unref(LessonTypeEnum).VIDEO && (!lessonFormValues.value.content.origin || (!lessonFormValues.value.content.url && !lessonFormValues.value.content.video) || (!lessonFormValues.value.content.duration && lessonFormValues.value.content.origin !== _unref(LessonContentOriginEnum).HOOPAY)))
                                      }, {
                                        default: _withCtx(() => [
                                          (isCreatingLesson.value || isUpdatingLesson.value)
                                            ? (_openBlock(), _createBlock(_unref(Loader2), {
                                                key: 0,
                                                class: "w-4 h-4 mr-2 animate-spin"
                                              }))
                                            : _createCommentVNode("", true),
                                          _createTextVNode(" " + _toDisplayString(lessonToEdit.value ? 'Salvar' : 'Adicionar'), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["disabled"])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          (moduleToEdit.value && course.value?.commentsType !== _unref(CourseCommentsTypeEnum).INACTIVE)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_93, [
                                (!!lessonToEdit.value)
                                  ? (_openBlock(), _createBlock(CommentsCard, {
                                      key: 0,
                                      lesson: lessonToEdit.value,
                                      onOnAdd: _cache[55] || (_cache[55] = (tab) => selectedTab.value = tab)
                                    }, null, 8, ["lesson"]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ], 40, _hoisted_70)
                      ]),
                      _: 1
                    }, 8, ["validation-schema"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (isLoadingModule.value || isLoadingModule.value)
              ? (_openBlock(), _createBlock(SpinnerLoader, { key: 2 }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["model-value"])
    ]),
    _createVNode(Toaster)
  ]))
}
}

})