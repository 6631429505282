import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { cn } from '@/lib/utils'
import { TagsInputInput, type TagsInputInputProps, useForwardProps } from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TagsInputInput',
  props: {
    placeholder: {},
    autoFocus: { type: Boolean },
    maxLength: {},
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TagsInputInput), _mergeProps(_unref(forwardedProps), {
    class: _unref(cn)('text-sm min-h-6 focus:outline-none flex-1 bg-transparent px-1', props.class)
  }), null, 16, ["class"]))
}
}

})