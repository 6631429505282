import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "border-2 border-dashed border-gray-700 rounded-lg p-8" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "progress-bar"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = {
  key: 1,
  class: "text-center"
}
const _hoisted_8 = { class: "mb-4" }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "relative" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { class: "flex items-center justify-center space-x-4" }
const _hoisted_14 = { class: "text-gray-300" }
const _hoisted_15 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_16 = {
  class: "w-full max-h-64 rounded-lg",
  controls: ""
}
const _hoisted_17 = ["src"]

import { CourseVideoTypeEnum } from '@/enums/course/CourseVideoTypeEnum'
import { Clapperboard } from 'lucide-vue-next'
import { ref, watch } from 'vue'

interface Props {
  modelValue: File | null | undefined
  maxFileSize?: number
  currentVideoSrc?: string | null
  currentVideoOrigin?: CourseVideoTypeEnum | null
  isUploading?: boolean
  progressPercentage?: number
  estimatedTime?: number
  uploadSpeed?: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoUpload',
  props: {
    modelValue: {},
    maxFileSize: {},
    currentVideoSrc: {},
    currentVideoOrigin: {},
    isUploading: { type: Boolean },
    progressPercentage: {},
    estimatedTime: {},
    uploadSpeed: {}
  },
  emits: ["update:modelValue", "error"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const fileInput = ref<HTMLInputElement | null>(null)
const selectedFile = ref<File | undefined | null>(props.modelValue)
const error = ref<string | null>(null)
const videoUrl = ref<string | null>(null)
const hideCurrentVideo = ref(!props.currentVideoSrc || props.currentVideoOrigin !== CourseVideoTypeEnum.HOOPAY)

watch(selectedFile, (newValue) => {
  emit('update:modelValue', newValue!)
  if (newValue) {
    videoUrl.value = URL.createObjectURL(newValue)
  } else {
    videoUrl.value = null
  }
})

const handleFileSelect = (event: Event) => {
  const target = event.target as HTMLInputElement
  if (target.files && target.files.length > 0) {
    handleFile(target.files[0])
  }
}

const handleFileDrop = (event: DragEvent) => {
  event.preventDefault()
  if (event.dataTransfer && event.dataTransfer.files.length > 0) {
    handleFile(event.dataTransfer.files[0])
  }
}

const handleFile = (file: File) => {
  if (!file.type.startsWith('video/')) {
    error.value = 'Por favor, selecione um arquivo de vídeo válido'
    emit('error', error.value)
    return
  }
  if (file.size > (props.maxFileSize ?? 4 * 1024 * 1024 * 1024)) {
    error.value = `O arquivo é muito grande. O tamanho máximo permitido é ${formatFileSize(props.maxFileSize ?? 4 * 1024 * 1024 * 1024)}`
    emit('error', error.value)
    return
  }
  selectedFile.value = file
  error.value = null
}

const resetUpload = () => {
  selectedFile.value = null
  error.value = null
  videoUrl.value = null
  if (fileInput.value) {
    fileInput.value.value = ''
  }
}

const resetVideoPreview = () => {
  hideCurrentVideo.value = true
}

const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 B'
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "w-full h-full",
      onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
      onDrop: _withModifiers(handleFileDrop, ["prevent"]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.fileInput.click()))
    }, [
      (_ctx.uploadSpeed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.isUploading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", {
                    class: "progress-fill",
                    style: _normalizeStyle({ width: _ctx.progressPercentage + '%' })
                  }, null, 4)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isUploading)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.progressPercentage) + "% carregado...", 1))
              : _createCommentVNode("", true),
            (_ctx.isUploading)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Velocidade: " + _toDisplayString(_ctx.uploadSpeed.toFixed(2)) + " MB/s", 1))
              : _createCommentVNode("", true),
            (_ctx.isUploading)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Tempo estimado: " + _toDisplayString(_ctx.estimatedTime) + " segundos", 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        ref_key: "fileInput",
        ref: fileInput,
        type: "file",
        class: "hidden",
        accept: "video/*",
        onChange: handleFileSelect
      }, null, 544),
      (!selectedFile.value && hideCurrentVideo.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(Clapperboard), { class: "w-12 h-12 mx-auto text-gray-500" })
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-gray-400" }, "Clique aqui para adicionar um arquivo", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-sm text-gray-500 mt-1" }, "ou arraste e solte", -1))
          ]))
        : _createCommentVNode("", true),
      (_ctx.currentVideoSrc && _ctx.currentVideoOrigin === _unref(CourseVideoTypeEnum).HOOPAY && !selectedFile.value && !hideCurrentVideo.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              (_ctx.currentVideoOrigin === _unref(CourseVideoTypeEnum).HOOPAY)
                ? (_openBlock(), _createElementBlock("iframe", {
                    key: 0,
                    class: "w-full min-h-h-[330px] md:min-h-[308px] border-0",
                    src: _ctx.currentVideoSrc,
                    title: "HooPay video player",
                    frameborder: "0",
                    referrerpolicy: "strict-origin-when-cross-origin",
                    allowfullscreen: ""
                  }, null, 8, _hoisted_11))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                type: "button",
                class: "absolute top-2 right-2 text-red-500 hover:text-red-400",
                onClick: _withModifiers(resetVideoPreview, ["stop"])
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("svg", {
                  class: "w-5 h-5",
                  xmlns: "http://www.w3.org/2000/svg",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  stroke: "currentColor"
                }, [
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M6 18L18 6M6 6l12 12"
                  })
                ], -1)
              ]))
            ])
          ]))
        : (selectedFile.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", _hoisted_14, _toDisplayString(selectedFile.value.name), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "text-red-500 hover:text-red-400",
                  onClick: _withModifiers(resetUpload, ["stop"])
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("svg", {
                    class: "w-5 h-5",
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor"
                  }, [
                    _createElementVNode("path", {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M6 18L18 6M6 6l12 12"
                    })
                  ], -1)
                ]))
              ]),
              (videoUrl.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("video", _hoisted_16, [
                      _createElementVNode("source", {
                        src: videoUrl.value,
                        type: "video/mp4"
                      }, null, 8, _hoisted_17),
                      _cache[6] || (_cache[6] = _createTextVNode(" Seu navegador não suporta a exibição deste vídeo. "))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
    ], 32)
  ]))
}
}

})