import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "mt-12" }
const _hoisted_2 = { class: "space-y-3" }
const _hoisted_3 = { class: "text-zinc-300" }

import { CircleIcon } from 'lucide-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseRequirements',
  props: {
    course: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-xl font-semibold text-white mb-6" }, "Requisitos", -1)),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.prerequisites, (req, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "flex gap-3"
        }, [
          _createVNode(_unref(CircleIcon), { class: "w-2 h-2 text-purple-500 flex-shrink-0 mt-2" }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(req), 1)
        ]))
      }), 128))
    ])
  ]))
}
}

})