import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "lg:flex items-start gap-8" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "text-3xl font-bold text-white mb-3" }
const _hoisted_4 = { class: "text-zinc-300 text-lg mb-6" }
const _hoisted_5 = { class: "flex flex-wrap items-center gap-4 text-sm mb-6" }
const _hoisted_6 = {
  key: 0,
  class: "flex items-center gap-2 text-zinc-400"
}
const _hoisted_7 = { class: "flex items-center gap-2 text-zinc-400" }
const _hoisted_8 = {
  key: 1,
  class: "flex items-center gap-2 text-zinc-400"
}
const _hoisted_9 = {
  key: 0,
  class: "flex items-center gap-3 mb-8"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "text-white font-medium" }
const _hoisted_12 = { class: "text-zinc-400 text-sm" }

import { BookOpenIcon, ClockIcon, StarIcon, UsersIcon } from 'lucide-vue-next';
import CoursePrincingCard from './CoursePrincingCard.vue';
import { CourseLevelEnum, CourseLevelLabels } from '@/enums/course/CourseLevelEnum';
import { Course } from '@/interfaces/Course';
import { Duration } from '@/interfaces/Duration';


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseHeader',
  props: {
    course: {}
  },
  setup(__props: any) {



function formatInterval(duration: Duration): string {
  let { seconds = 0, minutes = 0, hours = 0, days = 0 } = duration;

  // Converte os dias para horas
  hours += days * 24;

  // Se houver mais de 60s, converte para minutos
  minutes += Math.floor(seconds / 60);
  seconds %= 60;

  // Se houver mais de 60m, converte para horas
  hours += Math.floor(minutes / 60);
  minutes %= 60;

  // Gera a string legível com horas ou minutos
  if (hours > 0) {
      return `${hours}h`;
  } else if (minutes > 0) {
      return `${minutes}m`;
  } else {
      return `${seconds}s`;  // Exibe em segundos caso não haja minutos ou horas
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex items-center gap-3 mb-4" }, [
        _createElementVNode("span", { class: "bg-purple-500/20 text-purple-300 px-3 py-1 rounded-full text-sm font-medium" }, " Mais Popular ")
      ], -1)),
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.course.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.course.description), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", {
          class: _normalizeClass([{ 
            'bg-green-500/20 text-green-400': _ctx.course.level === _unref(CourseLevelEnum).BEGINNER, 
            'bg-yellow-500/20 text-yellow-400': _ctx.course.level === _unref(CourseLevelEnum).INTERMEDIATE, 
            'bg-purple-500/20 text-purple-400': _ctx.course.level === _unref(CourseLevelEnum).ADVANCED, 
            'bg-red-500/20 text-red-400': _ctx.course.level === _unref(CourseLevelEnum).EXPERT, 
          }, "px-3 py-1 rounded-full font-medium"])
        }, _toDisplayString(_unref(CourseLevelLabels)[_ctx.course.level]), 3),
        (_ctx.course.duration)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_unref(ClockIcon), { class: "w-4 h-4" }),
              _createTextVNode(" " + _toDisplayString(formatInterval(_ctx.course.duration)), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_unref(BookOpenIcon), { class: "w-4 h-4" }),
          _createTextVNode(" " + _toDisplayString(_ctx.course.totalLessons) + " aulas ", 1)
        ]),
        (_ctx.course.displayTotalStudents)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_unref(UsersIcon), { class: "w-4 h-4" }),
              _createTextVNode(" " + _toDisplayString(_ctx.course.totalStudents) + " alunos ", 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.course.creator)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("img", {
              src: _ctx.course.creator.avatar,
              alt: "Instructor",
              class: "w-12 h-12 rounded-full border-2 border-purple-500"
            }, null, 8, _hoisted_10),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.course.creator.name), 1),
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.course.creator.description), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(CoursePrincingCard, { course: _ctx.course }, null, 8, ["course"])
  ]))
}
}

})