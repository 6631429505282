export enum CourseVideoTypeEnum {
  HOOPAY = 'hoopay',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  PANDA_VIDEO = 'panda_video',
  EPLAY = 'eplay',
}

export const courseVideoTypeLabels = {
  [CourseVideoTypeEnum.HOOPAY]: 'HooPay',
  [CourseVideoTypeEnum.VIMEO]: 'Vimeo',
  [CourseVideoTypeEnum.PANDA_VIDEO]: 'Panda Video',
  [CourseVideoTypeEnum.YOUTUBE]: 'Youtube',
  [CourseVideoTypeEnum.EPLAY]: 'Eplay',
}