<template>
  <div class="border-2 border-dashed border-gray-700 rounded-lg p-8">
    <div
      class="w-full h-full"
      @dragover.prevent
      @drop.prevent="handleFileDrop"
      @click="$refs.fileInput.click()"
    >
      <div v-if="uploadSpeed">
        <!-- Barra de Progresso -->
        <div v-if="isUploading" class="progress-bar">
          <div class="progress-fill" :style="{ width: progressPercentage + '%' }"></div>
        </div>
    
        <p v-if="isUploading">{{ progressPercentage }}% carregado...</p>
        <p v-if="isUploading">Velocidade: {{ uploadSpeed.toFixed(2) }} MB/s</p>
        <p v-if="isUploading">Tempo estimado: {{ estimatedTime }} segundos</p>
      </div>

      <input
        ref="fileInput"
        type="file"
        class="hidden"
        accept="video/*"
        @change="handleFileSelect"
      />
      
      <!-- Estado inicial -->
      <div v-if="!selectedFile && hideCurrentVideo" class="text-center">
        <div class="mb-4">
          <Clapperboard class="w-12 h-12 mx-auto text-gray-500" />
        </div>
        <p class="text-gray-400">Clique aqui para adicionar um arquivo</p>
        <p class="text-sm text-gray-500 mt-1">ou arraste e solte</p>
      </div>
      
      <!-- Exibir vídeo atual -->
      <div v-if="currentVideoSrc && currentVideoOrigin === CourseVideoTypeEnum.HOOPAY && !selectedFile && !hideCurrentVideo">
        <div class="relative">
          <iframe v-if="currentVideoOrigin === CourseVideoTypeEnum.HOOPAY" class="w-full min-h-h-[330px] md:min-h-[308px] border-0" :src="currentVideoSrc" title="HooPay video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <!-- <iframe v-if="currentVideoOrigin === CourseVideoTypeEnum.YOUTUBE" :src="currentVideoSrc" class="w-full h-full absolute top-0" />
          <iframe v-if="currentVideoOrigin === CourseVideoTypeEnum.VIMEO" :src="currentVideoSrc" class="w-full h-full absolute top-0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen />
          <iframe v-if="currentVideoOrigin === CourseVideoTypeEnum.PANDA_VIDEO" :src="currentVideoSrc" class="w-full h-full absolute top-0" />
          <iframe v-if="currentVideoOrigin === CourseVideoTypeEnum.EPLAY" :src="currentVideoSrc" class="w-full h-full absolute top-0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen /> -->
          <button
            type="button"
            class="absolute top-2 right-2 text-red-500 hover:text-red-400"
            @click.stop="resetVideoPreview"
          >
            <svg 
              class="w-5 h-5" 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                stroke-width="2" 
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          </button>
        </div>
      </div>
      
      <!-- Estado de arquivo selecionado -->
      <div v-else-if="selectedFile">
        <div class="flex items-center justify-center space-x-4">
          <span class="text-gray-300">{{ selectedFile.name }}</span>
          <button 
            type="button"
            class="text-red-500 hover:text-red-400"
            @click.stop="resetUpload"
          >
            <svg 
              class="w-5 h-5" 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                stroke-width="2" 
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          </button>
        </div>

        <!-- Preview do vídeo -->
        <div class="mt-4" v-if="videoUrl">
          <video class="w-full max-h-64 rounded-lg" controls>
            <source :src="videoUrl" type="video/mp4" />
            Seu navegador não suporta a exibição deste vídeo.
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CourseVideoTypeEnum } from '@/enums/course/CourseVideoTypeEnum'
import { Clapperboard } from 'lucide-vue-next'
import { ref, watch, defineProps, defineEmits } from 'vue'

interface Props {
  modelValue: File | null | undefined
  maxFileSize?: number
  currentVideoSrc?: string | null
  currentVideoOrigin?: CourseVideoTypeEnum | null
  isUploading?: boolean
  progressPercentage?: number
  estimatedTime?: number
  uploadSpeed?: number
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: File | null): void
  (event: 'error', message: string): void
}>()

const fileInput = ref<HTMLInputElement | null>(null)
const selectedFile = ref<File | undefined | null>(props.modelValue)
const error = ref<string | null>(null)
const videoUrl = ref<string | null>(null)
const hideCurrentVideo = ref(!props.currentVideoSrc || props.currentVideoOrigin !== CourseVideoTypeEnum.HOOPAY)

watch(selectedFile, (newValue) => {
  emit('update:modelValue', newValue!)
  if (newValue) {
    videoUrl.value = URL.createObjectURL(newValue)
  } else {
    videoUrl.value = null
  }
})

const handleFileSelect = (event: Event) => {
  const target = event.target as HTMLInputElement
  if (target.files && target.files.length > 0) {
    handleFile(target.files[0])
  }
}

const handleFileDrop = (event: DragEvent) => {
  event.preventDefault()
  if (event.dataTransfer && event.dataTransfer.files.length > 0) {
    handleFile(event.dataTransfer.files[0])
  }
}

const handleFile = (file: File) => {
  if (!file.type.startsWith('video/')) {
    error.value = 'Por favor, selecione um arquivo de vídeo válido'
    emit('error', error.value)
    return
  }
  if (file.size > (props.maxFileSize ?? 4 * 1024 * 1024 * 1024)) {
    error.value = `O arquivo é muito grande. O tamanho máximo permitido é ${formatFileSize(props.maxFileSize ?? 4 * 1024 * 1024 * 1024)}`
    emit('error', error.value)
    return
  }
  selectedFile.value = file
  error.value = null
}

const resetUpload = () => {
  selectedFile.value = null
  error.value = null
  videoUrl.value = null
  if (fileInput.value) {
    fileInput.value.value = ''
  }
}

const resetVideoPreview = () => {
  hideCurrentVideo.value = true
}

const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 B'
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
}
</script>
