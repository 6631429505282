<template>
  <div class="fixed inset-0 bg-black/80 flex items-center justify-center z-50 overflow-y-auto transition-opacity duration top-0" :class="{ 'scale-1 opacity-1': open, 'scale-[0] opacity-0': !open }">
    <div class="bg-zinc-900 w-full max-w-5xl mx-auto my-8 rounded-xl shadow-2xl overflow-hidden border border-zinc-800 absolute top-0 min-h-full">
      <template v-if="!!course">
        <!-- <CoursePromotionalBanner /> -->
  
        <CourseBanner @close="$emit('update:open', false)" :banner="banner?.bucketLocation" :trailer="course.video" :trailer-origin="course.videoOriginType" />
  
        <div class="p-4 sm:p-8">
          <CourseHeader :course="course" />
          <CourseOverview v-if="!!course.learningOutcomes?.length" :course="course" />
          <CourseRequirements v-if="!!course.prerequisites?.length" :course="course" />
          <CourseModules v-if="!!course.modules.length" :course="course" />
          <!-- <CourseReviews :course="course" /> -->
        </div>
      </template>
      <CourseLoader v-else :isVisible="true" />
    </div>
  </div>
</template>

<script lang="ts" setup>
// import CourseReviews from './components/CourseReviews.vue';
import CourseModules from './components/CourseModules.vue';
import CourseRequirements from './components/CourseRequirements.vue';
import CourseOverview from './components/CourseOverview.vue';
import CourseHeader from './components/CourseHeader.vue';
import CourseBanner from './components/CourseBanner.vue';
// import CoursePromotionalBanner from './components/CoursePromotionalBanner.vue';
import CourseLoader from './components/CourseLoader.vue';
import { ref, watch, computed } from 'vue';
import { Course } from '@/interfaces/Course';
import { useAxios } from '@/composables/useAxios';
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum';
import { displayTrailer } from './states/CoursePreviewState';

const props = defineProps<{ open: boolean, course?: Course }>()
const course = ref<Course>()
const banner = computed(() => course.value?.media?.find(media => media.type === CourseMediaTypeEnum.BACKGROUND))
const axios = useAxios()
defineEmits(['update:open'])

// Example course data
// const course = {
//   title: 'ChatGPT, Chat GPT Plus, Dall-E e Criação de Videos com Sora',
//   description: 'Domine as ferramentas de IA mais avançadas e aprenda a criar conteúdo extraordinário com ChatGPT, Dall-E e Sora.',
//   banner: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-sqtD0plkqZ0K0gM3PdXgRUg0UK6u0b.png',
//   level: 'Intermediário',
//   duration: '15h 45min',
//   lessons: 38,
//   students: 15334,
//   rating: 4.8,
//   reviewCount: '3,652',
//   price: 79.90,
//   originalPrice: 159.90,
//   discount: 50,
//   instructor: {
//     name: 'Paulo Andrade',
//     title: 'Especialista em IA e Criação de Conteúdo',
//     avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu3AMxCyeSHozCTXPcZHK6RsKRjzO681AzTA&s'
//   },
//   learningPoints: [
//     'Dominar o ChatGPT para criar conteúdo profissional',
//     'Gerar imagens impressionantes com Dall-E',
//     'Criar vídeos inovadores usando Sora',
//     'Desenvolver prompts avançados e eficientes',
//     'Automatizar tarefas de criação de conteúdo',
//     'Aplicar IA em projetos reais e práticos'
//   ],
//   requirements: [
//     'Computador com acesso à internet',
//     'Conhecimento básico de informática',
//     'Interesse em inteligência artificial e criação de conteúdo'
//   ],
//   modules: [
//     {
//       title: 'Fundamentos do ChatGPT',
//       duration: '3h 30min',
//       lessons: 8
//     },
//     {
//       title: 'Prompts Avançados e Técnicas',
//       duration: '4h 15min',
//       lessons: 10
//     },
//     {
//       title: 'Dall-E: Arte e Design com IA',
//       duration: '3h 45min',
//       lessons: 12
//     },
//     {
//       title: 'Sora: O Futuro da Criação de Vídeos',
//       duration: '4h 15min',
//       lessons: 8
//     }
//   ]
// }

function getCourseById() {
  displayTrailer.value = false
  course.value = undefined

  axios.get(`/courses/${props.course?.id}`)
    .then((res) => {
      course.value = res.data.course
    })
}

watch(() => props.course, () => {
  getCourseById()
})
</script>

<style>
/* Custom scrollbar for dark theme */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #27272a;
}

::-webkit-scrollbar-thumb {
  background: #3f3f46;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #52525b;
}
</style>