<script setup lang="ts">
import { playlists } from '../data/playlists'
import { PlusCircleIcon } from 'lucide-vue-next'
import { cn } from '@/lib/utils'
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { Course } from '@/interfaces/Course';
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum';
import { computed } from 'vue';

interface AlbumArtworkProps {
  course: Course
  aspectRatio?: 'portrait' | 'square'
  width?: number
  height?: number
}
const props = withDefaults(defineProps<AlbumArtworkProps>(), {
  aspectRatio: 'portrait',
})

const courseImage = computed(() => props.course?.media?.find((m => m.type === CourseMediaTypeEnum.MAIN)))
</script>

<template>
    <div :class="cn('space-y-3', $attrs.class ?? '')">
    <div class="overflow-hidden rounded-md">
      <img
        :src="courseImage?.bucketLocation"
        :alt="course?.title"
        :width="width"
        :height="height"
        @error="($event.target as HTMLInputElement).src = '/assets/img/no-image.png'"

        :class="cn(
          'h-auto w-auto object-cover transition-all hover:scale-105',
          aspectRatio === 'portrait' ? 'aspect-[3/4]' : 'aspect-square',
        )"
      >
    </div>
    <!-- <ContextMenu>
      <ContextMenuTrigger>
        <div class="overflow-hidden rounded-md">
          <img
            :src="courseImage?.bucketLocation"
            :alt="course.title"
            :width="width"
            :height="height"
            @error="($event.target as HTMLInputElement).src = '/assets/img/no-image.png'"

            :class="cn(
              'h-auto w-auto object-cover transition-all hover:scale-105',
              aspectRatio === 'portrait' ? 'aspect-[3/4]' : 'aspect-square',
            )"
          >
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent class="w-40">
        <ContextMenuItem>Add to Library</ContextMenuItem>
        <ContextMenuSub>
          <ContextMenuSubTrigger>Add to Playlist</ContextMenuSubTrigger>
          <ContextMenuSubContent class="w-48">
            <ContextMenuItem>
              <PlusCircleIcon class="mr-2 h-4 w-4" />
              New Playlist
            </ContextMenuItem>
            <ContextMenuSeparator />
            <ContextMenuItem v-for="playlist in playlists" :key="playlist">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                class="mr-2 h-4 w-4"
                viewBox="0 0 24 24"
              >
                <path d="M21 15V6M18.5 18a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM12 12H3M16 6H3M12 18H3" />
              </svg>
              {{ playlist }}
            </ContextMenuItem>
          </ContextMenuSubContent>
        </ContextMenuSub>
        <ContextMenuSeparator />
        <ContextMenuItem>Play Next</ContextMenuItem>
        <ContextMenuItem>Play Later</ContextMenuItem>
        <ContextMenuItem>Create Station</ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem>Like</ContextMenuItem>
        <ContextMenuItem>Share</ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu> -->
    <div class="space-y-1 text-sm">
      <h3 class="font-medium leading-none">
        {{ course?.title }}
      </h3>
      <p class="text-xs text-muted-foreground">
        {{ course?.introduction }}
      </p>
    </div>
  </div>
</template>