import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "text-zinc-400 text-lg"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]

import { CourseVideoTypeEnum } from '@/enums/course/CourseVideoTypeEnum';
import { XIcon } from 'lucide-vue-next';
import { displayTrailer } from '../states/CoursePreviewState';
import { ref, watch, onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseBanner',
  props: {
    banner: {},
    trailer: {},
    trailerOrigin: {}
  },
  emits: ['close'],
  setup(__props: any) {

const iframeRef = ref<HTMLIFrameElement | null>(null);

const playVideo = () => {
  if (iframeRef.value) {
    const iframe = iframeRef.value;
    const iframeSrc = iframe.src;

    // Enviar o comando de play para o iframe, dependendo do seu origin
    if (iframeSrc.includes('youtube.com')) {
      iframe.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    } else if (iframeSrc.includes('vimeo.com')) {
      iframe.contentWindow?.postMessage({ method: 'play' }, '*');
    }
    // Adicionar casos específicos para outros players se necessário.
  }
};

// Usar um watcher para observar `displayTrailer` e dar play quando for `true`
watch(() => displayTrailer, (newValue) => {
  if (newValue) {
    playVideo();
  }
}, { immediate: true });




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative sm:min-h-[357px] max-h-[257px] w-full bg-zinc-800 flex items-center justify-center", { 'min-h-[120px]': !_ctx.banner }])
  }, [
    (!_unref(displayTrailer))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.banner)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.banner,
                alt: "Course banner",
                class: "w-full h-full object-cover"
              }, null, 8, _hoisted_1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, "Sem imagem disponível"))
        ], 64))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute inset-0 bg-gradient-to-t from-zinc-900 via-zinc-900/80 to-transparent" }, null, -1)),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
      class: "absolute top-4 right-4 p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors z-10"
    }, [
      _createVNode(_unref(XIcon), { class: "w-6 h-6 text-white" })
    ]),
    (_ctx.trailer && _unref(displayTrailer))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.trailerOrigin === _unref(CourseVideoTypeEnum).HOOPAY)
            ? (_openBlock(), _createElementBlock("iframe", {
                key: 0,
                ref_key: "iframeRef",
                ref: iframeRef,
                class: "w-full h-full border-0 absolute top-0",
                src: _ctx.trailer,
                title: "HooPay video player",
                frameborder: "0",
                referrerpolicy: "strict-origin-when-cross-origin",
                allowfullscreen: ""
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.trailerOrigin === _unref(CourseVideoTypeEnum).YOUTUBE)
            ? (_openBlock(), _createElementBlock("iframe", {
                key: 1,
                ref_key: "iframeRef",
                ref: iframeRef,
                src: _ctx.trailer,
                class: "w-full h-full absolute top-0"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.trailerOrigin === _unref(CourseVideoTypeEnum).VIMEO)
            ? (_openBlock(), _createElementBlock("iframe", {
                key: 2,
                ref_key: "iframeRef",
                ref: iframeRef,
                src: _ctx.trailer,
                class: "w-full h-full absolute top-0",
                frameborder: "0",
                allow: "autoplay; encrypted-media",
                allowfullscreen: ""
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.trailerOrigin === _unref(CourseVideoTypeEnum).PANDA_VIDEO)
            ? (_openBlock(), _createElementBlock("iframe", {
                key: 3,
                ref_key: "iframeRef",
                ref: iframeRef,
                src: _ctx.trailer,
                class: "w-full h-full absolute top-0"
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true),
          (_ctx.trailerOrigin === _unref(CourseVideoTypeEnum).EPLAY)
            ? (_openBlock(), _createElementBlock("iframe", {
                key: 4,
                ref_key: "iframeRef",
                ref: iframeRef,
                src: _ctx.trailer,
                class: "w-full h-full absolute top-0",
                frameborder: "0",
                allow: "autoplay; encrypted-media",
                allowfullscreen: ""
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}
}

})