<template>
  <!-- style="
      background: -webkit-radial-gradient(
        center,
        rgb(27 27 27),
        rgb(18, 18, 18)
      ) !important;
    " -->
  <div
    class="flex items-start justify-between p-4 border-b"
  >
    <div class="flex items-start gap-2">
      <RouterLink :to="{ name: 'profile', params: { profileId: comment.author.id } }">
        <Avatar>
          <AvatarImage v-if="avatar" :src="avatar.bucketLocation" :alt="comment.author.name" />
          <AvatarFallback>{{ comment.author.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }}</AvatarFallback>
        </Avatar>
      </RouterLink>
      <div>
        <RouterLink :to="{ name: 'profile', params: { profileId: comment.author.id } }">
          <h6 class="text-sm text-white m-0 font-medium">
            {{ comment.author.name }}
          </h6>
        </RouterLink>
        <p class="text-xs pt-3 text-zinc-300">{{ comment.content }}</p>
        <!-- <div class="flex items-center gap-4 mt-4">
          <Button variant="ghost" size="sm" class="px-2 flex items-center text-sm gap-2">
            <ThumbsUp class="h-4 w-4 text-white" />
            <span class="text-sm text-white">3</span>
          </Button>
          <Button variant="ghost" size="sm" class="px-2 flex items-center text-sm gap-2">
            <Reply class="h-4 w-4" />
            Responder
          </Button>
        </div> -->
      </div>
    </div>
    <div class="grid" v-if="comment.userId == userAuthenticated.userId">
      <span class="text-zinc-400 text-xs">{{
        new Date(comment.createdAt).toLocaleDateString()
      }}</span>
      <div class="flex gap-1 pt-1">
        <button
          class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg"
          @click="$emit('edit')"
        >
          <Pencil class="w-4 h-4" />
        </button>
        <button
          class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg"
          @click="$emit('delete')"
        >
          <Trash2 class="w-4 h-4" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import Button from '@/components/ui/button/Button.vue';
import { useAuth } from '@/composables/useAuth';
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum';
import { LessonComment } from '@/interfaces/lesson/LessonComment';
import { Pencil, Reply, ThumbsUp, Trash2 } from 'lucide-vue-next';
import { computed } from 'vue';

const props = defineProps<{ comment: LessonComment }>()
defineEmits(['edit', 'delete'])
const { userAuthenticated } = useAuth()
const avatar = computed(() =>
  props.comment.author.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
</script>