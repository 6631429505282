import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "min-h-screen flex items-center justify-center p-4" }
const _hoisted_2 = { class: "w-full max-w-md space-y-8" }
const _hoisted_3 = { class: "flex flex-col items-center justify-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "space-y-4" }
const _hoisted_6 = { class: "space-y-2" }
const _hoisted_7 = { class: "space-y-2" }
const _hoisted_8 = { class: "space-y-2" }
const _hoisted_9 = {
  key: 0,
  class: "space-y-2"
}
const _hoisted_10 = { class: "space-y-2" }
const _hoisted_11 = { class: "space-y-2" }
const _hoisted_12 = { class: "flex items-center space-x-2" }

import { computed, onMounted, ref } from 'vue'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Checkbox } from '@/components/ui/checkbox'
import { Button } from '@/components/ui/button'
import { RegisterPayload, useAuth } from '@/composables/useAuth'
import { useToast } from '@/components/ui/toast'
import { vMaska } from "maska/vue"
import router from '@/router'
import { useValidation } from '@/composables/useValidation'
import { Loader2 } from 'lucide-vue-next'
import { useTenant } from '@/composables/useTenant'
import Toaster from '@/components/ui/toast/Toaster.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterView',
  setup(__props) {

const { toast } = useToast()
const { register, isAuthLoading } = useAuth()
const { isCPFValid, isCNPJValid } = useValidation()
const { tenantFavicon, initializeTenant } = useTenant()
const form = ref<Partial<RegisterPayload>>({ document: '' })
const acceptTerms = ref(false)
const isDisabled = computed(() => 
  !form.value.name || 
  !form.value.email ||
  (!form.value.organizationName && (form.value.document?.length ?? 0) > 14) ||
  !form.value.password ||
  form.value.password.length < 8 ||
  !form.value.phoneNumber || 
  form.value.phoneNumber?.length < 16 ||
  !acceptTerms.value
)

function handleSubmit() {
  if ((form.value.document?.length ?? 0) <= 14) {
    if (!isCPFValid(form.value.document!)) {
      toast({
        title: 'Cadastro falhou',
        description: 'Documento CPF inválido',
        variant: 'destructive',
        duration: 2000
      })

      return null
    } 
  } else {
    if (!isCNPJValid(form.value.document!)) {
      toast({
        title: 'Cadastro falhou',
        description: 'Documento CNPJ inválido',
        variant: 'destructive',
        duration: 2000
      })

      return null
    } 
  }
  register(
    { 
      ...form.value,
      organizationName: form.value.organizationName ? form.value.organizationName : form.value.name
    } as RegisterPayload,
    () => {
      toast({
        title: 'Conta cadastrada com sucesso',
        class: 'bg-green-500 text-white',
        duration: 1200
      })
      router.push({ name: 'login' }) 
    },
    (errors) => toast({
      title: 'Cadastro falhou',
      description: errors[0].message,
      variant: 'destructive'
    })
  )
}

onMounted(() => {
  initializeTenant()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_unref(tenantFavicon))
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _unref(tenantFavicon).bucketLocation,
              height: "38",
              width: "38",
              class: ""
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "mt-4 text-2xl font-semibold text-gray-200" }, "Área de Membros", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("p", { class: "mt-2 text-sm text-gray-400" }, "Preencha os dados abaixo para se cadastrar", -1))
      ]),
      _createElementVNode("form", {
        onSubmit: _withModifiers(handleSubmit, ["prevent"]),
        class: "mt-8 space-y-6 bg-card p-8 rounded-lg backdrop-blur-sm"
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(Label), { for: "fullName" }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("Nome Completo")
              ])),
              _: 1
            }),
            _createVNode(_unref(Input), {
              id: "fullName",
              modelValue: form.value.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.name) = $event)),
              placeholder: "Seu nome completo"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(Label), { for: "email" }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("Email")
              ])),
              _: 1
            }),
            _createVNode(_unref(Input), {
              id: "email",
              modelValue: form.value.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.email) = $event)),
              type: "email",
              placeholder: "seu@email.com"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_unref(Label), { for: "cnpj" }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("CPF/CNPJ")
              ])),
              _: 1
            }),
            _withDirectives(_createVNode(_unref(Input), {
              id: "cnpj",
              modelValue: form.value.document,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.document) = $event)),
              placeholder: "00.000.000/0000-00"
            }, null, 8, ["modelValue"]), [
              [_unref(vMaska), (form.value.document?.length ?? 0) <= 14 ? '###.###.###-###' : '##.###.###/####-##']
            ])
          ]),
          ((form.value.document?.length ?? 0) > 14)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_unref(Label), { for: "companyName" }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("Razão Social")
                  ])),
                  _: 1
                }),
                _createVNode(_unref(Input), {
                  id: "companyName",
                  modelValue: form.value.organizationName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.organizationName) = $event)),
                  placeholder: "Nome da empresa"
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_unref(Label), { for: "password" }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("Senha")
              ])),
              _: 1
            }),
            _createVNode(_unref(Input), {
              id: "password",
              modelValue: form.value.password,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.password) = $event)),
              type: "password",
              placeholder: "Deve conter pelo menos 8 caracteres"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_unref(Label), { for: "whatsapp" }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("WhatsApp DDD + número")
              ])),
              _: 1
            }),
            _withDirectives(_createVNode(_unref(Input), {
              id: "whatsapp",
              modelValue: form.value.phoneNumber,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.value.phoneNumber) = $event)),
              placeholder: "+55 (00) 00000-0000"
            }, null, 8, ["modelValue"]), [
              [_unref(vMaska), '(##) # ####-####']
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_unref(Checkbox), {
              id: "terms",
              checked: acceptTerms.value,
              "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => ((acceptTerms).value = $event))
            }, null, 8, ["checked"]),
            _createVNode(_unref(Label), {
              for: "terms",
              class: "text-sm text-gray-300"
            }, {
              default: _withCtx(() => [
                _cache[17] || (_cache[17] = _createTextVNode(" Eu aceito os ")),
                _createVNode(_component_RouterLink, {
                  to: { name: 'terms-of-use' },
                  class: "text-primary hover:underline"
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode("Termos e Condições")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_unref(Button), {
          type: "submit",
          class: "w-full",
          disabled: isDisabled.value
        }, {
          default: _withCtx(() => [
            (_unref(isAuthLoading))
              ? (_openBlock(), _createBlock(_unref(Loader2), {
                  key: 0,
                  class: "w-4 h-4 mr-2 animate-spin"
                }))
              : _createCommentVNode("", true),
            _cache[18] || (_cache[18] = _createTextVNode(" Cadastrar "))
          ]),
          _: 1
        }, 8, ["disabled"]),
        _cache[20] || (_cache[20] = _createStaticVNode("<div class=\"relative\"><div class=\"absolute inset-0 flex items-center\"><div class=\"w-full border-t border-gray-700\"></div></div><div class=\"relative flex justify-center text-sm\"><span class=\"px-2 bg-zinc-700 text-gray-400 rounded-md\">ou</span></div></div>", 1)),
        _createVNode(_unref(Button), {
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push('login'))),
          variant: "outline",
          class: "w-full"
        }, {
          default: _withCtx(() => _cache[19] || (_cache[19] = [
            _createTextVNode(" Entrar ")
          ])),
          _: 1
        })
      ], 32)
    ]),
    _createVNode(Toaster)
  ]))
}
}

})