import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { ref } from 'vue'
import router from '@/router'
import Cookies from "js-cookie";

export const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
})

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken') ?? Cookies.get('token') ?? null

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

axiosClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401 && !['/login', '/password-recovery', '/reset-password', '/register', '/terms-of-use'].includes(window.location.pathname)) {
      router.push({ name: 'login' })
    }
    return Promise.reject(error)
  }
)

export interface ApiErrorResponse {
  errors: {
    [key: string]: string[]; // As chaves são os nomes dos campos, e os valores são arrays de mensagens de erro
  };
  message: string;
}

export const isPerformingRequest = ref<boolean>(false)

export function useAxios(baseUrl: string = process.env.VUE_APP_API_BASE_URL) {
  const get = async (url: string, config: AxiosRequestConfig = {}) => {
    isPerformingRequest.value = true

    try {
      return await axiosClient.get(url, {
        ...config,
        baseURL: baseUrl
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<ApiErrorResponse>
        for (const input in e.response?.data.errors) {
          // toast.error(e.response?.data.errors[input][0]!)
        }
        console.error('Erro na requisição:', error)
      } else {
        console.error('Erro na requisição:', error)
      }
      throw error // Lança o erro novamente para que o componente que chama useAxios possa lidar com ele.
    } finally {
      isPerformingRequest.value = false
    }
  }

  const post = async (url: string, body: any = {}, config: AxiosRequestConfig = {}) => {
    isPerformingRequest.value = true
    try {
      return await axiosClient.post(url, body, {
        headers: {
          "Content-Type": body instanceof FormData ? 'multipart/form-data' : 'application/json',
        },
        baseURL: baseUrl,
        ...config,
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<ApiErrorResponse>
        for (const input in e.response?.data.errors) {
          // toast.error(e.response?.data.errors[input][0]!)
        }
        console.error('Erro na requisição:', error)
      } else {
        console.error('Erro na requisição:', error)
      }
      throw error // Lança o erro novamente para que o componente que chama useAxios possa lidar com ele.
    } finally {
      isPerformingRequest.value = false
    }
  }

  const patch = async (url: string, body?: FormData | Record<string, any>) => {
    isPerformingRequest.value = true
    try {
      return await axiosClient.patch(url, body, {
        headers: {
          "Content-Type": body instanceof FormData ? 'multipart/form-data' : 'application/json'
        },
        baseURL: baseUrl
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<ApiErrorResponse>
        console.error('Erro na requisição:', error)
      } else {
        console.error('Erro na requisição:', error)
      }
      throw error
    } finally {
      isPerformingRequest.value = false
    }
  }

  const put = async (url: string, body: FormData | Record<string, any>) => {
    isPerformingRequest.value = true
    try {
      return await axiosClient.put(url, body, {
        headers: {
          "Content-Type": body instanceof FormData ? 'multipart/form-data' : 'application/json'
        },
        baseURL: baseUrl
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<ApiErrorResponse>
        console.error('Erro na requisição:', error)
      } else {
        console.error('Erro na requisição:', error)
      }
      throw error
    } finally {
      isPerformingRequest.value = false
    }
  }

  return {
    get,
    post,
    patch,
    put,
    delete: async (url: string, params?: Record<string, any>) => {
      isPerformingRequest.value = true
      try {
        return await axiosClient.delete(url, { params: params, baseURL: baseUrl })
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const e = error as AxiosError<ApiErrorResponse>
          console.error('Erro na requisição:', error)
        } else {
          console.error('Erro na requisição:', error)
        }
        throw error
      } finally {
        isPerformingRequest.value = false
      }
    }
  }
}
