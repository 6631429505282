import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full lg:w-[821px] sm:p-4"
}
const _hoisted_2 = { class: "lg:w-[821px] mx-auto bg-card rounded-xl shadow-md overflow-hidden" }
const _hoisted_3 = { class: "p-6 border-b border-gray-200 min-w-full" }
const _hoisted_4 = { class: "flex items-center space-x-3 mb-4" }
const _hoisted_5 = { class: "text-lg mb-4 text-sm sm:text-md" }
const _hoisted_6 = { class: "flex justify-between items-center text-gray-300 text-sm" }
const _hoisted_7 = { class: "flex items-center space-x-2" }
const _hoisted_8 = { class: "flex -space-x-1" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "flex justify-start py-2 border-b border-gray-200" }
const _hoisted_11 = { class: "flex space-x-2" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "p-6" }
const _hoisted_14 = {
  key: 0,
  class: "space-y-6"
}
const _hoisted_15 = { class: "mt-6" }
const _hoisted_16 = { class: "flex items-start space-x-2" }
const _hoisted_17 = { class: "flex-grow" }
const _hoisted_18 = { class: "mt-2 flex justify-end" }

import { computed, onMounted, ref } from 'vue'
import { ThumbsUpIcon, Loader2 } from 'lucide-vue-next'
import { Textarea } from '@/components/ui/textarea'
import Button from '@/components/ui/button/Button.vue'
import { useAxios } from '@/composables/useAxios';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { Pagination } from '@/interfaces/Pagination';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useDate } from '@/composables/useDate';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import ProfilePostComponent from './components/ProfilePostComponent.vue';
import { ProfileTopic } from '@/interfaces/profiles/profileTopics/ProfileTopic';
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum';
import { ProfileTopicReactionContentEnum, profileTopicReactionIcons, profileTopicReactionLabels } from '@/enums/profiles/profileTopics/reactions/ProfileTopicReactionContentEnum';
import { ProfilePost } from '@/interfaces/profiles/profilePosts/ProfilePost';
import { useAuth } from '@/composables/useAuth';
import { Profile } from '@/interfaces/profiles/Profile';
import ProfileComponent from '../components/ProfileComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShowProfileTopicView',
  props: {
    profileTopicId: {},
    profileId: {}
  },
  setup(__props: any) {

const { timeAgo } = useDate()
const axios = useAxios()
const { userAuthenticated, getProfile } = useAuth()
const props = __props
const arePostsLoading = ref(false)
const distinctReactions = computed(() => {
  return Array.from(new Set(topic.value?.reactions.map(reaction => reaction.content))) as ProfileTopicReactionContentEnum[];
})
const topic = ref<ProfileTopic>()
const profileAvatar = computed(() =>
  topic.value?.profile.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const authProfileAvatar = computed(() =>
  userAuthenticatedProfile.value?.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const alreadyReacted = computed(() => topic.value?.reactions.find((r) => r.userId === userAuthenticated.userId))

const posts = ref<Pagination<ProfilePost>>()
const postPayload = ref({
  content: ''
})
const isCreatingPost = ref(false)
const userAuthenticatedProfile = ref<Profile>()

const toggleReaction = async (content: ProfileTopicReactionContentEnum) => {
  axios.post(`/profile/${topic.value?.profileId}/topics/${topic.value!.id}/reactions`, { content })

  if (!alreadyReacted.value) {
    topic.value!.reactions.push({
      profileTopicId: topic.value!.id,
      content,
      userId: userAuthenticated.userId
    })
  } else {
    topic.value!.reactions = topic.value!.reactions.filter((r) => r.userId !== userAuthenticated.userId)
  }
}

function getTopic() {
  axios.get(`/profile/${props.profileId}/topics/${props.profileTopicId}`)
    .then((res) => {
      topic.value = res.data
    })
}

function getPosts(page = 1, reset = false) {
  arePostsLoading.value = true

  axios.get(`/profile/${props.profileId}/topics/${props.profileTopicId}/posts`, { params: { page } })
    .then((res) => {
      posts.value = reset ? res.data : { ...res.data, data: posts.value ? [...posts.value.data, ...res.data.data] : res.data.data }
    })
    .finally(() => arePostsLoading.value = false)
}

function createPost(payload: { content: string }) {
  isCreatingPost.value = true

  axios.post(`/profile/${props.profileId}/topics/${props.profileTopicId}/posts`, payload)
    .then(() => {
      getPosts(1, true)
      postPayload.value = {
        content: ''
      }
    })
    .finally(() => {
      isCreatingPost.value = false
    })
}

onMounted(async () => {
  getTopic()
  getPosts()
  userAuthenticatedProfile.value = await getProfile()
})

return (_ctx: any,_cache: any) => {
  return (topic.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(ProfileComponent, {
                hideDropdown: true,
                topic: { authorId: topic.value.profile.userId, ...topic.value, author: { ...topic.value.profile, avatar: profileAvatar.value } }
              }, null, 8, ["topic"])
            ]),
            _createElementVNode("p", _hoisted_5, _toDisplayString(topic.value.content), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(distinctReactions.value, (reaction, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "rounded-full bg-gray-500 w-5 h-5 flex items-center justify-center text-xs text-white"
                    }, _toDisplayString(reaction), 1))
                  }), 128))
                ]),
                (topic.value.reactions.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(topic.value.reactions.length), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(topic.value.postsCount) + " comentários", 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_unref(TooltipProvider), null, {
              default: _withCtx(() => [
                _createVNode(_unref(Tooltip), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(TooltipTrigger), { asChild: "" }, {
                      default: _withCtx(() => [
                        _createVNode(Button, {
                          variant: "ghost",
                          class: _normalizeClass(["flex-1", { 
                'text-blue-500 hover:text-blue-500': alreadyReacted.value?.content === _unref(ProfileTopicReactionContentEnum).LIKE,
                'text-red-500 hover:text-red-500': alreadyReacted.value?.content === _unref(ProfileTopicReactionContentEnum).LOVE,
              }]),
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleReaction(_unref(ProfileTopicReactionContentEnum).LIKE)))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(alreadyReacted.value ? _unref(profileTopicReactionIcons)[alreadyReacted.value.content] : _unref(ThumbsUpIcon)), {
                              "stroke-width": 1.25,
                              class: _normalizeClass(["h-5 w-5 mr-2 transition-colors duration-200", { 
                    'text-blue-500 fill-blue-500': alreadyReacted.value?.content === _unref(ProfileTopicReactionContentEnum).LIKE,
                    'text-red-500 fill-red-500': alreadyReacted.value?.content === _unref(ProfileTopicReactionContentEnum).LOVE,
                  }])
                            }, null, 8, ["class"])),
                            _createTextVNode(" " + _toDisplayString(alreadyReacted.value ? _unref(profileTopicReactionLabels)[alreadyReacted.value?.content] : 'Curtir'), 1)
                          ]),
                          _: 1
                        }, 8, ["class"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(TooltipContent), { class: "bg-gray-800 border-gray-700" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ProfileTopicReactionContentEnum), (reaction) => {
                            return (_openBlock(), _createElementBlock("button", {
                              onClick: ($event: any) => (toggleReaction(reaction)),
                              key: reaction,
                              class: "cursor-pointer text-xl hover:scale-125 transition-transform duration-200"
                            }, _toDisplayString(reaction), 9, _hoisted_12))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "text-base font-semibold mb-4" }, "Comentários", -1)),
            (posts.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(posts.value.data, (post) => {
                    return (_openBlock(), _createBlock(ProfilePostComponent, {
                      key: post.id,
                      post: post
                    }, null, 8, ["post"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (posts.value?.pagination.nextPage)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: _normalizeClass(["pl-8 w-full text-center flex justify-center items-center", { 'text-zinc-400': arePostsLoading.value }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (getPosts(posts.value?.pagination.nextPage)))
                }, [
                  (arePostsLoading.value)
                    ? (_openBlock(), _createBlock(_unref(Loader2), {
                        key: 0,
                        class: "w-4 h-4 mr-2 animate-spin"
                      }))
                    : _createCommentVNode("", true),
                  _cache[4] || (_cache[4] = _createTextVNode(" Ver mais comentários "))
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("form", _hoisted_16, [
                _createVNode(_unref(Avatar), null, {
                  default: _withCtx(() => [
                    (authProfileAvatar.value)
                      ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                          key: 0,
                          src: authProfileAvatar.value.bucketLocation,
                          alt: userAuthenticatedProfile.value!.name
                        }, null, 8, ["src", "alt"]))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(AvatarFallback), null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(userAuthenticatedProfile.value?.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_unref(Textarea), {
                    modelValue: postPayload.value.content,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((postPayload.value.content) = $event)),
                    placeholder: "Escreva um comentário...",
                    rows: "2"
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(Button, {
                      type: "submit",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (createPost(postPayload.value))),
                      disabled: isCreatingPost.value || !postPayload.value.content
                    }, {
                      default: _withCtx(() => [
                        (isCreatingPost.value)
                          ? (_openBlock(), _createBlock(_unref(Loader2), {
                              key: 0,
                              class: "w-4 h-4 mr-2 animate-spin"
                            }))
                          : _createCommentVNode("", true),
                        _cache[5] || (_cache[5] = _createTextVNode(" Comentar "))
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
}
}

})