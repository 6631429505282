<template>
  <div class="min-h-screen text-white">
    <!-- Navbar -->
    <!-- <nav class="border-b border-gray-800 bg-black/50 backdrop-blur-xl fixed w-full z-50">
      <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16 items-center">
          <div class="flex items-center">
            <img v-if="tenantLogo" :src="tenantLogo.bucketLocation" width="120px" class="" />
          </div>
          <div class="flex items-center space-x-6">
            <button class="text-gray-300 hover:text-white transition-colors">
              <SearchIcon class="w-5 h-5" />
            </button>
            <button class="text-gray-300 hover:text-white transition-colors">
              <BellIcon class="w-5 h-5" />
            </button>
            <div class="flex items-center space-x-3">
              <span class="text-sm text-gray-300">João Silva</span>
              <div class="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 flex items-center justify-center">
                <UserIcon class="w-4 h-4 text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav> -->

    <!-- Hero Banner -->
    <HomeHeroBanner v-if="courses" />
    <HomeHeroBannerSkeleton v-else />

    <!-- Main Content -->
    <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 md:pb-16">
      <!-- Featured Courses -->
      <section class="mb-16">
        <div class="flex items-center justify-between mb-8">
          <h2 class="text-xl sm:text-2xl font-bold">Cursos em Destaque</h2>
          <RouterLink :to="{ name: 'courses.all' }" class="text-blue-400 hover:text-blue-300 text-xs sm:text-sm font-medium flex items-center">
            Ver todos os cursos
            <ArrowRightIcon class="w-4 h-4 ml-1" />
          </RouterLink>
        </div>
        <CourseGrid v-if="courses" :courses="courses.data" />
        <CourseGridSkeleton v-else />
      </section>

      <!-- Learning Paths -->
      <section id="categories" class="mb-16">
        <h2 class="text-xl sm:text-2xl font-bold mb-8">Trilhas de Aprendizado</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <RouterLink :to="{ name: 'courses.all', query: { category: index } }" v-for="(detail, index) in CourseCategoryDetails" :key="index"
               class="group bg-zinc-900/50 backdrop-blur p-6 rounded-xl border border-gray-800 hover:border-blue-500/50 transition-all duration-300">
            <div class="w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500 to-purple-600 flex items-center justify-center mb-4 group-hover:scale-110 transition-transform">
              <component :is="detail.icon" class="w-6 h-6 text-white" />
            </div>
            <h3 class="text-md sm:text-lg font-bold mb-2">{{ detail.label }}</h3>
            <p class="text-gray-400 text-sm mb-4">{{ detail.description }}</p>
            <div class="flex items-center justify-between">
              <div class="flex items-center space-x-2">
                <!-- <BookOpenIcon class="w-4 h-4 text-gray-500" />
                <span class="text-sm text-gray-500">{{ path.modules }} módulos</span> -->
              </div>
              <ArrowRightIcon class="w-5 h-5 text-gray-400 group-hover:text-blue-400 group-hover:translate-x-1 transition-all" />
            </div>
          </RouterLink>
        </div>
      </section>

      <!-- Resources Grid -->
      <!-- <section>
        <h2 class="text-2xl font-bold mb-8">Recursos Gratuitos</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div v-for="resource in resources" :key="resource.id"
               class="group bg-gray-900/30 backdrop-blur rounded-xl p-6 hover:bg-gray-900/50 transition-all duration-300">
            <div class="flex items-start space-x-4">
              <div class="w-10 h-10 rounded bg-gradient-to-br from-green-500 to-blue-500 flex items-center justify-center flex-shrink-0 group-hover:scale-110 transition-transform">
                <component :is="resource.icon" class="w-5 h-5 text-white" />
              </div>
              <div>
                <h3 class="text-lg font-medium mb-2">{{ resource.title }}</h3>
                <p class="text-gray-400 text-sm">{{ resource.description }}</p>
                <button class="mt-4 text-sm text-blue-400 hover:text-blue-300 font-medium inline-flex items-center group">
                  Acessar
                  <ArrowRightIcon class="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PlayCircleIcon, ArrowRightIcon,BookOpenIcon, CodeIcon, BrushIcon, CameraIcon, SparklesIcon, } from 'lucide-vue-next'
import CourseGrid from '../courses/components/CourseGrid.vue';
import { onMounted, ref } from 'vue';
import { useAxios } from '@/composables/useAxios';
import CourseGridSkeleton from '../courses/components/CourseGridSkeleton.vue';
import HomeHeroBanner from './components/HomeHeroBanner.vue';
import HomeHeroBannerSkeleton from './components/HomeHeroBannerSkeleton.vue';
import { Pagination } from '@/interfaces/Pagination';
import { Course } from '@/interfaces/Course';
import { CourseCategoryDetails } from '@/enums/course/CourseCategoryEnum';

const courses = ref<Pagination<Course>>()
const axios = useAxios()

// const featuredCourses = [
//   {
//     id: 1,
//     title: 'Masterclass de Photoshop',
//     description: 'Aprenda técnicas avançadas de manipulação de imagens e composição digital.',
//     image: 'https://images.unsplash.com/photo-1611926653458-09294b3142bf?auto=format&fit=crop&q=80',
//     level: 'Avançado',
//     levelClass: 'bg-purple-500/20 text-purple-400',
//     lessons: 42,
//     duration: '12h 30min',
//     students: '2.1k alunos'
//   },
//   {
//     id: 2,
//     title: 'Design UI/UX Completo',
//     description: 'Do wireframe ao protótipo final: aprenda a criar interfaces modernas e funcionais.',
//     image: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?auto=format&fit=crop&q=80',
//     level: 'Intermediário',
//     levelClass: 'bg-blue-500/20 text-blue-400',
//     lessons: 38,
//     duration: '15h 45min',
//     students: '1.8k alunos'
//   },
//   {
//     id: 3,
//     title: 'Figma para Iniciantes',
//     description: 'Comece sua jornada no design de interfaces com a ferramenta mais utilizada do mercado.',
//     image: 'https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?auto=format&fit=crop&q=80',
//     level: 'Iniciante',
//     levelClass: 'bg-green-500/20 text-green-400',
//     lessons: 24,
//     duration: '8h 15min',
//     students: '3.4k alunos'
//   },
//   {
//     id: 4,
//     title: 'Motion Graphics com After Effects',
//     description: 'Crie animações impressionantes e efeitos visuais para vídeos e apresentações.',
//     image: 'https://images.unsplash.com/photo-1550751827-4bd374c3f58b?auto=format&fit=crop&q=80',
//     level: 'Intermediário',
//     levelClass: 'bg-yellow-500/20 text-yellow-400',
//     lessons: 36,
//     duration: '14h 20min',
//     students: '1.5k alunos'
//   },
//   {
//     id: 5,
//     title: 'Fotografia para Redes Sociais',
//     description: 'Aprenda a tirar e editar fotos incríveis para impulsionar seu perfil nas redes sociais.',
//     image: 'https://images.unsplash.com/photo-1554048612-b6a482bc67e5?auto=format&fit=crop&q=80',
//     level: 'Iniciante',
//     levelClass: 'bg-green-500/20 text-green-400',
//     lessons: 28,
//     duration: '9h 45min',
//     students: '2.7k alunos'
//   },
//   {
//     id: 6,
//     title: 'Ilustração Digital Avançada',
//     description: 'Domine técnicas avançadas de ilustração digital e crie artes profissionais.',
//     image: 'https://images.unsplash.com/photo-1607799279861-4dd421887fb3?auto=format&fit=crop&q=80',
//     level: 'Avançado',
//     levelClass: 'bg-purple-500/20 text-purple-400',
//     lessons: 45,
//     duration: '18h 30min',
//     students: '1.2k alunos'
//   }
// ]

function getCourses(page = 1) {
  axios.get('/courses', { params: { page, featuredInStore: true } })
    .then((res) => {
      courses.value = res.data
    })
}


onMounted(() => {
  getCourses()
})
</script>

<style>
/* Adicione estilos globais aqui se necessário */
</style>