import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "lg:w-80 mt-6 lg:mt-0" }
const _hoisted_2 = { class: "bg-zinc-800/50 rounded-xl p-6 border border-zinc-700" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-start justify-between mb-4"
}
const _hoisted_4 = { class: "flex items-baseline gap-2 flex-wrap" }
const _hoisted_5 = { class: "text-3xl font-bold text-white" }
const _hoisted_6 = {
  key: 0,
  class: "text-zinc-400 line-through"
}
const _hoisted_7 = {
  key: 0,
  style: {"min-width":"fit-content"},
  class: "bg-green-500/20 text-green-300 px-2 py-1 rounded text-sm w-auto"
}
const _hoisted_8 = {
  key: 1,
  class: "flex items-center gap-3 pb-4"
}
const _hoisted_9 = { class: "text-white text-sm" }
const _hoisted_10 = {
  key: 2,
  class: "flex items-center gap-3 pb-4"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "space-y-3 text-sm" }
const _hoisted_13 = { class: "flex items-center gap-2 text-zinc-300" }
const _hoisted_14 = {
  key: 0,
  class: "flex items-center gap-2 text-zinc-300"
}
const _hoisted_15 = {
  key: 1,
  class: "flex items-center gap-2 text-zinc-300"
}

import { computed } from 'vue';
import { CheckCircleIcon, Clapperboard, Info, Pause } from 'lucide-vue-next';
import { Course } from '@/interfaces/Course';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useCourse } from '@/composables/useCourse';
import { displayTrailer } from '../states/CoursePreviewState';


export default /*@__PURE__*/_defineComponent({
  __name: 'CoursePrincingCard',
  props: {
    course: {}
  },
  setup(__props: any) {

const { redirectToCurrentLesson } = useCourse()

const formatDate = (date: Date) => {
  return format(date, "d MMMM, yyyy", { locale: ptBR })
}

const props = __props;
const checkoutBaseUrl = process.env.VUE_APP_CHECKOUT_BASE_URL

const isPromotional = computed(() => props.course.variant.promotionalPrice < props.course.variant.price);

const discountPercentage = computed(() => {
  if (!isPromotional.value) return 0;
  const discount = ((props.course.variant.price - props.course.variant.promotionalPrice) / props.course.variant.price) * 100;
  return Math.round(discount);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.course.user && !_ctx.course.upcoming)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.course.variant.promotionalPrice.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })), 1),
              (isPromotional.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.course.variant.price.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })), 1))
                : _createCommentVNode("", true)
            ]),
            (isPromotional.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(discountPercentage.value) + "% OFF ", 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.course.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_unref(Info), { class: "min-w-5 min-h-5 text-white" }),
            _createElementVNode("span", _hoisted_9, " Você se matriculou neste curso em " + _toDisplayString(formatDate(_ctx.course.user.createdAt)), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.course.upcoming && !_ctx.course.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_unref(Info), { class: "min-w-5 min-h-5 text-white" }),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-white text-sm" }, " Este curso será lançado em breve ", -1))
          ]))
        : _createCommentVNode("", true),
      (_ctx.course.user)
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(redirectToCurrentLesson)(_ctx.course))),
            class: "w-full bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg font-medium transition-colors mb-4 block text-center"
          }, " Ir para o curso "))
        : _createCommentVNode("", true),
      (!_ctx.course.user && !_ctx.course.upcoming)
        ? (_openBlock(), _createElementBlock("a", {
            key: 4,
            href: `${_unref(checkoutBaseUrl)}/?productId[]=${_ctx.course.variant.uuid}&qty[]=1`,
            target: "_blank",
            class: "w-full bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg font-medium transition-colors mb-4 block text-center"
          }, " Matricular Agora ", 8, _hoisted_11))
        : _createCommentVNode("", true),
      (_ctx.course.video)
        ? (_openBlock(), _createElementBlock("button", {
            key: 5,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (displayTrailer.value = !_unref(displayTrailer))),
            class: "w-full border border-zinc-700 hover:bg-zinc-800 text-white px-6 py-3 rounded-lg font-medium transition-colors mb-6 block text-center flex justify-center gap-2 items-center"
          }, [
            (!_unref(displayTrailer))
              ? (_openBlock(), _createBlock(_unref(Clapperboard), {
                  key: 0,
                  class: "w-5 h-5"
                }))
              : (_openBlock(), _createBlock(_unref(Pause), {
                  key: 1,
                  class: "w-5 h-5"
                })),
            _cache[3] || (_cache[3] = _createTextVNode(" Trailer "))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_unref(CheckCircleIcon), { class: "w-5 h-5 text-purple-500" }),
          _cache[4] || (_cache[4] = _createTextVNode(" Acesso vitalício "))
        ]),
        (_ctx.course.certification)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_unref(CheckCircleIcon), { class: "w-5 h-5 text-purple-500" }),
              _cache[5] || (_cache[5] = _createTextVNode(" Certificado de conclusão "))
            ]))
          : _createCommentVNode("", true),
        (_ctx.course.variant.product!.refundPeriod)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_unref(CheckCircleIcon), { class: "w-5 h-5 text-purple-500" }),
              _createTextVNode(" " + _toDisplayString(_ctx.course.variant.product!.refundPeriod) + " dias de garantia ", 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})