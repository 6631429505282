import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = {
  key: 0,
  class: "min-w-[300px]"
}
const _hoisted_3 = { class: "flex flex-col gap-4 border-b" }
const _hoisted_4 = { class: "flex items-center justify-between hover:bg-muted transition-background duration-200 p-4" }
const _hoisted_5 = { class: "flex items-center gap-4" }
const _hoisted_6 = { class: "text-zinc-400 text-xs" }
const _hoisted_7 = { class: "flex gap-2" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "grid gap-3 py-6" }
const _hoisted_12 = { class: "flex gap-2 items-center" }

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { onMounted, ref } from 'vue';
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label';
import { useAxios } from '@/composables/useAxios';
import { Lesson } from '@/interfaces/lesson/Lessons';
import { toast } from '@/components/ui/toast';
import { AlertTriangle, CircleCheck, Loader2, MoreHorizontal, Trash2, User2 } from 'lucide-vue-next';
import { LessonComment } from '@/interfaces/lesson/LessonComment';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Pagination } from '@/interfaces/Pagination';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue'
import { LessonCommentStatusEnum } from '@/enums/lesson/lessonComment/LessonCommentStatusEnum';



export default /*@__PURE__*/_defineComponent({
  __name: 'CommentsCard',
  props: {
    lesson: {}
  },
  emits: ['onAdd'],
  setup(__props: any) {

const axios = useAxios()
const props = __props

const isCommentDialogOpen = ref(false)
const isLoading = ref(false)
const comments = ref<Pagination<LessonComment>>()
const commentToEdit = ref<LessonComment>()
const commentToDeleteId = ref<number>()
const isDeleteCommentDialogOpen = ref(false)
const isDeletingComment = ref(false)

const payload = ref<{ content?: string }>({
  content: ''
})

function createComment() {
  isLoading.value = true

  axios.post(`/courses/${props.lesson.courseId}/modules/${props.lesson.moduleId}/lessons/${props.lesson.id}/comments`, payload.value)
    .then(() => {
      toast({
        title: 'Comentário cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
      payload.value = {}
      isCommentDialogOpen.value = false
      getComments()
    })
    .finally(() => isLoading.value = false)
}

function updateComment(commentId: number, payload: { status: LessonCommentStatusEnum }) {
  isLoading.value = true

  axios.patch(`/courses/${props.lesson.courseId}/modules/${props.lesson.moduleId}/lessons/${props.lesson.id}/comments/${commentId}`, payload)
    .then(() => {
      toast({
        title: 'Comentário atualizado com sucesso',
        class: 'bg-green-500 text-white'
      })

      const commentIndex = comments.value?.data.findIndex((comment) => comment.id === commentId)

      if (commentIndex) {
        comments.value!.data[commentIndex] = { ...comments.value!.data[commentIndex], ...payload }
      }

      isCommentDialogOpen.value = false
    })
    .finally(() => isLoading.value = false)
}

function deleteComment(commentId: number) {
  axios.delete(`/courses/${props.lesson.courseId}/modules/${props.lesson.moduleId}/lessons/${props.lesson.id}/comments/${commentId}`)
    .then(() => {
      toast({
        title: 'Comentário excluído com sucesso',
        class: 'bg-green-500 text-white'
      })
      isDeleteCommentDialogOpen.value = false
      comments.value!.data = comments.value!.data.filter((comment) => comment.id !== commentId)
    })
}

function getComments(page = 1) {
  axios.get(`/courses/${props.lesson.courseId}/modules/${props.lesson.moduleId}/lessons/${props.lesson.id}/comments`, { params: { page } })
    .then((res) => {
      comments.value = res.data
    })
}

onMounted(() => {
  getComments()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Card), { class: "flex flex-col max-h-[1345px] overflow-auto relative" }, {
      default: _withCtx(() => [
        _createVNode(_unref(CardHeader), null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(CardTitle), null, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Comentários")
                ])),
                _: 1
              }),
              _createVNode(_unref(Button), {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => {_ctx.$emit('onAdd', 'lesson'); isCommentDialogOpen.value = true; commentToEdit.value = undefined; payload.value = {}}, ["prevent"]))
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Adicionar")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(_unref(CardContent), { class: "flex-1" }, {
          default: _withCtx(() => [
            (comments.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(comments.value?.data, (comment) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", null, [
                            _createVNode(_unref(User2), { class: "h-7 w-7" })
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("h3", null, _toDisplayString(comment.author.name), 1),
                            _createElementVNode("p", _hoisted_6, _toDisplayString(comment.content), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          (comment.status === _unref(LessonCommentStatusEnum).INACTIVE)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground h-10 w-10 rounded-lg hover:bg-success",
                                onClick: ($event: any) => (updateComment(comment.id, { status: _unref(LessonCommentStatusEnum).ACTIVE }))
                              }, [
                                _createVNode(_unref(CircleCheck), { class: "w-5 h-5" })
                              ], 8, _hoisted_8))
                            : _createCommentVNode("", true),
                          _createElementVNode("button", {
                            class: "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground h-10 w-10 rounded-lg hover:bg-danger",
                            onClick: _withModifiers(($event: any) => {commentToDeleteId.value = comment.id; isDeleteCommentDialogOpen.value = true}, ["prevent"])
                          }, [
                            _createVNode(_unref(Trash2), { class: "w-5 h-5" })
                          ], 8, _hoisted_9)
                        ])
                      ])
                    ]))
                  }), 256)),
                  _createVNode(PaginationApi, {
                    data: comments.value.pagination,
                    paginate: getComments
                  }, null, 8, ["data"])
                ]))
              : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
            (comments.value && !comments.value.data.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[8] || (_cache[8] = [
                  _createElementVNode("p", { class: "text-zinc-400 text-sm" }, "Nenhum comentário registrado.", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(Dialog), {
      open: isCommentDialogOpen.value,
      "onUpdate:open": _cache[3] || (_cache[3] = (open) => isCommentDialogOpen.value = open)
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
          default: _withCtx(() => [
            _createVNode(_unref(DialogHeader), null, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogTitle), { class: "text-[1.1rem]" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(commentToEdit.value ? 'Editar' : 'Adicionar') + " comentário", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(DialogDescription), { class: "" }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" Compartilhe sua opinião sobre a aula ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_unref(Label), { for: "content" }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Comentário")
                ])),
                _: 1
              }),
              _createVNode(_unref(Textarea), {
                placeholder: "ex: Os detalhes que foram ensinados nesta aula são importantes...",
                class: "min-h-32",
                id: "content",
                modelValue: payload.value.content,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((payload.value.content) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogClose), { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Button), {
                      type: "button",
                      variant: "secondary"
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode(" Cancelar ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Button), {
                  type: "button",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (commentToEdit.value ? '' : createComment())),
                  disabled: isLoading.value || !payload.value.content
                }, {
                  default: _withCtx(() => [
                    (isLoading.value)
                      ? (_openBlock(), _createBlock(_unref(Loader2), {
                          key: 0,
                          class: "w-4 h-4 mr-2 animate-spin"
                        }))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(commentToEdit.value ? 'Salvar' : 'Adicionar'), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["open"]),
    (commentToDeleteId.value)
      ? (_openBlock(), _createBlock(_unref(Dialog), {
          key: 0,
          open: isDeleteCommentDialogOpen.value,
          "onUpdate:open": _cache[5] || (_cache[5] = (open) => isDeleteCommentDialogOpen.value = open)
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogHeader), null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                      _createVNode(_unref(DialogTitle), { class: "text-[1.1rem]" }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode("Excluir Comentário")
                        ])),
                        _: 1
                      })
                    ]),
                    _createVNode(_unref(DialogDescription), { class: "py-2" }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode(" Essa ação é permanente e não poderá ser desfeita. O comentário será removido imediatamente. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
                _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DialogClose), { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          type: "button",
                          variant: "secondary"
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode(" Cancelar ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(Button), {
                      type: "button",
                      disabled: isDeletingComment.value,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (deleteComment(commentToDeleteId.value)))
                    }, {
                      default: _withCtx(() => [
                        (isDeletingComment.value)
                          ? (_openBlock(), _createBlock(_unref(Loader2), {
                              key: 0,
                              class: "w-4 h-4 mr-2 animate-spin"
                            }))
                          : _createCommentVNode("", true),
                        _cache[15] || (_cache[15] = _createTextVNode(" Excluir Comentário "))
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})