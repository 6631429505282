<template>
  <div class="min-h-screen text-gray-100">
    <!-- Header -->
    <header class="border-b border-gray-800 py-4 sm:py-6">
      <div class="container mx-auto px-4 flex items-center gap-6">
        <RouterLink :to="{ name: 'home' }" class="bg-[#d5d5d5] rounded-full p-3 hidden sm:block">
          <MoveLeft class="text-black" />
        </RouterLink>
        <div>
          <h1 class="text-lg sm:text-3xl font-bold mb-2 sm:mb-4">Tudo o que você precisa para crescer profissionalmente</h1>
          <p class="text-sm sm:text-[1rem] text-gray-400">Aprimore suas capacidades com conteúdos que vão do básico ao avançado.</p>
        </div>
      </div>
    </header>

    <CourseCategoryNavigation v-model="selectedCategory" />

    <!-- Skills Section -->
    <!-- <section class="py-6 border-b border-gray-800">
      <div class="container mx-auto px-4">
        <div class="relative">
          <div class="flex gap-4 overflow-x-auto py-2 scrollbar-hide">
            <div 
              v-for="skill in skills" 
              :key="skill.name"
              class="flex-none bg-zinc-800/50 rounded-full px-6 py-3 backdrop-blur"
            >
              <h3 class="font-medium">{{ skill.name }}</h3>
              <p class="text-sm text-gray-400">{{ skill.students }}</p>
            </div>
          </div>
          <div class="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-gray-900 pointer-events-none"></div>
        </div>
      </div>
    </section> -->

    <!-- Filters -->
    <div class="container mx-auto px-4 py-6">
      <!-- <div class="grid grid-cols-12 flex-wrap gap-4 mb-6">
        <div class="col-span-4 xl:col-span-2 2xl:col-span-1">
          <Select v-model="filters.rating">
            <SelectTrigger id="productId" aria-label="Selecionar o produto">
              <SelectValue placeholder="Preço" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="4.5">4.5 ou mais</SelectItem>
              <SelectItem value="4.0">4.0 ou mais</SelectItem>
              <SelectItem value="3.5">3.5 ou mais</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div class="col-span-4 xl:col-span-2 2xl:col-span-1">
          <Select v-model="filters.price">
            <SelectTrigger id="productId" aria-label="Selecionar o produto">
              <SelectValue placeholder="Preço" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="free">Grátis</SelectItem>
              <SelectItem value="paid">Pagos</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div class="col-span-4 xl:col-span-2 2xl:col-span-1">
          <Select v-model="filters.duration">
            <SelectTrigger id="productId" aria-label="Selecionar o produto">
              <SelectValue placeholder="Duração" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="short">0-3 horas</SelectItem>
              <SelectItem value="medium">3-6 horas</SelectItem>
              <SelectItem value="long">6+ horas</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div> -->

      <!-- Course Grid -->
      <CourseGrid v-if="courses" :courses="courses.data" />
      <CourseGridSkeleton v-else />
      <NoCoursesFound @clear="selectedCategory = undefined" v-if="courses && !courses.data.length" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Select from '@/components/ui/select/Select.vue'
import SelectContent from '@/components/ui/select/SelectContent.vue'
import SelectItem from '@/components/ui/select/SelectItem.vue'
import SelectTrigger from '@/components/ui/select/SelectTrigger.vue'
import SelectValue from '@/components/ui/select/SelectValue.vue'
import { MoveLeft } from 'lucide-vue-next'
import { ref, computed, onMounted, watch } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import CourseGrid from '../components/CourseGrid.vue'
import { Pagination } from '@/interfaces/Pagination'
import { Course } from '@/interfaces/Course'
import { useAxios } from '@/composables/useAxios'
import CourseGridSkeleton from '../components/CourseGridSkeleton.vue'
import CourseCategoryNavigation from './components/CourseCategoryNavigation.vue'
import { CourseCategoryEnum } from '@/enums/course/CourseCategoryEnum'
import NoCoursesFound from './components/NoCoursesFound.vue'

const skills = [
  { name: 'Habilidades em comunicação', students: 'Mais de 2 mi de alunos' },
  { name: 'Técnicas de apresentação', students: 'Mais de 2 mi de alunos' },
  { name: 'Discurso em público', students: 'Mais de 3 mi de alunos' },
  { name: 'Redação', students: 'Mais de 1 mi de alunos' },
  { name: 'PowerPoint', students: 'Mais de 2 mi de alunos' },
  { name: 'Comunicação', students: 'Mais de 407.400 alunos' }
]

const route = useRoute()
const courses = ref<Pagination<Course>>()
const axios = useAxios()

const selectedCategory = ref<CourseCategoryEnum | undefined>(route.query.category as CourseCategoryEnum)
const filters = ref({
  rating: '',
  price: '',
  duration: ''
})

function getCourses(page = 1, category?: CourseCategoryEnum) {
  courses.value = undefined

  let params: any = { page, featuredInStore: true }

  if (category) {
    params.category = category
  }

  axios.get('/courses', { params: params })
    .then((res) => {
      courses.value = res.data
    })
}

watch(() => selectedCategory.value, () => {
  getCourses(1, selectedCategory.value)
})

onMounted(() => {
  getCourses(1, selectedCategory.value)
})
</script>

<style>
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
</style>