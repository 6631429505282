import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createVNode as _createVNode, renderList as _renderList, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid grid-cols-12 gap-6"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["id", "src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "bg-background p-6 rounded-lg" }
const _hoisted_9 = { class: "mb-4 flex justify-between items-center" }
const _hoisted_10 = {
  key: 0,
  class: "text-zinc-400 text-sm text-xs"
}
const _hoisted_11 = { class: "text-xl font-poppins font-medium" }
const _hoisted_12 = {
  key: 1,
  class: "flex items-center gap-2 text-sm text-zinc-400"
}
const _hoisted_13 = { class: "flex gap-[2px]" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "my-8 bg-background rounded-lg" }
const _hoisted_17 = { class: "text-zinc-400 text-sm" }
const _hoisted_18 = { class: "flex flex-col gap-4" }
const _hoisted_19 = { class: "my-4 bg-background rounded-lg" }
const _hoisted_20 = { class: "flex items-center gap-2 bg-muted rounded-lg bg-opacity-50" }
const _hoisted_21 = { class: "bg-gray-200 bg-opacity-10 h-full p-4 rounded-tl-lg rounded-bl-lg" }
const _hoisted_22 = { class: "flex items-center justify-between w-full p-4" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "bg-background p-6 rounded-lg mt-4 mb-4" }
const _hoisted_25 = { class: "grid gap-3 mb-4" }
const _hoisted_26 = { class: "flex items-center gap-2" }
const _hoisted_27 = {
  key: 5,
  class: "bg-background p-6 rounded-lg mt-4 mb-4"
}
const _hoisted_28 = { class: "grid gap-3 mb-4" }
const _hoisted_29 = { class: "flex items-center gap-2" }
const _hoisted_30 = {
  key: 0,
  class: "grid gap-6 py-4"
}
const _hoisted_31 = { class: "bg-background p-6 rounded-lg w-full inline-block" }
const _hoisted_32 = { class: "text-start" }
const _hoisted_33 = { class: "text-sm text-gray-400" }
const _hoisted_34 = { class: "flex flex-col gap-1" }
const _hoisted_35 = { class: "flex items-center gap-2" }
const _hoisted_36 = {
  key: 0,
  class: "flex items-center gap-2 text-xs m-0"
}
const _hoisted_37 = { class: "text-gray-300" }
const _hoisted_38 = { class: "grid gap-3 py-6" }
const _hoisted_39 = { class: "flex gap-2 items-center" }

import Accordion from '@/components/ui/accordion/Accordion.vue';
import AccordionContent from '@/components/ui/accordion/AccordionContent.vue';
import AccordionItem from '@/components/ui/accordion/AccordionItem.vue';
import AccordionTrigger from '@/components/ui/accordion/AccordionTrigger.vue';
import Checkbox from '@/components/ui/checkbox/Checkbox.vue';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { Textarea } from '@/components/ui/textarea';
import { useAxios } from '@/composables/useAxios';
import { Course } from '@/interfaces/Course';
import { LessonComment } from '@/interfaces/lesson/LessonComment';
import { LessonProgress } from '@/interfaces/lesson/LessonProgress';
import { Lesson } from '@/interfaces/lesson/Lessons';
import {
  AlertTriangle,
  ChevronLeft,
  ChevronRight,
  Download,
  FileDown,
  Loader2,
  MessageCircle,
  NotebookPen,
  TvMinimalPlay,
} from 'lucide-vue-next'
import { computed, onMounted, ref, watch } from 'vue';
import { Pagination } from '@/interfaces/Pagination';
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue';
import { toast, Toaster } from '@/components/ui/toast';
import { Button } from '@/components/ui/button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { LessonContentOriginEnum } from '@/enums/lesson/LessonContentOriginEnum';
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum';
import { CourseCommentsTypeEnum } from '@/enums/course/CourseCommentsTypeEnum';
import LessonProgressBar from './components/LessonProgressBar.vue'
import { useFile } from '@/composables/useFile';
import LessonCommentComponent from './components/LessonComment.vue';
import { useLesson } from '@/composables/useLesson';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShowLessonView',
  props: {
    lessonId: {},
    moduleId: {},
    courseId: {}
  },
  setup(__props: any) {

const axios = useAxios()

const props = __props
const course = ref<Course>()
const lesson = ref<Lesson>()
const comments = ref<Pagination<LessonComment>>()
const createCommentForm = ref({ content: '' })
const editCommentForm = ref({ content: '' })
const isCreatingComment = ref(false)
const noteForm = ref<{ content?: string }>({ content: '' })
const isSavingNote = ref(false)
const commentToEdit = ref<LessonComment>()
const commentToDeleteId = ref<number>()
const isDeleteCommentDialogOpen = ref(false)
const isDeletingComment = ref(false)
const isCommentDialogOpen = ref(false)
const isUpdatingComment = ref(false)
const openModule = computed(() => course.value?.modules.find((m) => props.moduleId == m.id))
const courseBackgroundImage = computed(() => course.value?.media.find((m) => m.type === CourseMediaTypeEnum.BACKGROUND))
const allLessons = computed(() => {
  const allLessons = course.value?.modules.flatMap((module) => module.lessons) ?? []

  return allLessons
})
const lessonsCompleted = computed(() => allLessons.value.filter((l) => !!l.progress?.completedAt))
const courseProgressPercentage = computed(() => {
  return ((lessonsCompleted.value.length / allLessons.value.length) * 100).toFixed(2)
})
const lessonModule = computed(() => {
  return course.value?.modules.find((m) => m.id === lesson.value?.moduleId)
})
const nextLesson = computed(() => {
  const index = allLessons.value.findIndex((l) => l.id == props.lessonId)

  if (allLessons.value[index + 1]) {
    return allLessons.value[index + 1]
  }

  return null
})
const prevLesson = computed(() => {
  const index = allLessons.value.findIndex((l) => l.id == props.lessonId)

  if (allLessons.value[index - 1]) {
    return allLessons.value[index - 1]
  }

  return null
})
const { downloadFile } = useFile()
const { saveLessonNote } = useLesson()

function getCourseById() {
  // isLoadingCourse.value = true

  axios.get(`/courses/${props.courseId}`)
    .then((res) => {
      course.value = res.data.course
      putLessonProgress(props.lessonId, { isCompleted: true })
    })
}

function getLessonById() {
  axios.get(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${props.lessonId}`)
    .then((res) => {
      lesson.value =  res.data
      noteForm.value.content = lesson.value?.note?.content ?? undefined
    })
    // .finally(() => isLoadingLesson.value = false)
}

async function createLessonProgress(lesson: Lesson, data: { isCompleted: boolean, lastWatchedSeconds?: number }) {
  await axios.post(`/courses/${props.courseId}/modules/${lesson.moduleId}/lessons/${lesson.id}/progress`, data)
}

async function updateLessonProgress(lessonProgress: LessonProgress, data: { lastWatchedSeconds?: number; isCompleted?: boolean }) {
  await axios.patch(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${lessonProgress.lessonId}/progress/${lessonProgress.id}`, data)
}

function putLessonProgress(lessonId: number, data: { isCompleted: boolean, lastWatchedSeconds?: number }) {
  for (const module of course.value?.modules ?? []) {
    const lessonFound = module.lessons.find((l) => l.id == lessonId)
    
    if (lessonFound?.progress) {
      lessonFound.progress.completedAt = data.isCompleted ? new Date() : undefined
    }

    if (lessonFound) {
      if (lessonFound.progress) {
        updateLessonProgress(lessonFound.progress, data)
      } else if(!lessonFound.progress) {
        createLessonProgress(lessonFound, data)
      }
    }
  }
}

function getComments(page = 1) {
  axios.get(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${props.lessonId}/comments`, { params: { page } })
    .then((res) => {
      comments.value = res.data
    })
}

function createComment() {
  isCreatingComment.value = true

  axios.post(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${props.lessonId}/comments`, createCommentForm.value)
    .then(() => {
      toast({
        title: 'Comentário cadastrado com sucesso',
        class: 'bg-green-500 text-white',
        duration: 1000
      })
      createCommentForm.value = { content: '' }
      getComments()
    })
    .finally(() => isCreatingComment.value = false)
}

function updateComment(commentId: number) {
  isUpdatingComment.value = true

  axios.patch(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${props.lessonId}/comments/${commentId}`, editCommentForm.value)
    .then(() => {
      toast({
        title: 'Comentário atualizado com sucesso',
        class: 'bg-green-500 text-white',
        duration: 1000,
      })

      const commentIndex = comments.value?.data.findIndex((comment) => comment.id === commentId)

      if (commentIndex) {
        comments.value!.data[commentIndex].content = editCommentForm.value.content!
      }

      isCommentDialogOpen.value = false
    })
    .finally(() => isUpdatingComment.value = false)
}

function deleteComment(commentId: number) {
  axios.delete(`/courses/${props.courseId}/modules/${props.moduleId}/lessons/${props.lessonId}/comments/${commentId}`)
    .then(() => {
      toast({
        title: 'Comentário excluído com sucesso',
        class: 'bg-green-500 text-white',
        duration: 1000,
      })
      isDeleteCommentDialogOpen.value = false
      comments.value!.data = comments.value!.data.filter((comment) => comment.id !== commentId)
    })
}
 
function submitSaveLessonNotes() {
  isSavingNote.value = true; 
  saveLessonNote(
    lesson.value!.courseId, lesson.value!.moduleId, lesson.value!.id, noteForm.value.content!, 
    () => {
      isSavingNote.value = false
      toast({
        title: 'Anotação salva com sucesso',
        class: 'bg-green-500 text-white',
        duration: 1000,
      })
    }
  )
}

onMounted(() => {
  getCourseById()
  getLessonById()
  getComments()
})

watch(() => props.lessonId, () => {
  lesson.value = undefined
  getCourseById()
  getLessonById()
  getComments()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Label = _resolveComponent("Label")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!!lesson.value && !!course.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (courseBackgroundImage.value?.bucketLocation)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: courseBackgroundImage.value.bucketLocation,
                class: "object-cover w-full max-w-full h-80 absolute left-0"
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["col-span-12 xl:col-span-8 z-10 xl:pl-10 mt-7 text-white", { 'mt-[15rem]': courseBackgroundImage.value?.bucketLocation }])
          }, [
            (lesson.value.content.origin === _unref(LessonContentOriginEnum).HOOPAY)
              ? (_openBlock(), _createElementBlock("iframe", {
                  key: 0,
                  class: "w-full h-[330px] md:h-[615px] border-0",
                  src: lesson.value.content.url,
                  title: "HooPay video player",
                  frameborder: "0",
                  referrerpolicy: "strict-origin-when-cross-origin",
                  allowfullscreen: ""
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (lesson.value.content.origin === _unref(LessonContentOriginEnum).YOUTUBE)
              ? (_openBlock(), _createElementBlock("iframe", {
                  key: 1,
                  class: "w-full h-[330px] md:h-[615px] border-0",
                  src: lesson.value.content.url,
                  title: "YouTube video player",
                  frameborder: "0",
                  referrerpolicy: "strict-origin-when-cross-origin",
                  allowfullscreen: ""
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            (lesson.value.content.origin === _unref(LessonContentOriginEnum).VIMEO)
              ? (_openBlock(), _createElementBlock("iframe", {
                  key: 2,
                  class: "w-full h-[330px] md:h-[615px] border-0",
                  src: lesson.value.content.url,
                  frameborder: "0",
                  allow: "autoplay; encrypted-media",
                  allowfullscreen: ""
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            (lesson.value.content.origin === _unref(LessonContentOriginEnum).PANDA_VIDEO)
              ? (_openBlock(), _createElementBlock("iframe", {
                  key: 3,
                  id: 'panda-' + lesson.value.id,
                  class: "w-full h-[330px] md:h-[615px] border-0",
                  src: lesson.value.content.url
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true),
            (lesson.value.content.origin === _unref(LessonContentOriginEnum).EPLAY)
              ? (_openBlock(), _createElementBlock("iframe", {
                  key: 4,
                  class: "w-full h-[330px] md:h-[615px] border-0",
                  src: lesson.value.content.url,
                  frameborder: "0",
                  allow: "autoplay; encrypted-media",
                  allowfullscreen: ""
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", null, [
                  (lessonModule.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(lessonModule.value.title), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("h1", _hoisted_11, _toDisplayString(lesson.value.title), 1),
                  (lesson.value.content?.duration)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
                        (lesson.value.content.duration?.hours)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(lesson.value.content.duration.hours) + "h ", 1)
                            ], 64))
                          : _createCommentVNode("", true),
                        (lesson.value.content.duration?.minutes)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(lesson.value.content.duration.minutes) + "m ", 1)
                            ], 64))
                          : _createCommentVNode("", true),
                        (lesson.value.content.duration?.seconds)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              _createTextVNode(_toDisplayString(lesson.value.content.duration.seconds) + "s", 1)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("button", {
                    disabled: !prevLesson.value,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'lessons.show', params: { lessonId: prevLesson.value!.id, moduleId: prevLesson.value!.moduleId, courseId: prevLesson.value!.courseId  } }))),
                    class: "bg-muted p-2 rounded-tl-2xl rounded-bl-2xl hover:text-primary transition-color duration-200 disabled:opacity-40"
                  }, [
                    _createVNode(_unref(ChevronLeft))
                  ], 8, _hoisted_14),
                  _createElementVNode("button", {
                    disabled: !nextLesson.value,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'lessons.show', params: { lessonId: nextLesson.value!.id, moduleId: nextLesson.value!.moduleId, courseId: nextLesson.value!.courseId  } }))),
                    class: "bg-muted p-2 rounded-tr-2xl rounded-br-2xl hover:text-primary transition-color duration-200 disabled:opacity-40"
                  }, [
                    _createVNode(_unref(ChevronRight))
                  ], 8, _hoisted_15)
                ])
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("hr", { class: "my-2" }, null, -1)),
              _createElementVNode("div", _hoisted_16, [
                _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "text-lg font-medium" }, "Descrição", -1)),
                _createElementVNode("p", _hoisted_17, _toDisplayString(lesson.value.description), 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lesson.value.attachments, (attachment) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_unref(FileDown))
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("p", null, _toDisplayString(attachment.title), 1),
                        _createElementVNode("button", {
                          onClick: ($event: any) => (_unref(downloadFile)(attachment.bucketLocation, attachment.title))
                        }, [
                          _createVNode(_unref(Download))
                        ], 8, _hoisted_23)
                      ])
                    ])
                  ]))
                }), 256))
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_unref(NotebookPen)),
                  _cache[13] || (_cache[13] = _createElementVNode("h5", null, "Anotações", -1))
                ]),
                _createVNode(_unref(Textarea), {
                  placeholder: "ex: Deixe um comentário ou um pensamento rápido...",
                  modelValue: noteForm.value.content,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((noteForm.value.content) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_unref(Button), {
                  type: "button",
                  onClick: submitSaveLessonNotes,
                  disabled: (isSavingNote.value || !noteForm.value.content) || (noteForm.value.content === lesson.value.note?.content && lesson.value.note?.content)
                }, {
                  default: _withCtx(() => [
                    (isSavingNote.value)
                      ? (_openBlock(), _createBlock(_unref(Loader2), {
                          key: 0,
                          class: "w-4 h-4 mr-2 animate-spin"
                        }))
                      : _createCommentVNode("", true),
                    _cache[14] || (_cache[14] = _createTextVNode(" Salvar "))
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ])
            ]),
            (course.value.commentsType !== _unref(CourseCommentsTypeEnum).INACTIVE)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_unref(MessageCircle)),
                      _cache[15] || (_cache[15] = _createElementVNode("h5", null, "Adicionar comentário", -1))
                    ]),
                    _createVNode(_unref(Textarea), {
                      placeholder: "ex: Tenho uma dúvida...",
                      modelValue: createCommentForm.value.content,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((createCommentForm.value.content) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_unref(Button), {
                      type: "button",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (createComment())),
                      disabled: isCreatingComment.value || !createCommentForm.value.content
                    }, {
                      default: _withCtx(() => [
                        (isCreatingComment.value)
                          ? (_openBlock(), _createBlock(_unref(Loader2), {
                              key: 0,
                              class: "w-4 h-4 mr-2 animate-spin"
                            }))
                          : _createCommentVNode("", true),
                        _cache[16] || (_cache[16] = _createTextVNode(" Adicionar "))
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  (comments.value?.data.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(comments.value.data, (comment) => {
                          return (_openBlock(), _createBlock(LessonCommentComponent, {
                            comment: comment,
                            onEdit: ($event: any) => {
            commentToEdit.value = comment;
            isCommentDialogOpen.value = true;
            editCommentForm.value = { content: comment.content };
          },
                            onDelete: ($event: any) => {
            commentToDeleteId.value = comment.id;
            isDeleteCommentDialogOpen.value = true;
          }
                          }, null, 8, ["comment", "onEdit", "onDelete"]))
                        }), 256)),
                        _createVNode(PaginationApi, {
                          data: comments.value.pagination,
                          paginate: getComments
                        }, null, 8, ["data"])
                      ]))
                    : (!comments.value)
                      ? (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["col-span-12 xl:col-span-4 sm:p-4 xl:pr-10 z-10 mt-4", { 'mt-[14rem]': courseBackgroundImage.value?.bucketLocation }])
          }, [
            _createElementVNode("div", _hoisted_31, [
              (courseProgressPercentage.value)
                ? (_openBlock(), _createBlock(LessonProgressBar, {
                    key: 0,
                    lessonsLength: allLessons.value.length,
                    lessonsCompletedLength: lessonsCompleted.value.length,
                    percentage: Number(courseProgressPercentage.value)
                  }, null, 8, ["lessonsLength", "lessonsCompletedLength", "percentage"]))
                : _createCommentVNode("", true),
              _createVNode(Accordion, {
                type: "single",
                collapsible: "",
                class: "",
                "default-value": String(openModule.value!.id)
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(course.value.modules, (module) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: module.id
                    }, [
                      (!!module.lessons.length)
                        ? (_openBlock(), _createBlock(AccordionItem, {
                            key: 0,
                            value: String(module.id)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(AccordionTrigger, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_32, [
                                    _createElementVNode("h6", null, _toDisplayString(module.title), 1),
                                    _createElementVNode("p", _hoisted_33, _toDisplayString(module.description), 1)
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(AccordionContent, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_34, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(module.lessons, (lesson) => {
                                      return (_openBlock(), _createBlock(_component_RouterLink, {
                                        to: { name: 'lessons.show', params: { lessonId: lesson.id, moduleId: lesson.moduleId, courseId: lesson.courseId } },
                                        class: _normalizeClass(["flex items-center gap-4 hover:bg-muted transition-background duration-200 p-4 py-6 rounded-sm", { 'bg-muted': lesson.id == _ctx.lessonId }]),
                                        key: lesson.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", {
                                            onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
                                          }, [
                                            _createVNode(Checkbox, {
                                              id: "terms",
                                              "default-checked": !!lesson.progress?.completedAt,
                                              "onUpdate:checked": (checked) => putLessonProgress(lesson.id, { isCompleted: checked })
                                            }, null, 8, ["default-checked", "onUpdate:checked"])
                                          ]),
                                          _createElementVNode("div", null, [
                                            _createElementVNode("div", _hoisted_35, [
                                              _createElementVNode("h3", null, _toDisplayString(lesson.title), 1)
                                            ]),
                                            (lesson.content?.duration)
                                              ? (_openBlock(), _createElementBlock("p", _hoisted_36, [
                                                  _createVNode(_unref(TvMinimalPlay), { class: "h-4 w-4" }),
                                                  _createElementVNode("span", _hoisted_37, [
                                                    (lesson.content.duration?.hours)
                                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                          _createTextVNode(_toDisplayString(lesson.content.duration.hours) + "h ", 1)
                                                        ], 64))
                                                      : _createCommentVNode("", true),
                                                    (lesson.content.duration?.minutes)
                                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                          _createTextVNode(_toDisplayString(lesson.content.duration.minutes) + "m ", 1)
                                                        ], 64))
                                                      : _createCommentVNode("", true),
                                                    (lesson.content.duration?.seconds)
                                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                                          _createTextVNode(_toDisplayString(lesson.content.duration.seconds) + "s", 1)
                                                        ], 64))
                                                      : _createCommentVNode("", true)
                                                  ])
                                                ]))
                                              : _createCommentVNode("", true)
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["to", "class"]))
                                    }), 128))
                                  ])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]),
                _: 1
              }, 8, ["default-value"])
            ])
          ], 2),
          _createVNode(_unref(Dialog), {
            open: isCommentDialogOpen.value,
            "onUpdate:open": _cache[8] || (_cache[8] = (open) => isCommentDialogOpen.value = open)
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DialogHeader), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DialogTitle), { class: "text-[1.1rem]" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(commentToEdit.value ? 'Editar' : 'Adicionar') + " comentário", 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(DialogDescription), { class: "" }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createTextVNode(" Compartilhe sua opinião sobre a aula ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_38, [
                    _createVNode(_component_Label, { for: "content" }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode("Comentário")
                      ])),
                      _: 1
                    }),
                    _createVNode(_unref(Textarea), {
                      placeholder: "ex: Os detalhes que foram ensinados nesta aula são importantes...",
                      class: "min-h-32",
                      id: "content",
                      modelValue: editCommentForm.value.content,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((editCommentForm.value.content) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DialogClose), { "as-child": "" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Button), {
                            type: "button",
                            variant: "secondary"
                          }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode(" Cancelar ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(Button), {
                        type: "button",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (updateComment(commentToEdit.value!.id))),
                        disabled: isUpdatingComment.value || !editCommentForm.value.content
                      }, {
                        default: _withCtx(() => [
                          (isUpdatingComment.value)
                            ? (_openBlock(), _createBlock(_unref(Loader2), {
                                key: 0,
                                class: "w-4 h-4 mr-2 animate-spin"
                              }))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(commentToEdit.value ? 'Atualizar' : 'Adicionar'), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["open"]),
          (commentToDeleteId.value)
            ? (_openBlock(), _createBlock(_unref(Dialog), {
                key: 1,
                open: isDeleteCommentDialogOpen.value,
                "onUpdate:open": _cache[10] || (_cache[10] = (open) => isDeleteCommentDialogOpen.value = open)
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DialogHeader), null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_39, [
                            _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                            _createVNode(_unref(DialogTitle), { class: "text-[1.1rem]" }, {
                              default: _withCtx(() => _cache[20] || (_cache[20] = [
                                _createTextVNode("Excluir Comentário")
                              ])),
                              _: 1
                            })
                          ]),
                          _createVNode(_unref(DialogDescription), { class: "py-2" }, {
                            default: _withCtx(() => _cache[21] || (_cache[21] = [
                              _createTextVNode(" Essa ação é permanente e não poderá ser desfeita. O comentário será removido imediatamente. ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _cache[24] || (_cache[24] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
                      _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(DialogClose), { "as-child": "" }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Button), {
                                type: "button",
                                variant: "secondary"
                              }, {
                                default: _withCtx(() => _cache[22] || (_cache[22] = [
                                  _createTextVNode(" Cancelar ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(Button), {
                            type: "button",
                            disabled: isDeletingComment.value,
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (deleteComment(commentToDeleteId.value)))
                          }, {
                            default: _withCtx(() => [
                              (isDeletingComment.value)
                                ? (_openBlock(), _createBlock(_unref(Loader2), {
                                    key: 0,
                                    class: "w-4 h-4 mr-2 animate-spin"
                                  }))
                                : _createCommentVNode("", true),
                              _cache[23] || (_cache[23] = _createTextVNode(" Excluir Comentário "))
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["open"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
    _createVNode(_unref(Toaster))
  ], 64))
}
}

})