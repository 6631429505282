<template>
  <!-- Category Navigation -->
  <nav class="border-b border-gray-800 sticky top-0 z-10 backdrop-blur">
    <div class="container mx-auto px-4">
      <div 
        ref="carousel"
        class="flex gap-8 overflow-x-auto py-4 scrollbar-hide cursor-grab active:cursor-grabbing"
        @mousedown="startDrag"
        @mousemove="onDrag"
        @mouseup="endDrag"
        @mouseleave="endDrag"
        @touchstart="startDrag"
        @touchmove="onDrag"
        @touchend="endDrag"
      >
      <button 
        :class="[
          'whitespace-nowrap px-4 py-2 rounded-full transition-colors text-sm sm:text-md',
          !modelValue
            ? 'bg-purple-600 text-white' 
            : 'text-gray-400 hover:text-white'
        ]"
        @click="$emit('update:modelValue', undefined)"
      >
        Todos
      </button>
        <button 
          v-for="category in CourseCategoryEnum" 
          :key="category"
          :class="[
            'whitespace-nowrap px-4 py-2 rounded-full transition-colors text-sm sm:text-md',
            modelValue === category
              ? 'bg-purple-600 text-white' 
              : 'text-gray-400 hover:text-white'
          ]"
          @click="$emit('update:modelValue', category)"
        >
          {{ CourseCategoryLabels[category] }}
        </button>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits } from 'vue';
import { CourseCategoryEnum, CourseCategoryLabels } from '@/enums/course/CourseCategoryEnum';

defineProps<{ modelValue?: string }>();
defineEmits(['update:modelValue']);

const carousel = ref<HTMLElement | null>(null);
let isDragging = false;
let startX: number;
let scrollLeft: number;

const startDrag = (e: MouseEvent | TouchEvent) => {
  isDragging = true;
  if (carousel.value) {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    startX = clientX - carousel.value.offsetLeft;
    scrollLeft = carousel.value.scrollLeft;
    carousel.value.style.scrollBehavior = 'auto';
  }
};

const onDrag = (e: MouseEvent | TouchEvent) => {
  if (!isDragging) return;
  e.preventDefault();
  if (carousel.value) {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const x = clientX - carousel.value.offsetLeft;
    const walk = (x - startX) * 2; // Scroll-fast
    carousel.value.scrollLeft = scrollLeft - walk;
  }
};

const endDrag = () => {
  isDragging = false;
  if (carousel.value) {
    carousel.value.style.scrollBehavior = 'smooth';
  }
};
</script>

<style scoped>
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
</style>